import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// Components
import { notification, Modal } from "antd";
import PortfolioBanner from "../components/PortfolioBanner";
import PortfolioPageHeader from "../components/PortfolioPageHeader";
import ProjectsBannerSkeleton from "../components/ProjectsBannerSkeleton";
import { PortfolioPageContainer } from "../components/PortfolioPageContainer";
import NewConstructionModal from "../components/NewConstructionModal";
import EditConstructionModal from "../components/EditConstructionModal";
import Loading from "../../shared/components/Loading";

// Services
import { getConstructions } from "../services";

const PortfolioPage = () => {
  // Component states
  const [constructions, setConstructions] = useState([]);
  const [trigger, setTrigger] = useState(true);

  // Modal controls
  const [constructionModalOpen, setConstructionModalOpen] = useState(false);
  const [isCreationModal, setIsCreationModal] = useState(true);
  const [constructionId, setConstructionId] = useState(0);

  // Page controls
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);

  const history = useHistory();

  // Get entities handlers
  const errorHandler = (type = "server", message = "") => {
    switch (type) {
      case "server":
        notification.error({
          message: "Error de servidor. Inténtelo de nuevo",
        });
        break;
      case "bad_request":
        notification.error({ message: message });
        break;
      default:
        break;
    }
  };

  const getConstructionsSuccessHandler = (constructions) => {
    setConstructions(constructions);
  };

  useEffect(() => {
    const fetchPortfolio = async () => {
      setLoading(true);
      await getConstructions(
        { search: search },
        errorHandler,
        getConstructionsSuccessHandler
      );
      setLoading(false);
    };
    fetchPortfolio();
  }, [search, trigger]);

  // Handlers

  const reloadPortfolio = () => {
    setTrigger(!trigger);
  };

  const openNewConstructionModal = () => {
    setConstructionModalOpen(true);
    setIsCreationModal(true);
  };

  const openEditConstructionModal = (id) => {
    setConstructionId(id);
    setConstructionModalOpen(true);
    setIsCreationModal(false);
  };

  return (
    <PortfolioPageContainer>
      <br />
      <PortfolioPageHeader
        search={search}
        setSearch={setSearch}
        openNewConstructionModal={openNewConstructionModal}
      />
      {loading ? (
        <ProjectsBannerSkeleton />
      ) : (
        <PortfolioBanner
          constructions={constructions}
          reloadConstructions={reloadPortfolio}
          openNewConstructionModal={openNewConstructionModal}
          openEditConstructionModal={openEditConstructionModal}
        />
      )}
      <Modal
        title={isCreationModal ? "Nueva Obra" : "Editar Obra"}
        centered
        open={constructionModalOpen}
        onCancel={() => setConstructionModalOpen(false)}
        footer={null}
      >
        {isCreationModal ? (
          <NewConstructionModal
            closeModal={() => {
              setConstructionModalOpen(false);
            }}
            setModalLoading={setModalLoading}
            reloadPortfolio={reloadPortfolio}
          />
        ) : (
          <EditConstructionModal
            constructionId={constructionId}
            closeModal={() => {
              setConstructionModalOpen(false);
            }}
            setModalLoading={setModalLoading}
            reloadPortfolio={reloadPortfolio}
          />
        )}
        <Loading active={modalLoading} />
      </Modal>
    </PortfolioPageContainer>
  );
};

export default PortfolioPage;
