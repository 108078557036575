// React import
import React, { useState, useEffect } from "react";

// Third part libraries import
import { notification } from "antd";

// Components import
import ApprovalPanel from "../ApprovalPanel";

// Services import
import { getStartProjectDelivery } from "../../../../providers";
import { approveStartProjectDelivery } from "../../services";

const StartDeliveryModal = ({
  id,
  userRole,
  projectDeliverySummary,
  reloadProjectDelivery,
  setLoading,
}) => {
  const [startDelivery, setStartDelivery] = useState({
    super_approved_start: false,
    exec_approved_start: false,
  });
  const [triggerStartDelivery, setTriggerStartDelivery] = useState(true);

  useEffect(() => {
    const fetchProjectDeliveryStart = async () => {
      try {
        setLoading(true);
        const response = await getStartProjectDelivery(id);
        setStartDelivery(response);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchProjectDeliveryStart();
  }, [id, triggerStartDelivery]);

  // Handler approbation
  const approveSuccessHandler = (response) => {
    notification.success({
      message: Object.values(response)[0][0],
    });
    setLoading(false);
    reloadProjectDelivery();
    setTriggerStartDelivery(!triggerStartDelivery);
  };

  const approveErrorHandler = (response) => {
    notification.error({
      message: response
        ? Object.values(response)[0][0]
        : "Ocurrió un error al aprobar el inicio de entrega de obra",
    });
    setLoading(false);
  };

  const approveHandler = async () => {
    setLoading(true);
    await approveStartProjectDelivery(
      id,
      approveErrorHandler,
      approveSuccessHandler
    );
  };

  return (
    <>
      <p
        className="blue bold font14"
        style={{
          display: "flex",
          marginBottom: "16px",
          justifyContent: "center",
        }}
      >
        ¿QUIERE INICIAR EL PROCESO DE ENTREGA DE OBRA?
      </p>
      <div>
        <ApprovalPanel
          label="Iniciar"
          approvalEnable={projectDeliverySummary.start_delivery_enabled}
          approveHandler={() => {
            approveHandler();
          }}
          userRole={userRole}
          superApproved={startDelivery.super_approved_start}
          execApproved={startDelivery.exec_approved_start}
        />
      </div>
    </>
  );
};

export default StartDeliveryModal;
