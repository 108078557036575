import styled from "styled-components";

export const CounterContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 16px;
`;

export const CounterTitle = styled.p`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #4a4a4a;
  margin-bottom: 10px;
`;

export const CounterSubtitle = styled.p`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #014083;
  margin-bottom: 7px;
`;
