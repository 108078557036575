import { baseProvider } from "../baseProvider";

const getPredeductiveReport = async (id) =>
  baseProvider.httpGetFile(`/predeductives/${id}/report`);

const getPredeductiveValidatedReport = async (id) =>
  baseProvider.httpGetFile(`/predeductives/${id}/validated_report`);

const getDeductiveValorizationsSummary = async (id) =>
  baseProvider.httpGet(`/deductives/${id}/valorizations/summary`);

const sendPresentDeductiveValorization = async (id) =>
  baseProvider.httpPost(`/deductives/${id}/present_valorization/send`);

const evaluatePresentDeductiveValorization = async (id) =>
  baseProvider.httpPost(`/deductives/${id}/present_valorization/evaluate`);

const getPresentDeductiveValorizationReport = async (id) =>
  baseProvider.httpGetFile(`/deductives/${id}/present_valorization/report`);

const signPresentDeductiveValorization = async (id) =>
  baseProvider.httpPost(`/deductives/${id}/present_valorization/sign`);

export {
  getPredeductiveReport,
  getPredeductiveValidatedReport,
  getDeductiveValorizationsSummary,
  sendPresentDeductiveValorization,
  evaluatePresentDeductiveValorization,
  getPresentDeductiveValorizationReport,
  signPresentDeductiveValorization,
};
