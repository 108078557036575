import React from "react";
import { IconContext } from "react-icons";
import { FiSend } from "react-icons/fi";
import { MdAddAPhoto, MdComment } from "react-icons/md";
import { FaCheck, FaTimes } from "react-icons/fa";

import { parseFloatNumber, convertPartNumber } from "../../../../../../utils";

import profile from "../../../../assets/profile.jpg";

export const PrePart = ({
  part,
  group_id,
  color_class_name,
  tab_class,
  parts_children,
  filter_handler,
  user,
  setisVisibleImageModal,
  setId,
  fileGroup,
}) => {
  return (
    <React.Fragment>
      <div
        id={fileGroup + "_PART_I_" + group_id}
        className="valorization__partHeaders"
      >
        <div className={"parts__index" + color_class_name}>
          <p className="parts__text">{part.part_tag}</p>
        </div>
        <div className={"parts__contract-div " + color_class_name}>
          <button
            hidden={!part.has_children}
            id={fileGroup + "_CB_PART_GP_" + group_id}
            className="parts__contract-button"
            onClick={() => filter_handler(group_id, fileGroup)}
          >
            -
          </button>
        </div>
        <div className={"parts__name-div" + color_class_name + tab_class}>
          <span title={part.name} className="parts__name-text">
            {part.name}
          </span>
        </div>
        <div className="parts__content" style={{ width: "72px" }}>
          <p hidden={part.has_children} className="parts__text">
            {parseFloatNumber(part.quantity)}
          </p>
        </div>
        <div className="parts__content" style={{ width: "49px" }}>
          <p className="parts__text">{part.unity}</p>
        </div>
        <div className="parts__content" style={{ width: "72px" }}>
          <p hidden={part.has_children} className="parts__text">
            {parseFloatNumber(part.unityprice)}
          </p>
        </div>
        <div
          className="parts__content"
          style={{ width: "73px", borderRight: "solid 1px #f3f3f3" }}
        >
          <p className="parts__text">{parseFloatNumber(part.ppartial)}</p>
        </div>
        <div className="parts__content" style={{ width: "46px" }}>
          <input type="checkbox" className="project_part_check" />
        </div>
        <div className="parts__content" style={{ width: "72px" }}>
          <p className="parts__text">{part.has_children ? "-" : "0.00"}</p>
        </div>
        <div className="parts__content" style={{ width: "46px" }}>
          <img
            hidden={part.has_children}
            alt="profile_val_part"
            src={profile}
            className="valorization_part_profile_img"
          />
        </div>
        <div className="parts__content" style={{ width: "82px" }}>
          <p className="parts__text">0.00</p>
        </div>
        <div
          className="parts__content"
          style={{ width: "65px", fontSize: "12px" }}
        >
          <p className="parts__text">0.00 %</p>
        </div>
        <div
          hidden={user === "Supervisor"}
          className="parts__content"
          style={{ width: "35px" }}
        >
          <IconContext.Provider
            value={{
              color: "#808080",
            }}
          >
            <FiSend
              size={18}
              hidden={part.has_children}
              className="navbar__menu-icon"
              style={{ marginLeft: "8px" }}
            />
          </IconContext.Provider>
        </div>
        <div
          hidden={user === "Ejecutor"}
          className="parts__content"
          style={{ width: "35px" }}
        >
          <IconContext.Provider
            value={{
              color: "#808080",
            }}
          >
            <FaCheck
              hidden={part.has_children}
              size={18}
              className="navbar__menu-icon"
            />
          </IconContext.Provider>
        </div>
        <div
          hidden={user === "Ejecutor"}
          className="parts__content"
          style={{ width: "35px" }}
        >
          <IconContext.Provider
            value={{
              color: "#808080",
            }}
          >
            <FaTimes
              hidden={part.has_children}
              size={18}
              className="parts__content"
            />
          </IconContext.Provider>
        </div>
        <div className="parts__content" style={{ width: "46px" }}>
          <IconContext.Provider
            value={{
              color: "#808080",
            }}
          >
            <MdAddAPhoto
              hidden={part.has_children}
              size={18}
              className="navbar__menu-icon parts__pointer"
              onClick={() => {
                setId(part.id);
                setisVisibleImageModal(true);
              }}
            />
          </IconContext.Provider>
        </div>
        <div className="parts__content" style={{ width: "46px" }}>
          <IconContext.Provider
            value={{
              color: "#808080",
            }}
          >
            <MdComment
              hidden={part.has_children}
              size={18}
              className="navbar__menu-icon"
              style={{ marginTop: "12px" }}
            />
          </IconContext.Provider>
        </div>
        <div className="parts__content" style={{ width: "46px" }}>
          <p></p>
        </div>
        <div
          className="parts__content"
          style={{ width: "100px", borderRight: "solid 2px #f3f3f3" }}
        >
          <p
            hidden={part.has_children}
            className="parts__text"
            style={{ fontWeight: "bold !important", color: "black" }}
          >
            No comenzado
          </p>
        </div>
        <div className="parts__content" style={{ width: "72px" }}>
          <p className="parts__text">{part.has_children ? "-" : "0.00"}</p>
        </div>
        <div className="parts__content" style={{ width: "82px" }}>
          <p className="parts__text">0.00</p>
        </div>
        <div
          className="parts__content"
          style={{ width: "65px", borderRight: "solid 2px #f3f3f3" }}
        >
          <p className="parts__text">0.00 %</p>
        </div>
        <div className="parts__content" style={{ width: "72px" }}>
          <p className="parts__text">{part.has_children ? "-" : "0.00"}</p>
        </div>
        <div className="parts__content" style={{ width: "82px" }}>
          <p className="parts__text">0.00</p>
        </div>
        <div
          className="parts__content"
          style={{ width: "65px", borderRight: "solid 2px #f3f3f3" }}
        >
          <p className="parts__text">0.00 %</p>
        </div>
        <div className="parts__content" style={{ width: "72px" }}>
          <p className="parts__text">{part.has_children ? "-" : "0.00"}</p>
        </div>
        <div className="parts__content" style={{ width: "82px" }}>
          <p className="parts__text">0.00</p>
        </div>
        <div className="parts__content" style={{ width: "65px" }}>
          <p className="parts__text">0.00 %</p>
        </div>
      </div>
      <div id={fileGroup + "_PART_GP_" + group_id}>{parts_children}</div>
    </React.Fragment>
  );
};
