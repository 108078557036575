import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

//pages
import PortfolioPage from "../pages/PortfolioPage";
import ConstructionPage from "../pages/ConstructionPage";

const HomeRouter = () => {
  return (
    <Switch>
      <Route exact path={"/main"} component={PortfolioPage} />
      <Route exact path="/constructions/:id" component={ConstructionPage} />
      <Redirect to="/main" />
    </Switch>
  );
};

export default HomeRouter;
