import React, { useState, useEffect } from "react";

// Ant imports
import { notification } from "antd";

// Sections & submodules imports
import { Qrome } from "./Qrome";
import Qrome2 from "./Qrome2";

// Components import
import DeductiveSetting from "./DeductiveSetting";
import Loading from "../../../shared/components/Loading";
import VisorModal from "./Valorizations/components/VisorModal";

// Sections
import { Valorizations } from "./Valorizations";
import { PartProposals } from "./PartProposals";

// Context
import { useAppContext } from "../../../App/context";

// Services import
import { getPreDeductive, getProject } from "../../services";
import { getPredeductiveValidatedReport } from "../../../../providers";

import "./index.css";

const PredeductiveValorizationBanner = ({ history, match }) => {
  const { id } = match.params;

  // Context import
  const {
    projectRole,
    setProjectRole,
    setIsProjectAdmin,
    setProjectName,
    setProjectEnterprise,
  } = useAppContext();

  //* Component states
  const [predeductive, setPredeductive] = useState({ order: "X" });
  const [predeductiveForm, setPredeductiveForm] = useState({ order: "X" });
  const [filterActive, setFilterActive] = useState(false);
  // project
  const [project, setProject] = useState({ image: null });
  // trigger
  const [trigger, setTrigger] = useState(true);
  // loading
  const [isLoading, setIsLoading] = useState(false);
  // Visor modal
  const [activeVisorModalReport, setActiveVisorModalReport] = useState(false);
  const [predeductiveReport, setPredeductiveReport] = useState("");

  // State settings
  useEffect(() => {
    const fetchPredeductivePage = async () => {
      setIsLoading(true);
      await getPreDeductive(id, errorHandler, getPredeductiveSuccessHandler);
      setIsLoading(false);
    };

    fetchPredeductivePage();
  }, [trigger]);

  useEffect(() => {
    const fetchProject = async () => {
      setIsLoading(true);
      await getProject(
        predeductive.project,
        errorHandler,
        getProjectSuccessHandler
      );
      setIsLoading(false);
    };

    predeductive?.project && fetchProject();
  }, [predeductive]);

  // get predeductive handlers
  const errorHandler = (type = "server", message = "") => {
    switch (type) {
      case "server":
      case "no_found":
        history.push("/main");
        break;
      default:
        break;
    }
  };

  const getPredeductiveSuccessHandler = (predeductive) => {
    setPredeductive(predeductive);
    setPredeductiveForm(predeductive);
  };

  // get project handlers
  const getProjectSuccessHandler = (project) => {
    setProject(project);
    setProjectRole(
      project.role === "SUPERVISOR_LEADER" || project.role === "SUPERVISOR"
        ? "Supervisor"
        : "Ejecutor"
    );
    setIsProjectAdmin(project.role === "ADMIN");
    setProjectName(project.name);
    setProjectEnterprise(project.enterprise);
  };

  const reloadPredeductivePage = () => {
    setTrigger(!trigger);
  };

  const getPredeductiveEditionStatus = (predeductive) => {
    let enableEdition = false;
    if (
      predeductive.status === "REJECTED" ||
      predeductive.status === "VALIDATED"
    ) {
      enableEdition = false;
    } else {
      if (
        project.role === "EXECUTOR_LEADER" &&
        predeductive.approver_role === "SUPERVISOR"
      ) {
        enableEdition = true;
      }
      if (
        project.role === "SUPERVISOR_LEADER" &&
        predeductive.approver_role === "EXECUTOR"
      ) {
        enableEdition = true;
      }
      if (project.role === "ADMIN") {
        enableEdition = true;
      }
    }
    return enableEdition;
  };

  // Load predeductive report
  const blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const loadPredeductiveReport = async () => {
    try {
      setIsLoading(true);
      const response = await getPredeductiveValidatedReport(predeductive.id);
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        const json = await response.json();
        if (json.detail) {
          notification.success({
            message: json.detail,
          });
          setIsLoading(false);
          setTimeout(() => history.go(0), 3000);
        } else {
          notification.error({
            message: "Ocurrió un error interno. Por favor vuelva a intentarlo",
          });
          setIsLoading(false);
        }
      } else {
        const blobFile = await response.blob();
        const base64File = await blobToBase64(blobFile);
        setPredeductiveReport(base64File);
        setIsLoading(false);
        setActiveVisorModalReport(true);
      }
    } catch (error) {
      notification.error({
        message: "Ocurrió un error interno. Por favor vuelva a intentarlo",
      });
      setIsLoading(false);
    }
  };

  return (
    <div>
      <VisorModal
        active={activeVisorModalReport}
        seeReport={predeductiveReport}
        setActiveModal={setActiveVisorModalReport}
      />
      <Loading active={isLoading} />
      <Qrome
        user={projectRole}
        project={project}
        presentValorization={""}
        lastValorization={""}
        currentValorization={{
          to_be_closed: false,
          debt: null,
        }}
        general_aproved={project.general_aproved}
        predeductive={predeductive}
        loadPredeductiveReport={loadPredeductiveReport}
        sendpresentValorization={() => {}}
        evaluatepresentValorization={() => {}}
        loadFinalReportValorization={() => {}}
        setDebtModalActive={() => {}}
        setAmountsDatesModalActive={() => {}}
      />
      <Qrome2 />
      {predeductive.type === "NEW_PARTS" ? (
        <Valorizations
          id={id}
          role={projectRole}
          project={project}
          general_aproved={project.general_aproved}
          valorizations={[]}
          predeductive={predeductive}
          setPredeductive={setPredeductive}
          setCurrentValorization={() => {}}
          currentValorization={{
            to_be_closed: false,
            debt: null,
          }}
          setIsLoading={setIsLoading}
          reloadPredeductivePage={reloadPredeductivePage}
        />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            overflowX: "auto",
            flex: "1",
            background: "white",
            justifyContent: "space-between",
          }}
        >
          <PartProposals
            role={projectRole}
            predeductive={predeductive}
            valorizations={[]}
            currentValorization={{
              to_be_closed: false,
              debt: null,
            }}
            filterActive={filterActive}
            enableEdition={() => getPredeductiveEditionStatus(predeductive)}
            setIsLoading={setIsLoading}
            reloadPredeductivePage={reloadPredeductivePage}
          />
          <DeductiveSetting
            predeductive={predeductiveForm}
            setPredeductive={setPredeductiveForm}
            setIsLoading={setIsLoading}
            setFilterActive={setFilterActive}
            filterActive={filterActive}
            enableEdition={() => getPredeductiveEditionStatus(predeductive)}
          />
        </div>
      )}
    </div>
  );
};

export default PredeductiveValorizationBanner;
