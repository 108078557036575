import React from "react";

import { TextPanel } from "./styles";
import { Button } from "../../../../../components/Button";

const Qrome2 = () => {
  return (
    <div className="valorization__partHeaders-base">
      <Button
        width="152px"
        height="28px"
        background="#0169E4"
        boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
        borderRadius="4px 4px 0px 0px"
        margin="0px 1px"
        fontSize="12px"
      >
        Presupuesto
      </Button>
      <TextPanel />
    </div>
  );
};

export default Qrome2;
