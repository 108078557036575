import React from "react";
import Item from "./Item";
import FileList from "./FileList";
import { parseFloatNumber } from "../../../utils";
import { Input, DatePicker } from "antd";
import { clientDateFormat, serverDateFormat } from "../../../utils";
import moment from "moment";

export const PaymentCardSummary = ({
  payment,
  files,
  getPaymentDocument,
}) => {
  const { TextArea } = Input;
  return (
    <div className="projectVal__card__container">
      <div style={{ width: "90%" }}>
        <div className="projectVal__card__items">
          <Item
            title="Código"
            content={payment.code}
            width="192px"
            disabled={true}
          />
          <div className="debt_item">
            <p className="blue bold font14 projectVal__mb1">Fecha ingreso</p>
            <DatePicker
              value={moment(payment.date, serverDateFormat)}
              format={clientDateFormat}
              disabled={true}
              style={{ width: "143px" }}
              className="editProject__mb2"
            />{" "}
          </div>
        </div>
        <div className="projectVal__card__items">
          <Item
            title="Método de pago"
            content={payment.method}
            width="192px"
            disabled={true}
          />
          <Item
            title="Monto (S/.)"
            content={parseFloatNumber(payment.amount)}
            width="143px"
            disabled={true}
          />
        </div>
        {/*
        //TODO: Falta status of payment
        */}
        <div className="projectVal__card__items">
          <FileList
            files={files}
            getDebtDocument={getPaymentDocument}
            deleteDebtDocument={()=>{}}
          />
        </div>
        {payment.rejected_comments!=="" && (
          <>
            <p className="blue bold font14 projectVal__mb1">
              Comentarios de rechazo:
            </p>
            <TextArea
              value={payment.rejected_comments}
              rows={4}
              className="projectVal__mb1"
              placeholder="Indique porque rechaza la valorización del proyecto"
              disabled={true}
            />
          </>
        )}
      </div>
    </div>
  );
};
