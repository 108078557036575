import React from "react";
import Headers from "./Headers";
import Item from "./Item";

import "./index.css";

export default function ValorizationsSummaryTable({
  valorizations,
  activeValorization,
  setActiveValorization,
  setActiveDebtId,
  showMobileModal,
}) {
  return (
    <div
      className={
        showMobileModal
          ? "valorization_summary__card__container_mobile"
          : "valorization_summary__card__container"
      }
    >
      <div className="valorization_summary_table_container">
        <Headers showMobileModal={showMobileModal} />

        {valorizations.map((valorization) => (
          <Item
            valorization={valorization}
            isActive={valorization.order === activeValorization}
            setActiveValorization={setActiveValorization}
            setActiveDebtId={setActiveDebtId}
            showMobileModal={showMobileModal}
          />
        ))}
      </div>
    </div>
  );
}
