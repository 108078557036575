import React from "react";

import {
  PublicWallpaper,
  PublicMainCard,
  PublicMainCardRow,
  PublicMainCardLogo,
  PublicMainCardTitle,
  PublicMainCardSubtitle,
} from "./styles";

const PublicLayout = ({ children }) => {
  return (
    <PublicWallpaper>
      <PublicMainCard>
        <PublicMainCardRow>
          <PublicMainCardLogo />
        </PublicMainCardRow>
        <PublicMainCardRow>
          <PublicMainCardTitle bold>Construction projects</PublicMainCardTitle>
        </PublicMainCardRow>
        <PublicMainCardRow>
          <PublicMainCardSubtitle>Going faster together</PublicMainCardSubtitle>
        </PublicMainCardRow>
        {children}
      </PublicMainCard>
    </PublicWallpaper>
  );
};

export default PublicLayout;
