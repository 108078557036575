import { baseProvider } from "../baseProvider";

const getProjectDeliverySummary = async (id) =>
  baseProvider.httpGet(`/project_delivery/${id}`);

/* Information getting */

const getStartProjectDelivery = async (id) =>
  baseProvider.httpGet(`/project_delivery/${id}/start_delivery`);

const getProjectDeliveryMinute = async (id) =>
  baseProvider.httpGet(`/project_delivery/${id}/delivery_minute`);

const getProjectDeliveryObservationsListPeriod = async (id) =>
  baseProvider.httpGet(`/project_delivery/${id}/observations_list_period`);

const getProjectDeliveryObservationsList = async (id) =>
  baseProvider.httpGet(`/project_delivery/${id}/observations_list`);

const getProjectDeliverySolvingPeriod = async (id) =>
  baseProvider.httpGet(`/project_delivery/${id}/observations_solving_period`);

const getFinishProjectDelivery = async (id) =>
  baseProvider.httpGet(`/project_delivery/${id}/finish_delivery`);

export {
  getProjectDeliverySummary,
  getStartProjectDelivery,
  getProjectDeliveryMinute,
  getProjectDeliveryObservationsListPeriod,
  getProjectDeliveryObservationsList,
  getProjectDeliverySolvingPeriod,
  getFinishProjectDelivery,
};
