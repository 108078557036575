import React, { useEffect, useReducer, useMemo } from "react";

const TimeContext = React.createContext({
  reload: true,
});

const reducer = (state, action) => {
  switch (action.type) {
    case "TIME":
      return {
        ...state,
        reload: !state.reload,
      };
    default:
      return state;
  }
};

export const TimeContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    reload: true,
  });

  useEffect(() => {
    const interval = setInterval(() => dispatch({ type: "TIME" }), 1000);
    return () => clearInterval(interval);
  }, []);

  const value = useMemo(
    () => ({
      ...state,
    }),
    [state]
  );

  return <TimeContext.Provider value={value}>{children}</TimeContext.Provider>;
};

export function useTimeContext() {
  const context = React.useContext(TimeContext);
  if (!context) {
    throw new Error("TimeContext provider is required");
  }
  return context;
}
