import styled from "styled-components";

export const SectionContainer = styled.div`
  height: calc(100vh - 300px);
  overflow-x: auto;
  overflow-y: auto;
  background-color: #fff;
  @media (min-width: 790px) and (max-width: 1257px) {
    height: calc(100vh - 400px);
  }
  @media (max-width: 790px) {
    height: calc(100vh - 100px);
  }
`;

export const DeployButton = styled.button`
  width: 14px;
  height: 14px;
  font-size: 11px;
  margin-top: 12px;
  margin-right: 90px;
  border: solid 1px gray;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderLabelContainer = styled.div`
  width: 100%;
`;
