import React, { useState, useEffect } from "react";

// Sections & submodules imports
import { Qrome } from "./Qrome";
import Qrome2 from "./Qrome2";
import Loading from "../../../shared/components/Loading";

// Sections
import { Valorizations } from "./Valorizations";

// Context
import { useAppContext } from "../../../App/context";

// Services import
import { getPreAdditional, getProject } from "../../services";

import "./index.css";

const PreadditionalValorizationBanner = ({ history, match }) => {
  const { id } = match.params;

  // Context import
  const {
    projectRole,
    setProjectRole,
    setIsProjectAdmin,
    setProjectName,
    setProjectEnterprise,
  } = useAppContext();

  //* Component states
  const [preadditional, setPreadditional] = useState({ order: "X" });
  // project
  const [project, setProject] = useState({ image: null });
  // trigger
  const [trigger, setTrigger] = useState(true);
  // loading
  const [isLoading, setIsLoading] = useState(false);

  // State settings
  useEffect(() => {
    const fetchPreadditionalPage = async () => {
      setIsLoading(true);
      await getPreAdditional(id, errorHandler, getPreadditionalSuccessHandler);
      setIsLoading(false);
    };

    fetchPreadditionalPage();
  }, [trigger]);

  useEffect(() => {
    const fetchProject = async () => {
      setIsLoading(true);
      await getProject(
        preadditional.project,
        errorHandler,
        getProjectSuccessHandler
      );
      setIsLoading(false);
    };

    preadditional?.project && fetchProject();
  }, [preadditional]);

  // get preadditional handlers
  const errorHandler = (type = "server", message = "") => {
    switch (type) {
      case "server":
      case "no_found":
        history.push("/main");
        break;
      default:
        break;
    }
  };

  const getPreadditionalSuccessHandler = (preadditional) => {
    setPreadditional(preadditional);
  };

  // get project handlers
  const getProjectSuccessHandler = (project) => {
    setProject(project);
    setProjectRole(
      project.role === "SUPERVISOR_LEADER" || project.role === "SUPERVISOR"
        ? "Supervisor"
        : "Ejecutor"
    );
    setIsProjectAdmin(project.role === "ADMIN");
    setProjectName(project.name);
    setProjectEnterprise(project.enterprise);
  };

  const reloadPreadditionalPage = () => {
    setTrigger(!trigger);
  };

  return (
    <div>
      <Loading active={isLoading} />
      <Qrome
        user={projectRole}
        project={project}
        presentValorization={""}
        lastValorization={""}
        currentValorization={{
          to_be_closed: false,
          debt: null,
        }}
        general_aproved={project.general_aproved}
        preadditional={preadditional}
        sendpresentValorization={() => {}}
        evaluatepresentValorization={() => {}}
        loadFinalReportValorization={() => {}}
        setDebtModalActive={() => {}}
        setAmountsDatesModalActive={() => {}}
      />
      <Qrome2 />

      <Valorizations
        id={id}
        role={projectRole}
        project={project}
        general_aproved={project.general_aproved}
        valorizations={[]}
        preadditional={preadditional}
        setPreadditional={setPreadditional}
        setCurrentValorization={() => {}}
        currentValorization={{
          to_be_closed: false,
          debt: null,
        }}
        setIsLoading={setIsLoading}
        reloadPreadditionalPage={reloadPreadditionalPage}
      />
    </div>
  );
};

export default PreadditionalValorizationBanner;
