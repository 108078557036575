import styled from "styled-components";

export const ModalButtons = styled.div`
  display: flex;
  gap: 30px;
  align-self: center;
  /* justify-content: flex-end; */
  justify-content: center;
  margin: auto;
`;

export const Label = styled.div`
  width: ${({ width }) => width};
  color: #014083;
  margin-bottom: 1rem;
  ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop};`};
  font-weight: bold;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
