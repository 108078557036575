// React import
import React, { useState, useEffect } from "react";

// Ant components
import { notification } from "antd";

// Components import
import {
  ModalTitle,
  Modal,
  TableContainer,
  Indication,
  AddHiddenViceContainer,
} from "./styles";
import Table from "./components/Table";
import { Button } from "../../../Button";
import Loading from "../../../../modules/shared/components/Loading";

// Services import
import { getHiddenVices, createHiddenVice } from "../../services";

export default function HiddenViceModal({
  hiddenViceModalActive,
  setHiddenViceModalActive,
  projectDeliveryId,
  showMobileModal,
  setHiddenViceDetailModalActive,
  setHiddenViceSelectedId,
  triggerHiddenVices,
  reloadHiddenVices,
}) {
  const [hiddenVices, setHiddenVices] = useState([]);
  const [loading, setLoading] = useState(false);

  const errorHandler = () => {
    setHiddenVices([]);
    setLoading(false);
  };

  const successHandler = (results) => {
    let _hiddenVices = results.results.map((hiddenVice) => {
      return { ...hiddenVice, checked: false };
    });
    setHiddenVices(_hiddenVices);
    setLoading(false);
  };

  useEffect(() => {
    const fetchHiddenVices = async () => {
      setLoading(true);
      await getHiddenVices(projectDeliveryId, errorHandler, successHandler);
    };
    fetchHiddenVices();
  }, [projectDeliveryId, hiddenViceModalActive, triggerHiddenVices]);

  // Handlers
  const openHiddenViceDetailHandler = (id) => {
    setHiddenViceDetailModalActive(true);
    setHiddenViceSelectedId(id);
  };

  const errorHiddenViceHandler = (response) => {
    notification.error({
      message: response
        ? Object.values(response)[0][0]
        : "Ocurrió un error al crear el vicio oculto",
    });
    setLoading(false);
  };

  const successHiddenViceHandler = (response) => {
    notification.success({
      message: "Se ha creado correctamente el vicio oculto",
    });
    reloadHiddenVices();
    setHiddenViceDetailModalActive(true);
    setHiddenViceSelectedId(response.id);
    setLoading(false);
  };

  const handleCreateHiddenVice = async () => {
    setLoading(true);
    await createHiddenVice(
      projectDeliveryId,
      errorHiddenViceHandler,
      successHiddenViceHandler
    );
  };

  return (
    <>
      <Modal
        title={<ModalTitle>Vicios ocultos</ModalTitle>}
        centered
        open={hiddenViceModalActive}
        onCancel={() => {
          setHiddenViceModalActive(false);
        }}
        footer={null}
        width={showMobileModal ? 500 : 1000}
      >
        <Indication>Listado de observaciones de vicios ocultos</Indication>
        <TableContainer>
          <Table
            hiddenVices={hiddenVices}
            checkHiddenViceHandler={() => {}}
            checkAllHiddenVicesHandler={() => {}}
            openHiddenViceDetail={(id) => {
              openHiddenViceDetailHandler(id);
            }}
          />
        </TableContainer>
        <AddHiddenViceContainer>
          <Button
            background="#0169E4"
            width="fit-content"
            fontWeight="600"
            boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
            padding="0 16px 0 16px"
            onClick={() => {
              handleCreateHiddenVice();
            }}
          >
            Agregar vicio oculto
          </Button>
        </AddHiddenViceContainer>
      </Modal>
      <Loading active={loading} />
    </>
  );
}
