import React from "react";
import Skeleton from "react-loading-skeleton";

export const ToTalkModalSkeleton = () => {
  return (
    <div>
      <Skeleton count={1} height={30} />
      <p></p>
      <Skeleton count={1} height={90} />
      <p></p>
      <Skeleton count={1} height={150} />
      <p></p>
      <Skeleton count={1} height={90} />
    </div>
  );
};
