import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";

import {
  getAdministrators,
  getSupervisors,
  getExecutors,
} from "../../../providers";

import { EditProjectCard } from "../../EditProjectCard/EditProjectCard";
import { MemberCard } from "./MemberCard";
import { Button } from "../../Button";

export const ProjectTeam = ({ id, history, role }) => {
  const [administrators, setAdministrator] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const [executors, setExecutors] = useState([]);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const _administrators = await getAdministrators(id);
        const _supervisors = await getSupervisors(id);
        const _executors = await getExecutors(id);
        setAdministrator(_administrators.results);
        setSupervisors(_supervisors.results);
        setExecutors(_executors.results);
      } catch (error) {
        console.log(error);
      }
    };
    fetchProject();
  }, []);

  return (
    <EditProjectCard
      classname="editProject__team"
      title="2. Equipo de proyecto"
    >
      <div className="editProject__team__container">
        <Row>
          <p style={{ marginBottom: "10px", marginLeft: "15px" }}>
            Administradores
          </p>
        </Row>
        <div className=" editProject__team__containerCards">
          {administrators?.map((member) => (
            <MemberCard key={member.id} color="#818181" {...member} />
          ))}
        </div>
        <Row>
          <p style={{ marginBottom: "10px", marginLeft: "15px" }}>
            Supervisores
          </p>
        </Row>
        <div className=" editProject__team__containerCards">
          {supervisors?.map((member) => (
            <MemberCard key={member.id} color="#0169E4" {...member} />
          ))}
        </div>
        <Row>
          <p style={{ marginBottom: "10px", marginLeft: "15px" }}>Ejecutores</p>
        </Row>
        <div className=" editProject__team__containerCards">
          {executors?.map((member) => (
            <MemberCard key={member.id} color="#05BE8C" {...member} />
          ))}
        </div>
      </div>
      <Row className="">
        <Button
          margin="5px auto 20px auto"
          width="200px"
          fontSize="15px"
          background={role === "ADMIN" ? "#0169E4" : "#f5f5f5"}
          border={role !== "ADMIN" && "1px solid rgba(95, 111, 129, 0.5)"}
          boxShadow={role === "ADMIN" && "0px 2px 8px rgba(0, 0, 0, 0.25)"}
          color={role !== "ADMIN" && "#00000040"}
          onClick={() =>
            role === "ADMIN" && history.push(`/projects/${id}/edit/mates`)
          }
          disabled={role !== "ADMIN"}
        >
          Editar colaboradores
        </Button>
      </Row>
    </EditProjectCard>
  );
};
