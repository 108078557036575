// React import
import React, { useState } from "react";

// Third party libraries import
import { IconContext } from "react-icons";
import { MdPersonOutline } from "react-icons/md";
import { Popconfirm } from "antd";

// Components import
import { Button } from "../../../Button";

// Assets import
import checkStateIcon from "./assets/green-state.svg";
import unCheckStateIcon from "./assets/gray-state.svg";

import {
  ApprovalPanelContainer,
  ApprovalPanelCard,
  ApprovalPanelItem,
  LabelItem,
  IconContainer,
  ApprovalPanelIconsContainer,
} from "./styles";

const ApprovalPanel = ({
  label,
  approvalEnable,
  approveHandler,
  userRole,
  superApproved = false,
  execApproved = false,
}) => {
  const [showPopConfirm, setShowPopConfirm] = useState(false);
  let approvalControlsActive = false;

  switch (userRole) {
    case "SUPERVISOR_LEADER":
      approvalControlsActive = !superApproved && approvalEnable;
      break;
    case "EXECUTOR_LEADER":
      approvalControlsActive = !execApproved && approvalEnable;
      break;
    case "ADMIN":
      approvalControlsActive = approvalEnable;
      break;
    default:
      approvalControlsActive = false;
      break;
  }
  return (
    <>
      <ApprovalPanelContainer>
        <Popconfirm
          placement="topLeft"
          title="¿Estas seguro de realizar la aprobación?"
          open={showPopConfirm}
          onConfirm={() => {
            setShowPopConfirm(false);
            approvalControlsActive && approveHandler();
          }}
          onCancel={() => {
            setShowPopConfirm(false);
          }}
          okText="Sí"
          cancelText="No"
        >
          <Button
            background={approvalControlsActive ? "#05BE8C" : "#818181"}
            width="fit-content"
            fontWeight="600"
            boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
            padding="0 16px 0 16px"
            onClick={() => {
              approvalControlsActive && setShowPopConfirm(true);
            }}
          >
            {label}
          </Button>
        </Popconfirm>
        <ApprovalPanelCard>
          <ApprovalPanelItem>
            <LabelItem>Supervisor líder</LabelItem>
            <ApprovalPanelIconsContainer>
              <IconContainer>
                <IconContext.Provider value={{ color: "white" }}>
                  <MdPersonOutline size={22} />
                </IconContext.Provider>
              </IconContainer>
              <img
                alt="val_state"
                src={superApproved ? checkStateIcon : unCheckStateIcon}
              />
            </ApprovalPanelIconsContainer>
          </ApprovalPanelItem>
          <ApprovalPanelItem>
            <LabelItem>Ejecutor líder</LabelItem>
            <ApprovalPanelIconsContainer>
              <IconContainer>
                <IconContext.Provider value={{ color: "white" }}>
                  <MdPersonOutline size={22} />
                </IconContext.Provider>
              </IconContainer>
              <img
                alt="val_state"
                src={execApproved ? checkStateIcon : unCheckStateIcon}
              />
            </ApprovalPanelIconsContainer>
          </ApprovalPanelItem>
        </ApprovalPanelCard>
      </ApprovalPanelContainer>
    </>
  );
};

export default ApprovalPanel;
