import React from "react";
import "./styles.css";

const SectionSelector = ({ activeSection=0, sections, onClickSection }) => {
  return (
    <div className="debt_section_selector">
      {sections.map((section) => (
        <div className={activeSection===section.index?"debt_section_selector_active":"debt_section_selector_item"}>
          <p className="font14 debt_section_selector_text" onClick={()=>onClickSection(section.index)}>{section.label}</p>
        </div>
      ))}
    </div>
  );
};

export default SectionSelector;
