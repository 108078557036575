import styled from "styled-components";

export const SectionContainer = styled.div`
  height: calc(100vh - 353px);
  overflow-x: auto;
  overflow-y: auto;
  background-color: #fff;
  @media (min-width: 667px) and (max-width: 1257px) {
    height: calc(100vh - 506px);
  }
  @media (max-width: 666px) {
    height: calc(100vh - 99px - 127px);
  }
`;

export const SectionTitle = styled.p`
  margin-left: 10px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #0169e4;
  margin-top: 30px;
`;