import React from "react";
import { Row } from "reactstrap";
import { IconContext } from "react-icons";
import { RiSearchLine } from "react-icons/ri";
import styled from "styled-components";

import "./EditProjectCard.css";

const Container = styled.div`
  background: #ffffff;
  border-radius: 2px;
  padding: ${(props) => props.padding || "1rem"};
  margin-bottom: ${(props) => props.marginBottom || "30px"};
  .spaceBetween {
    justify-content: space-between;
  }
`;

export const EditProjectCard = ({
  title,
  children,
  searchVisible,
  padding,
  marginBottom,
  classname,
  setSearch,
}) => {
  return (
    <Container
      padding={padding}
      marginBottom={marginBottom}
      className={classname}
    >
      <Row className="ramp spaceBetween">
        <p className="editProjectCard__title">{title}</p>
        {searchVisible && (
          <div className="search">
            <input
              onFocus={(event) => event.target.select()}
              onChange={(e) => setSearch(e.target.value)}
              className="search_input"
            />
            <div className="search_container_icon">
              <IconContext.Provider
                value={{ color: "rgba(95, 111, 129, 0.5)" }}
              >
                <RiSearchLine className="search_icon" size={24} />
              </IconContext.Provider>
            </div>
          </div>
        )}
      </Row>
      {children}
    </Container>
  );
};

EditProjectCard.defaultProps = {
  searchVisible: false,
};
