import React from "react";
import { Link, useLocation } from "react-router-dom";

import { IconContext } from "react-icons";
import { MdRecycling } from "react-icons/md";
import { FaQuestionCircle } from "react-icons/fa";
import { IoIosSettings } from "react-icons/io";
import DirectoyIcon from "../../../../../../assets/directory.svg"

import {
  SidebarBottomMenuContainer,
  SidebarMenuContainer,
  SidebarOption,
} from "./styles";

import { pathMatch } from "./utils";

import "./styles.css";

const Sidebar = () => {
  const location = useLocation();
  const pathname = location.pathname;
  return (
    <SidebarMenuContainer>
      <Link to="/main">
        <SidebarOption isActive={pathMatch(pathname, ["/main", "/projects"])}>
            <img src={DirectoyIcon} style={{width:"30px", height:"30px"}} className="sidebar__menuIcon" />
        </SidebarOption>
      </Link>
      <SidebarBottomMenuContainer>
        <SidebarOption>
          <IconContext.Provider value={{ color: "#ffffff" }}>
            <MdRecycling size={30} className="sidebar__menuIcon" />
          </IconContext.Provider>
        </SidebarOption>
        <SidebarOption>
          <IconContext.Provider value={{ color: "#ffffff" }}>
            <FaQuestionCircle size={30} className="sidebar__menuIcon" />
          </IconContext.Provider>
        </SidebarOption>
        <SidebarOption>
          <IconContext.Provider value={{ color: "#ffffff" }}>
            <IoIosSettings size={30} className="sidebar__menuIcon" />
          </IconContext.Provider>
        </SidebarOption>
      </SidebarBottomMenuContainer>
    </SidebarMenuContainer>
  );
};

export default Sidebar;
