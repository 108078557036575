// React import
import React from "react";
import { Link } from "react-router-dom";

// Components import
import { IconContext } from "react-icons";
import { FiSettings } from "react-icons/fi";

// Assets import
import defaultImage from "../../assets/fondo.jpg";
import smaller from "../../assets/smaller.svg";

import {
  ConstructionContainer,
  ConstructionImageContainer,
  ConstructionInfoContainer,
  ImageContainer,
  ConstructionData,
  ConstructionConfigContainer,
  ImageConfigProject,
  ButtonConfigProject,
} from "./styles";

const ConstructionCard = ({
  id,
  name,
  direction,
  image,
  projects_number,
  active_projects_number,
  total_amount,
  additionals_advancement_percentage,
  additionals_total_amount,
  advancement_percentage,
  openEditConstructionModal,
}) => {
  const getConstructionsLink = (id, name) => {
    window.sessionStorage.setItem("construction_id", id);
    window.sessionStorage.setItem("construction_name", name);
    return `/constructions/${id}`;
  };

  return (
    <ConstructionContainer key={id}>
      <ConstructionImageContainer>
        <Link to={() => getConstructionsLink(id, name)}>
          <ImageContainer
            imageUrl={
              image
                ? `${image["content-type"]};base64, ${image.data}`
                : defaultImage
            }
          >
            <ButtonConfigProject>
              <ImageConfigProject src={smaller} alt="edit-icon" />
            </ButtonConfigProject>
          </ImageContainer>
        </Link>
        <ConstructionData>
          {name} <br />
          Dirección: <span style={{ fontWeight: "normal" }}>{direction}</span>
        </ConstructionData>
      </ConstructionImageContainer>
      <ConstructionInfoContainer>
        Nro de contratos:{" "}
        <span style={{ fontWeight: "normal" }}>{projects_number}</span>
        <br />
        Nro de contratos en curso:{" "}
        <span style={{ fontWeight: "normal" }}>{active_projects_number}</span>
        <br />
        Monto total de obra <br />
        (inc. IGV):{" "}
        <span style={{ fontWeight: "normal" }}>
          S/.{Number(total_amount + additionals_total_amount).toFixed(2)}
        </span>
        <br />
        <span style={{ color: "#05BE8C" }}>
          {" "}
          Monto total valorizado <br />
          (inc. IGV):{" "}
          <span style={{ fontWeight: "normal" }}>
            S/.{Number(total_amount).toFixed(2)}
          </span>
          <br />
          Porcentaje valorizado:{" "}
          <span style={{ fontWeight: "normal" }}>
            {Number(advancement_percentage).toFixed(2)}%
          </span>
        </span>
        <br />
        <span style={{ color: "#000000" }}>
          Monto total de adicionales <br />
          (inc. IGV):{" "}
          <span style={{ fontWeight: "normal" }}>
            S/.{Number(additionals_total_amount).toFixed(2)}
          </span>
          <br />
          Porcentaje valorizado:{" "}
          <span style={{ fontWeight: "normal" }}>
            {Number(additionals_advancement_percentage).toFixed(2)}%
          </span>
        </span>
        <br />
        <IconContext.Provider value={{ color: "black" }}>
          <ConstructionConfigContainer onClick={openEditConstructionModal}>
            <FiSettings size={40} />
            Configuración
          </ConstructionConfigContainer>
        </IconContext.Provider>
      </ConstructionInfoContainer>
    </ConstructionContainer>
  );
};

export default ConstructionCard;
