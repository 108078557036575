import styled from "styled-components";

export const BaseButton = styled.div`
  border: solid 1px ${(props) => (props.active ? "#014083" : "#818181")};
  color: ${(props) => (props.active ? "#014083" : "#818181")};
  width: 150px;
  height: 20px;
  text-align: center;
  align-items: center;
  font-size: 14px;
  cursor: ${(props) => (props.active ? "pointer" : "auto")};
  display: flex;
  justify-content: space-around;
`;
