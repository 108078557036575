// Basic imports
import React from "react";

// Components imports
import AdditionalValorizationBanner from "../components/AdditionalValorizationBanner";

const ProjectAdditionalsPage = ({ history, match }) => {
  return <AdditionalValorizationBanner history={history} match={match} />;
};

export default ProjectAdditionalsPage;
