// React import
import React, { useEffect, useState } from "react";

// Components import
import Button from "../../../../../shared/components/Button";
import Table from "./components/Table";

// Styled components import
import {
  ModalTitle,
  ModalHeader,
  ButtonContainer,
  TableContainer,
  Indication,
} from "./styles";

// Services import
import { getProjectPreAdditionals } from "../../../../services";

export default function AdditionalsValidation({
  projectId,
  setLoading,
  onConfirm,
  onReject
}) {
  const [preadditionals, setPreadditionals] = useState([]);
  const errorHandler = () => {
    setPreadditionals([]);
  };

  const successHandler = (results) => {
    let _preadditionals = results.results.map((preadditional) => {
      return { ...preadditional, checked: false };
    });
    setPreadditionals(_preadditionals);
  };

  useEffect(() => {
    const fetchPreAdditionals = async () => {
      setLoading(true);
      await getProjectPreAdditionals(projectId, errorHandler, successHandler);
      setLoading(false);
    };
    fetchPreAdditionals();
  }, [projectId]);

  return (
    <>
      <ModalHeader>
        <ModalTitle>Resumen pago de adicionales</ModalTitle>
      </ModalHeader>
      <TableContainer>
        <Table additionals={preadditionals} />
      </TableContainer>
      <ButtonContainer>
        <Button
          background="#05BE8C"
          width="80px"
          margin="20px 0 0 0"
          fontWeight="600"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
          onClick={() => {
            onReject();
          }}
        >
          Regresar
        </Button>
        <Button
          background="#05BE8C"
          width="80px"
          margin="20px 0 0 0"
          fontWeight="600"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
          onClick={() => {
            onConfirm();
          }}
        >
          Continuar
        </Button>
      </ButtonContainer>
      <Indication>
        Si el estado de cada adicional es el esperado, prosiga con la siguiente
        etapa, de lo contrario haga los ajustes necesarios a cada presupuesto
        adicional hasta que los estados en este resumen, sea el deseado
      </Indication>
      <Indication>
        <b>Advertencia: </b>Los adicionales de obra en estado propuesto no se
        incluirán en el cálculo del monto final de la liquidación. El monto
        total de los presupuestos adicionales en estado propuesto se mostrará en
        una sección aparte en el informe final de liquidación.
      </Indication>
    </>
  );
}
