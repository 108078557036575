import React from "react";
import { Row } from "reactstrap";
import { Input, Radio } from "antd";
import { Select } from "./Select";

import { EditProjectCard } from "../../EditProjectCard/EditProjectCard";
import { TextWithTooltip } from "./TextWithTooltip";
import { Item } from "./Item";

export const Valuation2 = ({
  radioStyle,
  valorization_no_sended_option,
  setValorization_no_sended_option,
  meter_entered_days,
  setMeter_entered_days,
  meter_validation_days,
  setMeter_validation_days,
  conflicts_resolution_days,
  setConflicts_resolution_days,
  updateProject,
  valorization_firm_days,
  setValorization_firm_days,
  exist_valorizations_program,
  general_aproved,
  calendarTypes,
  valorization_close_calendar_type,
  setValorization_close_calendar_type,
  valorization_firm_calendar_type,
  setValorization_firm_calendar_type,
  role,
}) => {
  const update = (body) => updateProject(body, true);

  return (
    <EditProjectCard
      classname="editProject__valSched__section2"
      title="5. Tiempos de Ingreso y validación de metrado"
      padding="1rem 1rem 1rem 1rem"
    >
      <Item>
        <strong>En caso la valorización no sea enviada por el ejecutor</strong>
      </Item>
      <Radio.Group
        value={valorization_no_sended_option}
        onChange={(e) => {
          setValorization_no_sended_option(e.target.value);
          update({
            valorization_no_sended_option: e.target.value,
          });
        }}
        disabled={
          !exist_valorizations_program || general_aproved || role !== "ADMIN"
        }
        className="editProject__mb4"
      >
        <Row className="editProject__ml editProject__mb2">
          <Radio style={radioStyle} value="NEXT_DATE">
            <TextWithTooltip
              id="fechaCorte"
              info="Si escoge esta opción, en caso no se envíe la valorización por el ejecutor en el plazo previsto, todos los datos valorizados pasan a la siguiente valorización de forma automática."
            >
              Se valoriza a la siguiente fecha de corte programada{" "}
            </TextWithTooltip>
          </Radio>
        </Row>
        <br />
        <Row className="editProject__ml editProject__mb2">
          <Radio style={radioStyle} value="SUPER_ENTER">
            <TextWithTooltip
              id="metrado"
              info="Si escoge esta opción, en caso no se envíe la valorización por el ejecutor en el plazo previsto, el supervisor ingresará los metrados y la valorización se aprobará de forma automática sin lugar a reclamo por parte del ejecutor."
            >
              El supervisor ingresa el metrado sin lugar a contestación{" "}
            </TextWithTooltip>
          </Radio>
        </Row>
      </Radio.Group>
      <br />
      <Item>
        <strong>Tiempo en días &nbsp;</strong>
        <Select
          className="editProject__mr"
          value={valorization_close_calendar_type}
          onChange={(value) => {
            update({
              valorization_close_calendar_type: value,
            });
            setValorization_close_calendar_type(value);
          }}
          options={calendarTypes}
          disabled={general_aproved || role !== "ADMIN"}
          width={133}
        />
        <strong>
          de ingreso y validación de metrado. A contar después de la fecha de
          corte
        </strong>
      </Item>
      <div className="editProject__mb2 editProject__valSched__gridVal2">
        <TextWithTooltip
          id="ingresoMetrado"
          info="Cantidad de días máximo en que el ejecutor se tomará para ingresar datos y enviar la propuesta de valorización luego de fecha de cierre."
        >
          Ingreso de metrado (Ejecutor){" "}
        </TextWithTooltip>
        <Input
          value={meter_entered_days}
          onFocus={(event) => event.target.select()}
          onChange={(e) => setMeter_entered_days(e.target.value)}
          onBlur={() => update({ meter_entered_days })}
          className="editProject__ml"
          style={{ width: "67px" }}
          disabled={
            !exist_valorizations_program || general_aproved || role !== "ADMIN"
          }
        />
      </div>
      <div className="editProject__mb2 editProject__valSched__gridVal2">
        <TextWithTooltip
          id="validacionMetrado"
          info="Cantidad de días máximo en que se tomará el supervisor para revisar, dar conformidad u observar los metrados y presupuestos propuestos por el ejecutor."
        >
          Validación de metrado (Supervisor){" "}
        </TextWithTooltip>
        <Input
          value={meter_validation_days}
          onFocus={(event) => event.target.select()}
          onChange={(e) => setMeter_validation_days(e.target.value)}
          onBlur={() => update({ meter_validation_days })}
          className="editProject__ml"
          style={{ width: "67px" }}
          disabled={
            !exist_valorizations_program || general_aproved || role !== "ADMIN"
          }
        />
      </div>
      <div className="editProject__mb2 editProject__valSched__gridVal2">
        <TextWithTooltip
          id="resolucionDiferencias"
          info="Días extra que se tomarán ambas partes para aprobar la valorización, luego de la respuesta del supervisor."
        >
          Resolución de diferencias{" "}
        </TextWithTooltip>
        <Input
          value={conflicts_resolution_days}
          onFocus={(event) => event.target.select()}
          onChange={(e) => setConflicts_resolution_days(e.target.value)}
          onBlur={() => update({ conflicts_resolution_days })}
          className="editProject__ml"
          style={{ width: "67px" }}
          disabled={
            !exist_valorizations_program || general_aproved || role !== "ADMIN"
          }
        />
      </div>
      <p
        style={{ color: "#00C187" }}
        className="editProject__mr editProject__mb4"
      >
        La duración total del período de validación de metrado (Ingreso +
        Validación + resolución de diferencias) no puede exceder la duración del
        período de valorización mas corto.
      </p>
      <Item>
        <strong>Firma de valorización</strong>
      </Item>
      <Row className="ramp" style={{ alignItems: "center" }}>
        <p className="editProject__mr editProject__mb0">
          Es posible firmar la valorización hasta{" "}
        </p>
        <Input
          value={valorization_firm_days}
          onFocus={(event) => event.target.select()}
          onChange={(e) => setValorization_firm_days(e.target.value)}
          onBlur={() => update({ valorization_firm_days })}
          className="editProject__mr"
          style={{ width: "67px" }}
          disabled={
            !exist_valorizations_program || general_aproved || role !== "ADMIN"
          }
        />
        <p className="editProject__mr editProject__mb0">día(s)</p>
        <Select
          className="editProject__mr"
          value={valorization_firm_calendar_type}
          onChange={(value) => {
            update({
              valorization_firm_calendar_type: value,
            });
            setValorization_firm_calendar_type(value);
          }}
          options={calendarTypes}
          disabled={general_aproved || role !== "ADMIN"}
          width={133}
        />
        <TextWithTooltip
          id="tiempoFirma"
          info="Días que se tomarán las partes para firmar la valorización, luego de que está sea aprobada. Si se coloca el valor '0', la valorización será aprobada de forma automática luego de aprobarse todos los valores."
        >
          antes de la fecha de corte{" "}
        </TextWithTooltip>
      </Row>
    </EditProjectCard>
  );
};
