// Base import
import React, { useState, useEffect } from "react";

// Support libraries import
import { notification, Select } from "antd";

// Styled components
import { TableSummaryContainer, TableItem } from "./styles";

// Components import
import { Button } from "../../../../../../../components/Button";

// Utils import
import { parseFloatNumber } from "../../../../../../../utils";

// Services import
import { deletePreAdditional } from "../../../../../services";

// Options
const additionalsSummaryOptions = [
  { value: 0, label: "Fecha de creación" },
  { value: 1, label: "Monto total ascendente" },
  { value: 2, label: "Monto total descendente" },
];

const TableSummary = ({
  preadditionals,
  userRole,
  setTrigger,
  trigger,
  setLoading,
  selectedOption,
  setSelectedOption,
}) => {
  // States
  const [selectedAdditionalsAmount, setSelectedAdditionalsAmount] = useState(0);
  const [selectedAdditionalsPaidAmount, setSelectedAdditionalsPaidAmount] =
    useState(0);

  useEffect(() => {
    setSelectedAdditionalsAmount(
      preadditionals.reduce((acc, preadditional) => {
        if (preadditional.checked) {
          acc +=
            preadditional.additional_budget_cost +
            preadditional.general_expenses_cost;
        }
        return acc;
      }, 0)
    );
    setSelectedAdditionalsPaidAmount(
      preadditionals.reduce((acc, preadditional) => {
        if (preadditional.checked && preadditional.additional) {
          acc += preadditional.additional.advance_amount;
        }
        return acc;
      }, 0)
    );
  }, [preadditionals]);

  const validatePreadditionalRole = (approverRole) => {
    switch (userRole) {
      case "EXECUTOR":
      case "SUPERVISOR":
        return false;
      case "ADMIN":
        return approverRole === "EXECUTOR";
      case "EXECUTOR_LEADER":
        return approverRole === "SUPERVISOR";
      case "SUPERVISOR_LEADER":
        return approverRole === "EXECUTOR";
      default:
        return false;
    }
  };

  // handlers

  const deletionErrorHandler = (error) => {
    notification.error({
      message: "Hubo un error al eliminar el adicional",
    });
  };

  const deletionSuccessHandler = (response) => {};

  const deleteButtonHandler = async () => {
    setLoading(true);
    let toDeletePreadditionals = preadditionals.filter(
      (preadditional) =>
        preadditional.checked &&
        (preadditional.status === "CREATED" ||
          preadditional.status === "SETTED") &&
        validatePreadditionalRole(preadditional.approver_role)
    );

    await toDeletePreadditionals.forEach(async (preadditional) => {
      await deletePreAdditional(
        preadditional.id,
        deletionErrorHandler,
        deletionSuccessHandler
      );
    });
    setTimeout(() => {
      setLoading(false);
      notification.success({
        message:
          "Se eliminaron las propuestas de adicional seleccionadas correctamente",
      });
      setTrigger(!trigger);
    }, 1000);
  };

  return (
    <>
      <TableSummaryContainer>
        <TableItem width="400px" header justifyContent="left">
          <Button
            background="#D93022"
            width="122px"
            fontWeight="600"
            boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
            onClick={() => {
              deleteButtonHandler();
            }}
          >
            Suprimir
          </Button>
        </TableItem>
        <TableItem width="764px" header justifyContent="left">
          Ordenar por:
        </TableItem>
        <TableItem width="200px" header>
          Monto total
        </TableItem>
        <TableItem width="200px" header>
          Monto total pagado
        </TableItem>
        <TableItem width="308px" header></TableItem>
      </TableSummaryContainer>
      <TableSummaryContainer>
        <TableItem width="400px" header justifyContent="right"></TableItem>
        <TableItem width="364px" header justifyContent="left">
          <Select
            style={{ width: "200px" }}
            value={selectedOption}
            onChange={(value) => {
              setSelectedOption(value);
            }}
            options={additionalsSummaryOptions}
          />
        </TableItem>
        <TableItem width="400px" header justifyContent="right">
          Total de elementos seleccionados
        </TableItem>
        <TableItem width="200px">
          {`S/. ${parseFloatNumber(selectedAdditionalsAmount)}`}
        </TableItem>
        <TableItem width="200px">
          {`S/. ${parseFloatNumber(selectedAdditionalsPaidAmount)}`}
        </TableItem>
        <TableItem width="308px" header></TableItem>
      </TableSummaryContainer>
    </>
  );
};

export default TableSummary;
