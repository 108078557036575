import styled from "styled-components";

export const BannerContainer = styled.div`
  margin: 0 !important;
  padding: 0 !important;
  padding-bottom: 2rem !important;
  display: grid;
  grid-template-columns: auto auto auto;
  row-gap: 20px;
  column-gap: 60px;
  @media screen and (max-width: 1230px) {
    grid-template-columns: auto auto;
    column-gap: 20px;
  }
  @media screen and (max-width: 790px) {
    grid-template-columns: auto;
    justify-content: center;
  }
`;
