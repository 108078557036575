// Basic import
import React from "react";

// React icons import
import SendIcon from "../../assets/send-icon.svg";
import SignIcon from "../../assets/sign-icon.svg";
import DeactivateSignIcon from "../../assets/deactivate-sign-icon.svg";

// Styled components import
import { Container, Text, Icon } from "./styles";

const MainButton = ({ children, color, sign, height, disabled, onClick }) => {
  return (
    <Container
      borderColor={color}
      height={height}
      disabled={disabled}
      onClick={onClick}
    >
      <Text color={color}>{children}</Text>
      <Icon
        src={sign ? (disabled ? DeactivateSignIcon : SignIcon) : SendIcon}
        alt="main-button-icon"
      />
    </Container>
  );
};

export default MainButton;
