import styled from "styled-components";
import { Checkbox } from "antd";

export const ButtonContainer = styled.div`
  display: flex;
  gap: 30px;
  align-self: center;
  justify-content: center;
  margin: auto;
`;

export const ModalTitle = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
`;

export const ModalSubTitle = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  margin-bottom: 0.5rem;
`;

export const Indication = styled.p`
  margin-top: 16px;
  font-weight: 500;
`;

export const AnomaliesSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 3rem;
  padding-right: 3rem;
  margin-bottom: 1rem;
`;

export const AnomalyContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const ReportTypeLabel = styled.p`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 0 !important;
`;
