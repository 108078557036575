// React import
import React from "react";

// Components import
import Button from "../../../../../shared/components/Button";

// Styled components import
import { ButtonContainer, ModalSubTitle } from "./styles";

export default function Introduction({ hasBeenDelivered, onConfirm }) {
  return (
    <>
      <ModalSubTitle>
        {hasBeenDelivered ? (
          <p>
            <b>Tener en cuenta que:</b> Dado que la obra ya ha sido entregada ya
            es posible realizar una liquidación de obra definitiva. Esta opción
            será propuesta al final del proceso de generación de informe de
            liquidación. De optar por la liquidación de obra definitiva,
            recordar que es una acción definitiva e irreversible.
          </p>
        ) : (
          <p>
            <b>Tener en cuenta que:</b> No es posible realizar una liquidación
            de obra definitiva porque la obra no ha sido entregada aún.
          </p>
        )}
      </ModalSubTitle>
      <ButtonContainer>
        <Button
          background="#05BE8C"
          width="80px"
          fontWeight="600"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
          onClick={() => {
            onConfirm();
          }}
        >
          Continuar
        </Button>
      </ButtonContainer>
    </>
  );
}
