import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import Button from "../../../shared/components/Button";
import { Popconfirm, notification } from "antd";
import {
  deleteConstruction,
  editConstruction,
  getConstruction,
} from "../../services";
import {
  EditConstructionLabel,
  EditConstructionInput,
  EditConstructionContainer,
} from "./styles";

const EditConstructionModal = ({
  constructionId,
  closeModal,
  setModalLoading,
  reloadPortfolio,
}) => {
  const [name, setName] = useState("");
  const [direction, setDirection] = useState("");
  const [showDeletePopConfirm, setShowDeletePopConfirm] = useState(false);

  // Getting construction handlers
  const gettingErrorHandler = (type = "server") => {
    switch (type) {
      case "server":
        notification.error({
          message: "Error de servidor. Inténtelo de nuevo",
        });
        setModalLoading(false);
        closeModal();
        break;
      default:
        break;
    }
  };

  const gettingSuccessHandler = (response) => {
    setName(response.name);
    setDirection(response.direction);
    setModalLoading(false);
  };

  useEffect(() => {
    const fetchConstruction = async () => {
      setModalLoading(true);
      await getConstruction(
        constructionId,
        gettingErrorHandler,
        gettingSuccessHandler
      );
    };
    fetchConstruction();
  }, [constructionId]);

  // Handlers
  const editConstructionSuccessHandler = () => {
    setModalLoading(false);
    notification.success({
      message: "La información de la obra se ha actualizado exitosamente",
    });
    reloadPortfolio();
  };

  const editConstructionErrorHandler = (type = "server", message = "") => {
    setModalLoading(false);
    switch (type) {
      case "server":
        notification.error({
          message: "Error de servidor. Inténtelo de nuevo",
        });
        break;
      case "bad_request":
        notification.error({ message: message });
        break;
      default:
        break;
    }
  };

  const constructionEditHandler = async () => {
    if (name === "") {
      notification.error({
        message: "Debe registrar un nombre para la obra",
      });
    } else {
      setModalLoading(true);
      await editConstruction(
        constructionId,
        { name, direction },
        editConstructionErrorHandler,
        editConstructionSuccessHandler
      );
    }
  };

  const deleteConstructionSuccessHandler = () => {
    notification.success({
      message: "La obra ha sido eliminada exitosamente",
    });
    setModalLoading(false);
    closeModal();
    reloadPortfolio();
  };

  const deleteConstructionErrorHandler = (type = "server", message = "") => {
    setModalLoading(false);
    switch (type) {
      case "server":
        notification.error({
          message: "Error de servidor. Inténtelo de nuevo",
        });
        break;
      case "bad_request":
        notification.error({ message: message });
        break;
      default:
        break;
    }
  };

  const constructionDeleteHandler = async () => {
    setModalLoading(true);
    await deleteConstruction(
      constructionId,
      deleteConstructionErrorHandler,
      deleteConstructionSuccessHandler
    );
    setShowDeletePopConfirm(false);
  };

  return (
    <>
      <EditConstructionLabel>Nombre de la Obra:</EditConstructionLabel>
      <Row>
        <EditConstructionInput
          value={name}
          onChange={(e) => setName(e.target.value)}
          type="text"
          placeholder="Inserte nombre de la obra"
        />
      </Row>
      <EditConstructionLabel>Dirección de la Obra:</EditConstructionLabel>
      <Row>
        <EditConstructionInput
          value={direction}
          onChange={(e) => setDirection(e.target.value)}
          type="text"
          placeholder="Inserte la dirección de la obra"
        />
      </Row>
      <EditConstructionContainer>
        <Button
          width="150px"
          background={!name ? "#808080" : "#0169E4"}
          fontSize="15px"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
          borderRadius="5px"
          onClick={() => {
            constructionEditHandler();
          }}
          disabled={!name}
        >
          Guardar cambios
        </Button>
        <Popconfirm
          placement="topLeft"
          title="¿Esta seguro de eliminar la obra?"
          open={showDeletePopConfirm}
          onConfirm={() => {
            constructionDeleteHandler();
          }}
          onCancel={() => {
            setShowDeletePopConfirm(false);
          }}
          okText="Sí"
          cancelText="No"
        >
          <Button
            width="130px"
            background="#D93022"
            fontSize="15px"
            boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
            borderRadius="5px"
            onClick={() => {
              setShowDeletePopConfirm(true);
            }}
          >
            Eliminar Obra
          </Button>
        </Popconfirm>
      </EditConstructionContainer>
    </>
  );
};
export default EditConstructionModal;
