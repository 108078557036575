// React import
import React, { useState } from "react";

// Components import
import Footer from "./components/Footer";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import {
  PrivateLayoutBase,
  PrivateLayoutContainer,
  PrivateLayoutEnvironment,
  PrivateLayoutContent,
} from "./styles";
import Loading from "../../../shared/components/Loading";

// Context import
import { useAppContext } from "../../../App/context";

const PrivateLayout = (props) => {
  // Context values
  const { generalParams } = useAppContext();
  const { loading } = generalParams;
  // Component states
  const [isMenuActive, setIsMenuActive] = useState(true);
  return (
    <PrivateLayoutBase>
      <PrivateLayoutContainer fluid={true}>
        <Navbar
          isActive={props.isActive}
          history={props.history}
          setIsMenuActive={setIsMenuActive}
        />
        <PrivateLayoutEnvironment>
          {isMenuActive && <Sidebar />}
          <PrivateLayoutContent isMenuActive={isMenuActive}>
            {props.children}
          </PrivateLayoutContent>
        </PrivateLayoutEnvironment>
        <Footer />
      </PrivateLayoutContainer>
      <Loading active={loading} />
    </PrivateLayoutBase>
  );
};

export default PrivateLayout;
