import styled from "styled-components";

export const ButtonContainer = styled.div`
  display: flex;
  gap: 30px;
  align-self: center;
  justify-content: center;
  margin: auto;
`;

export const ModalTitle = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
`;
