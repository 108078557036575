import React from "react";
import "./styles.css";

const ValueItem = ({ value, label }) => {
  return (
    <div className="value_item_debt">
      <p className="blue bold font14 projectVal__mb1">{label}</p>
      <p className="bold font14 projectVal__mb1">{value}</p>
    </div>
  );
};

export default ValueItem;
