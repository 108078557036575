import styled from "styled-components";
import { Container } from "reactstrap";

const BellContainer = styled(Container)`
  height: 32px;
  width: 32px;
  ${(props) =>
    props.whiteMode ? "background-color: white" : "background-color: #004283"};
  ${(props) =>
    props.whiteMode &&
    `border-color: #004283;
    border-style: solid;
    border-width: 2px;
    `};
  border-radius: 50%;
  margin: auto 20px auto 0px;
  display: grid;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 380px) {
    margin-right: 8px;
  }
`;

export default BellContainer;
