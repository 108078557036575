import React, { useMemo, useState, useEffect } from "react";
import { useLocation } from "react-router";

// services
import { meService } from "./services";

const AppContext = React.createContext();

export function AppContextProvider(props) {
  const [generalParams, setGeneralParams] = useState({
    search: "",
    loading: true,
  });
  const [user, setUser] = useState({});
  const [projectId, setProjectId] = useState("");
  const [notifications, setNotifications] = useState([]);

  // project role setting
  const [projectRole, setProjectRole] = useState("");
  const [isProjectAdmin, setIsProjectAdmin] = useState(false);

  // private navbar setting
  const [projectName, setProjectName] = useState("");
  const [projectEnterprise, setProjectEnterprise] = useState("");

  const location = useLocation();

  const memoizedGeneralParams = useMemo(
    () => ({ generalParams }),
    [generalParams]
  );

  const meSuccessHandler = (response) => {
    setUser(response);
  };

  useEffect(() => {
    const fetchGetUser = async () => {
      setGeneralParams((prevState) => {
        return { ...prevState, loading: true };
      });
      await meService(() => {}, meSuccessHandler);
      setGeneralParams((prevState) => {
        return { ...prevState, loading: false };
      });
    };
    fetchGetUser();
  }, []);

  useEffect(() => {
    setProjectId(location.pathname);
  }, [location]);

  const memoizedUser = useMemo(() => ({ user }), [user]);

  const sharedData = {
    ...memoizedGeneralParams,
    setGeneralParams: setGeneralParams,
    ...memoizedUser,
    setUser: setUser,
    projectRole: projectRole,
    setProjectRole: setProjectRole,
    setIsProjectAdmin: setIsProjectAdmin,
    isProjectAdmin: isProjectAdmin,
    setProjectName: setProjectName,
    projectName: projectName,
    setProjectEnterprise: setProjectEnterprise,
    projectEnterprise: projectEnterprise,
  };

  return <AppContext.Provider value={sharedData} {...props} />;
}

export function useAppContext() {
  const context = React.useContext(AppContext);
  if (!context) {
    throw new Error("AppContext provider is required");
  }
  return context;
}
