import React from "react";

// components
import {
  CounterContainer,
  CounterTitle,
  CounterSubtitle,
  NumberContainer,
  Number,
  NumberLabel,
} from "./styles";

const Counter = ({
  title,
  subtitle,
  days = "00",
  hours = "00",
  min = "00",
}) => {
  return (
    <CounterContainer>
      <CounterTitle>{title}</CounterTitle>
      <CounterSubtitle>{subtitle}</CounterSubtitle>
      <NumberContainer>
        <div>
          <Number>{days}</Number>
          <NumberLabel>DIAS</NumberLabel>
        </div>
        <div>
          <Number>{hours}</Number>
          <NumberLabel>HORAS</NumberLabel>
        </div>
        <div>
          <Number>{min}</Number>
          <NumberLabel>MIN</NumberLabel>
        </div>
      </NumberContainer>
    </CounterContainer>
  );
};

export default Counter;
