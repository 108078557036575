import React from "react";

import { Container } from "./styles.jsx";

import { FaPlayCircle } from "react-icons/fa";

const TutorialButton = ({handleModalVideoTurial, videourl}) => {
  return (
    <Container>
      <FaPlayCircle size={20} style={{ cursor: "pointer" }} onClick={() => {
              handleModalVideoTurial(videourl);
          }}/>
    </Container>
  );
};

export default TutorialButton;
