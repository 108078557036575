// Basic imports
import React, { useState } from "react";

// Support libraries import
import { Popconfirm } from "antd";
import { useHistory } from "react-router-dom";

// Components imports
import MainButton from "../MainButton";
import { PanelContainer, PanelSuperContainer } from "./styles";
import { Button } from "../../../../../../../components/Button";

const MainButtonsPanel = ({
  user,
  project,
  preadditional,
  lastValorization,
  general_aproved,
  loadFinalReportValorization,
  sendpresentValorization,
  evaluatepresentValorization,
}) => {
  // Component states
  const [activePopSend, setActivePopSend] = useState(false);
  const [activePopEvaluate, setActivePopEvaluate] = useState(false);

  const history = useHistory();

  let signaturedDisabled = true;
  let sendEvalDisabled = true;
  if (user === "Ejecutor") {
    if (lastValorization.executor_sign_enable) {
      signaturedDisabled = false;
    }
    if (lastValorization.to_send_valorization) {
      sendEvalDisabled = false;
    }
  } else {
    if (lastValorization.supervisor_sign_enable) {
      signaturedDisabled = false;
    }
    if (lastValorization.to_send_evaluation) {
      sendEvalDisabled = false;
    }
  }
  if (!general_aproved) {
    signaturedDisabled = true;
    sendEvalDisabled = true;
  }

  // navigate to preadditionals modal

  const navigateToPreadditionalModal = () => {
    history.push(`/projects/${project.id}/val?additional=${preadditional.id}`);
  };

  return (
    <PanelSuperContainer>
      <PanelContainer>
        <MainButton
          color="#4A4A4A"
          sign={true}
          disabled={signaturedDisabled}
          onClick={() => {
            if (!signaturedDisabled) {
              loadFinalReportValorization();
            }
          }}
        >
          Firmar val.
        </MainButton>
        <MainButton
          color="#0169E4"
          disabled={sendEvalDisabled}
          onClick={() => {
            if (!sendEvalDisabled) {
              if (user === "Ejecutor") {
                setActivePopSend(true);
              } else {
                setActivePopEvaluate(true);
              }
            }
          }}
        >
          {user === "Ejecutor" ? "Envio de val." : "Evaluar val."}
        </MainButton>
        <Popconfirm
          placement="topLeft"
          title="¿Está seguro que desea enviar la valorización?"
          open={activePopSend}
          onConfirm={() => {
            sendpresentValorization();
            setActivePopSend(false);
          }}
          onCancel={() => setActivePopSend(false)}
          okText="Sí"
          cancelText="No"
        />
        <Popconfirm
          placement="topLeft"
          title="¿Está seguro que desea enviar la evaluación de la valorización?"
          open={activePopEvaluate}
          onConfirm={() => {
            evaluatepresentValorization();
            setActivePopEvaluate(false);
          }}
          onCancel={() => setActivePopEvaluate(false)}
          okText="Sí"
          cancelText="No"
        />
      </PanelContainer>
      <PanelContainer>
        <Button
          width="126px"
          height="26px"
          background="#f0f4f9"
          border="1px solid black"
          borderRadius="4px"
          color="#4A4A4A"
          fontSize="12px"
          margin="0px 0px 0px 0px"
          padding="0px 0px 0px 0px"
        >
          Gestión de pagos
        </Button>
        <Button
          background="#0169E4"
          fontSize="12px"
          width="126px"
          height="26px"
          margin="0px 0px 0px 0px"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
          onClick={() => {
            navigateToPreadditionalModal();
          }}
        >
          Lista adicionales
        </Button>
      </PanelContainer>
    </PanelSuperContainer>
  );
};

export default MainButtonsPanel;
