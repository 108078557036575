import styled from "styled-components";
import compartirImage from "./compartir.jpeg";

export const Title = styled.h3`
  text-align: center;
  color: #014083;
  margin-bottom: 10px;
  margin-left: -74px;
  margin-right: -74px;
`;

export const ModalContent = styled.div`
  .contenedor-modal {
    display: flex;
    flex-direction: column;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    background: #fafafa;
    padding: 15px;
    border-radius: 4px;
  }

  .parrafo-container {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 0;
    height: 24px;
    padding: 0 20px;
  }

  .parrafo-model {
    height: 14px;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    white-space: nowrap;
    color: #696969;
  }

  .dato-model {
    text-align: left;
    width: 160px;
    height: 30px;
    color: #014083;
  }
`;

export const SharesButton = styled.button`
  position: absolute;
  bottom: 20px;
  right: 20px;
  height: 30px;
  width: 30px;
  padding: 0;
  border: none;
  background-color: transparent;
  background-image: url(${compartirImage});
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  z-index: 9999;
`;
