import { baseProvider } from "../baseProvider";

const postProjectCronogram = async (id) =>
  baseProvider.httpPost(`/projects/${id}/cronogram_generator`);

const getProjectPrevalorizations = async (id) =>
  baseProvider.httpGet(`/projects/${id}/pre_valorizations`);

const postProjectPrevalorization = async (id) =>
  baseProvider.httpPost(`/projects/${id}/pre_valorizations`);

const patchPrevalorizationById = async (id, body) =>
  baseProvider.httpPatch(`/pre_valorizations/${id}`, body);

const deletePrevalorizationById = async (id) =>
  baseProvider.httpDelete(`/pre_valorizations/${id}`);

const postPreUpdatePrevalorization = async (id, body) =>
  baseProvider.httpPost(`/pre_valorizations/${id}/pre_update`, body);

export {
  postProjectCronogram,
  getProjectPrevalorizations,
  postProjectPrevalorization,
  patchPrevalorizationById,
  deletePrevalorizationById,
  postPreUpdatePrevalorization,
};
