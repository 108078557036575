import React from "react";
import * as FileSaver from "file-saver";

import { getDefaultTemplate } from "../../../providers";

import { EditProjectCard } from "../../EditProjectCard/EditProjectCard";
import { Section } from "./Section";

export const ValuationDocuments = ({
  id,
  setToggleUpdateProject,
  general_aproved,
  role,
  general_expenses_filename,
  valued_shedule_filename,
  contractual_budget_filename,
  setSpinLoading
}) => {
  const downloadDocument = async (provider, name) => {
    try {
      const response = await getDefaultTemplate(provider);
      const blob = await response.blob();
      FileSaver.saveAs(blob, name);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <EditProjectCard
      classname="editProject__valDocuments"
      title="6. Documentos de valorización"
    >
      <Section
        title="Presupuesto contractual"
        id={id}
        onClick={() =>
          downloadDocument(
            "contractual_budget",
            "contractual-budget-template.xlsx"
          )
        }
        provider="contractual_budget"
        setToggleUpdateProject={setToggleUpdateProject}
        general_aproved={general_aproved}
        name={contractual_budget_filename}
        role={role}
        setSpinLoading={setSpinLoading}
      />
      <br />
      <br />
      <Section
        title="Gastos Generales (fijos y variables)"
        id={id}
        onClick={() =>
          downloadDocument("general_expenses", "general-expenses-template.xlsx")
        }
        provider="general_expenses"
        setToggleUpdateProject={setToggleUpdateProject}
        general_aproved={general_aproved}
        name={general_expenses_filename}
        role={role}
        setSpinLoading={setSpinLoading}
      />
      <br />
      <br />
      <Section
        title="Cronograma Valorizado"
        id={id}
        onClick={() =>
          downloadDocument("valued_shedule", "valued-schedule-template.xlsx")
        }
        provider="valued_shedule"
        setToggleUpdateProject={setToggleUpdateProject}
        general_aproved={general_aproved}
        name={valued_shedule_filename}
        role={role}
        setSpinLoading={setSpinLoading}
      />
    </EditProjectCard>
  );
};
