import React from "react";
import { GiPaperClip } from "react-icons/gi";
import { IconContext } from "react-icons";

import "./styles.css";

const ChargeFileButton = ({ onClick, disabled, chargeDebtDocument }) => {

  const handlerClick = () =>{
    const input = document.getElementById("debt_document_charger");
    input.click();
  }

  return (
    <>
    <div className="debt_charge_file" onClick={handlerClick}>
      <p className="debt_charge_file_text">Adjuntar documento</p>
      <IconContext.Provider value={{ color: "#014083" }}>
        <GiPaperClip
          style={{ marginRight: "6px", marginTop:"4px" }}
          size={16}
          strokeWidth={12}
        />
      </IconContext.Provider>
      <input type="file" hidden id="debt_document_charger" onChange={(e)=>chargeDebtDocument(e.target.files)}/>
    </div>
    </>
  );
};

export default ChargeFileButton;
