import React, { useState, useEffect } from "react";

import * as FileSaver from "file-saver";

import {
  updateDebtById,
  getDebtDocumentsById,
  downloadDebtDocumentsById,
} from "../../../providers";

import { ToTalkModalSkeleton } from "../../Skeletons";
import DebtSummaryCard from "./DebtSummaryCard";
import { DebtValidationCard } from "./DebtValidationCard";
import SectionSelector from "./SectionSelector";



const sections = [
  {
    index: 0,
    label: "Deuda",
  },
  { index: 1, label: "Pagos" },
];

const DebtSummary = ({ debt, setDebt, user, setPayment, setShowReject, debtReload, openPaymentSummary }) => {
  //file names
  const [files, setFiles] = useState([]);
  const [charged, setCharged] = useState(true);

  const [section, setSection] = useState(1);

  // get debt info
  useEffect(() => {
    const fetchDebt = async () => {
      try {
        const _debtDocuments = await getDebtDocumentsById(debt.id);
        setFiles(_debtDocuments.results);
        setCharged(false);
      } catch (error) {
        console.log(error);
      }
    };
    debt && fetchDebt();
  }, [debt]);

  const handleDebtUpdate = async () => {
    try {
      setCharged(true);
      await updateDebtById(debt.id, {
        ...(debt.invoice_series && { invoice_series: debt.invoice_series }),
        ...(debt.invoice_number && { invoice_number: debt.invoice_number }),
      });
      setCharged(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getDebtDocument = async (document_id, name) => {
    try {
      const debt_document = await downloadDebtDocumentsById(document_id);
      const blob = await debt_document.blob();
      FileSaver.saveAs(blob, name);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {charged ? (
        <ToTalkModalSkeleton />
      ) : (
        <div>
          <SectionSelector
            sections={sections}
            activeSection={section}
            onClickSection={setSection}
          />
          <div className="projectVal__modal__cardsContainer">
            {section === 0 ? (
              <DebtValidationCard
                debt={debt}
                comments={debt.reject_comments}
                setComments={() => {}}
                getDebtDocument={getDebtDocument}
                chargeDebtDocument={() => {}}
                deleteDebtDocument={() => {}}
                enableActionsSupervisor={() => false}
                user={user}
                role="ADMIN"
                files={files}
                activateComments={false}
              />
            ) : (
              <DebtSummaryCard
                debt={debt}
                setDebt={setDebt}
                handleDebtUpdate={handleDebtUpdate}
                getDebtDocument={getDebtDocument}
                user={user}
                role="ADMIN"
                files={files}
                setPayment={setPayment}
                setShowReject={setShowReject}
                debtReload={debtReload}
                openPaymentSummary={openPaymentSummary}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default DebtSummary;
