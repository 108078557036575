import styled from "styled-components";

export const ItemContainer = styled.div`
  margin-top: 16px;
  margin-bottom: ${(props) => props.marginBottom};
`;

export const CardOutput = styled.div`
  height: 32px;
  width: ${(props) => props.width};
  background: #f3f3f3;
  border-radius: 2px;
  padding: 4px 12px;
  font-size: 14px;
`;
