import React from "react";
import "./styles.css";
import { ItemDiv } from "../../styles";

const Headers = ({ withValidation = true }) => {
  return (
    <div
      className={
        withValidation ? "payments_table_headers" : "payments_table_headers_min"
      }
    >
      <ItemDiv width="90px">
        <p className="blue bold font14 projectVal__mb1">Pagos</p>
      </ItemDiv>
      <ItemDiv width="100px">
        <p className="blue bold font14 projectVal__mb1">Monto</p>
      </ItemDiv>
      {withValidation && (
        <ItemDiv width="80px">
          <p className="blue bold font14 projectVal__mb1">Aprobación</p>
        </ItemDiv>
      )}
      <ItemDiv width="85px">
        <p className="blue bold font14 projectVal__mb1">Detalle</p>
      </ItemDiv>
      <ItemDiv width="104px">
        <p className="blue bold font14 projectVal__mb1">Estado</p>
      </ItemDiv>
    </div>
  );
};

export default Headers;
