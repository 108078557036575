import React, { useState } from "react";
import { Row } from "reactstrap";
import Button from "../../../shared/components/Button";
import { notification } from "antd";
import { createConstruction } from "../../services";
import {
  NewProjectlabel,
  NewProjectInput,
  NewProjectContainerbtns,
} from "./styles";

const NewConstructionModal = ({
  closeModal,
  setModalLoading,
  reloadPortfolio,
}) => {
  const [name, setName] = useState("");
  const [direction, setDirection] = useState("");

  const constructionSuccess = () => {
    setModalLoading(false);
    closeModal();
    notification.success({
      message: "La obra ha sido creada exitosamente",
    });
    setName("");
    setDirection("");
    reloadPortfolio();
  };

  const constructionCreationError = (type = "server", message = "") => {
    setModalLoading(false);
    closeModal();
    switch (type) {
      case "bad_creation":
        notification.error({
          message: message,
        });
        break;
      case "server":
        notification.error({
          message: "Error de servidor. Inténtelo de nuevo",
        });
        break;
      default:
        break;
    }
  };

  const submitHandler = async () => {
    setModalLoading(true);
    await createConstruction(
      { name, direction },
      constructionCreationError,
      constructionSuccess
    );
    reloadPortfolio();
  };

  return (
    <>
      <NewProjectlabel>Nombre de la obra:</NewProjectlabel>
      <Row>
        <NewProjectInput
          value={name}
          onFocus={(event) => event.target.select()}
          onChange={(e) => setName(e.target.value)}
          type="text"
          placeholder="Inserte nombre de la obra"
        />
      </Row>
      <NewProjectlabel>Dirección de la obra:</NewProjectlabel>
      <Row>
        <NewProjectInput
          value={direction}
          onFocus={(event) => event.target.select()}
          onChange={(e) => setDirection(e.target.value)}
          type="text"
          placeholder="Inserte la dirección de la obra"
        />
      </Row>
      <NewProjectContainerbtns>
        <Button
          width="130px"
          background={!name ? "#808080" : "#0169E4"}
          fontSize="15px"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
          borderRadius="5px"
          onClick={submitHandler}
          disabled={!name}
        >
          Crear Obra
        </Button>
        <Button
          width="130px"
          background="#D93022"
          fontSize="15px"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
          borderRadius="5px"
          onClick={() => {
            closeModal();
            setName("");
            setDirection("");
          }}
        >
          Cancelar
        </Button>
      </NewProjectContainerbtns>
    </>
  );
};
export default NewConstructionModal;
