import { baseProvider } from "../baseProvider";

const getValorizationsScreenSettings = async () =>
baseProvider.httpGet(`/valorizations/screen_settings`);

const getValorizations = async (id) =>
  baseProvider.httpGet(`/projects/${id}/valorizations`);

const getValorizationsSummary = async (id) =>
  baseProvider.httpGet(`/projects/${id}/valorizations/summary`);

const getpresentValorization = async (id) =>
  baseProvider.httpGet(`/projects/${id}/present_valorization`);

const getLastValorization = async (id) =>
  baseProvider.httpGet(`/projects/${id}/last_valorization`);

const getNotifications = async (id, params) =>
  baseProvider.httpGet(`/projects/${id}/notifications`, params);

const getAllNotifications = async () =>
  baseProvider.httpGet(`/notifications`);

const receiveNotifications = async (id) =>
  baseProvider.httpPatch(`/projects/${id}/notifications/recieve`);

const cargoNotification = async (id) =>
  baseProvider.httpPatch(`/notifications/${id}/cargo`);

const sendPresentValorization = async (id) =>
  baseProvider.httpPost(`/projects/${id}/present_valorization/send`);

const evaluatePresentValorization = async (id) =>
  baseProvider.httpPost(`/projects/${id}/present_valorization/evaluate`);

const getPresentValorizationReport = async (id) =>
  baseProvider.httpGetFile(`/projects/${id}/present_valorization/report`);

const getValorizationSendReportValorization = async (id) =>
baseProvider.httpGetFile(`/valorizations/${id}/send_report`);

const getValorizationEvaluationReportValorization = async (id) =>
baseProvider.httpGetFile(`/valorizations/${id}/evaluation_report`);

const getValorizationFinalReportValorization = async (id) =>
baseProvider.httpGetFile(`/valorizations/${id}/final_report`);

const signPresentValorization = async (id) =>
  baseProvider.httpPost(`/projects/${id}/present_valorization/sign`);

export {
  getValorizationsScreenSettings,
  getValorizations,
  getValorizationsSummary,
  getpresentValorization,
  getLastValorization,
  sendPresentValorization,
  evaluatePresentValorization,
  signPresentValorization,
  getPresentValorizationReport,
  getValorizationSendReportValorization,
  getValorizationEvaluationReportValorization,
  getValorizationFinalReportValorization,
  getNotifications,
  cargoNotification,
  receiveNotifications,
  getAllNotifications,
};
