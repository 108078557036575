import React, { useState, useEffect } from "react";

import * as FileSaver from "file-saver";

import {
  getDebtDocumentsById,
  downloadDebtDocumentsById,
  validateDebtById,
  rejectDebtById,
} from "../../../providers";

import { ToTalkModalSkeleton } from "../../Skeletons";
import { DebtValidationCard } from "./DebtValidationCard";
import { Button } from "../../Button";



const DebtValidation = ({
  debt,
  user,
  setReloadDebt,
  reloadDebt,
  notification,
}) => {
  //file names
  const [files, setFiles] = useState([]);
  const [comments, setComments] = useState("");
  const [charged, setCharged] = useState(true);

  // get debt info
  useEffect(() => {
    const fetchDebt = async () => {
      try {
        const _debtDocuments = await getDebtDocumentsById(debt.id);
        setComments(debt.rejected_comments);
        setFiles(_debtDocuments.results);
        setCharged(false);
      } catch (error) {
        console.log(error);
      }
    };
    debt && fetchDebt();
  }, [debt]);

  const getDebtDocument = async (document_id, name) => {
    try {
      const debt_document = await downloadDebtDocumentsById(document_id);
      const blob = await debt_document.blob();
      FileSaver.saveAs(blob, name);
    } catch (error) {
      console.log(error);
    }
  };

  const rejectDebt = async () => {
    try {
      setCharged(true);
      const response = await rejectDebtById(debt.id, {
        rejected_comments: comments,
      });
      if (response.detail) {
        if (response.detail[0] === "Se ha rechazado la deuda correctamente") {
          notification.success({
            message: response.detail,
          });
          setReloadDebt(!reloadDebt);
        } else {
          notification.error({
            message: response.detail,
          });
        }
      } else {
        if (response.rejected_comments) {
          notification.error({
            message: response.rejected_comments,
          });
        }
        setReloadDebt(!reloadDebt);
      }
      setCharged(false);
    } catch (error) {
      console.log(error);
    }
  };

  const validateDebt = async () => {
    try {
      setCharged(true);
      const response = await validateDebtById(debt.id);
      if (response.detail) {
        if (response.detail[0] === "Se valido con éxito la deuda") {
          notification.success({
            message: response.detail,
          });
          setReloadDebt(!reloadDebt);
        } else {
          notification.error({
            message: response.detail,
          });
        }
      } else {
        setReloadDebt(!reloadDebt);
      }
      setCharged(false);
    } catch (error) {
      console.log(error);
    }
  };

  const enableActionsSupervisor =
    user === "Ejecutor" && debt.status === "SENDED";

  return (
    <>
      {charged ? (
        <ToTalkModalSkeleton />
      ) : (
        <div>
          <p className="projectVal__modal__description">
            En este sección podrá editar valores de la deuda
          </p>
          <div className="projectVal__modal__cardsContainer">
            <DebtValidationCard
              debt={debt}
              comments={comments}
              setComments={setComments}
              getDebtDocument={getDebtDocument}
              chargeDebtDocument={() => {}}
              deleteDebtDocument={() => {}}
              enableActionsSupervisor={enableActionsSupervisor}
              user={user}
              role="ADMIN"
              files={files}
            />
          </div>
          <div className="projectVal__modal__btns">
            <Button
              background={enableActionsSupervisor ? "#05BE8C" : "#818181"}
              width="122px"
              fontWeight="600"
              boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
              onClick={() => {
                enableActionsSupervisor && validateDebt();
              }}
            >
              Validar deuda
            </Button>
            <Button
              background={enableActionsSupervisor ? "#D93022" : "#818181"}
              width="122px"
              fontWeight="600"
              boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
              onClick={() => {
                enableActionsSupervisor && rejectDebt();
              }}
            >
              Rechazar deuda
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default DebtValidation;
