import React, { useState, useEffect } from "react";
import { Row, Table } from "reactstrap";
import { Modal, notification } from "antd";
import { IconContext } from "react-icons";
import { TiPencil } from "react-icons/ti";

import { getCalendarDays, getHolidays } from "../../providers";

import { EditProjectCard } from "../EditProjectCard/EditProjectCard";
import { EditHolidaysModal } from "../EditHolidaysModal/EditHolidaysModal";
import { Button } from "../Button";
import Pen from "../../assets/pen.svg"

export const Calendar = ({
  calendar_days,
  projectId,
  updateProject,
  general_aproved,
  role,
}) => {
  // Component states
  const [holidays, setHolidays] = useState([]);
  const [toggleHolidays, setToggleHolidays] = useState(false);
  const [calendarDays, setCalendarDays] = useState([]);
  const [days, setDays] = useState([]);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  useEffect(() => {
    const _daysArr = [];
    // eslint-disable-next-line array-callback-return
    calendar_days.map((day) => {
      _daysArr.push(day.id);
    });
    setCalendarDays(_daysArr);
    const fetchCalendarDays = async () => {
      try {
        const _days = await getCalendarDays();
        setDays(_days.results);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCalendarDays();
  }, []);

  useEffect(() => {
    const fetchHolidays = async () => {
      try {
        const response = await getHolidays(projectId, {});
        const _holidays = response.results.map((holiday) => {
          return { ...holiday, key: holiday.id };
        });
        setHolidays(_holidays);
      } catch (error) {
        console.log(error);
      }
    };
    fetchHolidays();
  }, [toggleHolidays]);

  const reloadHolidays = () => {
    setToggleHolidays(!toggleHolidays);
  };

  const updateCalendarDays = (id) => {
    const isIncluded = calendarDays.includes(id);
    let _newArr = [];
    if (calendarDays.length === 1 && isIncluded) {
      notification.error({
        message: "Ocurrió un error",
        description: "Debe estar seleccionado al menos un día laboral",
      });
      return;
    }
    if (isIncluded) {
      _newArr = calendarDays.filter((elem) => elem !== id);
    } else {
      _newArr = [...calendarDays, id];
    }
    setCalendarDays(_newArr);
    updateProject({ calendar_days: _newArr });
  };

  return (
    <EditProjectCard classname="editProject__calendar" title="3. Calendario">
      <div className="editProject__calendar__container">
        <Row className="ramp">
          <p>Dias laborables</p>
        </Row>
        <Row className="ramp editProject__calendar__btnContainer">
          {days.map((day) => (
            <Button
              key={day.id}
              background={calendarDays.includes(day.id) ? "#0169E4" : "white"}
              color={calendarDays.includes(day.id) ? "" : "#0169E4"}
              border={calendarDays.includes(day.id) ? "" : "1px solid #0169E4"}
              height="28px"
              width="84px"
              onClick={() => updateCalendarDays(day.id)}
              disabled={general_aproved || role !== "ADMIN"}
            >
              {day.name}
            </Button>
          ))}
        </Row>
        <Row className="ramp editProject__calendar__holidays-header">
          <p>Feriados y dias excepcionales</p>
          <div className="projectEditpen" onClick={() => role === "ADMIN" && toggle()}>
            Editar
            <img
            src={Pen}
            alt="edit-icon"
            style={{marginLeft:"5px"}}
            />
          </div>
        </Row>
        <div className="editProject__calendar__container-table">
          <Table className="editProject__calendar__table">
            <tbody>
              {holidays.map((holiday) => (
                <tr key={holiday.id}>
                  <td>{holiday.name}</td>
                  <td>{holiday.date}</td>
                  <td>{holiday.day_name}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal
        title="Editar feriados y dias excepcionales"
        centered
        open={modal}
        onCancel={() => setModal(false)}
        footer={null}
        width={800}
      >
        <EditHolidaysModal
          holidays={holidays}
          projectId={projectId}
          reloadHolidays={reloadHolidays}
        />
      </Modal>
    </EditProjectCard>
  );
};
