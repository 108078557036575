// React import
import React, { useState, useEffect } from "react";

// Components import
import { Modal, notification } from "antd";
import LiquidationVisorModal from "./components/LiquidationVisorModal";
import Introduction from "./components/Introduction";
import ValorizationsValidation from "./components/ValorizationsValidation";
import AdditionalsValidation from "./components/AdditionalsValidation";
import DeductivesValidation from "./components/DeductivesValidation";
import LiquidationSetting from "./components/LiquidationSetting";
import AnomaliesSection from "./components/AnomaliesSection";
import AdvicesSection from "./components/AdvicesSection";
import Loading from "../../../shared/components/Loading";

// Styled components import
import { ModalTitle } from "./styles";

// Services import
import {
  getLiquidationPreliminarReport,
  sendLiquidationPreliminarReport,
  getLiquidationFinalReport,
  closeLiquidation,
  validateLiquidation,
  getLiquidationAdvices,
} from "../../../../providers";

export default function LiquidationModal({
  liquidationModalActive,
  setLiquidationModalActive,
  projectId,
  project,
  setMainLoading,
  history,
}) {
  //
  const [liquidationStep, setLiquidationStep] = useState(1);
  const [adviceLiquidationStep, setAdviceLiquidationStep] = useState(1);

  // Modal activators
  const [activeLiquidationVisorModal, setActiveLiquidationVisorModal] =
    useState(false);
  const [activeAdviceModal, setActiveAdviceModal] = useState(false);
  const [liquidationPreliminarReport, setLiquidationPreliminarReport] =
    useState("");
  const [loading, setLoading] = useState(false);

  const [validations, setValidations] = useState({
    valorizations_wo_billable_existence: false,
    additionals_proposals_existence: false,
    deductive_proposals_existence: false,
  });

  const [advices, setAdvices] = useState({
    valorizations: [],
    additionals: [],
    deductives: [],
  });

  // Pre liquidation steps
  let title = "";
  let section = <></>;

  // Liquidation validation steps
  let adviceTitle = "";
  let adviceSection = <></>;

  const getCurrentUTCDate = () => {
    const now = new Date(project.project_date);
    const date = now.getUTCDate().toString().padStart(2, "0");
    const month = (now.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = now.getUTCFullYear().toString().slice(-2);
    return `${date}/${month}/${year}`;
  };

  const datenow = getCurrentUTCDate();

  switch (liquidationStep) {
    case 1:
      title = `Ariane generará el informe de liquidación de obra a la fecha de hoy ${datenow}`;
      section = (
        <Introduction
          hasBeenDelivered={project.has_been_delivered}
          onConfirm={() => setLiquidationStep(2)}
        />
      );
      break;
    case 2:
      title = "Resumen de pago de valorizaciones";
      section = (
        <ValorizationsValidation
          projectId={projectId}
          setLoading={setLoading}
          onConfirm={() => setLiquidationStep(3)}
          onReject={() => setLiquidationStep(1)}
        />
      );
      break;
    case 3:
      title = "Resumen de pago de adicionales";
      section = (
        <AdditionalsValidation
          projectId={projectId}
          setLoading={setLoading}
          onConfirm={() => setLiquidationStep(4)}
          onReject={() => setLiquidationStep(2)}
        />
      );
      break;
    case 4:
      title = "Resumen de pago de deductivos";
      section = (
        <DeductivesValidation
          projectId={projectId}
          setLoading={setLoading}
          onReject={() => setLiquidationStep(3)}
          onConfirm={() => setLiquidationStep(5)}
        />
      );
      break;
    case 5:
      title = "Configuración de informe de liquidación";
      section = (
        <LiquidationSetting
          onConfirm={() =>
            project.has_been_delivered
              ? loadFinalLiquidationReport()
              : loadPreliminarLiquidationReport()
          }
        />
      );
      break;
    default:
      title = "";
      break;
  }

  switch (adviceLiquidationStep) {
    case 1:
      adviceTitle = "Pendientes de pago";
      adviceSection = (
        <AdvicesSection
          advices={advices}
          onAccept={() => {
            validationLiquidationHandler();
          }}
        />
      );
      break;
    case 2:
      adviceTitle = "Atención, Ariane a detectado las siguientes anomalías";
      adviceSection = (
        <AnomaliesSection
          validations={validations}
          onClose={() => {
            setActiveAdviceModal(false);
          }}
        />
      );
      break;
    default:
      adviceTitle = "";
      break;
  }

  useEffect(() => {
    setLiquidationStep(1);
  }, [liquidationModalActive]);

  const blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const loadPreliminarLiquidationReport = async () => {
    try {
      setMainLoading(true);
      const response = await getLiquidationPreliminarReport(projectId);
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        const json = await response.json();
        if (json.detail) {
          notification.success({
            message: json.detail,
          });
          setMainLoading(false);
          setTimeout(() => history.go(0), 3000);
        } else {
          notification.error({
            message: "Ocurrió un error interno. Por favor vuelva a intentarlo",
          });
          setMainLoading(false);
        }
      } else {
        const blobFile = await response.blob();
        const base64File = await blobToBase64(blobFile);
        setLiquidationPreliminarReport(base64File);
        setMainLoading(false);
        setActiveLiquidationVisorModal(true);
      }
    } catch (error) {
      notification.error({
        message: "Ocurrió un error interno. Por favor vuelva a intentarlo",
      });
      setMainLoading(false);
    }
  };

  const loadFinalLiquidationReport = async () => {
    try {
      setMainLoading(true);
      const response = await getLiquidationFinalReport(projectId);
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        const json = await response.json();
        if (json.detail) {
          notification.success({
            message: json.detail,
          });
          setMainLoading(false);
          setTimeout(() => history.go(0), 3000);
        } else {
          notification.error({
            message: "Ocurrió un error interno. Por favor vuelva a intentarlo",
          });
          setMainLoading(false);
        }
      } else {
        const blobFile = await response.blob();
        const base64File = await blobToBase64(blobFile);
        setLiquidationPreliminarReport(base64File);
        setMainLoading(false);
        setActiveLiquidationVisorModal(true);
      }
    } catch (error) {
      notification.error({
        message: "Ocurrió un error interno. Por favor vuelva a intentarlo",
      });
      setMainLoading(false);
    }
  };

  const sendPreliminarLiquidationReport = async (email) => {
    try {
      const validator = new RegExp(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      );
      if (!validator.test(email)) {
        notification.error({
          message: "Ingrese un correo electrónico válido",
        });
      } else {
        const response = await sendLiquidationPreliminarReport(projectId, {
          email: email,
        });
        notification.success({
          message: response.detail,
        });
      }
    } catch (error) {
      notification.error({
        message:
          "Ocurrío un error al enviar el reporte preliminar de liquidación",
      });
    }
  };

  const closeLiquidationHandler = async () => {
    try {
      const response = await closeLiquidation(projectId);
      notification.success({
        message: response.detail,
      });
    } catch (error) {
      notification.error({
        message: "Ocurrío un error al cerrar la liquidación",
      });
    }
  };

  const validationLiquidationHandler = async () => {
    try {
      setMainLoading(true);
      const response = await validateLiquidation(projectId);
      if (response.liquidation_enable) {
        await closeLiquidationHandler();
      } else {
        setAdviceLiquidationStep(2);
        setValidations(response);
        setActiveAdviceModal(true);
        setMainLoading(false);
      }
    } catch (error) {
      setActiveAdviceModal(false);
      setMainLoading(false);
      notification.error({
        message: "Ocurrío un error al validar la liquidación",
      });
    }
  };

  const getLiquidationAdvicesHandler = async () => {
    try {
      const response = await getLiquidationAdvices(projectId);
      if (
        response.valorizations.lenght === 0 &&
        response.additionals.lenght === 0 &&
        response.deductives.lenght === 0
      ) {
        await validationLiquidationHandler();
      } else {
        setAdviceLiquidationStep(1);
        setAdvices(response);
        setActiveAdviceModal(true);
        setMainLoading(false);
      }
    } catch (error) {
      setActiveAdviceModal(false);
      setMainLoading(false);
      notification.error({
        message:
          "Ocurrío un error al obtener las advertencias para la liquidación",
      });
    }
  };

  return (
    <>
      <Modal
        title={<ModalTitle>{title}</ModalTitle>}
        centered
        open={liquidationModalActive}
        onCancel={() => {
          setLiquidationModalActive(false);
        }}
        footer={null}
        size={534}
      >
        {section}
        <Loading active={loading} />
      </Modal>
      <LiquidationVisorModal
        active={activeLiquidationVisorModal}
        seeReport={liquidationPreliminarReport}
        setActiveModal={setActiveLiquidationVisorModal}
        setLiquidationModalActive={setLiquidationModalActive}
        hasBeenDelivered={project.has_been_delivered}
        sendPreliminarLiquidationReport={sendPreliminarLiquidationReport}
        closeLiquidationHandler={getLiquidationAdvicesHandler}
      />
      <Modal
        title={<ModalTitle>{adviceTitle}</ModalTitle>}
        centered
        open={activeAdviceModal}
        onCancel={() => {
          setActiveAdviceModal(false);
        }}
        footer={null}
        size={534}
      >
        {adviceSection}
      </Modal>
    </>
  );
}
