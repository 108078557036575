// Base import
import React from "react";

// Libraries import
import { Row } from "reactstrap";

// Components import
import MainButtonsPanel from "./components/MainButtonsPanel";
import Counter from "./components/Counter";
import ProjectInfoPanel from "./components/ProjectInfoPanel";

export const Qrome = ({
  user,
  lastValorization,
  general_aproved,
  project,
  preadditional,
  sendpresentValorization,
  evaluatepresentValorization,
  loadFinalReportValorization,
}) => {
  const getCurrentUTCDate = () => {
    const now = new Date();
    const date = now.getUTCDate().toString().padStart(2, "0");
    const month = (now.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = now.getUTCFullYear().toString().slice(-2);
    return `${date}.${month}.${year}`;
  };

  const datenow = getCurrentUTCDate();

  return (
    <Row className="ramp projectVal__qrome">
      <MainButtonsPanel
        user={user}
        project={project}
        preadditional={preadditional}
        lastValorization={lastValorization}
        general_aproved={general_aproved}
        loadFinalReportValorization={loadFinalReportValorization}
        sendpresentValorization={sendpresentValorization}
        evaluatepresentValorization={evaluatepresentValorization}
      />
      <Counter
        title={`Adicional N° ${preadditional.order}`}
        subtitle={preadditional.name}
      />
      <Counter
        title={`Etapa de valorización N° 1`}
        subtitle="Ingreso de metrado"
      />
      <ProjectInfoPanel dateToday={datenow}></ProjectInfoPanel>
    </Row>
  );
};
