import React from "react";
import { Input } from "antd";

export const Item = ({
  title,
  content,
  setContent,
  width,
  disabled,
  onBlur,
}) => {
  return (
    <div>
      <p className="blue bold font14 projectVal__mb1">{title}</p>
      {disabled ? (
        <div className="projectVal__card__output" style={{ width }}>
          {content}
        </div>
      ) : (
        <Input
          value={content}
          onFocus={(event) => event.target.select()}
          onChange={(e) => setContent(e.target.value)}
          onBlur={onBlur}
          disabled={title === "Monto"}
          style={{ width }}
        />
      )}
    </div>
  );
};
