import React, { useState } from "react";
import { Modal, Popconfirm } from "antd";
import { Button } from "../../../../../../../components/Button";

export default function ReportModal(props) {
  const [activePopValidate, setActivePopValidate] = useState(false);
  return (
    <Modal
      open={props.active}
      title=""
      width={1000}
      footer={null}
      bodyStyle={{ "text-align": "center" }}
      centered
      closable={false}
    >
      <iframe
        id="pdf-iframe"
        title="predeductive_report"
        src={props.report}
      ></iframe>
      <div className="projectVal__modal__btns">
        <Popconfirm
          placement="topLeft"
          title="He leído completamente el reporte y estoy seguro que deseo validar la propuesta de deductivo"
          open={activePopValidate}
          onConfirm={() => {
            setActivePopValidate(false);
            setTimeout(() => {
              props.validatePredeductiveReport();
            }, 250);
          }}
          onCancel={() => setActivePopValidate(false)}
          okText="Sí"
          cancelText="No"
        />
        <Button
          width="122px"
          fontWeight="600"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
          background={false ? "#92DECD" : "#05BE8C"}
          disabled={false}
          onClick={() => {
            setActivePopValidate(true);
          }}
        >
          Validar
        </Button>
        <Button
          width="122px"
          fontWeight="600"
          background="#D93022"
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.25)"
          disabled={false}
          onClick={() => {
            setActivePopValidate(false);
            setTimeout(() => {
              props.setActiveReportModal(false);
            }, 250);
          }}
        >
          No Validar
        </Button>
      </div>
    </Modal>
  );
}
