import styled from "styled-components";
import { Container } from "reactstrap";

export const PrivateLayoutBase = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
  background-size: 100% 100%;
  z-index: 300;
`;

export const PrivateLayoutContainer = styled(Container)`
  margin: 0 !important;
  padding: 0 !important;
`;

export const PrivateLayoutEnvironment = styled.div`
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  flex-direction: row;
  text-align: left;
  background-color: rgb(213,213,213);
  min-height: calc(100vh - 90.5px);
`;

export const PrivateLayoutContent = styled.div`
  margin: 0 !important;
  padding: 0 !important;
  ${(props) =>
    props.isMenuActive
      ? `width: calc(100% - 62px);
  padding-left: 1.5rem !important;
`
      : `height: 100%;width: 100%;
  padding-left: 2rem !important;
`}

  @media (max-width: 575px) {
    padding-left: 12px;
    padding-right: 12px;
  }
`;
