// Basic imports
import React from "react";
import { Input } from "antd";

// Components import
import Item from "../Item";
import Button from "../../../../../../shared/components/Button";

// Styled components import
import { ButtonContainer, Container, FileRowContainer } from "./styles";

const DeductiveCreationBanner = ({
  predeductive,
  setPredeductive,
  createDeductive,
}) => {
  const { TextArea } = Input;

  return (
    <>
      <Item
        title="Nombre"
        content={predeductive.name}
        width="100%"
        onChange={(value) => {
          setPredeductive({ ...predeductive, name: value });
        }}
        disabled={false}
        marginBottom={"20px"}
      />
      <Container style={{ marginBottom: "20px" }}>
        <FileRowContainer>
          <p className="blue bold font14" style={{ marginBottom: "2px" }}>
            Presupuesto de deductivo
          </p>
        </FileRowContainer>
        <FileRowContainer>
          <Button
            background="#76817E"
            width="140px"
            margin="10px 0px 0px 0px"
            fontWeight="600"
            boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
            onClick={() => {}}
          >
            Ver presupuesto
          </Button>
        </FileRowContainer>
      </Container>
      <>
        <p className="blue bold font14 projectVal__mb1">Justificación:</p>
        <TextArea
          value={predeductive.reason}
          onFocus={(event) => event.target.select()}
          onChange={(event) => {
            setPredeductive({ ...predeductive, reason: event.target.value });
          }}
          rows={4}
          className="projectVal__mb1"
          placeholder="Razón de la creación del deductivo"
          disabled={false}
        />
      </>
      <ButtonContainer>
        <Button
          background={"#05BE8C"}
          width="140px"
          fontWeight="600"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
          onClick={() => {
            createDeductive();
          }}
        >
          Crear
        </Button>
      </ButtonContainer>
    </>
  );
};

export default DeductiveCreationBanner;
