import appFetcher from "../../App/utils/http/AppFetcher";

// Predeductives services

export const getPreDeductive = async (id, errorHandler, successHandler) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/predeductives/${id}`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("no_found");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const getProject = async (id, errorHandler, successHandler) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/projects/${id}`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("no_found");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const getPreDeductiveParts = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/predeductives/${id}/parts`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("no_found");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const updatePreDeductive = async (
  id,
  body,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/predeductives/${id}`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "PATCH",
      body: body,
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("bad_request");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const updatePreDeductiveBudget = async (
  id,
  body,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/predeductives/${id}/budget`,
      isAuthRequired: true,
      requestContentType: "multipart/form-data",
      responseContentType: "json",
      method: "POST",
      body: body,
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("bad_request", response);
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const getPreDeductiveBudget = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/predeductives/${id}/budget`,
      isAuthRequired: true,
      responseContentType: "blob",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("no_found");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const validatePreDeductiveBudget = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/predeductives/${id}/budget/validation`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "POST",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("bad_request", response);
    }
  } catch (error) {
    errorHandler("server");
  }
};

// Deductives services

export const getDeductiveValorizations = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/deductives/${id}/valorizations`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("bad_request", response);
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const getDeductiveValorizedParts = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/ded_valorizations/${id}/valorized_parts`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("bad_request", response);
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const getPresentDeductiveValorization = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/deductives/${id}/present_valorization`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("bad_request", response);
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const updatePreDeductiveProposalPart = async (
  id,
  body,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/ded_proposal_parts/${id}`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "PATCH",
      body: body,
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("bad_request");
    }
  } catch (error) {
    errorHandler("server");
  }
};
