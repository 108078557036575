import styled from "styled-components";

export const EditConstructionLabel = styled.h1`
  color: #004283;
  margin-left: 8%;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 15px;
`;

export const EditConstructionInput = styled.input`
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
`;

export const EditConstructionContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 20px;
  margin-left: 8%;
  margin-right: 8%;
`;