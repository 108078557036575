// Basic import
import React from "react";

// Components imports
import { ValuationCard } from "../../ValuationCard";

// Styled components import
import { InfoProjectContainer } from "./styles";

const ProjectInfoPanel = ({ dateToday }) => {

  return (
    <InfoProjectContainer>
      <ValuationCard width="400px" background="#f0f4f9">
        <div className="projectInfo__contentDiv">
          <p className="grey font14">HOY:</p>
          <p className="grey font14 bold">{dateToday}</p>
        </div>
        <div className="projectInfo__contentDiv">
          <p className="grey font14 bold">0.00%</p>
          <p className="grey font14">Avance de adicional</p>
        </div>
        <div className="projectInfo__contentDiv">
          <p className="grey font14 bold">1</p>
          <p className="grey font14">Valorización de adicional</p>
        </div>
      </ValuationCard>
    </InfoProjectContainer>
  );
};
export default ProjectInfoPanel;
