// Basic imports
import React from "react";

// Components imports
import PredeductiveValorizationBanner from "../components/PredeductiveValorizationBanner";

const ProjectPreDeductivesPage = ({ history, match }) => {
  return <PredeductiveValorizationBanner history={history} match={match} />;
};

export default ProjectPreDeductivesPage;
