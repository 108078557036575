import styled from "styled-components";

export const IndicationContainer = styled.div`
  background: #f0f4f9;
  ${(props) =>
    props.indication &&
    `
  font-weight:bold;
  color: red;
  `}
  margin: 40px 50px 40px 50px;
  padding: 10px 10px 10px 10px;
`;
