// Basic imports
import React, { useState } from "react";
import {
  Row,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Popconfirm } from "antd";

// Components imports
import MainButton from "../MainButton";
import { PanelContainer } from "./styles.jsx";

const MainButtonsPanel = ({
  user,
  lastValorization,
  valorizations = [],
  setCurrentValorization,
  currentValorization,
  general_aproved,
  loadFinalReportValorization,
  loadValorizationSendReportValorization,
  loadValorizationEvaluationReportValorization,
  loadValorizationFinalReportValorization,
  sendpresentValorization,
  evaluatepresentValorization,
}) => {
  const [dropdownActive, setDropdownActive] = useState(false);
  // Pops activation
  const [activePopSend, setActivePopSend] = useState(false);
  const [activePopEvaluate, setActivePopEvaluate] = useState(false);

  let signaturedDisabled = true;
  let sendEvalDisabled = true;
  let reportGenerated = lastValorization.previous_report_generated || false;
  let reportIsBeingGenerated =
    lastValorization.report_is_being_generated || false;

  if (user === "Ejecutor") {
    if (lastValorization.executor_sign_enable) {
      signaturedDisabled = false;
    }
    if (lastValorization.to_send_valorization) {
      sendEvalDisabled = false;
    }
  } else {
    if (lastValorization.supervisor_sign_enable) {
      signaturedDisabled = false;
    }
    if (lastValorization.to_send_evaluation) {
      sendEvalDisabled = false;
    }
  }
  if (!general_aproved || currentValorization.id !== lastValorization.id) {
    signaturedDisabled = true;
    sendEvalDisabled = true;
  }

  const toggleValorizationsDropdown = () => {
    setDropdownActive(!dropdownActive);
  };

  const onClickSelectHandler = (valorization) => {
    setCurrentValorization(valorization);
  };

  const CustomDropdown = () => {
    return (
      <Dropdown isOpen={dropdownActive} toggle={toggleValorizationsDropdown}>
        <DropdownToggle
          caret
          style={{
            width: "120px",
            height: "26px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span style={{ marginRight: "4px" }}>
            {currentValorization
              ? `Valorización ${currentValorization.order}`
              : "Valorizaciones"}
          </span>
        </DropdownToggle>
        <DropdownMenu>
          {valorizations.map((valorization) => (
            <DropdownItem
              key={valorization.order}
              onClick={() => onClickSelectHandler(valorization)}
            >{`Valorización ${valorization.order} ${
              valorization.id === lastValorization.id ? "(En curso)" : ""
            }`}</DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  };

  return (
    <>
      <PanelContainer>
        <CustomDropdown />
        <MainButton
          color={signaturedDisabled ? "#4A4A4A" : "#05BE8C"}
          sign={true}
          disabled={signaturedDisabled}
          onClick={() => {
            if (!signaturedDisabled) {
              loadFinalReportValorization();
            }
          }}
        >
          {currentValorization.is_closed
            ? "Val. firmada"
            : signaturedDisabled
            ? "Crear reporte"
            : reportGenerated
            ? "Firmar val."
            : reportIsBeingGenerated
            ? "Generando reporte"
            : "Crear reporte"}
        </MainButton>
        <MainButton
          color="#0169E4"
          disabled={sendEvalDisabled}
          onClick={() => {
            if (!sendEvalDisabled) {
              if (user === "Ejecutor") {
                setActivePopSend(true);
              } else {
                setActivePopEvaluate(true);
              }
            }
          }}
        >
          {user === "Ejecutor"
            ? currentValorization.is_closed ||
              currentValorization.exist_valorization_report
              ? "Val. enviada"
              : "Envio de val."
            : currentValorization.is_closed ||
              currentValorization.exist_evaluation_report
            ? "Val. evaluada"
            : "Evaluar val."}
        </MainButton>
        <Popconfirm
          placement="topLeft"
          title="¿Está seguro que desea enviar la valorización?"
          open={activePopSend}
          onConfirm={() => {
            sendpresentValorization();
            setActivePopSend(false);
          }}
          onCancel={() => setActivePopSend(false)}
          okText="Sí"
          cancelText="No"
        />
        <Popconfirm
          placement="topLeft"
          title="¿Está seguro que desea enviar la evaluación de la valorización?"
          open={activePopEvaluate}
          onConfirm={() => {
            evaluatepresentValorization();
            setActivePopEvaluate(false);
          }}
          onCancel={() => setActivePopEvaluate(false)}
          okText="Sí"
          cancelText="No"
        />
      </PanelContainer>
      <PanelContainer>
        <MainButton
          width="100px"
          color={"#000000"}
          sign={true}
          disabled={!currentValorization.exist_valorization_report}
          onClick={() => {
            if (currentValorization.exist_valorization_report) {
              loadValorizationSendReportValorization();
            }
          }}
          document={true}
        >
          Inf. envío val.
        </MainButton>
        <MainButton
          width="100px"
          color={"#000000"}
          disabled={!currentValorization.exist_evaluation_report}
          onClick={() => {
            if (currentValorization.exist_evaluation_report) {
              loadValorizationEvaluationReportValorization();
            }
          }}
          document={true}
        >
          Inf. eval. val.
        </MainButton>
        <MainButton
          width="100px"
          color={"#000000"}
          sign={true}
          disabled={
            !(
              currentValorization.exist_final_report &&
              currentValorization.is_closed
            )
          }
          onClick={() => {
            if (currentValorization.exist_final_report) {
              loadValorizationFinalReportValorization();
            }
          }}
          document={true}
        >
          Inf. final val.
        </MainButton>
      </PanelContainer>
    </>
  );
};

export default MainButtonsPanel;
