// React import
import React from "react";

// Third party import
import { Link } from "react-router-dom";

// Components import
import { Progress } from "reactstrap";
import { IconContext } from "react-icons";
import { FiSettings } from "react-icons/fi";
import { TiGroup } from "react-icons/ti";

// Assets import
import defaultImage from "../../assets/fondo.jpg";
import smaller from "../../assets/smaller.svg";

// Styles import
import "./styles.css";

import {
  ProjectContainer,
  ProjectImageContainer,
  ProjectInfoContainer,
  ImageContainer,
  ButtonConfigProject,
  ImageConfigProject,
  ProjectConfig,
  ProjectConfigContainer,
  ProjectButton,
  ProjectButtonContainer,
  ProjectButtomColumn,
} from "./styles";

const ProjectCard = ({
  id,
  name,
  direction,
  percentage,
  is_admin,
  total_project_cost,
  status,
  initial_date,
  final_date,
  image,
  general_aproved,
}) => {
  let color = "success";
  let color_status = "projectCard__status projectCard__success";
  let status_text = "";
  if (status < -2) {
    color = "danger";
    color_status = "projectCard__status projectCard__error";
    status_text = "Retrasada";
    if (status < -10) {
      status_text = "Muy Retrasada";
    }
  } else {
    status_text = "En tiempo";
    if (status > 2) {
      status_text = "Avanzada";
      if (status > 10) {
        status_text = "Muy Avanzada";
      }
    }
  }

  const getValorizationLink = (id, name) => {
    if (general_aproved) {
      window.sessionStorage.setItem("project_id", id);
      window.sessionStorage.setItem("project_name", name);
      return `/projects/${id}/val`;
    }
    return null;
  };

  const getProjectEditLink = (id, name) => {
    window.sessionStorage.setItem("project_id", id);
    window.sessionStorage.setItem("project_name", name);
    return `/projects/${id}/edit`;
  };

  const getProjectEditMatesLink = (id, name) => {
    window.sessionStorage.setItem("project_id", id);
    window.sessionStorage.setItem("project_name", name);
    return `/projects/${id}/edit/mates`;
  };

  return (
    <ProjectContainer className="ramp" key={id}>
      <ProjectInfoContainer>
        <div>
          <span style={{ fontSize: "20px" }}>{name}</span>
          <br />
          Inicio: <span style={{ fontWeight: "normal" }}>{initial_date}</span>
          <br />
          Fin: <span style={{ fontWeight: "normal" }}>{final_date}</span>
          <br />
          <span className={color_status}>{status_text}</span>
          <Progress
            className="projectCard__bar"
            value={parseInt(percentage) + "%"}
            color={color}
          >
            {parseInt(percentage) + "%"}
          </Progress>
          Monto de contrato (inc.IGV): <br />
          <span style={{ fontWeight: "normal" }}>
            S./{Number(total_project_cost).toFixed(2)}
          </span>
          <br />
          Dirección: <br />
          <span style={{ fontWeight: "normal" }}>{direction}</span>
        </div>
        <ProjectButtonContainer>
          <ProjectButtomColumn>
            <ProjectButton onClick={() => {}} style={{ background: "#0169E4" }}>
              MW
            </ProjectButton>
            Supervisión
          </ProjectButtomColumn>
          <ProjectButtomColumn>
            <ProjectButton onClick={() => {}}>YMSA</ProjectButton>
            Ejecución
          </ProjectButtomColumn>
        </ProjectButtonContainer>
      </ProjectInfoContainer>
      <ProjectImageContainer>
        {general_aproved ? (
          <span
            style={{ color: "#05BE8C", fontSize: "16px", marginRight: "8%" }}
          >
            Activo
          </span>
        ) : (
          <>
            <span
              style={{ color: "#D93022", fontSize: "16px", marginRight: "8%" }}
            >
              En configuración
            </span>
            <span
              style={{ color: "#D93022", fontSize: "11px", marginRight: "8%" }}
            >
              Aprobación de condiciones de contrato son necesarios
            </span>
          </>
        )}{" "}
        <Link
          to={() =>
            general_aproved
              ? getValorizationLink(id, name)
              : getProjectEditLink(id, name)
          }
        >
          <ImageContainer
            imageUrl={
              image
                ? `${image["content-type"]};base64, ${image.data}`
                : defaultImage
            }
          >
            <ButtonConfigProject>
              <ImageConfigProject src={smaller} alt="edit-icon" />
            </ButtonConfigProject>
          </ImageContainer>{" "}
        </Link>
        <ProjectConfig>
          <IconContext.Provider value={{ color: "black" }}>
            {is_admin ? (
              <Link to={() => getProjectEditMatesLink(id, name)}>
                <ProjectConfigContainer>
                  <TiGroup size={40} />
                  Equipo
                </ProjectConfigContainer>
              </Link>
            ) : (
              <Link
                to={"#"}
                style={{ textDecoration: "none", cursor: "default" }}
              >
                <ProjectConfigContainer>
                  <TiGroup size={40} />
                  Equipo
                </ProjectConfigContainer>
              </Link>
            )}
          </IconContext.Provider>
          <IconContext.Provider value={{ color: "black" }}>
            {is_admin ? (
              <Link to={() => getProjectEditLink(id, name)}>
                <ProjectConfigContainer>
                  <FiSettings size={40} />
                  Configuración
                </ProjectConfigContainer>
              </Link>
            ) : (
              <Link
                to={"#"}
                style={{ textDecoration: "none", cursor: "default" }}
              >
                <ProjectConfigContainer>
                  <FiSettings size={40} />
                  Configuración
                </ProjectConfigContainer>
              </Link>
            )}
          </IconContext.Provider>
        </ProjectConfig>
      </ProjectImageContainer>
    </ProjectContainer>
  );
};

export default ProjectCard;
