import React from "react";
import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaYoutube,
  FaInstagram,
} from "react-icons/fa";
import { IconContext } from "react-icons";

import { BaseFooter, FooterRow, FooterMainText } from "./styles";
//TODO: Evaluate deletion of css
import "./styles.css";

const Footer = () => {
  return (
    <BaseFooter>
      <FooterRow>
        <div>
          <IconContext.Provider value={{ color: "#4267b2" }}>
            <FaFacebook size={20} className="footer__icons" />
          </IconContext.Provider>
          <IconContext.Provider value={{ color: "#1A91DA" }}>
            <FaTwitter size={20} className="footer__icons" />
          </IconContext.Provider>
          <IconContext.Provider value={{ color: "#067EA3" }}>
            <FaLinkedin size={20} className="footer__icons" />
          </IconContext.Provider>
          <IconContext.Provider value={{ color: "#FF0000" }}>
            <FaYoutube size={20} className="footer__icons" />
          </IconContext.Provider>
          <IconContext.Provider value={{ color: "#C12E87" }}>
            <FaInstagram size={20} className="footer__icons" />
          </IconContext.Provider>
        </div>
        <div>
          <FooterMainText>
            © 2023 todos los derechos reservados ARIANE INC
          </FooterMainText>
        </div>
      </FooterRow>
    </BaseFooter>
  );
};

export default Footer;
