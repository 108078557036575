import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

//pages
import ProjectValorizationPage from "../pages/ProjectValorizationPage";

const ProjectValorizationRouter = () => {
  return (
    <Switch>
      <Route
        exact
        path="/projects/:id/val"
        component={ProjectValorizationPage}
      />
      <Route
        exact
        path="/projects/:id/pre-val"
        component={ProjectValorizationPage}
      />
      <Redirect to="/main" />
    </Switch>
  );
};

export default ProjectValorizationRouter;
