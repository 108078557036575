import styled from "styled-components";
import { Modal as BaseModal } from "antd";

export const ButtonContainer = styled.div`
  display: flex;
  gap: 30px;
  align-self: center;
  justify-content: center;
  margin: auto;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: ${({ showMobileModal }) =>
    showMobileModal ? "column-reverse" : "row"};
`;

export const ModalTitle = styled.p`
  font-family: Mulish;
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px;
  line-height: 22px !important;
  color: #014083 !important;
  margin-bottom: 32px;
`;

export const Modal = styled(BaseModal)`
  .ant-modal-body {
    padding: 0;
  }
`;

export const ToggleButton = styled.img`
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  ${({ showMobileModal }) =>
    showMobileModal &&
    `
    margin-top: 0;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
  `}
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ModalCloseButton = styled.img`
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
`;

export const TableContainer = styled.div`
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 16px;
`;

export const Indication = styled.p`
  margin-top: 16px;
  font-weight: 500;
`;
