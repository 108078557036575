import React, { useState } from "react";

// Services
import { loginService } from "../services";
// Utils
import { getLoginBody } from "../utils";
// Components
import LoginForm from "../components/LoginForm";

const LoginPage = () => {
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState({ show: false, message: "" });

  const setForm = (key, value) => {
    switch (key) {
      case "username":
        setCredentials({ ...credentials, username: value });
        break;
      case "password":
        setCredentials({ ...credentials, password: value });
        break;
      default:
        break;
    }
  };

  const loginErrorHandler = (type = "server") => {
    switch (type) {
      case "server":
        setError({ show: true, message: "Usuario o contraseña incorrectos" });
        break;
      case "no_credentials":
        setError({ show: true, message: "Falta ingresar credenciales" });
        break;
      default:
        break;
    }
  };

  const submitHandler = async () => {
    const { body, error } = getLoginBody(credentials, loginErrorHandler);
    !error && (await loginService(body, loginErrorHandler));
  };

  return (
    <LoginForm
      credentials={credentials}
      error={error}
      setForm={setForm}
      submitHandler={submitHandler}
    />
  );
};

export default LoginPage;
