import React, { useEffect, useState } from "react";

import { CardDiv, Modal, CardContainer } from "./styles";

import DebtMainCard from "./index";

import { getDeductiveValorizationsSummary } from "../../providers";

export default function DeductiveDebtModal({
  debtModalActive,
  setDebtModalActive,
  user,
  deductive_id,
  debt_id,
  currentValorization,
}) {
  const [reloadValorizationsSummary, setReloadValorizationsSummary] =
    useState(true);
  const [valorizations, setValorizations] = useState([]);
  const [charged, setCharged] = useState(true);
  const [activeValorization, setActiveValorization] = useState(
    currentValorization.order
  );
  const [activeDebtId, setActiveDebtId] = useState(debt_id);
  const [showLeftMenu, setShowLeftMenu] = useState(true);
  const [showMobileModal, setShowMobileModal] = useState(false);

  useEffect(() => {
    setActiveValorization(currentValorization.order);
    setActiveDebtId(currentValorization.debt);
  }, [currentValorization]);

  useEffect(() => {
    const fetchValorizations = async () => {
      try {
        setCharged(true);
        const response = await getDeductiveValorizationsSummary(deductive_id);
        setValorizations(response.results);
        setCharged(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchValorizations();
  }, [deductive_id, debtModalActive, reloadValorizationsSummary]);

  useEffect(() => {
    setShowMobileModal(window.innerWidth < 1118);
  }, [window.innerWidth]);

  return (
    <>
      <Modal
        centered
        open={debtModalActive}
        onCancel={() => {
          setDebtModalActive(false);
        }}
        closable={false}
        footer={null}
        width={520}
      >
        <CardContainer showMobileModal={showMobileModal}>
          <CardDiv order="second" showMobileModal={showMobileModal}>
            <DebtMainCard
              debtModalActive={debtModalActive}
              setDebtModalActive={setDebtModalActive}
              activeValorization={activeValorization}
              user={user}
              debt_id={debt_id}
              reloadValorizationsSummary={reloadValorizationsSummary}
              setReloadValorizationsSummary={setReloadValorizationsSummary}
            />
          </CardDiv>
        </CardContainer>
      </Modal>
    </>
  );
}
