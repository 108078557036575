import { baseProvider } from "../baseProvider";

const postFileXlsx = async (name, id, body) =>
  baseProvider.httpPostForm(`/projects/${id}/${name}`, body);

const getFileXlsx = async (name, id) =>
  baseProvider.httpGetFile(`/projects/${id}/${name}`);

//Default Project Files
const getDefaultTemplate = async (name) =>
  baseProvider.httpGetFile(`/projects/default_files/${name}`);

export { postFileXlsx, getFileXlsx, getDefaultTemplate };
