import React, { useState } from "react";
import { Row, Col, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { IconContext } from "react-icons";
import { IoIosFunnel, IoMdSend } from "react-icons/io";

import profile from "../../../../assets/profile.jpg";
import "./Header.css";

export const Header = ({ current_order, user, setStatus }) => {
  const [dropdown, setDropdown] = useState(false);
  const [dropdown1, setDropdown1] = useState(false);

  const [filters, setFilters] = useState({
    NO_STARTED: false,
    TO_SEND: false,
    PRE_SENDED: false,
    SENDED: false,
    PRE_REJECTED: false,
    REJECTED: false,
    PRE_VALIDATED: false,
    VALIDATED: false,
    DEBATABLE: false,
    "SENDED*": false,
    "REJECTED*": false,
  });

  const handleStatusChange = (e) => {
    //console.log("name", e.target.name);
    //console.log("isChecked", e.target.checked);
    let name = e.target.name;
    let isChecked = e.target.checked;
    let _filters = filters;
    let _status = [];
    if (name === "NO_STARTED") {
      _filters = { ...filters, NO_STARTED: isChecked };
      setFilters({
        ...filters,
        NO_STARTED: isChecked,
      });
    }

    if (name === "TO_SEND") {
      _filters = { ...filters, TO_SEND: isChecked };
      setFilters({
        ...filters,
        TO_SEND: isChecked,
      });
    }

    if (name === "PRE_SENDED") {
      _filters = { ...filters, PRE_SENDED: isChecked };
      setFilters({
        ...filters,
        PRE_SENDED: isChecked,
      });
    }

    if (name === "SENDED") {
      _filters = { ...filters, SENDED: isChecked };
      setFilters({
        ...filters,
        SENDED: isChecked,
      });
    }
    if (name === "PRE_REJECTED") {
      _filters = { ...filters, PRE_REJECTED: isChecked };
      setFilters({
        ...filters,
        PRE_REJECTED: isChecked,
      });
    }
    if (name === "REJECTED") {
      _filters = { ...filters, REJECTED: isChecked };
      setFilters({
        ...filters,
        REJECTED: isChecked,
      });
    }
    if (name === "PRE_VALIDATED") {
      _filters = { ...filters, PRE_VALIDATED: isChecked };
      setFilters({
        ...filters,
        PRE_VALIDATED: isChecked,
      });
    }
    if (name === "VALIDATED") {
      _filters = { ...filters, VALIDATED: isChecked };
      setFilters({
        ...filters,
        VALIDATED: isChecked,
      });
    }
    if (name === "DEBATABLE") {
      _filters = { ...filters, DEBATABLE: isChecked };
      setFilters({
        ...filters,
        DEBATABLE: isChecked,
      });
    }
    if (name === "SENDED*") {
      _filters = { ...filters, "SENDED*": isChecked };
      setFilters({
        ...filters,
        "SENDED*": isChecked,
      });
    }
    if (name === "REJECTED*") {
      _filters = { ...filters, "REJECTED*": isChecked };
      setFilters({
        ...filters,
        "REJECTED*": isChecked,
      });
    }

    for (const property in _filters) {
      if (_filters[property]) {
        _status.push(property);
      }
    }
    if (
      _status.length === 0 ||
      _status.length === Object.keys(filters).length
    ) {
      setStatus([]);
    } else {
      _status.push("HAS_CHILDREN");
      setStatus(_status);
    }
  };

  return (
    <div>
      <div className="headerVal__sticky">
        <div className="headerVal__subheader">
          {current_order !== 1 && (
            <div
              className="headerVal__secondHeader headerVal__valItems"
              style={{ borderLeft: "solid 1px #f3f3f3" }}
            >
              VALORIZACIÓN ANTERIOR
            </div>
          )}
          <div
            className="headerVal__secondHeader headerVal__user"
            style={{ width: `${user === "Ejecutor" ? "584px" : "619px"}` }}
          >
            <img
              alt="profile_val_part"
              src={profile}
              className="headerVal__profileImg"
            />
            <p className="headerVal__text">
              {user === "Ejecutor" ? "EJECUCIÓN" : "SUPERVISIÓN"}
            </p>
            <p></p>
          </div>
          <div className="headerVal__secondHeader headerVal__valItems">
            VALORIZACIÓN PRESENTE
          </div>
          <div className="headerVal__secondHeader headerVal__valItems">
            ACUMULADO ACTUAL
          </div>
          <div className="headerVal__secondHeader headerVal__valItems">
            SALDO POR VALORIZAR
          </div>
        </div>

        <div className="headerVal__subheader">
          {current_order !== 1 && (
            <>
              <div
                className="headerVal__content"
                style={{ width: "72px", borderLeft: "solid 1px #f3f3f3" }}
              >
                <p className="headerVal__text">Metrado</p>
              </div>
              <div className="headerVal__content" style={{ width: "82px" }}>
                <p className="headerVal__text">Monto (S/.)</p>
              </div>
              <div
                className="headerVal__content"
                style={{ width: "65px", borderRight: "solid 2px #f3f3f3" }}
              >
                <p className="headerVal__text">%Avance</p>
              </div>
            </>
          )}

          <div className="headerVal__content" style={{ width: "46px" }}>
            <Dropdown
              isOpen={dropdown}
              toggle={() => setDropdown(!dropdown)}
              className="headerVal__alignCenter"
            >
              <DropdownToggle
                tag="span"
                data-toggle="dropdown"
                aria-expanded={dropdown}
              >
                {" "}
                <p className="headerVal__text">
                  <IconContext.Provider value={{ color: "black" }}>
                    <IoIosFunnel size={14} style={{ cursor: "pointer" }} />
                  </IconContext.Provider>{" "}
                </p>
              </DropdownToggle>
              <DropdownMenu className="headerVal__dropdown">
                <Row className="ramp headerVal__dropdown__button-mr">
                  <Col className="ramp headerVal__alignCenter" xs="6">
                    <button className="headerVal__dropdown__button headerVal__dropdown__buttonAll">
                      Todos
                    </button>
                  </Col>
                  <Col className="ramp headerVal__alignCenter" xs="6">
                    <button className="headerVal__dropdown__button headerVal__dropdown__buttonNone">
                      Ninguno
                    </button>
                  </Col>
                </Row>{" "}
                <div>
                  <input name="check" type="checkbox" /> Checked
                </div>
                <div>
                  {" "}
                  <input name="no_check" type="checkbox" /> No Checked
                </div>
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className="headerVal__content" style={{ width: "72px" }}>
            <p className="headerVal__text">Metrado</p>
          </div>
          <div className="headerVal__content" style={{ width: "46px" }}></div>
          <div className="headerVal__content" style={{ width: "82px" }}>
            <p className="headerVal__text">Monto (S/.)</p>
          </div>
          <div className="headerVal__content" style={{ width: "65px" }}>
            <p className="headerVal__text">%Avance</p>
          </div>
          <div
            hidden={user === "Supervisor"}
            className="headerVal__content"
            style={{ width: "35px" }}
          >
            <IconContext.Provider value={{ color: "#808080" }}>
              <IoMdSend
                size={18}
                className="navbar__menu-icon"
                style={{ marginLeft: "8px" }}
              />
            </IconContext.Provider>
          </div>
          <div
            hidden={user === "Ejecutor"}
            className="headerVal__content"
            style={{ width: "35px" }}
          ></div>
          <div
            hidden={user === "Ejecutor"}
            className="headerVal__content"
            style={{ width: "35px" }}
          ></div>
          <div className="headerVal__content" style={{ width: "46px" }}></div>
          <div className="headerVal__content" style={{ width: "46px" }}></div>
          <div className="headerVal__content" style={{ width: "46px" }}></div>
          <div
            className="headerVal__content"
            style={{
              width: "100px",
              borderRight: "solid 2px #f3f3f3",
              cursor: "pointer",
            }}
          >
            <Dropdown
              isOpen={dropdown1}
              toggle={() => setDropdown1(!dropdown1)}
              className="headerVal__alignCenter"
            >
              <DropdownToggle
                tag="span"
                data-toggle="dropdown"
                aria-expanded={dropdown1}
              >
                <p className="headerVal__text">
                  Estatus &nbsp;
                  <IconContext.Provider value={{ color: "black" }}>
                    <IoIosFunnel size={14} />
                  </IconContext.Provider>
                </p>
              </DropdownToggle>
              <DropdownMenu className="headerVal__dropdown">
                <Row className="ramp headerVal__dropdown__button-mr">
                  <Col className="ramp headerVal__alignCenter" xs="6">
                    <button
                      onClick={() => {
                        setFilters({
                          NO_STARTED: true,
                          TO_SEND: true,
                          PRE_SENDED: true,
                          SENDED: true,
                          PRE_REJECTED: true,
                          REJECTED: true,
                          PRE_VALIDATED: true,
                          VALIDATED: true,
                          DEBATABLE: true,
                          "SENDED*": true,
                          "REJECTED*": true,
                        });
                        setStatus([]);
                      }}
                      className="headerVal__dropdown__button headerVal__dropdown__buttonAll"
                    >
                      Todos
                    </button>
                  </Col>
                  <Col className="ramp headerVal__alignCenter" xs="6">
                    <button
                      onClick={() => {
                        setFilters({
                          NO_STARTED: false,
                          TO_SEND: false,
                          PRE_SENDED: false,
                          SENDED: false,
                          PRE_REJECTED: false,
                          REJECTED: false,
                          PRE_VALIDATED: false,
                          VALIDATED: false,
                          DEBATABLE: false,
                          "SENDED*": false,
                          "REJECTED*": false,
                        });
                        setStatus([]);
                      }}
                      className="headerVal__dropdown__button headerVal__dropdown__buttonNone"
                    >
                      Ninguno
                    </button>
                  </Col>
                </Row>{" "}
                <div>
                  <input
                    name="NO_STARTED"
                    type="checkbox"
                    onChange={handleStatusChange}
                    checked={filters.NO_STARTED}
                  />{" "}
                  No Comenzado
                </div>
                <div>
                  <input
                    name="TO_SEND"
                    type="checkbox"
                    onChange={handleStatusChange}
                    checked={filters.TO_SEND}
                  />{" "}
                  Por Enviar
                </div>
                <div>
                  <input
                    name="PRE_SENDED"
                    type="checkbox"
                    onChange={handleStatusChange}
                    checked={filters.PRE_SENDED}
                  />{" "}
                  Pre Enviado
                </div>
                <div>
                  <input
                    name="SENDED"
                    type="checkbox"
                    onChange={handleStatusChange}
                    checked={filters.SENDED}
                  />{" "}
                  Por Validar
                </div>
                <div>
                  <input
                    name="PRE_REJECTED"
                    type="checkbox"
                    onChange={handleStatusChange}
                    checked={filters.PRE_REJECTED}
                  />{" "}
                  Pre Objetado
                </div>
                <div>
                  <input
                    name="REJECTED"
                    type="checkbox"
                    onChange={handleStatusChange}
                    checked={filters.REJECTED}
                  />{" "}
                  Objetado
                </div>
                <div>
                  <input
                    name="PRE_VALIDATED"
                    type="checkbox"
                    onChange={handleStatusChange}
                    checked={filters.PRE_VALIDATED}
                  />{" "}
                  Pre Validado
                </div>
                <div>
                  <input
                    name="VALIDATED"
                    type="checkbox"
                    onChange={handleStatusChange}
                    checked={filters.VALIDATED}
                  />{" "}
                  Validado
                </div>
                <div>
                  <input
                    name="DEBATABLE"
                    type="checkbox"
                    onChange={handleStatusChange}
                    checked={filters.DEBATABLE}
                  />{" "}
                  A conversar
                </div>
                <div>
                  <input
                    name="SENDED*"
                    type="checkbox"
                    onChange={handleStatusChange}
                    checked={filters["SENDED*"]}
                  />{" "}
                  Por Validar*
                </div>
                <div>
                  <input
                    name="REJECTED*"
                    type="checkbox"
                    onChange={handleStatusChange}
                    checked={filters["REJECTED*"]}
                  />{" "}
                  Objetado*
                </div>
              </DropdownMenu>
            </Dropdown>
          </div>

          <div className="headerVal__content" style={{ width: "72px" }}>
            <p className="headerVal__text">Metrado</p>
          </div>
          <div className="headerVal__content" style={{ width: "82px" }}>
            <p className="headerVal__text">Monto (S/.)</p>
          </div>
          <div
            className="headerVal__content"
            style={{ width: "65px", borderRight: "solid 2px #f3f3f3" }}
          >
            <p className="headerVal__text">%Avance</p>
          </div>

          <div className="headerVal__content" style={{ width: "72px" }}>
            <p className="headerVal__text">Metrado</p>
          </div>
          <div className="headerVal__content" style={{ width: "82px" }}>
            <p className="headerVal__text">Monto (S/.)</p>
          </div>
          <div
            className="headerVal__content"
            style={{ width: "65px", borderRight: "solid 2px #f3f3f3" }}
          >
            <p className="headerVal__text">%Avance</p>
          </div>

          <div className="headerVal__content" style={{ width: "72px" }}>
            <p className="headerVal__text">Metrado</p>
          </div>
          <div className="headerVal__content" style={{ width: "82px" }}>
            <p className="headerVal__text">Monto (S/.)</p>
          </div>
          <div
            className="headerVal__content"
            style={{ width: "65px", borderRight: "solid 2px #f3f3f3" }}
          >
            <p className="headerVal__text">%Avance</p>
          </div>
        </div>
      </div>
    </div>
  );
};
