import styled from "styled-components";

export const FileDeleteButton = styled.img`
  cursor: pointer;
  height: 18px;
  width: 12px;
`;

export const FileName = styled.p`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #05be8c;
  cursor: pointer;
`;

export const FileItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
`;

export const FileListContainer = styled.div`
  text-align: center;
  margin-top: 30px;
  width: 220px;
`;
