import styled from "styled-components";
import { FiPlusSquare } from "react-icons/fi";
import { Row } from "reactstrap";

export const CardContainer = styled.div`
  margin: 0 !important;
  padding: 0 !important;
`;

export const CardRow = styled(Row)`
  margin: 0 !important;
  padding: 0 !important;
`;

export const CardContent = styled.div`
  width: 340px;
  text-align: center;
  padding-bottom: 60px;
  margin-top: 20px;
  border: solid gray 3px;
  border-radius: 10px;
  border-style: dashed;

  @media screen and (max-width: 370px) {
    width: 300px;
  }
`;

export const CardContentIcon = styled(FiPlusSquare)`
  margin-right: auto;
  margin-left: auto;
  margin-top: 60px;
  cursor: pointer;
`;
