import styled from "styled-components";

export const DropdownItemName = styled.div`
  font-size: 12px;
  font-weight: bold;
  text-align: right;
  color: #0169e4;
`;

export const DropdownItemTimestamp = styled.div`
  font-size: 12px;
  font-weight: bold;
  text-align: right;
`;

export const DropdownItemContainer = styled.div`
${(props) =>
    props.active ? "background-color: #d3e4f8" : "background-color: #ffffff"};
  padding: 5px !important;
  left: -50px !important;
`