import styled from "styled-components";

export const Container = styled.div`
  display: "flex";
  flex-direction: "column";
  margin-bottom: "20px" !important;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 30px;
  align-self: center;
  justify-content: center;
  margin: auto;
  margin-top: 20px;
`;

export const FileRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LabelContainer = styled.div`
  width: 210px;
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const Text = styled.p`
  margin: 3px 10px 0px 10px;
`;
