// Basic imports
import React from "react";
import { Input } from "antd";
import { useHistory } from "react-router-dom";

// Components import
import Item from "../Item";
import FileItem from "../FileItem";
import ChargeFileButton from "../ChargeFileButton";
import Button from "../../../../../../shared/components/Button";
import ArrowButton from "../../../../../../shared/components/ArrowButton";
import IconArrow from "../../../../../assets/arrowbutton.svg";

// Styled components import
import { ButtonContainer, Container, FileRowContainer } from "./styles";

const AdditionalSettingBanner = ({
  preadditional,
  setPreadditional,
  saveAdditionalAPU,
  getAPU,
  saveAdditionalSettings,
  closeAdditionalModal,
  enableEdition,
  backButtonHandler,
}) => {
  const { TextArea } = Input;
  const history = useHistory();

  const handleRedirectionPage = (preadditionalId) => {
    history.push(`/additionals/${preadditionalId}/preadditionals`);
  };

  return (
    <>
      <Item
        title="Nombre"
        content={preadditional.name}
        width="100%"
        onChange={(value) => {
          setPreadditional({ ...preadditional, name: value });
        }}
        disabled={!enableEdition}
        marginBottom={"20px"}
      />
      <Container style={{ marginBottom: "20px" }}>
        <FileRowContainer>
          <p className="blue bold font14" style={{ marginBottom: "2px" }}>
            Presupuesto de adicional
          </p>
        </FileRowContainer>
        <FileRowContainer>
          <div onClick={() => {
            handleRedirectionPage(preadditional.id);
          }}>
          <ArrowButton
          imageUrl={IconArrow}
          text="Acceder"
          /></div>
        </FileRowContainer>
      </Container>
      <Container style={{ marginBottom: "20px" }}>
        <p className="blue bold font14" style={{ marginBottom: "2px" }}>
          Análisis de precios unitarios (APU):
        </p>
        <FileRowContainer>
          <ChargeFileButton chargeDocument={saveAdditionalAPU} />
          <FileItem
            fileName={preadditional.apu_filename}
            getFile={() => getAPU(preadditional.id)}
          />
        </FileRowContainer>
      </Container>
      <>
        <p className="blue bold font14 projectVal__mb1">Justificación:</p>
        <TextArea
          value={preadditional.reason}
          onFocus={(event) => event.target.select()}
          onChange={(event) => {
            setPreadditional({ ...preadditional, reason: event.target.value });
          }}
          rows={4}
          className="projectVal__mb1"
          placeholder="Razón de la creación del adicional"
          disabled={!enableEdition}
        />
      </>
      <ButtonContainer>
        {preadditional.type === "TERM_EXTENSION_INCLUDED" && (
          <Button
            background="#D93022"
            width="140px"
            fontWeight="600"
            boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
            onClick={() => {
              backButtonHandler();
            }}
          >
            Atrás
          </Button>
        )}
        <Button
          background={enableEdition ? "#05BE8C" : "#76817E"}
          width="140px"
          fontWeight="600"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
          onClick={() => {
            enableEdition ? saveAdditionalSettings() : closeAdditionalModal();
          }}
        >
          {enableEdition ? "Guardar" : "Cerrar"}
        </Button>
      </ButtonContainer>
    </>
  );
};

export default AdditionalSettingBanner;
