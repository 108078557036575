import appFetcher from "../../App/utils/http/AppFetcher";
import getErrorMessage from "../../shared/utils/getErrorMessage";

export const deleteProject = async (id, errorHandler, successHandler) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/projects/${id}`,
      isAuthRequired: true,
      requestContentType: "application/json",
      responseContentType: "empty",
      method: "DELETE",
    });
    if (status === 204) {
      successHandler(response.detail);
    } else {
      errorHandler(getErrorMessage(response), "bad_request");
    }
  } catch (error) {
    errorHandler("server");
  }
};
