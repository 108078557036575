import React, { useState, useEffect } from "react";

// styled components
import { SectionContainer, SectionTitle } from "./styles";
import Table from "./components/Table";
import TableSummary from "./components/TableSummary";

// services import
import { getProjectPreAdditionals } from "../../../services";

const AdditionalsSection = ({
  projectId,
  userRole,
  openPreadditionalModal,
  additionalTrigger,
  setAdditionalTrigger,
  setLoading,
}) => {
  const [preadditionals, setPreadditionals] = useState([]);
  const [selectedOption, setSelectedOption] = useState(0);

  const errorHandler = () => {
    setPreadditionals([]);
  };

  const successHandler = (response) => {
    let _preadditionals = response.results;
    let orderedPreadditionals = _preadditionals;
    console.log("XX", selectedOption);
    if (selectedOption === 1) {
      console.log("XX");
      orderedPreadditionals = _preadditionals.sort((a, b) => {
        if (a.additional_budget_cost > b.additional_budget_cost) {
          return 1;
        }
        if (a.additional_budget_cost < b.additional_budget_cost) {
          return -1;
        }
        return 0;
      });
    }
    if (selectedOption === 2) {
      console.log("WS");
      orderedPreadditionals = _preadditionals.sort((a, b) => {
        if (a.additional_budget_cost < b.additional_budget_cost) {
          return 1;
        }
        if (a.additional_budget_cost > b.additional_budget_cost) {
          return -1;
        }
        return 0;
      });
    }
    let finalPreadditionals = orderedPreadditionals.map((preadditional) => {
      return { ...preadditional, checked: false };
    });
    setPreadditionals(finalPreadditionals);
  };

  useEffect(() => {
    const fetchPreAdditionals = async () => {
      await getProjectPreAdditionals(projectId, errorHandler, successHandler);
    };
    fetchPreAdditionals();
  }, [additionalTrigger, projectId, selectedOption]);

  // Check preadditional handlers
  const checkPreadditionalHandler = (id) => {
    let _preadditionals = preadditionals.map((preadditional) => {
      if (preadditional.id === id) {
        return { ...preadditional, checked: !preadditional.checked };
      }
      return preadditional;
    });
    setPreadditionals(_preadditionals);
  };

  const checkAllPreadditionalsHandler = (value) => {
    let _preadditionals = preadditionals.map((preadditional) => {
      return { ...preadditional, checked: value };
    });
    setPreadditionals(_preadditionals);
  };

  return (
    <SectionContainer>
      <SectionTitle>Lista de presupuestos adicionales de obra</SectionTitle>
      <TableSummary
        preadditionals={preadditionals}
        userRole={userRole}
        setTrigger={setAdditionalTrigger}
        trigger={additionalTrigger}
        setLoading={setLoading}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      />
      <Table
        preadditionals={preadditionals}
        checkPreadditionalHandler={checkPreadditionalHandler}
        checkAllPreadditionalsHandler={checkAllPreadditionalsHandler}
        userRole={userRole}
        setTrigger={setAdditionalTrigger}
        trigger={additionalTrigger}
        openPreadditionalModal={openPreadditionalModal}
      />
    </SectionContainer>
  );
};

export default AdditionalsSection;
