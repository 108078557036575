import React, { useState } from "react";
import { Upload, Button, message, Popconfirm, notification } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import { postFileXlsx } from "../../../providers";

export const Section = ({
  title,
  id,
  onClick,
  provider,
  setToggleUpdateProject,
  general_aproved,
  role,
  name,
  setSpinLoading
}) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState(name);

  const beforeUpload = (file) => {
    const isXlsx =
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (!isXlsx) {
      setFile(null);
      message.error("¡Solo puede cargar archivos xlsx!");
      return;
    }
    setFile(file);
    setFileName(file.name);
  };

  const confirm = async () => {
    setSpinLoading(true)
    setLoading(true)
    let body = new FormData();
    body.append("file", file);
    try {
      const response = await postFileXlsx(provider, id, body);
      if (response.detail) {
        notification.success({
          message: response.detail[0],
        });
        setToggleUpdateProject((prev) => !prev);
      } else {
        if(response.file){
          notification.error({
            message: response.file[0],
          });
        }
        else{
          notification.error({
            message: "Ocurrió un error interno. Por favor vuelva a intentarlo.",
          });
        }
      }
    } catch (error) {
      notification.error({
        message: "Ocurrió un error interno. Por favor vuelva a intentarlo.",
      });
    }
    setFile(null);
    setSpinLoading(false);
    setLoading(false);
  };

  const cancel = () => {
    message.error("Archivo eliminado");
    setFile(null);
    setFileName(name);
  };

  return (
    <>
      <div className="editProject__valDocuments__container">
        <p>{title}</p>
        <Upload
          className="editProject__valDocuments__secondItem"
          beforeUpload={beforeUpload}
          maxCount={1}
        >
          <Button
            icon={<UploadOutlined />}
            disabled={general_aproved || role !== "ADMIN"}
          >
            Select File
          </Button>
        </Upload>
      </div>
      <div className="editProject__valDocuments__container">
        <Popconfirm
          placement="topLeft"
          title="¿Está seguro que desea subir este archivo?"
          open={!!file && !loading}
          onConfirm={confirm}
          onCancel={cancel}
          okText="Sí"
          cancelText="No"
        >
          {/* <p className="font14 blue">{file && file.name}</p> */}
          <p className="font14 blue">{fileName}</p>
        </Popconfirm>
        <p
          onClick={onClick}
          className="font14 editProject__green editProject__underline editProject__valDocuments__secondItem"
        >
          descargar modelo
        </p>
      </div>
    </>
  );
};
