// React import
import React from "react";

// Styled components import
import { TableItemContainer, TableItem, BaseTable } from "./styles";

//Utils import
// Utils import
import { parseFloatNumber } from "../../../../../../../../utils";

const Table = ({ deductives }) => {
  const getStatus = (status) => {
    switch (status) {
      case "CREATED":
        return ["#000000", "Por configurar"];
      case "SETTED":
        return ["#000000", "Propuesto"];
      case "VALIDATED":
        return ["#28A745", "Aceptado"];
      case "REJECTED":
        return ["#DC3545", "Rechazado"];
      default:
        break;
    }
  };

  return (
    <BaseTable>
      <TableItemContainer height="3rem">
        <TableItem width="40px" header>
          Orden
        </TableItem>
        <TableItem width="100px" header>
          Nombre
        </TableItem>
        <TableItem width="150px" header>
          Tipo
        </TableItem>
        <TableItem width="100px" header>
          Estatus
        </TableItem>
        <TableItem width="200px" header>
          Monto total - Presupuesto
        </TableItem>
        <TableItem width="200px" header>
          Monto total - Presupuesto modificado
        </TableItem>
        <TableItem width="200px" header>
          Deductivo - Monto total
        </TableItem>
      </TableItemContainer>
      {deductives ? (
        <>
          {deductives.map((deductive) => (
            <TableItemContainer key={deductive.id}>
              <TableItem width="40px">{deductive.order}</TableItem>
              <TableItem width="100px">{deductive.name || "-"}</TableItem>
              <TableItem width="150px">
                {deductive.type === "NEW_PARTS"
                  ? "Partidas no existentes"
                  : "Partidas existentes"}
              </TableItem>
              <TableItem width="100px" color={getStatus(deductive.status)[0]}>
                {getStatus(deductive.status)[1]}
              </TableItem>
              <TableItem width="200px">
                {deductive.type === "NEW_PARTS"
                  ? "NA-Partida nueva"
                  : `S/. ${parseFloatNumber(
                      deductive.original_project_amount
                    )}`}
              </TableItem>
              <TableItem width="200px">
                {deductive.type === "NEW_PARTS"
                  ? "NA-Partida nueva"
                  : `S/. ${parseFloatNumber(
                      deductive.proposal_project_amount
                    )}`}
              </TableItem>
              <TableItem width="200px">
                {deductive.type === "NEW_PARTS"
                  ? `S/. ${parseFloatNumber(deductive.budget_cost)}`
                  : "no, partidas existentes"}
              </TableItem>
            </TableItemContainer>
          ))}
        </>
      ) : (
        <></>
      )}
    </BaseTable>
  );
};

export default Table;
