// React import
import React from "react";

// Components import
import Button from "../../../../../shared/components/Button";

// Styled components import
import {
  ButtonContainer,
  Indication,
  AnomaliesSectionContainer,
  AnomalyContainer,
  ReportTypeLabel,
} from "./styles";

// Utils import
import { parseFloatNumber } from "../../../../../shared/utils";

export default function AdvicesSection({ onAccept, advices }) {
  return (
    <>
      <Indication>
        Se cuentan con los siguientes items pendientes de pago en el proyecto:
      </Indication>
      <AnomaliesSectionContainer>
        {advices.valorizations?.map((valorization, index) => (
          <AnomalyContainer>
            <ReportTypeLabel>
              <b>Valorizacion {valorization.order}</b>{" "}
              <p style={{ marginBottom: "0rem" }}>{`S/. ${parseFloatNumber(
                valorization.to_pay_amount
              )}`}</p>
            </ReportTypeLabel>
          </AnomalyContainer>
        ))}
        {advices.additionals?.map((additional, index) => (
          <AnomalyContainer>
            <ReportTypeLabel>
              <b>Adicional {additional.order}</b>{" "}
              <p style={{ marginBottom: "0rem" }}>{`S/. ${parseFloatNumber(
                additional.to_pay_amount
              )}`}</p>
            </ReportTypeLabel>
          </AnomalyContainer>
        ))}
        {advices.deductives?.map((deductive, index) => (
          <AnomalyContainer>
            <ReportTypeLabel>
              <b>Deductivo {deductive.order}</b>{" "}
              <p style={{ marginBottom: "0rem" }}>{`S/. ${parseFloatNumber(
                deductive.to_pay_amount
              )}`}</p>
            </ReportTypeLabel>
          </AnomalyContainer>
        ))}
        <AnomalyContainer>
          <ReportTypeLabel>
            <b>Total:</b>
            <b>{`S/. ${parseFloatNumber(0)}`}</b>
          </ReportTypeLabel>
        </AnomalyContainer>
      </AnomaliesSectionContainer>
      <Indication>
        Si desea continuar con el proceso, estos montos pasarían a la
        liquidación
      </Indication>
      <ButtonContainer>
        <Button
          background="#05BE8C"
          width="160px"
          fontWeight="600"
          margin="0 0 1rem 0"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
          onClick={() => {
            onAccept();
          }}
        >
          Pasar a la liquidación
        </Button>
      </ButtonContainer>
    </>
  );
}
