import React from "react";
import Headers from "./Headers";
import Item from "./Item";

import { ProjectDeliverySummaryTableContainer } from "./styles";

export default function ProjectDeliverySummary({
  projectDeliverySummary,
  showMobileModal,
  setSection,
  section,
}) {
  let projectDeliveryEnables = [false, false, false, false, false, false];

  if (projectDeliverySummary.start_delivery_enabled) {
    projectDeliveryEnables = [true, false, false, false, false, false];
  }

  if (projectDeliverySummary.set_delivery_minute_enabled) {
    projectDeliveryEnables = [true, true, false, false, false, false];
  }

  if (projectDeliverySummary.set_observations_list_period_enabled) {
    projectDeliveryEnables = [true, true, true, false, false, false];
  }

  if (projectDeliverySummary.set_observations_list_enabled) {
    projectDeliveryEnables = [true, true, true, true, false, false];
  }

  if (projectDeliverySummary.set_observations_solving_period_enabled) {
    projectDeliveryEnables = [true, true, true, true, true, false];
  }

  if (projectDeliverySummary.finish_delivery_enabled) {
    projectDeliveryEnables = [true, true, true, true, true, true];
  }

  return (
    <div
      className={
        showMobileModal
          ? "project_delivery_summary__card__container_mobile"
          : "project_delivery_summary__card__container"
      }
    >
      <ProjectDeliverySummaryTableContainer>
        <Headers showMobileModal={showMobileModal} />
        <Item
          label="Inicio de entrega de obra"
          isActive={section === 1}
          isBlocked={!projectDeliveryEnables[0]}
          onClick={() => setSection(1)}
          showMobileModal={showMobileModal}
        />
        <Item
          label="Acta de entrega de obra"
          isActive={section === 2}
          isBlocked={!projectDeliveryEnables[1]}
          onClick={() => setSection(2)}
          showMobileModal={showMobileModal}
        />
        <Item
          label="Periodo listado de observaciones"
          isActive={section === 3}
          isBlocked={!projectDeliveryEnables[2]}
          onClick={() => setSection(3)}
          showMobileModal={showMobileModal}
        />
        <Item
          label="Listado de observaciones"
          isActive={section === 4}
          isBlocked={!projectDeliveryEnables[3]}
          onClick={() => setSection(4)}
          showMobileModal={showMobileModal}
        />
        <Item
          label="Periodo resolución observaciones"
          isActive={section === 5}
          isBlocked={!projectDeliveryEnables[4]}
          onClick={() => setSection(5)}
          showMobileModal={showMobileModal}
        />
        <Item
          label="Firma de entrega de obra"
          isActive={section === 6}
          isBlocked={!projectDeliveryEnables[5]}
          onClick={() => setSection(6)}
          showMobileModal={showMobileModal}
        />
      </ProjectDeliverySummaryTableContainer>
    </div>
  );
}
