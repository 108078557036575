import React from "react";
import { Spin, Modal } from "antd";

export default function Loading(props) {
  return (
    <Modal
      open={props.active}
      title=""
      width={150}
      footer={null}
      bodyStyle={{ "text-align": "center" }}
      centered
      closable={false}
    >
      <Spin spinning={true} size="large" tip="Cargando..." />
    </Modal>
  );
}
