import styled from "styled-components";

export const CountersContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
`;

export const CountersRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const SectionLabel = styled.div`
  font-size: 14px;
  color: black;
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: center;
`;