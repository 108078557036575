// React import
import React, { useState, useEffect } from "react";

// Third part libraries import
import { Input, DatePicker, notification } from "antd";
import dayjs from "dayjs";
import moment from "moment";

// Components import
import ApprovalPanel from "../ApprovalPanel";
import { ItemContainer } from "./styles";

// Services import
import { getProjectDeliverySolvingPeriod } from "../../../../providers";
import {
  approveProjectDeliverySolvingPeriod,
  updateObservationsSolvingPeriod,
} from "../../services";

// Utils import
import { clientDateFormat, serverDateFormat } from "../../../../utils";

const ObservationsSolvingPeriodModal = ({
  id,
  userRole,
  projectDeliverySummary,
  reloadProjectDelivery,
  setLoading,
}) => {
  const [observationsSolvingPeriod, setObservationsSolvingPeriod] = useState({
    super_approved_observations_solving_period: false,
    exec_approved_observations_solving_period: false,
  });
  const [
    triggerObservationsSolvingPeriod,
    setTriggerObservationsSolvingPeriod,
  ] = useState(true);
  const [periodDuration, setPeriodDuration] = useState(1);

  const disableForm =
    !projectDeliverySummary.set_observations_solving_period_enabled ||
    userRole === "SUPERVISOR" ||
    userRole === "EXECUTOR";

  useEffect(() => {
    const fetchObservationsSolvingPeriod = async () => {
      try {
        setLoading(true);
        const response = await getProjectDeliverySolvingPeriod(id);
        setObservationsSolvingPeriod(response);
        setPeriodDuration(response.observations_solving_days);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchObservationsSolvingPeriod();
  }, [id, triggerObservationsSolvingPeriod]);

  // Handler approbation
  const approveSuccessHandler = (response) => {
    notification.success({
      message: Object.values(response)[0][0],
    });
    setLoading(false);
    reloadProjectDelivery();
    setTriggerObservationsSolvingPeriod(!triggerObservationsSolvingPeriod);
  };

  const approveErrorHandler = (response) => {
    notification.error({
      message: response
        ? Object.values(response)[0][0]
        : "Ocurrió un error al aprobar el periodo de resolución de observaciones",
    });
    setLoading(false);
  };

  const approveHandler = async () => {
    setLoading(true);
    await approveProjectDeliverySolvingPeriod(
      id,
      approveErrorHandler,
      approveSuccessHandler
    );
  };

  // Handler update
  const updateSuccessHandler = (response) => {
    notification.success({
      message:
        "Se actualizo correctamente el periodo de resolución de observaciones",
    });
    setLoading(false);
    setTriggerObservationsSolvingPeriod(!triggerObservationsSolvingPeriod);
  };

  const updateErrorHandler = (response) => {
    notification.error({
      message: response
        ? Object.values(response)[0][0]
        : "Ocurrió un error al actualizar el periodo de resolución de observaciones",
    });
    setLoading(false);
  };

  const getUpdateBody = (_observationsSolvingPeriod, label) => {
    let formData = new FormData();
    _observationsSolvingPeriod.observations_solving_finish_date &&
      label === "observations_solving_finish_date" &&
      formData.append(
        "observations_solving_finish_date",
        _observationsSolvingPeriod.observations_solving_finish_date
      );
    _observationsSolvingPeriod.observations_solving_days &&
      label === "observations_solving_days" &&
      formData.append(
        "observations_solving_days",
        _observationsSolvingPeriod.observations_solving_days
      );
    return formData;
  };

  const updateHandler = async (item, label) => {
    setLoading(true);
    const _observationsSolvingPeriod = {
      ...observationsSolvingPeriod,
      [label]: item,
    };
    const updateBody = getUpdateBody(_observationsSolvingPeriod, label);
    await updateObservationsSolvingPeriod(
      id,
      updateBody,
      updateErrorHandler,
      updateSuccessHandler
    );
  };

  return (
    <>
      <ItemContainer>
        <p
          className="blue bold font14"
          style={{ marginBottom: "16px", marginTop: "16px" }}
        >
          Fecha de inicio de periodo:
        </p>
        <DatePicker
          value={dayjs(
            observationsSolvingPeriod.observations_solving_start_date ||
              new Date()
          )}
          onChange={async (value) => {}}
          format={clientDateFormat}
          disabled={true}
          style={{ width: "150px" }}
        />
      </ItemContainer>
      <ItemContainer>
        <p
          className="blue bold font14"
          style={{ marginBottom: "16px", marginTop: "16px" }}
        >
          Fecha de fin de periodo:
        </p>
        <DatePicker
          value={dayjs(
            observationsSolvingPeriod.observations_solving_finish_date ||
              new Date()
          )}
          onChange={async (value) => {
            await updateHandler(
              moment(value.toDate()).format(serverDateFormat),
              "observations_solving_finish_date"
            );
          }}
          format={clientDateFormat}
          disabled={disableForm}
          style={{ width: "150px" }}
        />
      </ItemContainer>
      <ItemContainer>
        <p
          className="blue bold font14"
          style={{ marginBottom: "16px", marginTop: "16px" }}
        >
          Duración de periodo:
        </p>
        <Input
          style={{ width: "70px" }}
          value={periodDuration}
          onFocus={(event) => event.target.select()}
          onChange={(event) => {
            setPeriodDuration(event.target.value.replace(/[^\d]/g, ""));
          }}
          onBlur={async () => {
            await updateHandler(periodDuration, "observations_solving_days");
          }}
          disabled={disableForm}
        />
      </ItemContainer>
      <div>
        <ApprovalPanel
          label="Aprobar periodo de resolución de observaciones"
          approvalEnable={
            projectDeliverySummary.set_observations_solving_period_enabled
          }
          approveHandler={() => {
            approveHandler();
          }}
          userRole={userRole}
          superApproved={
            observationsSolvingPeriod.super_approved_observations_solving_period
          }
          execApproved={
            observationsSolvingPeriod.exec_approved_observations_solving_period
          }
        />
      </div>
    </>
  );
};

export default ObservationsSolvingPeriodModal;
