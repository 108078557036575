// React import
import React, { useEffect, useState } from "react";

// Support libraries import
import dayjs from "dayjs";
import moment from "moment";
import { DatePicker } from "antd";

// Components import
import ApprobationPanel from "./components/ApprobationPanel";

// Styled components import
import { TimelineSectionContainer, TimelineTitle, InnerCircle } from "./styles";

// Services import
import {
  getTimelineService,
  getScheduleEditionProposal,
} from "../../../../../services";

// Utils import
import { clientDateFormat, serverDateFormat } from "../../../../../../../utils";

// Styles import
import "./index.css";

const Timeline = ({
  id,
  project,
  scheduleModificationActive,
  userRole,
  currentValorization,
  scheduleEditionProposal,
  setScheduleEditionProposal,
  setIsProposalActive,
  setLoading,
  setTrigger,
  trigger,
  timelineTrigger,
}) => {
  const [timeline, setTimeline] = useState({
    valorization: { order: null },
    next_valorization: { order: null },
  });

  const errorHandler = () => {
    setTimeline({
      valorization: { order: null },
      next_valorization: { order: null },
    });
  };

  const successHandler = (results) => {
    setTimeline(results);
    setIsProposalActive(results.valorization.is_proposal_active);
  };

  useEffect(() => {
    const fetchTimeline = async () => {
      setLoading(true);
      await getTimelineService(
        currentValorization.id,
        errorHandler,
        successHandler
      );
      setLoading(false);
    };
    fetchTimeline();
  }, [timelineTrigger, currentValorization]);

  const errorGetScheduleEditionProposalHandler = () => {};

  const successGetScheduleEditionProposalHandler = (results) => {
    setScheduleEditionProposal(results);
  };

  useEffect(() => {
    const fetchScheduleEditionProposal = async () => {
      setLoading(true);
      await getScheduleEditionProposal(
        currentValorization.id,
        errorGetScheduleEditionProposalHandler,
        successGetScheduleEditionProposalHandler
      );
      setLoading(false);
    };
    scheduleModificationActive && fetchScheduleEditionProposal();
  }, [scheduleModificationActive, trigger, currentValorization]);

  const getDateFormated = (originalDate) => {
    return dayjs(originalDate || "01/01/2000").format(clientDateFormat) || "";
  };

  const reloadScheduleEditionProposal = () => {
    setTrigger(!trigger);
  };

  const adminPermission = userRole === "ADMIN";
  const activeScheduleSetting =
    scheduleEditionProposal.executor_approves_valorization_final_date !==
      "SETTED" &&
    scheduleEditionProposal.executor_approves_meter_entering_final_date !==
      "SETTED" &&
    scheduleEditionProposal.executor_approves_meter_validation_final_date !==
      "SETTED" &&
    scheduleEditionProposal.executor_approves_differences_closing_final_date !==
      "SETTED" &&
    scheduleEditionProposal.supervisor_approves_valorization_final_date !==
      "SETTED" &&
    scheduleEditionProposal.supervisor_approves_meter_entering_final_date !==
      "SETTED" &&
    scheduleEditionProposal.supervisor_approves_meter_validation_final_date !==
      "SETTED" &&
    scheduleEditionProposal.supervisor_approves_differences_closing_final_date !==
      "SETTED";

  return (
    <>
      <TimelineTitle>
        Periodo valorización Nº {timeline.valorization.order}
      </TimelineTitle>
      {timeline.valorization.order && (
        <TimelineSectionContainer>
          <div class="row">
            <div class="timeline-steps aos-init aos-animate" data-aos="fade-up">
              <div class="timeline-step">
                <div
                  class="timeline-content"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-placement="top"
                >
                  <InnerCircle />
                  <p class="p mt-3 mb-1">
                    {getDateFormated(timeline.valorization.initial_date)}
                  </p>
                  <p class="p text-muted mb-0 mb-lg-0">
                    Inicio de valorización
                  </p>
                </div>
              </div>
              <div class="timeline-step">
                <div
                  class="timeline-content"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-placement="top"
                >
                  <InnerCircle
                    active={
                      timeline.valorization.is_active &&
                      !timeline.valorization.in_anticipated_signature
                    }
                  />
                  {timeline.valorization.is_active &&
                    !timeline.valorization.in_anticipated_signature && (
                      <>
                        <p class="p mt-3 mb-1">
                          {getDateFormated(project.project_date)}
                        </p>
                        <p class="p mt-3 mb-1 bold">Estamos aquí</p>
                      </>
                    )}
                </div>
              </div>
              <div class="timeline-step">
                <div
                  class="timeline-content"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-placement="top"
                >
                  <InnerCircle />
                  <p class="p mt-3 mb-1">
                    {getDateFormated(
                      timeline.valorization.anticipated_signature_start_date
                    )}
                  </p>
                  <p class="p text-muted mb-0 mb-lg-0">
                    Inicio de cierre anticipado
                  </p>
                </div>
              </div>
              <div class="timeline-step">
                <div
                  class="timeline-content"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-placement="top"
                >
                  <InnerCircle
                    active={
                      timeline.valorization.is_active &&
                      timeline.valorization.in_anticipated_signature
                    }
                  />
                  {timeline.valorization.is_active &&
                    timeline.valorization.in_anticipated_signature && (
                      <>
                        <p class="p mt-3 mb-1">
                          {getDateFormated(project.project_date)}
                        </p>
                        <p class="p mt-3 mb-1 bold">Estamos aquí</p>
                      </>
                    )}
                </div>
              </div>
              <div class="timeline-step">
                <div
                  class="timeline-content"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-placement="top"
                >
                  <InnerCircle />
                  {scheduleModificationActive ? (
                    <>
                      <DatePicker
                        value={dayjs(
                          scheduleEditionProposal.valorization_final_date
                        )}
                        onChange={(value) => {
                          adminPermission &&
                            setScheduleEditionProposal({
                              ...scheduleEditionProposal,
                              valorization_final_date: moment(
                                value.toDate()
                              ).format(serverDateFormat),
                            });
                        }}
                        format={clientDateFormat}
                        disabled={!adminPermission || !activeScheduleSetting}
                        style={{ width: "100px", marginTop: "10px" }}
                        className="editProject__mb2"
                        suffixIcon={null}
                      />
                      <p class="p mb-1 bold">Supervisor líder</p>
                      <ApprobationPanel
                        isAdmin={adminPermission}
                        currentValorization={currentValorization}
                        setLoading={setLoading}
                        date="valorization_final_date"
                        reload={reloadScheduleEditionProposal}
                        activeForRole={userRole === "SUPERVISOR_LEADER"}
                        dateChanged={
                          scheduleEditionProposal.is_valorization_final_date_changed
                        }
                        approbationStatus={
                          scheduleEditionProposal.supervisor_approves_valorization_final_date
                        }
                      />
                      <p class="p mb-1 bold">Ejecutor líder</p>
                      <ApprobationPanel
                        isAdmin={adminPermission}
                        currentValorization={currentValorization}
                        setLoading={setLoading}
                        date="valorization_final_date"
                        reload={reloadScheduleEditionProposal}
                        activeForRole={userRole === "EXECUTOR_LEADER"}
                        dateChanged={
                          scheduleEditionProposal.is_valorization_final_date_changed
                        }
                        approbationStatus={
                          scheduleEditionProposal.executor_approves_valorization_final_date
                        }
                      />
                    </>
                  ) : (
                    <p class="p mt-3 mb-1">
                      {getDateFormated(timeline.valorization.final_date)}
                    </p>
                  )}
                  <p class="p text-muted mb-0 mb-lg-0">
                    Cierre de valorización
                  </p>
                </div>
              </div>
              <div class="timeline-step">
                <div
                  class="timeline-content"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-placement="top"
                >
                  <InnerCircle
                    active={timeline.valorization.in_meter_entering}
                  />
                  {timeline.valorization.in_meter_entering && (
                    <>
                      <p class="p mt-3 mb-1">
                        {getDateFormated(project.project_date)}
                      </p>
                      <p class="p mt-3 mb-1 bold">Estamos aquí</p>
                    </>
                  )}
                </div>
              </div>
              <div class="timeline-step">
                <div
                  class="timeline-content"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-placement="top"
                >
                  <InnerCircle />
                  {scheduleModificationActive ? (
                    <>
                      <DatePicker
                        value={dayjs(
                          scheduleEditionProposal.meter_entering_final_date
                        )}
                        onChange={(value) => {
                          adminPermission &&
                            setScheduleEditionProposal({
                              ...scheduleEditionProposal,
                              meter_entering_final_date: moment(
                                value.toDate()
                              ).format(serverDateFormat),
                            });
                        }}
                        format={clientDateFormat}
                        disabled={!adminPermission || !activeScheduleSetting}
                        style={{ width: "100px", marginTop: "10px" }}
                        className="editProject__mb2"
                        suffixIcon={null}
                      />
                      <p class="p mb-1 bold">Supervisor líder</p>
                      <ApprobationPanel
                        isAdmin={adminPermission}
                        currentValorization={currentValorization}
                        setLoading={setLoading}
                        date="meter_entering_final_date"
                        reload={reloadScheduleEditionProposal}
                        activeForRole={userRole === "SUPERVISOR_LEADER"}
                        dateChanged={
                          scheduleEditionProposal.is_meter_entering_final_date_changed
                        }
                        approbationStatus={
                          scheduleEditionProposal.supervisor_approves_meter_entering_final_date
                        }
                      />
                      <p class="p mb-1 bold">Ejecutor líder</p>
                      <ApprobationPanel
                        isAdmin={adminPermission}
                        currentValorization={currentValorization}
                        setLoading={setLoading}
                        date="meter_entering_final_date"
                        reload={reloadScheduleEditionProposal}
                        activeForRole={userRole === "EXECUTOR_LEADER"}
                        dateChanged={
                          scheduleEditionProposal.is_meter_entering_final_date_changed
                        }
                        approbationStatus={
                          scheduleEditionProposal.executor_approves_meter_entering_final_date
                        }
                      />
                    </>
                  ) : (
                    <p class="p mt-3 mb-1">
                      {getDateFormated(
                        timeline.valorization.meter_entering_final_date
                      )}
                    </p>
                  )}
                  <p class="p text-muted mb-0 mb-lg-0">
                    Cierre ingreso de metrado
                  </p>
                </div>
              </div>
              <div class="timeline-step">
                <div
                  class="timeline-content"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-placement="top"
                >
                  <InnerCircle
                    active={timeline.valorization.in_meter_validation}
                  />
                  {timeline.valorization.in_meter_validation && (
                    <>
                      <p class="p mt-3 mb-1">
                        {getDateFormated(project.project_date)}
                      </p>
                      <p class="p mt-3 mb-1 bold">Estamos aquí</p>
                    </>
                  )}
                </div>
              </div>
              <div class="timeline-step">
                <div
                  class="timeline-content"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-placement="top"
                >
                  <InnerCircle />
                  {scheduleModificationActive ? (
                    <>
                      <DatePicker
                        value={dayjs(
                          scheduleEditionProposal.meter_validation_final_date
                        )}
                        onChange={(value) => {
                          adminPermission &&
                            setScheduleEditionProposal({
                              ...scheduleEditionProposal,
                              meter_validation_final_date: moment(
                                value.toDate()
                              ).format(serverDateFormat),
                            });
                        }}
                        format={clientDateFormat}
                        disabled={!adminPermission || !activeScheduleSetting}
                        style={{ width: "100px", marginTop: "10px" }}
                        className="editProject__mb2"
                        suffixIcon={null}
                      />
                      <p class="p mb-1 bold">Supervisor líder</p>
                      <ApprobationPanel
                        isAdmin={adminPermission}
                        currentValorization={currentValorization}
                        setLoading={setLoading}
                        date="meter_validation_final_date"
                        reload={reloadScheduleEditionProposal}
                        activeForRole={userRole === "SUPERVISOR_LEADER"}
                        dateChanged={
                          scheduleEditionProposal.is_meter_validation_final_date_changed
                        }
                        approbationStatus={
                          scheduleEditionProposal.supervisor_approves_meter_validation_final_date
                        }
                      />
                      <p class="p mb-1 bold">Ejecutor líder</p>
                      <ApprobationPanel
                        isAdmin={adminPermission}
                        currentValorization={currentValorization}
                        setLoading={setLoading}
                        date="meter_validation_final_date"
                        reload={reloadScheduleEditionProposal}
                        activeForRole={userRole === "EXECUTOR_LEADER"}
                        dateChanged={
                          scheduleEditionProposal.is_meter_validation_final_date_changed
                        }
                        approbationStatus={
                          scheduleEditionProposal.executor_approves_meter_validation_final_date
                        }
                      />
                    </>
                  ) : (
                    <p class="p mt-3 mb-1">
                      {getDateFormated(
                        timeline.valorization.meter_validation_final_date
                      )}
                    </p>
                  )}
                  <p class="p text-muted mb-0 mb-lg-0">
                    Cierre de validación de metrado
                  </p>
                </div>
              </div>
              <div class="timeline-step">
                <div
                  class="timeline-content"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-placement="top"
                >
                  <InnerCircle
                    active={timeline.valorization.in_differences_closing}
                  />
                  {timeline.valorization.in_differences_closing && (
                    <>
                      <p class="p mt-3 mb-1">
                        {getDateFormated(project.project_date)}
                      </p>
                      <p class="p mt-3 mb-1 bold">Estamos aquí</p>
                    </>
                  )}
                </div>
              </div>
              <div class="timeline-step mb-0">
                <div
                  class="timeline-content"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-placement="top"
                >
                  <InnerCircle />
                  {scheduleModificationActive ? (
                    <>
                      <DatePicker
                        value={dayjs(
                          scheduleEditionProposal.differences_closing_final_date
                        )}
                        onChange={(value) => {
                          adminPermission &&
                            setScheduleEditionProposal({
                              ...scheduleEditionProposal,
                              differences_closing_final_date: moment(
                                value.toDate()
                              ).format(serverDateFormat),
                            });
                        }}
                        format={clientDateFormat}
                        disabled={!adminPermission || !activeScheduleSetting}
                        style={{ width: "100px", marginTop: "10px" }}
                        className="editProject__mb2"
                        suffixIcon={null}
                      />
                      <p class="p mb-1 bold">Supervisor líder</p>
                      <ApprobationPanel
                        isAdmin={adminPermission}
                        currentValorization={currentValorization}
                        setLoading={setLoading}
                        date="differences_closing_final_date"
                        reload={reloadScheduleEditionProposal}
                        activeForRole={userRole === "SUPERVISOR_LEADER"}
                        dateChanged={
                          scheduleEditionProposal.is_differences_closing_final_date_changed
                        }
                        approbationStatus={
                          scheduleEditionProposal.supervisor_approves_differences_closing_final_date
                        }
                      />
                      <p class="p mb-1 bold">Ejecutor líder</p>
                      <ApprobationPanel
                        isAdmin={adminPermission}
                        currentValorization={currentValorization}
                        setLoading={setLoading}
                        date="differences_closing_final_date"
                        reload={reloadScheduleEditionProposal}
                        activeForRole={userRole === "EXECUTOR_LEADER"}
                        dateChanged={
                          scheduleEditionProposal.is_differences_closing_final_date_changed
                        }
                        approbationStatus={
                          scheduleEditionProposal.executor_approves_differences_closing_final_date
                        }
                      />
                    </>
                  ) : (
                    <p class="p mt-3 mb-1">
                      {getDateFormated(
                        timeline.valorization.differences_closing_final_date
                      )}
                    </p>
                  )}
                  <p class="p text-muted mb-0 mb-lg-0">Cierre de diferencias</p>
                </div>
              </div>
            </div>
          </div>
        </TimelineSectionContainer>
      )}
      <TimelineTitle>
        Periodo valorización Nº {timeline.next_valorization.order}
      </TimelineTitle>
      {timeline.valorization.order && (
        <TimelineSectionContainer marginLeft="455px" width="773px">
          <div class="row">
            <div class="timeline-steps aos-init aos-animate" data-aos="fade-up">
              <div class="timeline-step">
                <div
                  class="timeline-content"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-placement="top"
                >
                  <InnerCircle />
                  <p class="p mt-3 mb-1">
                    {scheduleModificationActive
                      ? getDateFormated(
                          scheduleEditionProposal.valorization_final_date
                        )
                      : getDateFormated(
                          timeline.next_valorization.initial_date
                        )}
                  </p>
                  <p class="p text-muted mb-0 mb-lg-0">
                    Inicio de valorización
                  </p>
                </div>
              </div>
              <div class="timeline-step">
                <div
                  class="timeline-content"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-placement="top"
                >
                  <InnerCircle
                    active={timeline.valorization.in_meter_entering}
                  />
                  {timeline.valorization.in_meter_entering && (
                    <>
                      <p class="p mt-3 mb-1">
                        {getDateFormated(project.project_date)}
                      </p>
                      <p class="p mt-3 mb-1 bold">Estamos aquí</p>
                    </>
                  )}
                </div>
              </div>
              <div class="timeline-step">
                <div
                  class="timeline-content"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-placement="top"
                >
                  <InnerCircle
                    active={timeline.valorization.in_meter_validation}
                  />
                  {timeline.valorization.in_meter_validation && (
                    <>
                      <p class="p mt-3 mb-1">
                        {getDateFormated(project.project_date)}
                      </p>
                      <p class="p mt-3 mb-1 bold">Estamos aquí</p>
                    </>
                  )}
                </div>
              </div>
              <div class="timeline-step">
                <div
                  class="timeline-content"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-placement="top"
                >
                  <InnerCircle
                    active={timeline.valorization.in_differences_closing}
                  />
                  {timeline.valorization.in_differences_closing && (
                    <>
                      <p class="p mt-3 mb-1">
                        {getDateFormated(project.project_date)}
                      </p>
                      <p class="p mt-3 mb-1 bold">Estamos aquí</p>
                    </>
                  )}
                </div>
              </div>
              <div class="timeline-step">
                <div
                  class="timeline-content"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-placement="top"
                >
                  <InnerCircle />
                  <p class="p mt-3 mb-1">
                    {getDateFormated(
                      timeline.next_valorization
                        .anticipated_signature_start_date
                    )}
                  </p>
                  <p class="p text-muted mb-0 mb-lg-0">
                    Inicio de cierre anticipado
                  </p>
                </div>
              </div>
              <div class="timeline-step">
                <div
                  class="timeline-content"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-placement="top"
                >
                  <InnerCircle
                    active={
                      timeline.valorization.in_differences_closing &&
                      timeline.next_valorization.in_anticipated_signature
                    }
                  />
                  {timeline.valorization.in_differences_closing &&
                    timeline.next_valorization.in_anticipated_signature && (
                      <>
                        <p class="p mt-3 mb-1">
                          {getDateFormated(project.project_date)}
                        </p>
                        <p class="p mt-3 mb-1 bold">Estamos aquí</p>
                      </>
                    )}
                </div>
              </div>
              <div class="timeline-step">
                <div
                  class="timeline-content"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-placement="top"
                >
                  <InnerCircle />
                  <p class="p mt-3 mb-1">
                    {getDateFormated(timeline.next_valorization.final_date)}
                  </p>
                  <p class="p text-muted mb-0 mb-lg-0">
                    Cierre de valorización
                  </p>
                </div>
              </div>
            </div>
          </div>
        </TimelineSectionContainer>
      )}
    </>
  );
};

export default Timeline;
