import React, { useEffect, useState } from "react";
import ValueItem from "./components/ValueItem";
import PaymentsTable from "./components/PaymentsTable";
import { parseFloatNumber } from "../../../../utils";

import { getPaymentsByDebtId } from "../../../../providers";

const DebtSummaryCard = ({ debt, user, setPayment, setShowReject,debtReload, openPaymentSummary }) => {
  const [payments, setPayments] = useState([]);
  const [reload, setReload] = useState(true);

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const _debtPayments = await getPaymentsByDebtId(debt.id);
        setPayments(_debtPayments.results);
      } catch (error) {
        console.log(error);
      }
    };
    debt && fetchPayments();
  }, [debt, reload]);

  const paymentReload = () => {
    setReload(!reload);
    debtReload();
  };

  const withValidation = user === "Supervisor";

  return (
    <div className="projectVal__card__container">
      <div style={{ width: "100%" }}>
        <div className="projectVal__card__items">
          <p className="font14 projectVal__mb1">
            Quedan 10 días útiles para el cierre de plazo de pago
          </p>
        </div>
        <div className="projectVal__card__items">
          <ValueItem
            label="Deuda total"
            value={"S/." + parseFloatNumber(debt.amount)}
          />
          <ValueItem
            label="Deuda por pagar"
            value={"S/." + parseFloatNumber(debt.amount-debt.paid_amount)}
          />
        </div>
        <div className="projectVal__card__items">
          <PaymentsTable
            withValidation={withValidation}
            showAddPayment={debt.status !== "CLOSED"}
            user={user}
            setPayment={setPayment}
            payments={payments}
            paymentReload={paymentReload}
            setShowReject={setShowReject}
            openPaymentSummary={openPaymentSummary}
          />
        </div>
      </div>
    </div>
  );
};

export default DebtSummaryCard;
