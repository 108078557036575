import React from "react";
import * as FileSaver from "file-saver";

import { getFileXlsx } from "../../../providers";

export const ShowFile = ({
  title,
  id,
  provider,
  name,
  isFile,
  existDocument,
  setModal,
}) => {
  const downloadFile = async () => {
    try {
      const response = await getFileXlsx(provider, id);
      const blob = await response.blob();
      FileSaver.saveAs(blob, name);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div style={{ display: "flex" }}>
      <div className="editProject__mr">
        <p className="editProject__mb0">{title}</p>
        <span
          className={`${
            existDocument ? "editProject__green" : "editProject__red"
          } font12`}
        >
          {existDocument ? "Encontrado" : "No encontrado"}
        </span>
      </div>
      {existDocument && (
        <p
          onClick={() => (isFile ? downloadFile() : setModal(true))}
          className="editProject__underline editProject__ml blue-light "
        >
          ver
        </p>
      )}
    </div>
  );
};
