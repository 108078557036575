import translate from "./translate";

const errorMessageMaker = (field, detail) => {
  if (detail === "Este campo es requerido.") {
    return `El campo ${translate(field)} es requerido`;
  }
  return "";
};

const getErrorMessage = (response) => {
  const items = Object.keys(response);
  return items.length === 0
    ? ""
    : errorMessageMaker(items[0], response[items[0]][0]);
};

export default getErrorMessage;