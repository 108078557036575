import React, { useState, useEffect } from "react";
import { Input, Checkbox, notification } from "antd";
import { IconContext } from "react-icons";
import { FiSend } from "react-icons/fi";

import { getPartById, getValorizedPartLogs } from "../../../../../providers";
import { parseFloatNumber, convertPartNumber } from "../../../../../utils";

import { ToTalkModalSkeleton } from "../../../../../components/Skeletons";
import { Button } from "../../../../../components/Button";
import { Card } from "./Card";
import { UserPhoto } from "./UserPhoto";

import user1 from "../../../assets/projectValuation/user1.png";
import user2 from "../../../assets/projectValuation/user2.png";

export const ToTalkModal = ({
  id,
  editPart,
  setIsVisibleToTalkModal,
  toggleUpdatePart,
  setToggleUpdatePart,
  disabled = true,
  rejectValorizedPart,
  correctValorizedPart,
  debateValorizedPart,
  createValorizedPartLog,
  validateValorizedPartLog,
  rejectValorizedPartLog,
  user,
  setPartName,
}) => {
  const [part, setPart] = useState(null);
  const [comment_quantity, setComment_quantity] = useState();
  const [comment_advance_percentage, setComment_advance_percentage] =
    useState();
  const [comments, setComments] = useState("");

  const [logs, setLogs] = useState([]);
  const [comment_quantity_log, setComment_quantity_log] = useState();
  const [option1, setOption1] = useState(false);
  const [option2, setOption2] = useState(false);
  const [is_incorrect, setIs_incorrect] = useState(null);

  useEffect(() => {
    const fetchPart = async () => {
      try {
        const _part = await getPartById(id);
        setPartName(convertPartNumber(_part.part.part_id));
        setComment_quantity(parseFloatNumber(_part.comment_quantity));
        setComment_advance_percentage(
          parseFloatNumber(_part.comment_advance_percentage * 100)
        );
        setComments(_part.comments);
        setPart(_part);
      } catch (error) {
        console.log(error);
      }
    };
    fetchPart();
  }, [id, toggleUpdatePart, user]);

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const _logs = await getValorizedPartLogs(id);
        setLogs(_logs.results);
        /* console.log("_logs", _logs); */
      } catch (error) {
        console.log(error);
      }
    };
    fetchLogs();
  }, [id, toggleUpdatePart]);

  useEffect(() => {
    setIs_incorrect(option2 ? true : option1 ? false : null);
  }, [option1, option2]);

  return (
    <>
      {!part ? (
        <ToTalkModalSkeleton />
      ) : (
        <div>
          <p className="projectVal__modal__description">
            {part?.part.name.charAt(0) + part?.part.name.slice(1).toLowerCase()}
          </p>
          <div className="projectVal__modal__cardsContainer">
            <Card
              quantity={parseFloatNumber(part?.pre_quantity)}
              percentage={parseFloatNumber(part?.pre_advance_percentage * 100)}
              mount={parseFloatNumber(part?.pre_mount)}
              img={user1}
              disabled={disabled}
              initial={true}
              color="#818181"
            />
            <Card
              img={user2}
              color="#05BE8C"
              quantity={comment_quantity}
              percentage={comment_advance_percentage}
              mount={parseFloatNumber(part?.comment_mount)}
              comments={comments}
              setQuantity={setComment_quantity}
              setPercentage={setComment_advance_percentage}
              setComments={setComments}
              id={id}
              editPart={editPart}
              disabled={part?.is_rejected || part?.is_debatable}
              isThereComment={true}
              setToggleUpdatePart={setToggleUpdatePart}
              existFile={part?.comment_file_name}
              existImage={part?.comment_image}
            />
            {logs?.map((log) => (
              <Card
                key={log.order}
                quantity={parseFloatNumber(log?.comment_quantity)}
                percentage={parseFloatNumber(
                  log?.comment_advance_percentage * 100
                )}
                mount={parseFloatNumber(log?.comment_mount)}
                isThereComment={log?.comments}
                comments={log?.comments}
                img={user1}
                disabled={true}
                color={log?.comments ? "#05BE8C" : "#818181"}
              />
            ))}
          </div>
          <div
            /* hidden={part?.is_debatable && user === "Supervisor"} */
            className={
              part?.is_debatable && user === "Ejecutor"
                ? "projectVal__card__container"
                : "projectVal__modal__footer"
            }
          >
            {part?.is_debatable && user === "Supervisor" && logs?.length > 0 ? (
              <div style={{ width: "calc(100% - 95px)" }}>
                <Checkbox
                  checked={option1}
                  onChange={(e) => {
                    setOption1(e.target.checked);
                    if (e.target.checked) {
                      setOption2(false);
                    }
                  }}
                >
                  No hemos conversado sobre esta partida
                </Checkbox>
                <br />
                <Checkbox
                  checked={option2}
                  onChange={(e) => {
                    setOption2(e.target.checked);
                    if (e.target.checked) {
                      setOption1(false);
                    }
                  }}
                >
                  No es el valor correcto
                </Checkbox>
                <br />
                <br />
                <div className="projectVal__modal__btns">
                  <Button
                    width="122px"
                    fontWeight="600"
                    boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
                    background={is_incorrect !== null ? "#92DECD" : "#05BE8C"}
                    disabled={is_incorrect !== null}
                    onClick={() => {
                      validateValorizedPartLog(id);
                      setIsVisibleToTalkModal(false);
                    }}
                  >
                    Aceptar
                  </Button>
                  <Button
                    width="122px"
                    fontWeight="600"
                    background={is_incorrect === null ? "#E7A6A3" : "#D93022"}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.25)"
                    disabled={is_incorrect === null}
                    onClick={() => {
                      rejectValorizedPartLog(id, { is_incorrect });
                      setOption1(false);
                      setOption2(false);
                      setIs_incorrect(null);
                    }}
                  >
                    Rechazar
                  </Button>
                </div>
              </div>
            ) : part?.is_debatable && user === "Ejecutor" ? (
              <div className="">
                <p className="blue bold font14 projectVal__mb2">
                  Solicitar reapertura del ciclo de validación
                </p>
                <p className="bold font14 projectVal__mb2">
                  Si está viendo esta sección es porque hay un desacuerdo an
                  algún valor. Una vez el valor correcto se haya acordado,
                  introduzcalo aquí y solicite la reapertura del ciclo de
                  validación enviándolo.
                </p>
                <div className="projectVal__mb2">
                  <Input
                    value={comment_quantity_log}
                    onFocus={(event) => event.target.select()}
                    onChange={(e) => setComment_quantity_log(e.target.value)}
                    placeholder="Ingresar metrado acordado"
                    style={{ width: "219px" }}
                  />
                  <IconContext.Provider value={{ color: "#05BE8C" }}>
                    <FiSend
                      onClick={() => {
                        createValorizedPartLog(id, {
                          comment_quantity: Number(comment_quantity_log),
                        });
                        setComment_quantity_log();
                      }}
                      style={{ marginLeft: "15px", cursor: "pointer" }}
                      size={22}
                    />
                  </IconContext.Provider>
                </div>
              </div>
            ) : (part?.is_rejected && user === "Ejecutor") ||
              !(part?.is_debatable && user === "Supervisor") ? (
              <div className="projectVal__modal__btns">
                <Button
                  background="#05BE8C"
                  width="122px"
                  fontWeight="600"
                  boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
                  onClick={() => {
                    if (part?.is_rejected && user === "Ejecutor") {
                      correctValorizedPart(id);
                      setIsVisibleToTalkModal(false);
                    } else {
                      if (comments !== "") {
                        if (part?.is_rejected) {
                          notification.error({
                            message:
                              "No se puede rechazar una partida ya rechazada",
                          });
                        } else {
                          rejectValorizedPart(id);
                          setIsVisibleToTalkModal(false);
                        }
                      } else {
                        notification.error({
                          message:
                            "No se puede rechazar una partida sin comentarios",
                        });
                      }
                    }
                  }}
                >
                  {part?.is_rejected && user === "Ejecutor"
                    ? "Aceptar"
                    : "Rechazar"}
                </Button>
                <Button
                  width="122px"
                  fontWeight="600"
                  background="#FFFFFF"
                  color="#D93022"
                  border="1px solid #D93022"
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.25)"
                  onClick={() => {
                    if (part?.is_rejected && user === "Ejecutor") {
                      debateValorizedPart(id);
                      //setIsVisibleToTalkModal(false);
                    } else {
                      setIsVisibleToTalkModal(false);
                    }
                  }}
                >
                  {part?.is_rejected && user === "Ejecutor"
                    ? "A conversar"
                    : "Cancelar"}
                </Button>
              </div>
            ) : (
              ""
            )}

            <UserPhoto
              hidden={
                part?.is_debatable &&
                user === "Supervisor" &&
                logs?.length === 0
              }
              color={
                part?.is_debatable && user === "Supervisor" && logs?.length > 0
                  ? "#05BE8C"
                  : part?.is_debatable && user === "Ejecutor"
                  ? "#818181"
                  : part?.is_rejected && user === "Ejecutor"
                  ? "#818181"
                  : "#05BE8C"
              }
              img={user1}
            />
          </div>
        </div>
      )}
    </>
  );
};
