import React from "react";
import { Row } from "reactstrap";
import { IconContext } from "react-icons";
import { IoPersonCircleOutline } from "react-icons/io5";
import { Input } from "antd";

import { dateFormat } from "../../../utils";

import { EditProjectCard } from "../../EditProjectCard/EditProjectCard";
import { ShowFile } from "./ShowFile";
import { AcceptButtons } from "./AcceptButtons";

export const InitialConditions = ({
  id,
  exist_contractual_budget,
  exist_general_expenses,
  exist_valued_shedule,
  exist_valorizations_program,
  exec_aproved_contractual_budget,
  exec_aproved_valued_shedule,
  exec_aproved_general_expenses,
  exec_aproved_valorizations_program,
  exec_aproved_initial_date,
  exec_aproved_final_date,
  exec_aproved_project_delivery_settings,
  super_aproved_contractual_budget,
  super_aproved_valued_shedule,
  super_aproved_general_expenses,
  super_aproved_valorizations_program,
  super_aproved_initial_date,
  super_aproved_final_date,
  super_aproved_project_delivery_settings,
  initial_date,
  final_date,
  role,
  setModal,
  setToggleUpdateProject,
  setSpinLoading
}) => {
  return (
    <EditProjectCard
      title="9. Aprobación de condiciones iniciales"
      classname="editProject__initConditions"
    >
      <div className="ramp editProject__initConditions__container">
        <p></p>
        <div className="editProject__initConditions__icons editProject__center">
          <IconContext.Provider value={{ color: "#818181" }}>
            <IoPersonCircleOutline className="editProject__ml" size={28} />
          </IconContext.Provider>
          <span>Ejecutor</span>
        </div>
        <div className="editProject__initConditions__icons editProject__center">
          <IconContext.Provider value={{ color: "#05BE8C" }}>
            <IoPersonCircleOutline className="editProject__ml" size={28} />
          </IconContext.Provider>
          <span>Supervisor</span>
        </div>
      </div>
      <p></p>
      <div className="ramp editProject__initConditions__container">
        <ShowFile
          title="Presupuesto contractual"
          id={id}
          provider="contractual_budget"
          name="contractual-budget.xlsx"
          isFile={true}
          existDocument={exist_contractual_budget}
        />
        <AcceptButtons
          approvedByExecutor={exec_aproved_contractual_budget}
          approvedBySupervisor={super_aproved_contractual_budget}
          role={role}
          id={id}
          name="contractual_budget"
          setToggleUpdateProject={setToggleUpdateProject}
          setSpinLoading={setSpinLoading}
        />
      </div>
      <p></p>
      <div className="ramp editProject__initConditions__container">
        <ShowFile
          title="Gastos Generales"
          id={id}
          provider="general_expenses"
          name="general-expenses.xlsx"
          isFile={true}
          existDocument={exist_general_expenses}
        />
        <AcceptButtons
          approvedByExecutor={exec_aproved_general_expenses}
          approvedBySupervisor={super_aproved_general_expenses}
          role={role}
          id={id}
          name="general_expenses"
          setToggleUpdateProject={setToggleUpdateProject}
          setSpinLoading={setSpinLoading}
        />
      </div>
      <p></p>
      <div className="ramp editProject__initConditions__container">
        <ShowFile
          title="Cronograma valorizado"
          id={id}
          provider="valued_shedule"
          name="valued-shedule.xlsx"
          isFile={true}
          existDocument={exist_valued_shedule}
        />
        <AcceptButtons
          approvedByExecutor={exec_aproved_valued_shedule}
          approvedBySupervisor={super_aproved_valued_shedule}
          role={role}
          id={id}
          name="valued_shedule"
          setToggleUpdateProject={setToggleUpdateProject}
          setSpinLoading={setSpinLoading}
        />
      </div>
      <p></p>
      <div className="ramp editProject__initConditions__container">
        <Row className="ramp">
          <p className="editProject__mr">Inicio de plazo contractual </p>
          <Input
            value={dateFormat(initial_date)}
            disabled
            style={{ width: "95px" }}
            className="editProject__initConditions__input"
          />
        </Row>
        <AcceptButtons
          approvedByExecutor={exec_aproved_initial_date}
          approvedBySupervisor={super_aproved_initial_date}
          role={role}
          id={id}
          name="initial_date"
          setToggleUpdateProject={setToggleUpdateProject}
          setSpinLoading={setSpinLoading}
        />
      </div>
      <p></p>
      <div className="ramp editProject__initConditions__container">
        <Row className="ramp">
          <p className="editProject__mr">Fin de plazo contractual </p>
          <Input
            value={dateFormat(final_date)}
            disabled
            style={{ width: "95px" }}
            className="editProject__initConditions__input"
          />
        </Row>
        <AcceptButtons
          approvedByExecutor={exec_aproved_final_date}
          approvedBySupervisor={super_aproved_final_date}
          role={role}
          id={id}
          name="final_date"
          setToggleUpdateProject={setToggleUpdateProject}
          setSpinLoading={setSpinLoading}
        />
      </div>
      <p></p>
      <div className="ramp editProject__initConditions__container">
        <ShowFile
          title="Programación de valorizaciones"
          id={id}
          isFile={false}
          existDocument={exist_valorizations_program}
          setModal={setModal}
        />
        <AcceptButtons
          approvedByExecutor={exec_aproved_valorizations_program}
          approvedBySupervisor={super_aproved_valorizations_program}
          role={role}
          id={id}
          name="valorizations_program"
          setToggleUpdateProject={setToggleUpdateProject}
          setSpinLoading={setSpinLoading}
        />
      </div>
      <p></p>
      <div className="ramp editProject__initConditions__container">
      <p className="editProject__mr">Condiciones de entrega de obra</p>
        <AcceptButtons
          approvedByExecutor={exec_aproved_project_delivery_settings}
          approvedBySupervisor={super_aproved_project_delivery_settings}
          role={role}
          id={id}
          name="project_delivery"
          setToggleUpdateProject={setToggleUpdateProject}
          setSpinLoading={setSpinLoading}
        />
      </div>
    </EditProjectCard>
  );
};
