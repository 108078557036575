import React from "react";

// Styles imports
import {
  ButtonsContainer,
  ButtonsColumn,
  ButtonsRow,
  SectionLabel,
} from "./styles";

// Components import
import { Button } from "../../../../../../../components/Button";
import TutorialButton from "../TutorialButton";

const ActionButtonsPanel = ({
  setDebtModalActive,
  projectDeliveryCreationHandler,
  setAmountsDatesModalActive,
  setLiquidationModalActive,
  handleModalVideoTurial,
  screenSettings,
}) => {
  return (
    <>
      <ButtonsContainer>
        <ButtonsRow>
          <ButtonsColumn>
            <Button
              width="126px"
              height="26px"
              background="#f0f4f9"
              border="1px solid black"
              borderRadius="4px"
              color="#4A4A4A"
              fontSize="12px"
              margin="2px 0px 2px 0px"
              padding="0px 0px 0px 0px"
              onClick={() => setDebtModalActive(true)}
            >
              Gestión de pagos
            </Button>
            <Button
              width="126px"
              height="26px"
              background="#f0f4f9"
              border="1px solid black"
              borderRadius="4px"
              color="#4A4A4A"
              fontSize="12px"
              margin="2px 0px 2px 0px"
              padding="0px 0px 0px 0px"
              onClick={() => projectDeliveryCreationHandler()}
            >
              Entrega de obra
            </Button>
            <Button
              width="126px"
              height="26px"
              background="#f0f4f9"
              border="1px solid black"
              borderRadius="4px"
              color="#4A4A4A"
              fontSize="12px"
              margin="2px 0px 2px 0px"
              padding="0px 0px 0px 0px"
              onClick={() => setLiquidationModalActive(true)}
            >
              Liquidación
            </Button>
          </ButtonsColumn>
          <ButtonsColumn>
            <Button
              width="126px"
              height="26px"
              background="#f0f4f9"
              border="1px solid black"
              borderRadius="4px"
              color="#4A4A4A"
              fontSize="12px"
              margin="2px 0px 2px 0px"
              padding="0px 0px 0px 0px"
              onClick={() => setAmountsDatesModalActive(true)}
            >
              Montos y fechas
            </Button>
          </ButtonsColumn>
        </ButtonsRow>
        <ButtonsRow>
          <SectionLabel>Operaciones de obra</SectionLabel>
          {screenSettings.show_tutorial_videos === true ? (
            <TutorialButton
              handleModalVideoTurial={handleModalVideoTurial}
              videourl={screenSettings.construction_operations_tutorial_url}
            />
          ) : (
            <></>
          )}
        </ButtonsRow>
      </ButtonsContainer>
    </>
  );
};

export default ActionButtonsPanel;
