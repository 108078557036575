import { baseProvider } from "../baseProvider";

/* Additional Valorized Part */
const getAdditionalParts = async (id, search, params) =>
  baseProvider.httpGetPrueba(
    `/add_valorizations/${id}/valorized_parts`,
    search,
    params
  );

const getAdditionalPartById = async (id) =>
  baseProvider.httpGet(`/add_valorized_parts/${id}`);

const patchAdditionalPartById = async (id, body) =>
  baseProvider.httpPatch(`/add_valorized_parts/${id}`, body);

const patchAdditionalImagePart = async (id, body) =>
  baseProvider.httpPatchForm(`/add_valorized_parts/${id}`, body);

// /* Valorized Part Flux */
const getAdditionalValorizedPartLogs = async (id) =>
  baseProvider.httpGet(`/add_valorized_parts/${id}/logs`);

// /* Ejecutor */
const patchSendAdditionalValorizedPart = async (id) =>
  baseProvider.httpPatch(`/add_valorized_parts/${id}/send`);

const patchCorrectAdditionalValorizedPart = async (id) =>
  baseProvider.httpPatch(`/add_valorized_parts/${id}/correct`);

const patchDebateAdditionalValorizedPart = async (id) =>
  baseProvider.httpPatch(`/add_valorized_parts/${id}/debate`);

const postAdditionalValorizedPartLog = async (id, body) =>
  baseProvider.httpPost(`/add_valorized_parts/${id}/logs`, body);

// /* Supervisor */
const patchValidateAdditionalValorizedPart = async (id) =>
  baseProvider.httpPatch(`/add_valorized_parts/${id}/validate`);

const patchRejectAdditionalValorizedPart = async (id) =>
  baseProvider.httpPatch(`/add_valorized_parts/${id}/reject`);

const patchValidateAdditionalValorizedPartLog = async (id) =>
  baseProvider.httpPatch(`/add_valorized_parts/${id}/log_validate`);

const patchRejectAdditionalValorizedPartLog = async (id, body) =>
  baseProvider.httpPatch(`/add_valorized_parts/${id}/log_reject`, body);

// /* Valorized Part Comment */
const getCommentFileFromAdditionalValorizedPart = async (id) =>
  baseProvider.httpGetFile(`/add_valorized_parts/${id}/comment_file`);

export {
  getAdditionalParts,
  getAdditionalPartById,
  patchAdditionalPartById,
  patchAdditionalImagePart,
  patchSendAdditionalValorizedPart,
  patchDebateAdditionalValorizedPart,
  patchValidateAdditionalValorizedPart,
  patchRejectAdditionalValorizedPart,
  patchCorrectAdditionalValorizedPart,
  getCommentFileFromAdditionalValorizedPart,
  getAdditionalValorizedPartLogs,
  postAdditionalValorizedPartLog,
  patchValidateAdditionalValorizedPartLog,
  patchRejectAdditionalValorizedPartLog,
};
