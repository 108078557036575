import React from "react";
import styled from "styled-components";
import { IconContext } from "react-icons";
import { IoPersonCircleOutline } from "react-icons/io5";

const Card = styled.div`
  position: relative;
  width: 180px;
  height: 60px;
  border: 1px solid ${(props) => props.color || "#05be8c"};
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  padding-left: 8px;
  padding-right: 7px;
`;

const LiderDiv = styled.div`
  position: absolute;
  background: ${(props) => props.background || "#05be8c"};
  width: 39px;
  height: 14px;
  border-radius: 2px;
  font-size: 10px;
  font-weight: 700;
  text-align: center;
  color: #fff;
  right: 7px;
  top: -6px;
`;

const MateDiv = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: 13px;
  align-items: center;
`;

export const EnterpriseAcron = styled.div`
    display: flex;
    background-color: #00c187;
    text-align: center;
    color: white;
    width: 60px;
    height: 14px;
    border-radius: 12px;
    display: flex;
    font-size: 10px;
    justify-content: space-around;
    align-items: center;
    margin-top: 5px;
`;


export const MemberCard = ({ color, user, is_leader }) => {
  return (
    <div>
      <Card color={color}>
        {is_leader && <LiderDiv background={color}>LIDER</LiderDiv>}
        <div>
          <IconContext.Provider value={{ color }}>
            <IoPersonCircleOutline style={{ marginRight: "8px" }} size={28} />
          </IconContext.Provider>
        </div>
        <MateDiv>
          <span>
            {user?.first_name} {user?.last_name}
          </span>
          <EnterpriseAcron
              style={{background: color}}
            >
              MW
            </EnterpriseAcron>
        </MateDiv>
      </Card>
    </div>
  );
};
