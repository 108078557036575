import React from "react";
import BoxIcon from "./assets/box.svg";

import "./styles.css";

const NoDebtSetting = () => {
  return (
    <>
      <div>
        <p className="projectVal__modal__description">
          En este sección podrá realizar la validación de la deuda
        </p>
        <div className="projectVal__modal__cardsContainer">
          <div className="projectVal__card__container">
            <div style={{ width: "90%", textAlign: "center" }}>
              <img alt="box_icon" src={BoxIcon} className="no_debt_icon" />
              <p className="no_debt_indication">
                No hay deuda registrada por el supervisor
              </p>
            </div>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default NoDebtSetting;
