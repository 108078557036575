import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

//pages
import LoginPage from "../pages/LoginPage";

const LoginRouter = () => {
  return (
    <Switch>
      <Route exact path={"/"} component={LoginPage} />
      <Redirect to="/" />
    </Switch>
  );
};

export default LoginRouter;
