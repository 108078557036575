import React, { useState, useEffect } from "react";

// Components
import { notification, Modal } from "antd";
import ProjectsBanner from "../components/ProjectsBanner";
import ConstructionPageHeader from "../components/ConstructionPageHeader";
import ProjectsBannerSkeleton from "../components/ProjectsBannerSkeleton";
import { PortfolioPageContainer } from "../components/PortfolioPageContainer";
import NewProjectModal from "../components/NewProjectModal";

// Services
import { getProjects, getConstruction } from "../services";

const ConstructionPage = ({ history, match }) => {
  const { id } = match.params;
  // Component states
  const [construction, setConstruction] = useState({ name: "" });
  const [projects, setProjects] = useState([]);
  const [projectModalOpen, setProjectModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [trigger, setTrigger] = useState(true);
  const [search, setSearch] = useState("");

  const errorHandler = (type = "server", message = "") => {
    switch (type) {
      case "server":
        notification.error({
          message: "Error de servidor. Inténtelo de nuevo",
        });
        break;
      case "bad_request":
        notification.error({ message: message });
        break;
      default:
        break;
    }
  };

  const getProjectsSuccessHandler = (projects) => {
    setProjects(projects);
  };

  const getConstructionSuccessHandler = (construction) => {
    setConstruction(construction);
  };

  useEffect(() => {
    const fetchConstruction = async () => {
      await getConstruction(id, errorHandler, getConstructionSuccessHandler);
    };
    fetchConstruction();
  }, []);

  useEffect(() => {
    const fetchProjects = async () => {
      setLoading(true);
      await getProjects(
        id,
        { search: search },
        errorHandler,
        getProjectsSuccessHandler
      );
      setLoading(false);
    };
    fetchProjects();
  }, [search, trigger]);

  const reloadProjects = () => {
    setTrigger(!trigger);
  };

  const openProjectModal = () => {
    setProjectModalOpen(true);
  };

  return (
    <PortfolioPageContainer>
      <br />
      <ConstructionPageHeader
        search={search}
        setSearch={setSearch}
        constructionName={construction.name}
        openProjectModal={openProjectModal}
      />
      {loading ? (
        <ProjectsBannerSkeleton />
      ) : (
        <ProjectsBanner
          projects={projects}
          reloadProjects={reloadProjects}
          openProjectModal={openProjectModal}
        />
      )}
      <Modal
        title="Nuevo Contrato"
        centered
        open={projectModalOpen}
        onCancel={() => setProjectModalOpen(false)}
        footer={null}
      >
        <NewProjectModal
          history={history}
          setModal={setProjectModalOpen}
          reloadProjects={reloadProjects}
          constructionId={id}
        />
      </Modal>
    </PortfolioPageContainer>
  );
};

export default ConstructionPage;
