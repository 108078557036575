class AppSession {
  constructor({ sessionSaver = localStorage, sessionItemName = "User" }) {
    this.sessionSaver = sessionSaver;
    this.sessionItemName = sessionItemName;
  }

  create(sessionData = { accessToken: "" }) {
    this.sessionSaver.setItem(
      this.sessionItemName,
      JSON.stringify(sessionData)
    );
  }

  get() {
    try {
      const sessionData =
        JSON.parse(this.sessionSaver.getItem(this.sessionItemName)) || null;
      return sessionData;
    } catch {
      return null;
    }
  }

  //TODO: To be implemented. Now, its not used.
  getRefreshToken() {}

  destroy() {
    this.sessionSaver.removeItem(this.sessionItemName);
    window.location.replace("/");
  }
}

export default new AppSession({
  sessionProvider: localStorage,
  sessionItemName: "User",
});
