import React, { useEffect, useState } from "react";
import { Modal, notification } from "antd";
import { ModalTitle, ModalHeader, ModalCloseButton } from "./styles";
import {
  DebtSetting,
  NoDebtSetting,
  DebtValidation,
  DebtSummary,
  PaymentSetting,
  PaymentReject,
  PaymentSummary,
} from "./Modals";

import { getDebtById, rejectPaymentById } from "../../providers";

import { serverDateFormat } from "../../utils";
import moment from "moment";
import CloseButtonModal from "./assets/modal-close-button.png";

export default function DebtMainCard({
  debtModalActive,
  setDebtModalActive,
  user,
  debt_id,
  activeValorization,
  reloadValorizationsSummary,
  setReloadValorizationsSummary,
}) {
  //debt values
  const [debt, setDebt] = useState({
    amount: 0.0,
    invoice_series: "",
    invoice_number: "",
    status: "NO_STARTED",
  });
  const [reloadDebt, setReloadDebt] = useState(true);
  const [showReject, setShowReject] = useState(false);
  const [showPaymentSetting, setShowPaymentSetting] = useState(false);

  const [showPaymentSummary, setShowPaymentSummary] = useState(false);
  const [paymentSummary, setPaymentSummary] = useState("");

  const [rejectedPayment, setRejectedPayment] = useState("");

  // get debt info
  useEffect(() => {
    const fetchDebt = async () => {
      try {
        const _debt = await getDebtById(debt_id);
        setDebt({
          ..._debt,
          date: _debt.date || moment().format(serverDateFormat),
        });
      } catch (error) {
        console.log(error);
      }
    };
    debt_id && fetchDebt();
    setReloadValorizationsSummary(!reloadValorizationsSummary);
  }, [debt_id, reloadDebt]);

  const debtReload = () => {
    setReloadDebt(!reloadDebt);
  };

  const rejectPayment = async (comments) => {
    try {
      //TODO: Implementar validaciones
      await rejectPaymentById(rejectedPayment, { rejected_comments: comments });
      debtReload();
    } catch (error) {
      console.log(error);
    }
  };

  // Rules to show modal content
  let modalToShow = 1;

  if (debt.status === "NO_STARTED" && user === "Supervisor") {
    modalToShow = 2;
  }

  if (
    (debt.status === "SENDED" || debt.status === "REJECTED") &&
    user === "Supervisor"
  ) {
    modalToShow = 3;
  }

  if (debt.status === "VALIDATED" || debt.status === "CLOSED") {
    modalToShow = 4;
  }

  if (debt_id === null) {
    modalToShow = 2;
    //TODO: Puede mostrarse mensaje especifico para este caso
  }

  const setPayment = () => {
    setShowPaymentSetting(true);
  };

  const closePayment = () => {
    setShowPaymentSetting(false);
  };

  const openPaymentSummary = (id) => {
    setShowPaymentSummary(true);
    setPaymentSummary(id);
  };

  const closePaymentSummary = () => {
    setShowPaymentSummary(false);
  };

  const setRejectModal = (value, paymentId) => {
    setShowReject(value);
    setRejectedPayment(paymentId);
  };

  const getTitle = (modalIndex) => {
    let title = "";
    switch (modalIndex) {
      case 1:
        title = "Creación de deuda de valorización " + activeValorization;
        break;
      case 2:
        title = "Creación de deuda de valorización " + activeValorization;
        break;
      case 3:
        title = "Validación de deuda de valorización " + activeValorization;
        break;
      case 4:
        title = "Gestión de pagos de valorización " + activeValorization;
        if (showPaymentSetting) {
          title = "Creación de pago de valorización " + activeValorization;
        }
        if (showPaymentSummary) {
          title = "Información de pago de valorización " + activeValorization;
        }
        break;
      default:
        title = "Creación de deuda de valorización " + activeValorization;
        break;
    }
    return title;
  };
  return (
    <>
      <Modal
        title={`Rechazo de pago`}
        centered
        open={showReject}
        onCancel={() => {
          setShowReject(false);
        }}
        footer={null}
        width={534}
      >
        <PaymentReject
          rejectPayment={(comments) => {
            rejectPayment(comments);
            setShowReject(false);
          }}
        />
      </Modal>
      {debtModalActive && (
        <>
          <ModalHeader>
            <ModalTitle>{getTitle(modalToShow)}</ModalTitle>
            <ModalCloseButton
              alt="modal-close-button"
              src={CloseButtonModal}
              onClick={() => {
                setDebtModalActive(false);
              }}
            />
          </ModalHeader>

          {modalToShow === 1 && (
            <DebtSetting
              user={user}
              debt={debt}
              setDebt={setDebt}
              setReloadDebt={setReloadDebt}
              reloadDebt={reloadDebt}
              notification={notification}
            />
          )}
          {modalToShow === 2 && <NoDebtSetting />}
          {modalToShow === 3 && (
            <DebtValidation
              user={user}
              debt={debt}
              setReloadDebt={setReloadDebt}
              reloadDebt={reloadDebt}
              notification={notification}
            />
          )}
          {modalToShow === 4 && showPaymentSetting && (
            <PaymentSetting
              user={user}
              debt={debt}
              setDebt={setDebt}
              setReloadDebt={setReloadDebt}
              reloadDebt={reloadDebt}
              notification={notification}
              closePayment={closePayment}
            />
          )}
          {modalToShow === 4 && showPaymentSummary && (
            <PaymentSummary
              closePaymentSummary={closePaymentSummary}
              paymentSummary={paymentSummary}
            />
          )}
          {modalToShow === 4 && !showPaymentSetting && !showPaymentSummary && (
            <DebtSummary
              user={user}
              debt={debt}
              setReloadDebt={setReloadDebt}
              reloadDebt={reloadDebt}
              notification={notification}
              setPayment={setPayment}
              setShowReject={setRejectModal}
              debtReload={debtReload}
              openPaymentSummary={openPaymentSummary}
            />
          )}
        </>
      )}
    </>
  );
}
