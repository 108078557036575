import React from "react";
import { IconContext } from "react-icons";

import { CardContainer, CardRow, CardContent, CardContentIcon } from "./styles";

const NewConstructionCard = ({ createConstruction }) => {
  return (
    <CardContainer>
      <CardRow>
        <CardContent>
          <IconContext.Provider value={{ color: "gray" }}>
            <CardContentIcon size={40} onClick={createConstruction} />
          </IconContext.Provider>
        </CardContent>
      </CardRow>
    </CardContainer>
  );
};

export default NewConstructionCard;
