// React import
import React from "react";
import { useHistory } from "react-router-dom";

// Support libraries import
import { notification } from "antd";

// Components import
import { Modal } from "antd";
import AddValorizationBanner from "./components/AddValorizationBanner";

// Services import
import { addAdditionalValorization } from "./services";

// Styled components import

const AddValorizationModal = ({
  addValorizationModalActive,
  setAddValorizationModalActive,
  setSpinLoading,
  userRole,
  project,
}) => {
  const history = useHistory();
  const errorHandler = (error) => {
    let message = "";
    switch (error) {
      case "unauthorized":
        message = "No tienes permisos para realizar esta acción";
        break;
      case "server":
        message = "Ocurrió un error en la adición de valorizaciones";
        break;
      default:
        break;
    }
    notification.error({
      message: message,
    });
  };

  const successHandler = () => {
    notification.success({
      message: "Se realizo la adición de valorizaciones con éxito",
    });
    history.go(0);
  };

  const addValorizationHandler = async (number) => {
    setSpinLoading(true);
    await addAdditionalValorization(
      project.id,
      { number: number },
      errorHandler,
      successHandler
    );
    setSpinLoading(false);
    setAddValorizationModalActive(false);
  };

  return (
    <>
      <Modal
        title="Agregar valorización"
        centered
        open={addValorizationModalActive}
        onCancel={() => {
          setAddValorizationModalActive(false);
        }}
        footer={null}
        size={534}
      >
        <AddValorizationBanner
          project={project}
          addValorizationHandler={addValorizationHandler}
          bannerActivation={userRole === "ADMIN"}
        />
      </Modal>
    </>
  );
};

export default AddValorizationModal;
