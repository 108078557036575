import styled from "styled-components";

export const SectionContainer = styled.div`
  height: calc(100vh - 300px);
  overflow-x: auto;
  overflow-y: auto;
  background-color: #fff;
  @media (min-width: 790px) and (max-width: 1257px) {
    height: calc(100vh - 400px);
  }
  @media (max-width: 790px) {
    height: calc(100vh - 100px);
  }
`;

export const DeployButton = styled.button`
  width: 14px;
  height: 14px;
  font-size: 11px;
  margin-top: 12px;
  margin-right: 90px;
  border: solid 1px gray;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WorkspaceDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Workspace = styled.div`
  display: flex;
  top: 0;
`;

export const Workspace_header = styled.div `
  display: flex;
  position: sticky;
  top: 0;
`;

export const PartList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PartHeaders_sticky = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
`;

export const PartHeaders = styled.div`
display: flex;

`;

export const PartHeaders_base = styled.div`

`;

export const HeaderItem  = styled.div`
  height: 40px;
  background-color: white;
  text-align: center;
  border-bottom: solid 2px #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: black;
  font-weight: bold;
`;

export const HeaderItem_Budget  = styled.div`
  height: 40px;
  background-color: white;
  text-align: center;
  border-bottom: solid 2px #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: black;
  font-weight: bold;
  width: 266px;
  border-right: solid 1px #f3f3f3;
`;

export const HeaderItem_SearchContainer= styled.div`
  width: 350px;
  border-right: solid 2px #f3f3f3;
  padding-top: 4px;
  height: 40px;
  background-color: white;
  text-align: center;
  border-bottom: solid 2px #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: black;
  font-weight: bold;
`;

export const SearchContainer = styled.div`

`;

export const SearchInput = styled.input`
  font-size: 12px;
  width: 280px;
  color: black;
  margin-top: 1px;
  margin-right: 10px;
  border: white solid 1px;
  outline: none;

`;

export const Budget = styled.div`
  width: 266px;
  border-right: solid 1px #f3f3f3;
`;

export const PartHeaders_index = styled.div`
  height: 40px;
  background-color: white;
  text-align: center;
  border-bottom: solid 1px #efefef;
  padding-top: 1px;
`;
// este es
export const PartHeaders_text  = styled.p`
  font-size: 12px;
  color: black;
  font-weight: bold;
  margin-top: 10px;
`;

export const ValList = styled.div`

`;

export const ValHeaders = styled.div`

`;






