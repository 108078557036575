// React import
import React, { useState, useEffect } from "react";

// Support libraries import
import { IconContext } from "react-icons";
import { IoMdSearch } from "react-icons/io";
import { useHistory } from "react-router-dom";

// Components import
import DocumentManagerSection from "./components/DocumentManagerSection";
import Indication from "./components/Indication";
import { PreParts } from "./Parts/PreParts";

// Styled components import
import {
  SectionContainer,
  DeployButton,
  HeaderItem_Budget,
  PartHeaders_text,
  PartHeaders_index,
  PartHeaders,
  SearchInput,
  PartHeaders_sticky,
  PartList,
  Workspace_header,
  WorkspaceDiv,
  Workspace,
  HeaderItem_SearchContainer,
} from "./styles";

// Services import
import { getPreDeductiveParts } from "../../../services";

export const Valorizations = ({
  id,
  user,
  role,
  project,
  predeductive,
  setIsLoading,
  reloadPredeductivePage,
}) => {
  const history = useHistory();
  const [toggleUpdateParts, setToggleUpdateParts] = useState(false);
  const [search, setSearch] = useState("");

  const [partsContractualBudget, setPartsContractualBudget] = useState([]);
  const [parentIdCB, setParentIdCB] = useState("");
  const [partsGeneralExpenses, setPartsGeneralExpenses] = useState([]);
  const [parentIdGE, setParentIdGE] = useState("");

  const [is_active, setIs_active] = useState(true);
  // habilitaciones para supervisor y ejecutor
  const [current_order, setCurrent_order] = useState("");

  const [reloadPartsTrigger, setReloadPartsTrigger] = useState(false);

  // All Parts toggle button
  const [allPartsToggleButton, setAllPartsToggleButton] = useState("-");

  const successHandler = (response) => {
    let parts = response.results;

    const _parts_contractual_budget = parts.filter(
      (item) => item.doc_type === "CONTRACTUAL_BUDGET"
    );

    const _parts_general_expenses = parts.filter(
      (item) => item.doc_type === "GENERAL_EXPENSES"
    );

    setPartsContractualBudget(_parts_contractual_budget);
    setParentIdCB(_parts_contractual_budget[0]?.parent_id);
    setPartsGeneralExpenses(_parts_general_expenses);
    setParentIdGE(_parts_general_expenses[0]?.parent_id);
  };

  const errorHandler = (error) => {
    history.push("/main");
    console.log("error");
  };

  useEffect(() => {
    const fetchPartsByProject = async () => {
      await getPreDeductiveParts(id, errorHandler, successHandler);
    };

    fetchPartsByProject();
  }, [search, id, reloadPartsTrigger]);

  const reloadParts = () => {
    setReloadPartsTrigger(!reloadPartsTrigger);
  };

  const toggleHandler = () => {
    let groups = document.querySelectorAll(`[id*="_PART_GP_"]`);
    let buttons = document.querySelectorAll(`[id*="_CB_PART_GP_"]`);
    if (allPartsToggleButton === "+") {
      groups.forEach((group) => {
        group.style.display = "block";
      });
      buttons.forEach((button) => {
        if (button.innerHTML === "+") {
          button.innerHTML = "-";
          button.style.display = "flex";
        }
      });
      setAllPartsToggleButton("-");
    } else {
      groups.forEach((group) => {
        group.style.display = "none";
      });
      buttons.forEach((button) => {
        if (button.innerHTML === "-") {
          button.innerHTML = "+";
          button.style.display = "flex";
        }
      });
      setAllPartsToggleButton("+");
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          overflowX: "auto",
          flex: "1",
          background: "white",
          justifyContent: "space-between",
        }}
      >
        <SectionContainer
          style={{
            overflowY: "auto",
            minWidth: "624px",
            maxWidth: "100%",
            minHeight: "570px",
          }}
        >
          <WorkspaceDiv>
            <Workspace_header>
              <PartList>
                <PartHeaders_sticky>
                  <PartHeaders>
                    <HeaderItem_SearchContainer>
                      <SearchInput
                        placeholder="BUSCAR"
                        value={search}
                        onFocus={(event) => event.target.select()}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <IconContext.Provider value={{ color: "gray" }}>
                        <IoMdSearch size={25} />
                      </IconContext.Provider>{" "}
                    </HeaderItem_SearchContainer>
                    <HeaderItem_Budget>PRESUPUESTO BASE</HeaderItem_Budget>
                  </PartHeaders>
                  <PartHeaders>
                    <PartHeaders_index style={{ width: " 86px" }}>
                      <PartHeaders_text>Partidas</PartHeaders_text>{" "}
                    </PartHeaders_index>
                    <PartHeaders_index
                      style={{
                        width: "264px",
                        display: "flex",
                      }}
                    >
                      {" "}
                      <DeployButton onClick={() => toggleHandler()}>
                        {allPartsToggleButton}
                      </DeployButton>
                      <PartHeaders_text>Descripción</PartHeaders_text>
                    </PartHeaders_index>
                    <PartHeaders_index style={{ width: "72px" }}>
                      <PartHeaders_text>Metrado</PartHeaders_text>
                    </PartHeaders_index>
                    <PartHeaders_index style={{ width: "49px" }}>
                      <PartHeaders_text>Und</PartHeaders_text>
                    </PartHeaders_index>
                    <PartHeaders_index style={{ width: "72px" }}>
                      <PartHeaders_text>P.U (S/.)</PartHeaders_text>
                    </PartHeaders_index>
                    <PartHeaders_index
                      style={{
                        width: "73px",
                        borderRight: "solid 1px #f3f3f3",
                      }}
                    >
                      <PartHeaders_text>Parcial (S/.)</PartHeaders_text>
                    </PartHeaders_index>
                  </PartHeaders>
                </PartHeaders_sticky>
              </PartList>
            </Workspace_header>
            <Workspace style={{ display: "flex", flexDirection: "row" }}>
              <PartList>
                <PreParts
                  parts={partsContractualBudget}
                  search={search}
                  pruebaid={parentIdCB}
                  current_order={current_order}
                  user={user}
                  role={role}
                  setToggleUpdateParts={setToggleUpdateParts}
                  is_active={is_active}
                  fileGroup={"CB"}
                />
                <PreParts
                  parts={partsGeneralExpenses}
                  search={search}
                  pruebaid={parentIdGE}
                  current_order={current_order}
                  user={user}
                  role={role}
                  setToggleUpdateParts={setToggleUpdateParts}
                  is_active={is_active}
                  fileGroup={"GE"}
                />
              </PartList>
            </Workspace>
          </WorkspaceDiv>
        </SectionContainer>
        <DocumentManagerSection
          project={project}
          predeductive={predeductive}
          setIsLoading={setIsLoading}
          reloadPredeductivePage={reloadPredeductivePage}
          reloadParts={reloadParts}
        />
        <Indication />
      </div>
    </>
  );
};
