// Base import
import React, { useState, useEffect } from "react";

// Support libraries import
import { notification } from "antd";

// Styled components
import { TableSummaryContainer, TableItem } from "./styles";

// Components import
import { Button } from "../../../../../../../components/Button";

// Utils import
import { parseFloatNumber } from "../../../../../../../utils";

// Services import
import { deletePreDeductive } from "../../../../../services";

const TableSummary = ({
  predeductives,
  userRole,
  setTrigger,
  trigger,
  setLoading,
}) => {
  // States

  const [
    selectedDeductivesOriginalAmount,
    setSelectedDeductivesOriginalAmount,
  ] = useState(0);
  const [
    selectedDeductivesModifiedAmount,
    setSelectedDeductivesModifiedAmount,
  ] = useState(0);
  const [selectedDeductivesAmount, setSelectedDeductivesAmount] = useState(0);

  useEffect(() => {
    setSelectedDeductivesOriginalAmount(
      predeductives.reduce((acc, predeductive) => {
        if (predeductive.checked) {
          acc += predeductive.original_project_amount;
        }
        return acc;
      }, 0)
    );
    setSelectedDeductivesModifiedAmount(
      predeductives.reduce((acc, predeductive) => {
        if (predeductive.checked) {
          acc += predeductive.proposal_project_amount;
        }
        return acc;
      }, 0)
    );
    setSelectedDeductivesAmount(
      predeductives.reduce((acc, predeductive) => {
        if (predeductive.checked) {
          acc += predeductive.budget_cost;
        }
        return acc;
      }, 0)
    );
  }, [predeductives]);

  const validatePredeductiveRole = (approverRole) => {
    switch (userRole) {
      case "EXECUTOR":
      case "SUPERVISOR":
        return false;
      case "ADMIN":
        return approverRole === "EXECUTOR";
      case "EXECUTOR_LEADER":
        return approverRole === "SUPERVISOR";
      case "SUPERVISOR_LEADER":
        return approverRole === "EXECUTOR";
      default:
        return false;
    }
  };

  // handlers

  const deletionErrorHandler = (error) => {
    notification.error({
      message: "Hubo un error al eliminar el deductivo",
    });
  };

  const deletionSuccessHandler = (response) => {};

  const deleteButtonHandler = async () => {
    setLoading(true);
    let toDeletePredeductives = predeductives.filter(
      (predeductive) =>
        predeductive.checked &&
        (predeductive.status === "CREATED" ||
          predeductive.status === "SETTED") &&
        validatePredeductiveRole(predeductive.approver_role)
    );
    await toDeletePredeductives.forEach(async (predeductive) => {
      await deletePreDeductive(
        predeductive.id,
        deletionErrorHandler,
        deletionSuccessHandler
      );
    });
    setTimeout(() => {
      setLoading(false);
      notification.success({
        message:
          "Se eliminaron las propuestas de deductivo seleccionadas correctamente",
      });
      setTrigger(!trigger);
    }, 1000);
  };

  return (
    <>
      <TableSummaryContainer>
        <TableItem width="1118px" header justifyContent="left">
          <Button
            background="#D93022"
            width="122px"
            fontWeight="600"
            boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
            onClick={() => {
              deleteButtonHandler();
            }}
          >
            Suprimir
          </Button>
        </TableItem>
        <TableItem width="200px" header>
          Monto total - Presupuesto
        </TableItem>
        <TableItem width="270px" header>
          Monto total - Presupuesto modificado
        </TableItem>
        <TableItem width="200px" header>
          Deductivo - Monto total
        </TableItem>
        <TableItem width="308px" header></TableItem>
      </TableSummaryContainer>
      <TableSummaryContainer>
        <TableItem width="1118px" header justifyContent="right">
          Total de elementos seleccionados
        </TableItem>
        <TableItem width="200px">
          {`S/. ${parseFloatNumber(selectedDeductivesOriginalAmount)}`}
        </TableItem>
        <TableItem width="270px">
          {`S/. ${parseFloatNumber(selectedDeductivesModifiedAmount)}`}
        </TableItem>
        <TableItem width="200px">
          {`S/. ${parseFloatNumber(selectedDeductivesAmount)}`}
        </TableItem>
        <TableItem width="308px" header></TableItem>
      </TableSummaryContainer>
    </>
  );
};

export default TableSummary;
