import { baseProvider } from "../baseProvider";

const getPartsByProject = async (id, params) =>
  baseProvider.httpGet(`/projects/${id}/parts`, params);

/* Valorized Part */
const getParts = async (id, search, params) =>
  baseProvider.httpGetPrueba(
    `/valorizations/${id}/valorized_parts`,
    search,
    params
  );

const getPartById = async (id) =>
  baseProvider.httpGet(`/valorized_parts/${id}`);

const patchPartById = async (id, body) =>
  baseProvider.httpPatch(`/valorized_parts/${id}`, body);

const patchImagePart = async (id, body) =>
  baseProvider.httpPatchForm(`/valorized_parts/${id}`, body);

/* Valorized Part Flux */
const getValorizedPartLogs = async (id) =>
  baseProvider.httpGet(`/valorized_parts/${id}/logs`);

/* Ejecutor */
const patchSendValorizedPart = async (id) =>
  baseProvider.httpPatch(`/valorized_parts/${id}/send`);

const patchStopSendValorizedPart = async (id) =>
  baseProvider.httpPatch(`/valorized_parts/${id}/stop_send`);

const patchCorrectValorizedPart = async (id) =>
  baseProvider.httpPatch(`/valorized_parts/${id}/correct`);

const patchDebateValorizedPart = async (id) =>
  baseProvider.httpPatch(`/valorized_parts/${id}/debate`);

const postValorizedPartLog = async (id, body) =>
  baseProvider.httpPost(`/valorized_parts/${id}/logs`, body);

/* Supervisor */
const patchValidateValorizedPart = async (id) =>
  baseProvider.httpPatch(`/valorized_parts/${id}/validate`);

const patchRejectValorizedPart = async (id) =>
  baseProvider.httpPatch(`/valorized_parts/${id}/reject`);

const patchStopVerdictValorizedPart = async (id) =>
  baseProvider.httpPatch(`/valorized_parts/${id}/stop_verdict`);

const patchValidateValorizedPartLog = async (id) =>
  baseProvider.httpPatch(`/valorized_parts/${id}/log_validate`);

const patchRejectValorizedPartLog = async (id, body) =>
  baseProvider.httpPatch(`/valorized_parts/${id}/log_reject`, body);

/* Valorized Part Comment */
const getCommentFileFromPart = async (id) =>
  baseProvider.httpGetFile(`/valorized_parts/${id}/comment_file`);

export {
  getPartsByProject,
  getParts,
  getPartById,
  patchPartById,
  patchImagePart,
  getValorizedPartLogs,
  patchSendValorizedPart,
  patchStopSendValorizedPart,
  patchCorrectValorizedPart,
  patchDebateValorizedPart,
  postValorizedPartLog,
  patchValidateValorizedPart,
  patchRejectValorizedPart,
  patchValidateValorizedPartLog,
  patchRejectValorizedPartLog,
  getCommentFileFromPart,
  patchStopVerdictValorizedPart,
};
