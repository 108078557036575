// React import
import React from "react";

// Utils import
import { parseFloatNumber } from "../../../../../../utils";

export const PrePart = ({
  part,
  group_id,
  color_class_name,
  tab_class,
  parts_children,
  filter_handler,
  fileGroup,
}) => {
  return (
    <React.Fragment>
      <div
        id={fileGroup + "_PART_I_" + group_id}
        className="valorization__partHeaders"
      >
        <div className={"parts__index" + color_class_name}>
          <p className="parts__text">{part.part_tag}</p>
        </div>
        <div className={"parts__contract-div " + color_class_name}>
          <button
            hidden={!part.has_children}
            id={fileGroup + "_CB_PART_GP_" + group_id}
            className="parts__contract-button"
            onClick={() => filter_handler(group_id, fileGroup)}
          >
            -
          </button>
        </div>
        <div className={"parts__name-div" + color_class_name + tab_class}>
          <span title={part.name} className="parts__name-text">
            {part.name}
          </span>
        </div>
        <div className="parts__content" style={{ width: "72px" }}>
          <p hidden={part.has_children} className="parts__text">
            {parseFloatNumber(part.quantity)}
          </p>
        </div>
        <div className="parts__content" style={{ width: "49px" }}>
          <p className="parts__text">{part.unity}</p>
        </div>
        <div className="parts__content" style={{ width: "72px" }}>
          <p hidden={part.has_children} className="parts__text">
            {parseFloatNumber(part.unityprice)}
          </p>
        </div>
        <div
          className="parts__content"
          style={{ width: "73px", borderRight: "solid 1px #f3f3f3" }}
        >
          <p className="parts__text">{parseFloatNumber(part.ppartial)}</p>
        </div>
      </div>
      <div id={fileGroup + "_PART_GP_" + group_id}>{parts_children}</div>
    </React.Fragment>
  );
};
