import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import { notification } from "antd";
import { projectCreateService, getConstructions } from "../../services";
import Button from "../../../../../../../shared/components/Button";
import {
  NewProjectlabel,
  NewProjectInput,
  NewProjectContainerbtns,
  ConstructionContainer,
  ConstructionSelector,
  ConstructionOption,
} from "./styles";
import { useAppContext } from "../../../../../../../App/context";

const NewProjectModal = ({ history, setModal }) => {
  const { generalParams } = useAppContext();
  const [name, setName] = useState("");
  const { search } = generalParams;
  const [portfolioConstructions, setPortfolioConstructions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [construction, setConstruction] = useState("");

  const projectCreationSuccess = (projectId) => {
    setModal(false);
    notification.success({
      message: "El proyecto ha sido creado exitosamente",
    });
    history.push(`/projects/${projectId}/edit`);
  };

  const projectCreationError = (type = "server", message = "") => {
    setModal(false);
    switch (type) {
      case "bad_creation":
        notification.error({
          message: message,
        });
        break;
      case "server":
        notification.error({
          message: "Error de servidor. Inténtelo de nuevo",
        });
        break;
      default:
        break;
    }
  };

  const submitHandler = async () => {
    await projectCreateService(
      { name, construction },
      projectCreationError,
      projectCreationSuccess
    );
  };

  const errorHandler = (type = "server", message = "") => {
    switch (type) {
      case "server":
        notification.error({
          message: "Error de servidor. Inténtelo de nuevo",
        });
        break;
      case "bad_request":
        notification.error({ message: message });
        break;
      default:
        break;
    }
  };

  const getConstructionsSuccessHandler = (portfolioConstructions) => {
    setPortfolioConstructions(portfolioConstructions);
    setConstruction(portfolioConstructions[0].id);
  };

  useEffect(() => {
    const fetchPortfolioConstructions = async () => {
      setLoading(true);
      await getConstructions(
        { search: search },
        errorHandler,
        getConstructionsSuccessHandler
      );
      setLoading(false);
    };
    fetchPortfolioConstructions();
  }, [search]);

  const handleSelect = (event) => {
    setConstruction(event.target.value);
  };

  return (
    <>
      <NewProjectlabel>Nombre de proyecto:</NewProjectlabel>
      <Row>
        <NewProjectInput
          value={name}
          onFocus={(event) => event.target.select()}
          onChange={(e) => setName(e.target.value)}
          type="text"
          placeholder="Inserte nombre del proyecto"
        />
      </Row>
      <NewProjectlabel>Obra:</NewProjectlabel>
      <ConstructionContainer>
        <ConstructionSelector value={construction} onChange={handleSelect}>
          {portfolioConstructions.map((obra, index) => (
            <ConstructionOption value={obra.id}>{obra.name}</ConstructionOption>
          ))}
        </ConstructionSelector>
      </ConstructionContainer>
      <NewProjectContainerbtns>
        <Button
          width="130px"
          background="#0169E4"
          fontSize="15px"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
          borderRadius="5px"
          onClick={submitHandler}
          disabled={!name}
        >
          Crear proyecto
        </Button>
        <Button
          width="130px"
          background="#D93022"
          fontSize="15px"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
          borderRadius="5px"
          onClick={() => {
            setModal(false);
            setName("");
          }}
        >
          Cancelar
        </Button>
      </NewProjectContainerbtns>
    </>
  );
};
export default NewProjectModal;
