import React from "react";
import { IconContext } from "react-icons";
import { IoEllipse } from "react-icons/io5";

export const Item = ({ children }) => {
  return (
    <div className="editProject__mb2">
      <IconContext.Provider value={{ color: "#014083" }}>
        <IoEllipse style={{ marginRight: "10px" }} size={9} />
      </IconContext.Provider>
      {children}
    </div>
  );
};
