import React from "react";
import { notification } from "antd";
import { DropTarget } from "react-drag-drop-container";

import "./AddMateCard.css";
import iconProfile from "../../assets/iconProfile.png";
import iconAdd from "../../assets/addIcon.png";

export const AddMateCard = ({
  id,
  role,
  setToggleUpdateTeam,
  allTeam,
  setAllTeam,
}) => {
  const handleDrop = async (e) => {
    const user = e.dragData;
    const isAdministrator = allTeam.administrador.some(
      (elem) => elem.id === user.id
    );
    const isSupervisor = allTeam.supervisor.some((elem) => elem.id === user.id);
    const isExecutor = allTeam.ejecutor.some((elem) => elem.id === user.id);

    if (isAdministrator || isSupervisor || isExecutor) {
      notification.error({
        message: "No puede asignar a un compañero ya asignado",
      });
      return true;
    }

    let isLeader = false;
    if (allTeam[role].length === 0) {
      isLeader = true;
    }

    setAllTeam((prev) => ({
      ...prev,
      [role]: [...prev[role], { ...user, is_leader: isLeader }],
    }));

    notification.success({
      message: "Se asignado al compañero como " + role,
    });

    setToggleUpdateTeam((prev) => !prev);
  };

  return (
    <div className="addMateCard_container">
      <DropTarget targetKey="card" onHit={handleDrop}>
        <div className="addMateCard_header"></div>
        <div className="addMateCard_containerImg">
          <img src={iconProfile} alt="img" />
        </div>
        <div className="addMateCard_body">
          <p className="addMateCard_name addMateCard_bold">Usuario</p>
          <p className="addMateCard_position addMateCard_bold">Puesto</p>
          <p className="addMateCard_advice addMateCard_bold">
            Deslize aqui la tarjeta de colaboradores
          </p>
          <br />
          <div className="addMateCard_containerIcon">
            <img src={iconAdd} alt="img" />
          </div>
        </div>
      </DropTarget>
    </div>
  );
};
