import React, { useState } from "react";

import { Part } from "./Part";
import AdviceModal from "./AdviceModal";

import "./Parts.css";

export const Parts = ({
  parts,
  pruebaid,
  reloadPartProposals,
  setToggleUpdateParts,
  enableEdition,
  fileGroup,
  reloadPredeductivePage
}) => {
  const [toggleUpdatePart, setToggleUpdatePart] = useState(false);
  const [advisorModalActive, setAdvisorModalActive] = useState(false);

  const filter_handler = (id, fileGroup) => {
    let element1 = document.getElementById(fileGroup + "_PART_GP_" + id);
    let button = document.getElementById(fileGroup + "_CB_PART_GP_" + id);
    try {
      if (element1.innerHTML === "") {
        console.log("invalid");
      } else {
        if (element1.style.display === "none") {
          element1.style.display = "block";
          button.innerHTML = "-";
        } else {
          element1.style.display = "none";
          button.innerHTML = "+";
        }
      }
    } catch {}
  };

  const list_parts = (id, i, fileGroup) => {
    let partidas = parts?.map(function (part) {
      if (part.part?.parent_id === id) {
        i = i + 1;
        //TODO: group id i con 2 digitos
        /* let group_id = txti + i + "."; */
        let group_id = part.part.part_id;
        /* let group_txtci = txtci + String(i).padStart(2, "0") + "."; */
        let parts_children = list_parts(part.part.part_id, 0, fileGroup);
        let jerarchy_number = String(part.part.part_id).length;
        //Cambio de estilo según su jerarquia
        let color_class_name = "";
        let tab_class = " parts__tab__secondJerarchy";
        if (jerarchy_number < 4) {
          color_class_name = " parts__firstJerarchy";
          tab_class = "";
        }
        if (jerarchy_number > 3 && jerarchy_number < 7) {
          color_class_name = " parts__secondJerarchy";
          tab_class = " parts__tab__firstJerarchy";
        }

        return (
          <Part
            key={part.part.id}
            part={part}
            group_id={group_id}
            color_class_name={color_class_name}
            tab_class={tab_class}
            parts_children={parts_children}
            filter_handler={filter_handler}
            setToggleUpdateParts={setToggleUpdateParts}
            reloadPartProposals={reloadPartProposals}
            setToggleUpdatePart={setToggleUpdatePart}
            enableEdition={enableEdition}
            fileGroup={fileGroup}
            setAdvisorModalActive={setAdvisorModalActive}
            reloadPredeductivePage={reloadPredeductivePage}
          />
        );
      }
    });
    return partidas;
  };

  /* let partidas = list_parts(pruebaid, 0, "", ""); */
  let partidas = list_parts(pruebaid, 0, fileGroup);
  return (
    <>
      <AdviceModal
        advisorModalActive={advisorModalActive}
        setAdvisorModalActive={setAdvisorModalActive}
      />
      {partidas}
    </>
  );
};
