// Base import
import React, { useState, useEffect } from "react";

// Support libraries import
import { Checkbox } from "antd";

// Components import
import TableButton from "../TableButton";

// Styled components
import { TableItemContainer, TableItem, BaseTable } from "./styles";

const Table = ({
  hiddenVices,
  userRole,
  checkHiddenViceHandler,
  checkAllHiddenVicesHandler,
  setTrigger,
  trigger,
  openHiddenViceDetail,
}) => {
  const [allPreadditionalsChecked, setAllPreadditionalsChecked] =
    useState(false);

  useEffect(() => {
    setAllPreadditionalsChecked(
      hiddenVices.every((preadditional) => preadditional.checked)
    );
  }, [hiddenVices]);

  const getStatus = (hiddenVice) => {
    if (hiddenVice.is_closed) {
      return ["#28A745", "Levantado"];
    }
    if (
      hiddenVice.super_approved_hidden_vice &&
      hiddenVice.exec_approved_hidden_vice
    ) {
      return ["#0169E4", "Aceptado"];
    }
    return ["#000000", "Ingresado"];
  };

  return (
    <BaseTable>
      <TableItemContainer height="3rem">
        <TableItem width="40px" paddingTop="8px" header>
          <Checkbox
            checked={allPreadditionalsChecked}
            onChange={() => {
              checkAllHiddenVicesHandler(!allPreadditionalsChecked);
            }}
          />
        </TableItem>
        <TableItem width="40px" header>
          Orden
        </TableItem>
        <TableItem width="150px" header>
          Nombre
        </TableItem>
        <TableItem width="85px" header></TableItem>
        <TableItem width="150px" header>
          Fecha de ingreso del vicio oculto
        </TableItem>
        <TableItem width="150px" header>
          Fecha de aceptación del vicio oculto
        </TableItem>
        <TableItem width="150px" header>
          Fecha de levantamiento
        </TableItem>
        <TableItem width="120px" header>
          Estatus
        </TableItem>
      </TableItemContainer>
      {hiddenVices.map((hidden_vice) => (
        <TableItemContainer key={hidden_vice.id}>
          <TableItem width="40px" paddingTop="8px" header>
            <Checkbox
              checked={hidden_vice.checked}
              onChange={() => {
                checkHiddenViceHandler(hidden_vice.id);
              }}
            />
          </TableItem>
          <TableItem width="40px">{hidden_vice.order}</TableItem>
          <TableItem width="150px">{hidden_vice.name || "-"}</TableItem>
          <TableItem width="85px">
            <TableButton
              title="Ver detalle"
              active={true}
              onClick={() => {
                openHiddenViceDetail(hidden_vice.id);
              }}
            />
          </TableItem>
          <TableItem width="150px">
            {hidden_vice.hidden_vice_creation_date}
          </TableItem>
          <TableItem width="150px">
            {hidden_vice.hidden_vice_approbation_date}
          </TableItem>
          <TableItem width="150px">
            {hidden_vice.hidden_vice_closing_date}
          </TableItem>
          <TableItem width="120px" color={getStatus(hidden_vice)[0]}>
            {getStatus(hidden_vice)[1]}
          </TableItem>
        </TableItemContainer>
      ))}
    </BaseTable>
  );
};

export default Table;
