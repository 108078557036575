import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import { Switch, Input } from "antd";

import { EditProjectCard } from "../EditProjectCard/EditProjectCard";

export const CommercialTerms = (props) => {
  const [project_cost, setProject_cost] = useState(props.project_cost);
  const [default_project_cost, setDefault_project_cost] = useState(
    props.default_project_cost
  );
  const [tax_percentage, setTax_percentage] = useState(
    `${props.tax_percentage * 100}%`
  );
  const [utility_percentage, setUtility_percentage] = useState(
    `${props.utility_percentage * 100}%`
  );
  const [utility, setUtility] = useState(props.utility);
  const [advancement_percentage, setAdvancement_percentage] = useState(
    props.advancement_percentage
  );
  const [advancement, setAdvancement] = useState(props.advancement);
  const [retention_percentage, setRetention_percentage] = useState(
    `${props.retention_percentage * 100}%`
  );

  useEffect(() => {
    transformAmount(props.project_cost, setProject_cost);
  }, [props.project_cost]);

  useEffect(() => {
    setUtility_percentage(`${props.utility_percentage * 100}%`);
  }, [props.utility_percentage]);

  useEffect(() => {
    transformAmount(props.utility, setUtility);
  }, [props.utility]);

  useEffect(() => {
    setAdvancement_percentage(`${props.advancement_percentage * 100}%`);
  }, [props.advancement_percentage]);

  useEffect(() => {
    transformAmount(props.advancement, setAdvancement);
  }, [props.advancement]);

  const update = (body) => props.updateProject(body, true);

  const transformPercentage = (value, setValue) => {
    let newValue = value.replace("%", "");
    setValue(`${newValue}%`);
    return Number(newValue) / 100;
  };

  const separator = (numb) => {
    var str = numb.toString().split(".");
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return str.join(".");
  };

  const transformAmount = (value, setValue) => {
    let numberValue;
    if (typeof value === "number") {
      numberValue = value;
      //let newValue = Number(value.toFixed(2)).toLocaleString("en-US");
      //setValue(newValue);
    } else {
      numberValue = parseFloat(value.replace(",", ""));
    }
    let newValue = separator(numberValue.toFixed(2));
    setValue(newValue);
    //return Number(value.toFixed(2)).toLocaleString("en-US");
    return numberValue;
  };

  return (
    <EditProjectCard
      title="7. Condiciones comerciales del proyecto"
      classname="editProject__commercialTerms"
    >
      <Row className=" editProject__commercialTerms__container ">
        <p>Costo directo (Presupuesto base)</p>
        <Input
          value={separator(props.contractual_budget_cost.toFixed(2))}
          disabled
          style={{
            width: `${8 * project_cost.length + 40}px`,
            minWidth: "130px",
          }}
          addonBefore="S/"
        />
      </Row>
      <Row className=" editProject__commercialTerms__container ">
        <p>Gastos generales</p>
        <Input
          value={separator(props.general_expenses_cost.toFixed(2))}
          disabled
          style={{
            width: `${8 * project_cost.length + 40}px`,
            minWidth: "130px",
          }}
          addonBefore="S/"
        />
      </Row>
      <Row
        className=" editProject__commercialTerms__container"
        style={{ gap: "10px" }}
      >
        <p>Utilidad empresa ejecutora</p>
        <Row className="">
          <Input
            value={utility}
            onFocus={(event) => event.target.select()}
            onChange={(e) => setUtility(e.target.value)}
            onBlur={() => {
              const value = transformAmount(utility, setUtility);
              update({ utility: value });
            }}
            disabled={props.general_aproved || props.role !== "ADMIN"}
            style={{
              width: `${8 * utility.length + 40}px`,
              minWidth: "115px",
            }}
            addonBefore="S/"
          />
          <Input
            value={utility_percentage}
            onFocus={(event) => event.target.select()}
            onChange={(e) => setUtility_percentage(e.target.value)}
            onBlur={() => {
              const value = transformPercentage(
                utility_percentage,
                setUtility_percentage
              );
              update({ utility_percentage: value });
            }}
            disabled={props.general_aproved || props.role !== "ADMIN"}
            className="editProject__ml"
            style={{ width: "58px" }}
          />
        </Row>
      </Row>
      <Row className=" editProject__commercialTerms__container ">
        <p>Monto total de obra sin IGV</p>
        <Input
          value={separator(props.project_cost.toFixed(2))}
          disabled
          style={{
            width: `${8 * project_cost.length + 40}px`,
            minWidth: "130px",
          }}
          addonBefore="S/"
        />
      </Row>
      <Row className=" editProject__commercialTerms__container ">
        <p>Porcentaje IGV</p>
        <Input
          value={tax_percentage}
          onFocus={(event) => event.target.select()}
          onChange={(e) => setTax_percentage(e.target.value)}
          onBlur={() => {
            const value = transformPercentage(
              tax_percentage,
              setTax_percentage
            );
            update({ tax_percentage: value });
          }}
          disabled={props.general_aproved || props.role !== "ADMIN"}
          style={{
            width: `${8 * tax_percentage.length}px`,
            minWidth: "65px",
          }}
          type="text"
        />
      </Row>
      <Row className=" editProject__commercialTerms__container ">
        <p>Monto total de obra con IGV</p>
        <Input
          value={separator(props.total_project_cost.toFixed(2))}
          disabled
          style={{
            width: `${8 * project_cost.length + 40}px`,
            minWidth: "130px",
          }}
          addonBefore="S/"
        />
      </Row>
      <Row
        className=" editProject__commercialTerms__container"
        style={{ gap: "10px" }}
      >
        <p>Adelanto de obra (Inc. IGV)</p>
        <Row className="">
          <Input
            value={advancement}
            onFocus={(event) => event.target.select()}
            onChange={(e) => setAdvancement(e.target.value)}
            onBlur={() => {
              const value = transformAmount(advancement, setAdvancement);
              update({ advancement: value });
            }}
            disabled={props.general_aproved || props.role !== "ADMIN"}
            style={{
              width: `${8 * advancement.length + 40}px`,
              minWidth: "125px",
            }}
            addonBefore="S/"
          />
          <Input
            value={advancement_percentage}
            onFocus={(event) => event.target.select()}
            onChange={(e) => setAdvancement_percentage(e.target.value)}
            onBlur={() => {
              const value = transformPercentage(
                advancement_percentage,
                setAdvancement_percentage
              );
              update({ advancement_percentage: value });
            }}
            disabled={props.general_aproved || props.role !== "ADMIN"}
            className="editProject__ml"
            style={{ width: "58px" }}
          />
        </Row>
      </Row>
      <div className=" editProject__commercialTerms__retention">
        <p>Porcentaje de retención del fondo de garantía</p>
        <Input
          value={retention_percentage}
          onFocus={(event) => event.target.select()}
          onChange={(e) => setRetention_percentage(e.target.value)}
          onBlur={() => {
            const value = transformPercentage(
              retention_percentage,
              setRetention_percentage
            );
            update({ retention_percentage: value });
          }}
          disabled={props.general_aproved || props.role !== "ADMIN"}
          style={{
            width: `${8 * tax_percentage.length}px`,
            minWidth: "65px",
          }}
          type="text"
        />
      </div>
    </EditProjectCard>
  );
};
