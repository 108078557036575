import React, { useState, useEffect } from "react";
import { IconContext } from "react-icons";
import { IoMdSearch } from "react-icons/io";

import { getAdditionalParts } from "../../../../../providers";

import { Parts } from "./Parts/Parts";
import { Header } from "./Header/Header";

// styled components
import { SectionContainer, DeployButton } from "./styles";
import "./Valorizations.css";

export const Valorizations = ({
  id,
  user,
  role,
  valorizations,
  setCurrentValorization,
  currentValorization,
}) => {
  const [toggleUpdateParts, setToggleUpdateParts] = useState(false);
  const [val_id, setVal_id] = useState(0);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState([]);

  const [partsContractualBudget, setPartsContractualBudget] = useState([]);
  const [parentIdCB, setParentIdCB] = useState("");
  const [partsGeneralExpenses, setPartsGeneralExpenses] = useState([]);
  const [parentIdGE, setParentIdGE] = useState("");

  const [is_active, setIs_active] = useState(true);
  // habilitaciones para supervisor y ejecutor
  const [executorEnable, setExecutorEnable] = useState(false);
  const [supervisorEnable, setSupervisorEnable] = useState(false);
  const [current_order, setCurrent_order] = useState("");

  // All Parts toggle button
  const [allPartsToggleButton, setAllPartsToggleButton] = useState("-");

  useEffect(() => {
    setCurrent_order(currentValorization.order);
    setVal_id(currentValorization.id);
    setExecutorEnable(currentValorization.executor_enable);
    setSupervisorEnable(currentValorization.supervisor_enable);
  }, [currentValorization]);

  useEffect(() => {
    const fetchParts = async () => {
      try {
        const _parts = await getAdditionalParts(val_id, search, status);
        const _parts_contractual_budget = _parts.results.filter(
          (part) => part.part.doc_type === "CONTRACTUAL_BUDGET"
        );
        const _parts_general_expenses = _parts.results.filter(
          (part) => part.part.doc_type !== "CONTRACTUAL_BUDGET"
        );
        setPartsContractualBudget(_parts_contractual_budget);
        setParentIdCB(_parts_contractual_budget[0].part?.parent_id);
        setPartsGeneralExpenses(_parts_general_expenses);
        setParentIdGE(_parts_general_expenses[0].part?.parent_id);
      } catch (error) {
        console.log(error);
      }
    };
    const changeCurrentValorization = () => {
      let _current_val = valorizations.find((elem) => elem.id === val_id);
      setExecutorEnable(_current_val.executor_enable);
      setSupervisorEnable(_current_val.supervisor_enable);
      setCurrentValorization(_current_val);
    };

    if (val_id !== 0 && val_id) {
      fetchParts();
      changeCurrentValorization();
    }
    const partsInterval = setInterval(() => fetchParts(), 10000);
    return () => clearInterval(partsInterval);
  }, [val_id, search, toggleUpdateParts, user, status, valorizations]);



  const toggleHandler = () => {
    let groups = document.querySelectorAll(`[id*="_PART_GP_"]`);
    let buttons = document.querySelectorAll(`[id*="_CB_PART_GP_"]`);
    if (allPartsToggleButton === "+") {
      groups.forEach((group) => {
        group.style.display = "block";
      });
      buttons.forEach((button) => {
        if (button.innerHTML === "+") {
          button.innerHTML = "-";
          button.style.display = "flex";
        }
      });
      setAllPartsToggleButton("-");
    } else {
      groups.forEach((group) => {
        group.style.display = "none";
      });
      buttons.forEach((button) => {
        if (button.innerHTML === "-") {
          button.innerHTML = "+";
          button.style.display = "flex";
        }
      });
      setAllPartsToggleButton("+");
    }
  };

  return (
    <SectionContainer>
      <div className="valorization__workspaceDiv">
        <div className="valorization_workspace_header">
          <div className="valorization__partList">
            <div className="valorization__partHeaders-sticky">
              <div className="valorization__partHeaders">
                <div className="valorization__headerItem valorization__searchContainer">
                  <input
                    className="valorization__searchInput"
                    placeholder="BUSCAR"
                    value={search}
                    onFocus={(event) => event.target.select()}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <IconContext.Provider value={{ color: "gray" }}>
                    <IoMdSearch size={25} />
                  </IconContext.Provider>{" "}
                </div>
                <div className="valorization__headerItem valorization__budget">
                  PRESUPUESTO BASE
                </div>
              </div>
              <div className="valorization__partHeaders">
                <div
                  className="valorization__partHeaders-index"
                  style={{ width: " 86px" }}
                >
                  <p className="valorization__partHeaders-text">Partidas</p>{" "}
                </div>
                <div
                  className="valorization__partHeaders-index"
                  style={{
                    width: "264px",
                    display: "flex",
                  }}
                >
                  {" "}
                  <DeployButton onClick={() => toggleHandler()}>
                    {allPartsToggleButton}
                  </DeployButton>
                  <p className="valorization__partHeaders-text">Descripción</p>
                </div>
                <div
                  className="valorization__partHeaders-index"
                  style={{ width: "72px" }}
                >
                  <p className="valorization__partHeaders-text">Metrado</p>
                </div>
                <div
                  className="valorization__partHeaders-index"
                  style={{ width: "49px" }}
                >
                  <p className="valorization__partHeaders-text">Und</p>
                </div>
                <div
                  className="valorization__partHeaders-index"
                  style={{ width: "72px" }}
                >
                  <p className="valorization__partHeaders-text">P.U (S/.)</p>
                </div>
                <div
                  className="valorization__partHeaders-index"
                  style={{ width: "73px", borderRight: "solid 1px #f3f3f3" }}
                >
                  <p className="valorization__partHeaders-text">
                    Parcial (S/.)
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Header
            current_order={current_order}
            setCurrent_order={setCurrent_order}
            val_id={val_id}
            setVal_id={setVal_id}
            valorizations={valorizations}
            total_val={valorizations.length}
            user={user}
            setStatus={setStatus}
          />
        </div>
        <div className="valorization__workspace">
          <div className="valorization__partList">
            <Parts
              parts={partsContractualBudget}
              search={search}
              pruebaid={parentIdCB}
              current_order={current_order}
              user={user}
              role={role}
              setToggleUpdateParts={setToggleUpdateParts}
              is_active={is_active}
              executorEnable={executorEnable}
              supervisorEnable={supervisorEnable}
              fileGroup={"CB"}
            />
            <Parts
              parts={partsGeneralExpenses}
              search={search}
              pruebaid={parentIdGE}
              current_order={current_order}
              user={user}
              role={role}
              setToggleUpdateParts={setToggleUpdateParts}
              is_active={is_active}
              executorEnable={executorEnable}
              supervisorEnable={supervisorEnable}
              fileGroup={"GE"}
            />
          </div>
        </div>
      </div>
    </SectionContainer>
  );
};
