// React import
import React, { useState, useEffect } from "react";

// Third part libraries import
import { notification, Input } from "antd";
import * as FileSaver from "file-saver";
import { useHistory } from "react-router-dom";

// Components import
import ChargeFileButton from "../ChargeFileButton";
import FileItem from "../FileItem";

// Services import
import {
  updatePreDeductive,
  updatePreDeductiveBudget,
  getPreDeductiveBudget,
  validatePreDeductiveBudget,
} from "../../../../../services";

// Providers import
import { getPredeductiveReport } from "../../../../../../../providers";

// Styles import
import { FileRowContainer, Container } from "./styles";
import ReportModal from "../ReportModal";

const DocumentManagerSection = ({
  project,
  predeductive,
  setIsLoading,
  reloadPredeductivePage,
  reloadParts,
}) => {
  // States
  const [enableEdition, setEnableEdition] = useState(false);
  const [utility, setUtility] = useState(0);
  const [utilityPercentage, setUtilityPercentage] = useState(0);
  const [generalExpenses, setGeneralExpenses] = useState(0);
  const [generalExpensesPercentage, setGeneralExpensesPercentage] = useState(0);
  const [igvIncluded, setIgvIncluded] = useState(false);
  // Report modal status
  const [activeReport, setActiveReportModal] = useState(false);
  const [report, setReport] = useState("");

  const history = useHistory();

  useEffect(() => {
    setEnableEdition(getPredeductiveEditionStatus(predeductive));
    transformAmount(predeductive.utility, setUtility);
    setUtilityPercentage(`${predeductive.utility_percentage * 100}%`);
    transformAmount(predeductive.general_expenses, setGeneralExpenses);
    setGeneralExpensesPercentage(
      `${predeductive.general_expenses_percentage * 100}%`
    );
    setIgvIncluded(predeductive.igv_included);
  }, [predeductive, project]);

  const getPredeductiveEditionStatus = (predeductive) => {
    let enableEdition = false;
    if (
      predeductive.status === "REJECTED" ||
      predeductive.status === "VALIDATED"
    ) {
      enableEdition = false;
    } else {
      if (
        project.role === "EXECUTOR_LEADER" &&
        predeductive.approver_role === "SUPERVISOR"
      ) {
        enableEdition = true;
      }
      if (
        project.role === "SUPERVISOR_LEADER" &&
        predeductive.approver_role === "EXECUTOR"
      ) {
        enableEdition = true;
      }
      if (project.role === "ADMIN") {
        enableEdition = true;
      }
    }
    return enableEdition;
  };

  // Saving files

  const getPredeductiveFileUpdateBody = (file) => {
    let formData = new FormData();
    formData.append("file", file);
    return formData;
  };

  const predeductiveFileUpdateErrorHandler = (errorType, response = {}) => {
    switch (errorType) {
      case "bad_request":
        notification.error({
          message: response[Object.keys(response)[0]],
        });
        break;
      case "server":
        notification.error({
          message: "Hubo un error en el servidor",
        });
        break;
      default:
        notification.error({
          message: "Hubo un error en el servidor",
        });
        break;
    }
  };

  const predeductiveBudgetUpdateSuccessHandler = (predeductive) => {
    notification.success({
      message: "Se realizo correctamente la carga del archivo de presupuesto",
    });
    reloadPredeductivePage();
    reloadParts();
  };

  const savePredeductiveBudget = async (file) => {
    setIsLoading(true);
    await updatePreDeductiveBudget(
      predeductive.id,
      getPredeductiveFileUpdateBody(file),
      predeductiveFileUpdateErrorHandler,
      predeductiveBudgetUpdateSuccessHandler
    );
    setIsLoading(false);
  };

  // Documents getting

  const errorFileGetHandler = (errorType, response = {}) => {
    switch (errorType) {
      case "no_found":
        notification.error({
          message: response[Object.keys(response)[0]],
        });
        break;
      case "server":
        notification.error({
          message: "Hubo un error en el servidor",
        });
        break;
      default:
        notification.error({
          message: "Hubo un error en el servidor",
        });
        break;
    }
  };

  const successBudgetGetHandler = (response) => {
    FileSaver.saveAs(response, predeductive.budget_filename);
  };

  const getPredeductiveBudgetFile = async () => {
    await getPreDeductiveBudget(
      predeductive.id,
      errorFileGetHandler,
      successBudgetGetHandler
    );
  };

  // Documents validation

  const errorFileValidationHandler = (errorType, response = {}) => {
    switch (errorType) {
      case "bad_request":
        notification.error({
          message: response[Object.keys(response)[0]],
        });
        break;
      case "server":
        notification.error({
          message: "Hubo un error en el servidor",
        });
        break;
      default:
        notification.error({
          message: "Hubo un error en el servidor",
        });
        break;
    }
  };

  const successBudgetValidationHandler = (predeductive) => {
    notification.success({
      message:
        "Se realizo correctamente la validación de la propuesta de deductivo",
    });
    reloadPredeductivePage();
  };

  const validateBudget = async (file) => {
    setIsLoading(true);
    setActiveReportModal(false);
    await validatePreDeductiveBudget(
      predeductive.id,
      errorFileValidationHandler,
      successBudgetValidationHandler
    );
    setIsLoading(false);
  };

  // Predeductive update

  const predeductiveUpdateErrorHandler = (error) => {
    setIsLoading(false);
    notification.error({
      message:
        "Ocurrió un error en la actualización de la propuesta de deductivo",
    });
    reloadPredeductivePage();
  };

  const predeductiveUpdateSuccessHandler = (response) => {
    setIsLoading(false);
    notification.success({
      message: "Se actualizó la propuesta de deductivo correctamente",
    });
    reloadPredeductivePage();
  };

  const updatePredeductive = async (body) => {
    setIsLoading(true);
    await updatePreDeductive(
      predeductive.id,
      body,
      predeductiveUpdateErrorHandler,
      predeductiveUpdateSuccessHandler
    );
  };

  // Report getting
  const blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const loadPredeductiveReportHandler = async () => {
    try {
      setIsLoading(true);
      const response = await getPredeductiveReport(predeductive.id);
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        const json = await response.json();
        if (json.detail) {
          notification.success({
            message: json.detail,
          });
          setIsLoading(false);
          setTimeout(() => history.go(0), 3000);
        } else {
          notification.error({
            message: "Ocurrió un error interno. Por favor vuelva a intentarlo",
          });
          setIsLoading(false);
        }
      } else {
        const blobFile = await response.blob();
        const base64File = await blobToBase64(blobFile);
        setReport(base64File);
        setIsLoading(false);
        setActiveReportModal(true);
      }
    } catch (error) {
      notification.error({
        message: "Ocurrió un error interno. Por favor vuelva a intentarlo",
      });
      setIsLoading(false);
    }
  };

  // transforms
  const transformPercentage = (value, setValue) => {
    let newValue = value.replace("%", "");
    setValue(`${newValue}%`);
    return Number(newValue) / 100;
  };

  const separator = (numb) => {
    var str = numb.toString().split(".");
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return str.join(".");
  };

  const transformAmount = (value, setValue) => {
    let numberValue;
    if (typeof value === "number") {
      numberValue = value;
    } else {
      numberValue = parseFloat(value.replace(",", ""));
    }
    let newValue = separator(numberValue.toFixed(2));
    setValue(newValue);
    return numberValue;
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minWidth: "400px",
          maxWidth: "500px",
          alignItems: "center",
          lineHeight: "22px",
          fontWeight: "700",
          background: "white",
          marginBottom: "50px",
        }}
      >
        <div style={{ fontSize: "14px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              textAlign: "left",
              height: "32px",
              marginTop: "20px",
            }}
          >
            <p style={{ width: "250px", height: "32px", color: "#014083" }}>
              Estado Presupuesto Deductivo
            </p>
            <div
              style={{
                marginLeft: "100px",
                whiteSpace: "nowrap",
                color: predeductive.is_budget_approved
                  ? "#05BE8C"
                  : predeductive.budget_filename === ""
                  ? "#818181"
                  : "#D93022",
              }}
            >
              <p>
                {predeductive.is_budget_approved
                  ? "Validado"
                  : predeductive.budget_filename === ""
                  ? "Por registrar"
                  : "Por Validar"}{" "}
              </p>
            </div>
          </div>
          <Container style={{ marginBottom: "20px" }}>
            <FileRowContainer>
              <ChargeFileButton chargeDocument={savePredeductiveBudget} />
              <FileItem
                fileName={predeductive.budget_filename}
                getFile={() => {
                  getPredeductiveBudgetFile();
                }}
              />
            </FileRowContainer>
          </Container>
          <div
            style={{
              gap: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <p>Monto de presupuesto ingresado</p>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Input
                value={predeductive.budget_cost}
                disabled={true}
                style={{
                  width: `${8 * predeductive.budget_cost.length || 0 + 40}px`,
                  minWidth: "115px",
                }}
                addonBefore="S/"
              />
            </div>
          </div>
          <div
            style={{
              gap: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <p>Utilidad</p>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Input
                value={utility}
                onFocus={(event) => event.target.select()}
                onChange={(e) => setUtility(e.target.value)}
                onBlur={() => {
                  const value = transformAmount(utility, setUtility);
                  updatePredeductive({ utility: value });
                }}
                disabled={!enableEdition}
                style={{
                  width: `${8 * utility.length || 0 + 40}px`,
                  minWidth: "115px",
                }}
                addonBefore="S/"
              />
              <Input
                value={utilityPercentage}
                onFocus={(event) => event.target.select()}
                onChange={(e) => setUtilityPercentage(e.target.value)}
                onBlur={() => {
                  const value = transformPercentage(
                    utilityPercentage,
                    setUtilityPercentage
                  );
                  updatePredeductive({ utility_percentage: value });
                }}
                disabled={!enableEdition}
                className="editProject__ml"
                style={{ width: "58px" }}
              />
            </div>
          </div>
          <div
            style={{
              gap: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <p>Gastos generales</p>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Input
                value={generalExpenses}
                onFocus={(event) => event.target.select()}
                onChange={(e) => setGeneralExpenses(e.target.value)}
                onBlur={() => {
                  const value = transformAmount(
                    generalExpenses,
                    setGeneralExpenses
                  );
                  updatePredeductive({ general_expenses: value });
                }}
                disabled={!enableEdition}
                style={{
                  width: `${8 * utility.length || 0 + 40}px`,
                  minWidth: "115px",
                }}
                addonBefore="S/"
              />
              <Input
                value={generalExpensesPercentage}
                onFocus={(event) => event.target.select()}
                onChange={(e) => setGeneralExpensesPercentage(e.target.value)}
                onBlur={() => {
                  const value = transformPercentage(
                    generalExpensesPercentage,
                    setGeneralExpensesPercentage
                  );
                  updatePredeductive({ general_expenses_percentage: value });
                }}
                disabled={!enableEdition}
                className="editProject__ml"
                style={{ width: "58px" }}
              />
            </div>
          </div>
          <div
            style={{
              gap: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <p>Subtotal</p>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Input
                value={predeductive.deductive_subtotal}
                disabled={true}
                style={{
                  width: `${
                    8 * predeductive.deductive_subtotal.length || 0 + 40
                  }px`,
                  minWidth: "115px",
                }}
                addonBefore="S/"
              />
            </div>
          </div>{" "}
          <div
            style={{
              gap: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            {" "}
            <p>¿Incluye IGV?</p>
            <input
              name="check"
              type="checkbox"
              checked={igvIncluded}
              onClick={() => {
                if (enableEdition) {
                  setIgvIncluded(!igvIncluded);
                  updatePredeductive({ igv_included: !igvIncluded });
                }
              }}
            />
          </div>
          {igvIncluded ? (
            <div
              style={{
                gap: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <p>Monto de igv</p>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Input
                  value={predeductive.igv_amount}
                  disabled={true}
                  style={{
                    width: `${8 * predeductive.igv_amount.length || 0 + 40}px`,
                    minWidth: "115px",
                  }}
                  addonBefore="S/"
                />
              </div>
            </div>
          ) : (
            <></>
          )}
          <div
            style={{
              gap: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <p>Total por deducir</p>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Input
                value={predeductive.deductive_total}
                disabled={true}
                style={{
                  width: `${
                    8 * predeductive.deductive_total.length || 0 + 40
                  }px`,
                  minWidth: "115px",
                }}
                addonBefore="S/"
              />
            </div>
          </div>
        </div>
        <div
          style={{
            height: "32px",
            width: "140px",
            background:
              predeductive.budget_filename !== "" &&
              !predeductive.is_budget_approved &&
              enableEdition
                ? "#05BE8C"
                : "#818181",
            borderRadius: "5px",
            fontWeight: "100",
            boxShadow: "0px 2px 8px rgba(26, 18, 18, 0.25)",
            cursor:
              predeductive.budget_filename !== "" &&
              !predeductive.is_budget_approved &&
              enableEdition
                ? "pointer"
                : "auto",
          }}
          onClick={() => {
            predeductive.budget_filename !== "" &&
              !predeductive.is_budget_approved &&
              enableEdition &&
              loadPredeductiveReportHandler();
          }}
        >
          <div
            style={{
              paddingTop: "4px",
              color: "white",
              justifyContent: "space-around",
              textAlign: "center",
            }}
          >
            {predeductive.report_is_being_generated
              ? "Generando..."
              : "Validar"}
          </div>
        </div>
        <ReportModal
          active={activeReport}
          report={report}
          setActiveReportModal={setActiveReportModal}
          validatePredeductiveReport={validateBudget}
        />
      </div>
    </div>
  );
};

export default DocumentManagerSection;
