import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";

import { notification } from "antd";

import {
  getAdministrators,
  getSupervisors,
  getExecutors,
  editProjectTeam,
} from "../../providers";

import { AddMateCard } from "../AddMateCard/AddMateCard";
import { EditProjectCard } from "../EditProjectCard/EditProjectCard";
import { MateCard } from "../MateCard/MateCard";

export const ProjectTeam = forwardRef((props, ref) => {
  const [search, setSearch] = useState("");

  const [toggleUpdateTeam, setToggleUpdateTeam] = useState(false);

  const id = props.id;
  const allTeam = props.allTeam;
  const setAllTeam = props.setAllTeam;

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const _administrators = await getAdministrators(id, { search });
        const _supervisors = await getSupervisors(id, { search });
        const _executors = await getExecutors(id, { search });
        setAllTeam({
          administrador: _administrators.results,
          supervisor: _supervisors.results,
          ejecutor: _executors.results,
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useImperativeHandle(ref, () => ({
    saveProjectTeam() {
      async function _saveProjectTeam() {
        try {
          const response = await editProjectTeam(id, {
            administrators: allTeam["administrador"].map((elem) => elem.id),
            executors: allTeam["ejecutor"].map((elem) => elem.id),
            supervisors: allTeam["supervisor"].map((elem) => elem.id),
            lead_administrator:
              allTeam["administrador"].find((elem) => elem.is_leader)?.id ||
              null,
            lead_executor:
              allTeam["ejecutor"].find((elem) => elem.is_leader)?.id || null,
            lead_supervisor:
              allTeam["supervisor"].find((elem) => elem.is_leader)?.id || null,
          });

          if (response.detail === "Se ha editado al equipo del proyecto") {
            notification.success({
              message:
                "El equipo del proyecto ha sido actualizado exitosamente",
            });
            setTimeout(() => {
              props.history.goBack();
            }, 2000);
          } else {
            notification.error({
              message: response.detail,
            });
          }
        } catch (error) {
          notification.error({
            message: "Ocurrió un " + error,
          });
        }
      }
      _saveProjectTeam();
    },
  }));

  return (
    <>
      <EditProjectCard
        classname="editProjectMates__team"
        title="Equipo de proyecto"
        searchVisible={true}
        setSearch={setSearch}
      >
        <p></p>
        <EditProjectCard
          title="Administradores"
          searchVisible={false}
          padding="0rem"
          marginBottom="15px"
        >
          <div
            className="ramp"
            style={{ overflowX: "auto", display: "flex", gap: "15px" }}
          >
            {allTeam?.administrador?.map((member) => (
              <MateCard
                key={member.id}
                {...member}
                data={member}
                projectId={id}
                setToggleUpdateTeam={setToggleUpdateTeam}
                background="rgba(1, 105, 228, 0.3)"
                leaderColor="#0169e4"
                marginBottom="10px"
                role="administrador"
                allTeam={allTeam}
                setAllTeam={setAllTeam}
              />
            ))}
            <AddMateCard
              id={id}
              role="administrador"
              setToggleUpdateTeam={setToggleUpdateTeam}
              allTeam={allTeam}
              setAllTeam={setAllTeam}
            />
          </div>
        </EditProjectCard>
        <EditProjectCard
          title="Supervisores"
          searchVisible={false}
          padding="0rem"
          marginBottom="15px"
        >
          <div
            className="ramp"
            style={{ overflowX: "auto", display: "flex", gap: "15px" }}
          >
            {allTeam?.supervisor?.map((member) => (
              <MateCard
                key={member.id}
                {...member}
                data={member}
                projectId={id}
                setToggleUpdateTeam={setToggleUpdateTeam}
                background="rgba(5, 190, 140, 0.3)"
                leaderColor="#05BE8C"
                marginBottom="10px"
                role="supervisor"
                allTeam={allTeam}
                setAllTeam={setAllTeam}
              />
            ))}
            <AddMateCard
              id={id}
              role="supervisor"
              setToggleUpdateTeam={setToggleUpdateTeam}
              allTeam={allTeam}
              setAllTeam={setAllTeam}
            />
          </div>
        </EditProjectCard>
        <EditProjectCard
          title="Ejecutores"
          searchVisible={false}
          padding="0rem"
          marginBottom="0px"
        >
          <div
            className="ramp"
            style={{ overflowX: "auto", display: "flex", gap: "15px" }}
          >
            {allTeam?.ejecutor?.map((member) => (
              <MateCard
                key={member.id}
                {...member}
                data={member}
                projectId={id}
                setToggleUpdateTeam={setToggleUpdateTeam}
                background="rgba(93, 93, 93, 0.3)"
                leaderColor="#5D5D5D"
                marginBottom="10px"
                role="ejecutor"
                allTeam={allTeam}
                setAllTeam={setAllTeam}
              />
            ))}
            <AddMateCard
              id={id}
              role="ejecutor"
              setToggleUpdateTeam={setToggleUpdateTeam}
              allTeam={allTeam}
              setAllTeam={setAllTeam}
            />
          </div>
        </EditProjectCard>
      </EditProjectCard>
    </>
  );
});
