import { baseProvider } from "../baseProvider";

const getAdministrators = async (id, params) =>
  baseProvider.httpGet(`/projects/${id}/administrators`, params);

const getSupervisors = async (id, params) =>
  baseProvider.httpGet(`/projects/${id}/supervisors`, params);

const getExecutors = async (id, params) =>
  baseProvider.httpGet(`/projects/${id}/executors`, params);

const editProjectTeam = async (id, body) =>
  baseProvider.httpPatch(`/projects/${id}/save_project_team`, body);

export {
  getAdministrators,
  getSupervisors,
  getExecutors,
  editProjectTeam
};
