import appFetcher from "../../App/utils/http/AppFetcher";
import getErrorMessage from "../../shared/utils/getErrorMessage";

export const getProjects = async (
  id,
  queryParams = {},
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/constructions/${id}/projects`,
      isAuthRequired: true,
      queryParams: queryParams,
      requestContentType: "application/json",
      responseContentType: "json",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response.results);
    } else {
      errorHandler("server");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const createProject = async (body, errorHandler, successHandler) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: "/projects",
      isAuthRequired: true,
      body: body,
      requestContentType: "application/json",
      responseContentType: "json",
      method: "POST",
    });
    if (status === 201) {
      successHandler(response);
    } else {
      errorHandler(getErrorMessage(response), "bad_request");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const deleteProject = async (id, errorHandler, successHandler) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/projects/${id}`,
      isAuthRequired: true,
      requestContentType: "application/json",
      responseContentType: "empty",
      method: "DELETE",
    });
    if (status === 204) {
      successHandler(response.detail);
    } else {
      errorHandler(getErrorMessage(response), "bad_request");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const getPortfolio = async (
  queryParams = {},
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: "/portfolio",
      isAuthRequired: true,
      queryParams: queryParams,
      requestContentType: "application/json",
      responseContentType: "json",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response.results);
    } else {
      errorHandler("server");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const getConstructions = async (
  queryParams = {},
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: "/constructions",
      isAuthRequired: true,
      queryParams: queryParams,
      requestContentType: "application/json",
      responseContentType: "json",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response.results);
    } else {
      errorHandler("server");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const getConstruction = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/constructions/${id}`,
      isAuthRequired: true,
      requestContentType: "application/json",
      responseContentType: "json",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("server");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const createConstruction = async (
  body,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: "/constructions",
      isAuthRequired: true,
      body: body,
      requestContentType: "application/json",
      responseContentType: "json",
      method: "POST",
    });
    if (status === 201) {
      successHandler(response.detail);
    } else {
      errorHandler(getErrorMessage(response), "bad_request");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const editConstruction = async (
  id,
  body,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/constructions/${id}`,
      isAuthRequired: true,
      body: body,
      requestContentType: "application/json",
      responseContentType: "json",
      method: "PATCH",
    });
    if (status === 200) {
      successHandler(response.detail);
    } else {
      errorHandler(getErrorMessage(response), "bad_request");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const deleteConstruction = async (id, errorHandler, successHandler) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/constructions/${id}`,
      isAuthRequired: true,
      requestContentType: "application/json",
      responseContentType: "empty",
      method: "DELETE",
    });
    if (status === 204) {
      successHandler(response.detail);
    } else {
      errorHandler(getErrorMessage(response), "bad_request");
    }
  } catch (error) {
    errorHandler("server");
  }
};
