import React from "react";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 2rem !important;
  justify-content: space-between;
  gap: 20px;
  .card {
    width: 340px;
    height: 180px;
    border-radius: 10px;
    border: none;
  }
  @media screen and (max-width: 500px) {
    justify-content: center;
    .card {
      width: 300px;
    }
  }
`;

export const ProjectsSkeleton = () => {
  return (
    <Container>
      <Skeleton className="card" />
      <Skeleton className="card" />
      <Skeleton className="card" />
      <Skeleton className="card" />
      <Skeleton className="card" />
      <Skeleton className="card" />
    </Container>
  );
};
