export const convertTwoDigits = (number = 0) => {
  if (String(number).length === 1) {
    return `0${number}`;
  }
  return number;
};

export const parseFloatNumber = (number = 0) =>
  parseFloat(number.toFixed(2)).toLocaleString("en-US", {
    minimumFractionDigits: 2,
  });

export const convertPartNumber = (part_id = "") => {
  const name = "0" + String(part_id);
  let partName = "";
  for (let i = 0; i < name.length; i += 2) {
    if (i + 2 < name.length) {
      partName += name.substring(i, i + 2) + ".";
    } else {
      partName += name.substring(i, name.length);
    }
  }
  return partName;
};
