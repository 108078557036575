import styled from "styled-components";

export const ImageContainermin = styled.div`
  display: none;
  align-items: start;
  flex-direction: row-reverse;
  background-image: url(${(props) => props.imageUrl});
  background-size: 100% 100%;
  width: 160px;
  height: 140px;
  @media (max-width: 666px) {
    display: flex;
  }
`;

export const ImageConfigProjectmin = styled.img`
  display: none;
  cursor: pointer;
  @media (max-width: 666px) {
    display: block;
  }
`;

export const ButtonConfigProjectmin = styled.button`
  display: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  @media (max-width: 666px) {
    display: block;
  }
`;
