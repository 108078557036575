import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  min-width: 300px;
  max-width: 33%;
  width: auto;
  height: auto;
  line-height: 22px;
  background: #f0f4f9;
  font-weight: 700;
  align-items: stretch;
`;

export const ContainerText = styled.div`
  padding-bottom: 10px;
`;

export const Img = styled.img`
  width: 14px;
  height: 14px;
`;

export const Paragraph = styled.p`
  margin: 0;
  padding: 0;
`;

export const ContainerImage = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Download = styled.div`
  color: #014083;
  text-align: center;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
`;

export const Title = styled.div`
  color: #014083;
  text-align: center;
  font-size: 14px;
  text-decoration: underline;
`;

export const Uncheck = styled.div`
  margin-left: 15px;
`;
