import { propOr, isEmpty } from "ramda";

import AppSession from "../session/AppSession";

// AppSession utils
const accessTokenGetter = () => propOr("User", "accessToken", AppSession.get());
// TODO: implement in case of refresh token use
//const refreshTokenGetter = () => propOr('user', 'refreshToken', AppSession.get());

// Build url
const buildUrl = (url, queryParams = {}) => {
  const queries = Object.keys(queryParams)
    .map(
      (key) =>
        encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])
    )
    .join("&");
  if (!queries.length) return url;
  return `${url}?${queries}`;
};

// Request body setter
const getFormattedRequestBody = (body, requestContentType) => {
  switch (requestContentType) {
    case "application/json":
      return JSON.stringify(body);
    case "multipart/form-data":
      return body;
    default:
      return JSON.stringify(body);
  }
};

// TODO: implement right logic in case of refresh token use
const unauthorizedCaseHandler = () => {
  AppSession.destroy();
};

// Response body management
const supportEmptyResponse = async (response) => {
  const clonedResponse = await response.clone();
  const contentText = await clonedResponse.text();
  return isEmpty(contentText) ? contentText : response.json();
};

const getFormattedResponse = async (response, responseContentType) => {
  switch (responseContentType) {
    case "json":
      return await response.json();
    case "blob":
      return await response.blob();
    case "text":
      return await response.text();
    case "empty":
      return {};
    default:
      return await supportEmptyResponse(response);
  }
};

const appFetcher = async (fetcherOptions) => {
  const BASE_PATH = process.env.REACT_APP_ARIANE_BFF_URL;
  // TODO: implement in case of refresh token use
  //const TOKEN_REFRESH_RELATIVE_PATH = '';

  const relativePath = propOr("", "relativePath", fetcherOptions);
  const method = propOr("POST", "method", fetcherOptions);
  const isAuthRequired = propOr(true, "isAuthRequired", fetcherOptions);
  const requestContentType = propOr(
    "application/json",
    "requestContentType",
    fetcherOptions
  );
  const body = propOr(null, "body", fetcherOptions);
  const queryParams = propOr({}, "queryParams", fetcherOptions);
  const responseContentType = propOr(
    "json",
    "responseContentType",
    fetcherOptions
  );
  const _options = {
    headers: {
      ...(requestContentType !== "multipart/form-data" && {
        "Content-Type": requestContentType,
      }),
      accept: " */*",
      ...(isAuthRequired && {
        Authorization: `Bearer ${accessTokenGetter()}`,
      }),
    },
    method: method,
    ...(body && { body: getFormattedRequestBody(body, requestContentType) }),
  };
  const response = await fetch(
    buildUrl(`${BASE_PATH}${relativePath}`, queryParams),
    _options
  );
  const responseStatus = await response.status;
  // TODO: 401 Unauthorized should trigger token refreshing
  if (responseStatus === 401) unauthorizedCaseHandler();
  const responseBody =
    response.status !== 204
      ? await getFormattedResponse(response, responseContentType)
      : {};
  return { status: responseStatus, response: responseBody };
};

export default appFetcher;
