import styled from "styled-components";

export const Label = styled.p`
  color: #014083;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
`;

export const File = styled.div`
  border: solid 1px #014083;
  width: 185px;
  height: 32px;
  text-align: center;
  margin-top: 20px;
  padding-top: 4px;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
`;
