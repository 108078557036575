import React, { useEffect, useState } from "react";
import { ToTalkModalSkeleton } from "../Skeletons";
import ValorizationsSummaryTable from "./ValorizationsSummaryTable";
import {
  CardDiv,
  ModalTitle,
  Modal,
  CardContainer,
  ToggleButton,
  ModalHeader,
} from "./styles";
import ToggleButtonLeft from "./assets/toggle-button-left.png";
import ToggleButtonBottom from "./assets/toggle-button-bottom.png";

import DebtMainCard from "./index";

import { getAdditionalValorizationsSummary } from "../../providers";

export default function AdditionalDebtModal({
  debtModalActive,
  setDebtModalActive,
  user,
  additional_id,
  debt_id,
  currentValorization,
}) {
  const [reloadValorizationsSummary, setReloadValorizationsSummary] =
    useState(true);
  const [valorizations, setValorizations] = useState([]);
  const [charged, setCharged] = useState(true);
  const [activeValorization, setActiveValorization] = useState(
    currentValorization.order
  );
  const [activeDebtId, setActiveDebtId] = useState(debt_id);
  const [showLeftMenu, setShowLeftMenu] = useState(true);
  const [showMobileModal, setShowMobileModal] = useState(false);

  useEffect(() => {
    setActiveValorization(currentValorization.order);
    setActiveDebtId(currentValorization.debt);
  }, [currentValorization]);

  useEffect(() => {
    const fetchValorizations = async () => {
      try {
        setCharged(true);
        const response = await getAdditionalValorizationsSummary(additional_id);
        setValorizations(response.results);
        setCharged(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchValorizations();
  }, [additional_id, debtModalActive, reloadValorizationsSummary]);

  useEffect(() => {
    setShowMobileModal(window.innerWidth < 1118);
  }, [window.innerWidth]);

  return (
    <>
      <Modal
        centered
        open={debtModalActive}
        onCancel={() => {
          setDebtModalActive(false);
        }}
        closable={false}
        footer={null}
        width={showLeftMenu ? (showMobileModal ? 520 : 1150) : 520}
      >
        <CardContainer showMobileModal={showMobileModal}>
          {showLeftMenu}
          {showLeftMenu && (
            <CardDiv order="first" showMobileModal={showMobileModal}>
              {charged ? (
                <ToTalkModalSkeleton />
              ) : (
                <>
                  <ModalHeader>
                    <ModalTitle>Resumen pago de valorizaciones</ModalTitle>
                  </ModalHeader>
                  <div>
                    <p className="projectVal__modal__description">
                      En este sección podrá editar valores de la deuda.
                    </p>
                  </div>
                  <ValorizationsSummaryTable
                    valorizations={valorizations}
                    activeValorization={activeValorization}
                    setActiveValorization={setActiveValorization}
                    setActiveDebtId={setActiveDebtId}
                    showMobileModal={showMobileModal}
                  />
                </>
              )}
            </CardDiv>
          )}
          <ToggleButton
            onClick={() => {
              setShowLeftMenu(!showLeftMenu);
            }}
            src={showMobileModal ? ToggleButtonBottom : ToggleButtonLeft}
            showMobileModal={showMobileModal}
            alt="toggle-button-summary"
          />
          <CardDiv order="second" showMobileModal={showMobileModal}>
            <DebtMainCard
              debtModalActive={debtModalActive}
              setDebtModalActive={setDebtModalActive}
              activeValorization={activeValorization}
              user={user}
              debt_id={activeDebtId}
              reloadValorizationsSummary={reloadValorizationsSummary}
              setReloadValorizationsSummary={setReloadValorizationsSummary}
            />
          </CardDiv>
        </CardContainer>
      </Modal>
    </>
  );
}
