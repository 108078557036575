import styled from "styled-components";

export const DeductiveSettingContainer = styled.div`
  width: 400px;
  min-width: 400px;
  padding: 0 20px 0 20px;
`;

export const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;
