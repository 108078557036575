// React import
import React from "react";

// Components import
import Button from "../../../../../shared/components/Button";

// Styled components import
import {
  ButtonContainer,
  Indication,
  AnomaliesSectionContainer,
  AnomalyContainer,
  ReportTypeLabel,
} from "./styles";

export default function AnomaliesSection({ onClose, validations }) {
  return (
    <>
      <AnomaliesSectionContainer>
        {!validations.valorizations_wo_billable_existence && (
          <AnomalyContainer>
            <ReportTypeLabel>
              <b>⚫ Valorizaciones sin facturas</b>
            </ReportTypeLabel>
          </AnomalyContainer>
        )}
        {!validations.additionals_proposals_existence && (
          <AnomalyContainer>
            <ReportTypeLabel>
              <b>⚫ Adicionales en estado propuestos</b>
            </ReportTypeLabel>
          </AnomalyContainer>
        )}
        {!validations.deductives_proposals_existence && (
          <AnomalyContainer>
            <ReportTypeLabel>
              <b>⚫Deductivos en estado propuestos</b>
            </ReportTypeLabel>
          </AnomalyContainer>
        )}{" "}
      </AnomaliesSectionContainer>
      <Indication>
        No se puede realizar la liquidación definitiva de obra debido a la
        existencia de anomalías.
      </Indication>
      <ButtonContainer>
        <Button
          background="#D93022"
          width="80px"
          fontWeight="600"
          margin="0 0 1rem 0"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
          onClick={() => {
            onClose();
          }}
        >
          Cerrar
        </Button>
      </ButtonContainer>
    </>
  );
}
