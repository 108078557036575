import React from "react";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

const Container = styled.div`
  text-align: center;
  .div-upload {
    width: 200px;
    height: 230px;
    margin-bottom: 22px;
  }
  .btn {
    width: 122px;
  }
`;

export const ImageModalSkeleton = () => {
  return (
    <Container>
      <Skeleton className="div-upload" />
      <div className="imageModal__btns">
        <Skeleton className="btn" />
        <Skeleton className="btn" />
      </div>
    </Container>
  );
};
