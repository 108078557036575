import styled from "styled-components";

export const PortfolioHeaderRow = styled.div`
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  justify-content: space-between;
`;

export const PortfolioMainTitle = styled.div`
  color: #000000;
  margin-bottom: 25px;
  font-family: "Mulish";
  font-weight: bold;
  font-size: 22px;
  width: 100%;
  @media (min-width: 700px) {
    width: 200px;
    min-width: 200px;
  }
  @media (min-width: 1024px) {
    width: 400px;
    min-width: 400px;
  }
`;

export const NewConstructionButton = styled.div`
  display: flex;
  background-color: #00c187;
  cursor: pointer;
  text-align: center;
  color: white;
  margin: auto 20px;
  width: 130px;
  min-width: 130px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 0.5rem;
  margin-top: 0rem;
`;

export const SearchContainer = styled.div`
  height: 32px;
  width: 100vh;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  margin-left: 2rem;
  margin-right: 2rem;
  @media (max-width: 700px) {
    display: none;
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  border: 1px solid rgba(95, 111, 129, 0.2);
  border-radius: 4px 0px 0px 4px;
  padding: 8px;
  background: #ffffff;
  outline: none;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  padding-top: 0rem;
`;
