import React from "react";
import styled from "styled-components";

const Container = styled.div`
  background: #ffffff;
  border-radius: 2px;
  padding: ${(props) => props.padding || "1rem"};
  margin-bottom: ${(props) => props.marginBottom || "30px"};
  .spaceBetween {
    justify-content: space-between;
  }
`;

const Card = ({ children, padding, marginBottom, classname }) => {
  return (
    <Container
      padding={padding}
      marginBottom={marginBottom}
      className={classname}
    >
      {children}
    </Container>
  );
};

export default Card;
