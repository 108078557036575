import React from "react";

// Styled components
import {
  StyledRow,
  StyledLink,
  StyledInput,
  Advice,
  ResetPasswordTitle,
  ResetPasswordIndications,
} from "./styles";
// Shared components
import Button from "../../../shared/components/Button";

const ResetPasswordForm = ({
  email,
  setEmail,
  submitHandler,
  error,
  success,
}) => {
  return (
    <StyledRow>
      <ResetPasswordTitle>¿Olvido su contraseña?</ResetPasswordTitle>
      <ResetPasswordIndications>
        Ingrese su correo electrónico y le enviaremos un enlace para restablecer
        su contraseña
      </ResetPasswordIndications>
      {error.show && <Advice error>{error.message}</Advice>}
      {success.show && <Advice success>{success.message}</Advice>}
      <StyledInput
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Correo electrónico"
        type="email"
      />
      <StyledLink to="/login">Regresar a inicio de sesión</StyledLink>
      <StyledRow>
        <Button
          margin="15px 0px 20px 0px"
          width="152px"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
          onClick={submitHandler}
        >
          Enviar
        </Button>
      </StyledRow>
    </StyledRow>
  );
};

export default ResetPasswordForm;
