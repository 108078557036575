// Libraries import
import React from "react";
import { Input } from "antd";

// Components import
import { ItemContainer, CardOutput } from "./styles";

const Item = ({
  title,
  content,
  onChange,
  width,
  disabled,
  onBlur,
  marginBottom,
}) => {
  return (
    <ItemContainer marginBottom={marginBottom}>
      <p className="blue bold font14 projectVal__mb1">{title}</p>
      {disabled ? (
        <CardOutput width={width}>{content}</CardOutput>
      ) : (
        <Input
          value={content}
          onChange={(e) => onChange(e.target.value)}
          onBlur={onBlur}
          disabled={title === "Monto"}
          style={{ width }}
        />
      )}
    </ItemContainer>
  );
};

export default Item;
