import React from "react";

// App
import { AppRouter } from "./modules/App/router";

// Public Modules
import LoginModule from "./modules/Login";
import PasswordResetModule from "./modules/PasswordReset";

// Private Modules
import HomeModule from "./modules/Home";
import ProjectEditModule from "./modules/ProjectEdit";
import ProjectValorizationModule from "./modules/ProjectValorization";
import ProjectAdditionalsModule from "./modules/ProjectAdditionals";
import ProjectDeductivesModule from "./modules/ProjectDeductives";

function App() {
  return (
    <AppRouter
      publicModules={[PasswordResetModule(), LoginModule()]}
      privateModules={[
        ProjectEditModule(),
        ProjectValorizationModule(),
        ProjectAdditionalsModule(),
        ProjectDeductivesModule(),
        HomeModule(),
      ]}
    />
  );
}

export default App;
