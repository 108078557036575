import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

//pages
import EditProjectPage from "../pages/EditProjectPage";
import EditProjectMatesPage from "../pages/EditProjectMatesPage";

const ProjectEditRouter = () => {
  return (
    <Switch>
      <Route exact path="/projects/:id/edit" component={EditProjectPage} />
      <Route
        exact
        path="/projects/:id/edit/mates"
        component={EditProjectMatesPage}
      />
      <Redirect to="/main" />
    </Switch>
  );
};

export default ProjectEditRouter;
