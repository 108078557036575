import styled from "styled-components";
import { Modal as BaseModal } from "antd";

export const CardDiv = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
  padding-right: 24px;
  padding-left: 13px;
  width: ${({ order, showMobileModal }) =>
    order === "first" ? (showMobileModal ? "470px" : "623px") : "495px"};
  ${({ order, showMobileModal }) =>
    order === "first" &&
    !showMobileModal &&
    `
    border-right: gray solid 1px;
    padding-left: 24px;
  `}
  ${({ order, showMobileModal }) =>
    order === "first" &&
    showMobileModal &&
    `
    border-top: gray solid 1px;
    padding-left: 24px;
  `}
  ${({ order, showMobileModal }) =>
    order !== "first" &&
    showMobileModal &&
    `
    padding-left: 24px;
    padding-bottom: 13px;
  `}
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: ${({ showMobileModal }) =>
    showMobileModal ? "column-reverse" : "row"};
`;

export const ModalTitle = styled.p`
  font-family: Mulish;
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px;
  line-height: 22px !important;
  color: #014083 !important;
  margin-bottom: 32px;
`;

export const Modal = styled(BaseModal)`
  .ant-modal-body {
    padding: 0;
  }
`;

export const ToggleButton = styled.img`
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  ${({ showMobileModal }) =>
    showMobileModal &&
    `
    margin-top: 0;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
  `}
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ModalCloseButton = styled.img`
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
`;
