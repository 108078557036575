// React import
import React, { useState, useEffect } from "react";

// Support libraries import
import { useHistory } from "react-router-dom";
import { Popconfirm, notification } from "antd";

// Components import
import { Modal } from "antd";
import Button from "../../../../shared/components/Button";
import DeductiveSettingBanner from "./components/DeductiveSettingBanner";

// Styled components import
import { ButtonContainer, ModalTitle } from "./styles";

// Services import
import {
  getPreDeductive,
  createPreDeductive,
  updatePreDeductive,
} from "../../../services";
import DeductiveCreationBanner from "./components/DeductiveCreationBanner";

export default function DeductiveModal({
  deductiveCreationActive,
  setDeductiveCreationActive,
  deductiveModalActive,
  setDeductiveModalActive,
  predeductiveId = 0,
  setSpinLoading,
  projectId,
  userRole,
  setActiveSection,
  deductiveTrigger,
  setDeductiveTrigger,
  project,
}) {
  const history = useHistory();
  // States
  const [enableEdition, setEnableEdition] = useState(false);
  const [predeductiveCreationModal, setPredeductiveCreationModal] =
    useState(false);
  const [predeductive, setPredeductive] = useState({
    order: "",
    name: "",
    reason: "",
  });

  // Pop confirm states
  const [showPopConfirmCloseCreation, setShowPopConfirmCloseCreation] =
    useState(false);
  const [showPopConfirmCloseUpdate, setShowPopConfirmCloseUpdate] =
    useState(false);

  // Predeductive getting
  const predeductiveErrorHandler = () => {
    setDeductiveCreationActive(false);
    setDeductiveModalActive(false);
    notification.error({
      message: "Ocurrió un error en la obtención de la propuesta de deductivo",
    });
  };

  const getPredeductiveEditionStatus = (predeductive) => {
    let enableEdition = false;
    if (
      predeductive.status === "REJECTED" ||
      predeductive.status === "VALIDATED"
    ) {
      enableEdition = false;
    } else {
      if (
        userRole === "EXECUTOR_LEADER" &&
        predeductive.approver_role === "SUPERVISOR"
      ) {
        enableEdition = true;
      }
      if (
        userRole === "SUPERVISOR_LEADER" &&
        predeductive.approver_role === "EXECUTOR"
      ) {
        enableEdition = true;
      }
      if (userRole === "ADMIN") {
        enableEdition = true;
      }
    }
    return enableEdition;
  };

  const predeductiveSuccessHandler = (predeductive) => {
    setPredeductive(predeductive);
    setEnableEdition(getPredeductiveEditionStatus(predeductive));
  };

  // Predeductive creation
  const predeductiveCreationErrorHandler = (error, detail = "") => {
    switch (error) {
      case "limit_exceeded":
        notification.error({
          message: detail,
        });
        break;
      case "server":
      default:
        notification.error({
          message: "Ocurrió un error al crear el deductivo",
        });
        break;
    }
    setSpinLoading(false);
    setDeductiveCreationActive(false);
    setDeductiveModalActive(false);
  };

  const predeductiveNewPartsCreationSuccessHandler = (response) => {
    setSpinLoading(false);
    setPredeductiveCreationModal(false);
    history.push(`/projects/${project.id}/val?deductive=${response.id}`);
  };

  const predeductiveExistingPartsCreationSuccessHandler = (response) => {
    setSpinLoading(false);
    history.push(`/deductives/${response.id}/predeductives`);
  };

  useEffect(() => {
    predeductiveId !== 0 &&
      getPreDeductive(
        predeductiveId,
        predeductiveErrorHandler,
        predeductiveSuccessHandler
      );
  }, [predeductiveId, userRole]);

  // First modal handlers
  const createFromExistingParts = async () => {
    setDeductiveCreationActive(false);
    setSpinLoading(true);
    await createPreDeductive(
      projectId,
      { type: "EXISTING_PARTS" },
      predeductiveCreationErrorHandler,
      predeductiveExistingPartsCreationSuccessHandler
    );
  };

  const createFromNewParts = async () => {
    setSpinLoading(true);
    if (predeductive.name === "") {
      notification.error({
        message: "Debe ingresar un nombre para la propuesta de deductivo",
      });
      setSpinLoading(false);
    } else if (predeductive.reason === "") {
      notification.error({
        message:
          "Debe ingresar una justificación para la propuesta de deductivo",
      });
      setSpinLoading(false);
    } else {
      await createPreDeductive(
        projectId,
        {
          type: "NEW_PARTS",
          name: predeductive.name,
          reason: predeductive.reason,
        },
        predeductiveCreationErrorHandler,
        predeductiveNewPartsCreationSuccessHandler
      );
    }
  };

  // Predeductive update

  const predeductiveUpdateErrorHandler = (error) => {
    setSpinLoading(false);
    notification.error({
      message:
        "Ocurrió un error en la actualización de la propuesta de deductivo",
    });
  };

  const predeductiveUpdateSuccessHandler = (response) => {
    setSpinLoading(false);
    setDeductiveCreationActive(false);
    setDeductiveModalActive(false);
    notification.success({
      message: "Se actualizó la propuesta de deductivo correctamente",
    });
  };

  const saveDeductiveSettings = async () => {
    setSpinLoading(true);
    await updatePreDeductive(
      predeductiveId,
      { name: predeductive.name, reason: predeductive.reason },
      predeductiveUpdateErrorHandler,
      predeductiveUpdateSuccessHandler
    );
    //TODO: Change deductives section
    setActiveSection(3);
    setDeductiveTrigger(!deductiveTrigger);
  };

  return (
    <>
      <Modal
        title={<ModalTitle>¿Deductivo de partida existente?</ModalTitle>}
        centered
        open={deductiveCreationActive}
        onCancel={() => {
          setDeductiveCreationActive(false);
        }}
        footer={null}
        size={534}
      >
        <ButtonContainer>
          <Button
            background="#05BE8C"
            width="80px"
            fontWeight="600"
            boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
            onClick={() => {
              createFromExistingParts();
            }}
          >
            Si
          </Button>
          <Button
            background="#D93022"
            width="80px"
            fontWeight="600"
            boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
            onClick={() => {
              setDeductiveCreationActive(false);
              setPredeductiveCreationModal(true);
              setPredeductive({ name: "", reason: "" });
            }}
          >
            No
          </Button>
        </ButtonContainer>
      </Modal>

      <Modal
        title={`Creación de propuesta de deductivo de obra`}
        centered
        open={predeductiveCreationModal}
        onCancel={() => {
          setShowPopConfirmCloseCreation(true);
        }}
        footer={null}
        size={534}
      >
        <Popconfirm
          placement="topLeft"
          title="No ha guardado los cambios. ¿Desea continuar?"
          open={showPopConfirmCloseCreation}
          onConfirm={() => {
            setShowPopConfirmCloseCreation(false);
            setTimeout(() => {
              setPredeductive({ name: "", reason: "" });
              setPredeductiveCreationModal(false);
            }, 250);
          }}
          onCancel={() => {
            setShowPopConfirmCloseCreation(false);
          }}
          okText="Sí"
          cancelText="No"
        >
          <DeductiveCreationBanner
            predeductive={predeductive}
            setPredeductive={setPredeductive}
            createDeductive={() => {
              createFromNewParts();
            }}
          />
        </Popconfirm>
      </Modal>
      <Modal
        title={`Propuesta de deductivo de obra número ${predeductive.id}: ${predeductive.name}`}
        centered
        open={deductiveModalActive}
        onCancel={() => {
          if (enableEdition) {
            setShowPopConfirmCloseUpdate(true);
          } else {
            setDeductiveModalActive(false);
            setActiveSection(3);
            setDeductiveTrigger(!deductiveTrigger);
          }
        }}
        footer={null}
        size={534}
      >
        <Popconfirm
          placement="topLeft"
          title="No ha guardado los cambios. ¿Desea continuar?"
          open={showPopConfirmCloseUpdate}
          onConfirm={() => {
            setShowPopConfirmCloseUpdate(false);
            setTimeout(() => {
              setDeductiveModalActive(false);
              setActiveSection(3);
              setDeductiveTrigger(!deductiveTrigger);
            }, 250);
          }}
          onCancel={() => {
            setShowPopConfirmCloseUpdate(false);
          }}
          okText="Sí"
          cancelText="No"
        >
          <DeductiveSettingBanner
            predeductive={predeductive}
            setPredeductive={setPredeductive}
            saveDeductiveSettings={saveDeductiveSettings}
            closeDeductiveModal={() => {
              setDeductiveModalActive(false);
            }}
            enableEdition={enableEdition}
          />
        </Popconfirm>
      </Modal>
    </>
  );
}
