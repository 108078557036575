// React import
import React, { useState, useEffect } from "react";

// Third part libraries import
import { DatePicker, notification } from "antd";
import * as FileSaver from "file-saver";
import dayjs from "dayjs";
import moment from "moment";

// Components import
import ApprovalPanel from "../ApprovalPanel";
import ChargeFileButton from "../ChargeFileButton";
import FileItem from "../FileItem";
import { ItemContainer } from "./styles";

// Services import
import { getProjectDeliveryMinute } from "../../../../providers";
import {
  approveProjectDeliveryMinute,
  updateProjectDeliveryMinute,
  getProjectDeliveryMinuteDocument,
} from "../../services";

// Utils import
import { clientDateFormat, serverDateFormat } from "../../../../utils";

const DeliveryMinuteModal = ({
  id,
  userRole,
  projectDeliverySummary,
  reloadProjectDelivery,
  setLoading,
}) => {
  const [deliveryMinute, setDeliveryMinute] = useState({
    super_approved_delivery_minute: false,
    exec_approved_delivery_minute: false,
    minute_filename: "",
    minute: null,
    minute_load_date: null,
  });
  const [triggerDeliveryMinute, setTriggerDeliveryMinute] = useState(true);
  const disableForm =
    !projectDeliverySummary.set_delivery_minute_enabled ||
    userRole === "SUPERVISOR" ||
    userRole === "EXECUTOR";

  useEffect(() => {
    const fetchProjectDeliveryMinute = async () => {
      try {
        setLoading(true);
        const response = await getProjectDeliveryMinute(id);
        setDeliveryMinute(response);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchProjectDeliveryMinute();
  }, [id, triggerDeliveryMinute]);

  // Handler approbation
  const approveSuccessHandler = (response) => {
    notification.success({
      message: Object.values(response)[0][0],
    });
    setLoading(false);
    reloadProjectDelivery();
    setTriggerDeliveryMinute(!triggerDeliveryMinute);
  };

  const approveErrorHandler = (response) => {
    notification.error({
      message: response
        ? Object.values(response)[0][0]
        : "Ocurrió un error al aprobar el acta de entrega de obra",
    });
    setLoading(false);
  };

  const approveHandler = async () => {
    setLoading(true);
    await approveProjectDeliveryMinute(
      id,
      approveErrorHandler,
      approveSuccessHandler
    );
  };

  // Handler update
  const updateSuccessHandler = (response) => {
    notification.success({
      message:
        "Se actualizo correctamente el acta de entrega de obra provisional",
    });
    setLoading(false);
    setTriggerDeliveryMinute(!triggerDeliveryMinute);
  };

  const updateErrorHandler = (response) => {
    notification.error({
      message: response
        ? Object.values(response)[0][0]
        : "Ocurrió un error al actualizar la acta de entrega de obra provisional",
    });
    setLoading(false);
  };

  const getUpdateBody = (_projectDeliveryMinute) => {
    let formData = new FormData();
    _projectDeliveryMinute.minute &&
      formData.append("minute", _projectDeliveryMinute.minute);
    _projectDeliveryMinute.minute_load_date &&
      formData.append(
        "minute_load_date",
        _projectDeliveryMinute.minute_load_date
      );
    return formData;
  };

  const updateHandler = async (item, label) => {
    setLoading(true);
    const _projectDeliveryMinute = { ...deliveryMinute, [label]: item };
    const updateBody = getUpdateBody(_projectDeliveryMinute);
    await updateProjectDeliveryMinute(
      id,
      updateBody,
      updateErrorHandler,
      updateSuccessHandler
    );
  };

  // Handler get document
  const documentGetErrorHandler = () => {
    notification.error({
      message: "Ocurrió un error en la descarga del acta de entrega",
    });
  };

  const documentGetSuccessHandler = (response) => {
    FileSaver.saveAs(response, deliveryMinute.minute_filename);
  };

  const getDocument = async (id) => {
    await getProjectDeliveryMinuteDocument(
      id,
      documentGetErrorHandler,
      documentGetSuccessHandler
    );
  };

  return (
    <>
      <p className="blue bold font14" style={{ marginBottom: "2px" }}>
        Acta de entrega de obra provisional:
      </p>
      <div className="projectVal__card__items">
        <ChargeFileButton
          chargeDocument={async (file) => {
            !disableForm && (await updateHandler(file, "minute"));
          }}
        />
        <FileItem
          fileName={deliveryMinute.minute_filename}
          getFile={() => getDocument(id)}
        />
      </div>

      <ItemContainer>
        <p
          className="blue bold font14"
          style={{ marginBottom: "16px", marginTop: "16px" }}
        >
          Fecha de suscripción de acta:
        </p>
        <DatePicker
          value={dayjs(deliveryMinute.minute_load_date || new Date())}
          onChange={async (value) => {
            await updateHandler(
              moment(value.toDate()).format(serverDateFormat),
              "minute_load_date"
            );
          }}
          format={clientDateFormat}
          disabled={disableForm}
          style={{ width: "150px" }}
        />
      </ItemContainer>

      <div>
        <ApprovalPanel
          label="Aprobar acta de entrega de obra"
          approvalEnable={projectDeliverySummary.set_delivery_minute_enabled}
          approveHandler={() => {
            approveHandler();
          }}
          userRole={userRole}
          superApproved={deliveryMinute.super_approved_delivery_minute}
          execApproved={deliveryMinute.exec_approved_delivery_minute}
        />
      </div>
    </>
  );
};

export default DeliveryMinuteModal;
