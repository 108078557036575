import React, { useState } from "react";
import { Tooltip } from "reactstrap";
import { AiFillQuestionCircle } from "react-icons/ai";

export const TextWithTooltip = ({ children, info, id }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <>
      <span>
        {children}
        <AiFillQuestionCircle style={{ cursor: "pointer" }} id={id} />
      </span>
      <Tooltip placement="top" isOpen={tooltipOpen} target={id} toggle={toggle}>
        {info}
      </Tooltip>
    </>
  );
};
