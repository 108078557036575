import React from "react";
import { Modal } from "antd";

// Components import
import Button from "../../../../../../shared/components/Button";

// Styled components import
import { ButtonContainer, ModalTitle, ModalSubTitle } from "./styles";

const AdviceModal = ({ advisorModalActive, setAdvisorModalActive }) => {
  return (
    <Modal
      title={
        <ModalTitle>El nuevo metrado deduce el avance no firmado</ModalTitle>
      }
      centered
      open={advisorModalActive}
      onCancel={() => {
        setAdvisorModalActive(false);
      }}
      footer={null}
      size={534}
    >
      <ModalSubTitle>
        <p>En caso de aprobarse el avance no firmado se revertirá</p>
      </ModalSubTitle>
      <ButtonContainer>
        <Button
          background="#05BE8C"
          width="80px"
          fontWeight="600"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
          onClick={() => {
            setAdvisorModalActive(false);
          }}
        >
          Aceptar
        </Button>
      </ButtonContainer>
    </Modal>
  );
};

export default AdviceModal;
