// Basic import
import React from "react";

// Components import
import {
  FileDeleteButton,
  FileName,
  FileItemContainer,
  FileListContainer,
} from "./styles.jsx";

// Utils import
import TrashIcon from "./assets/trash_icon.svg";
import { filenameFormat } from "../../../../../../../utils/index.js";

const FileItem = ({ fileName = "", deleteFile, getFile }) => {
  return (
    fileName !== "" && (
      <FileListContainer>
        <FileItemContainer>
          <FileName
            onClick={() => {
              getFile();
            }}
          >
            {filenameFormat(fileName)}
          </FileName>
          <FileDeleteButton
            src={TrashIcon}
            alt="file_trash_icon"
            onClick={() => deleteFile()}
          />
        </FileItemContainer>
      </FileListContainer>
    )
  );
};

export default FileItem;
