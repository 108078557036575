import React from "react";

import { IconContext } from "react-icons";
import { AiOutlinePlus } from "react-icons/ai";
import { RiSearchLine } from "react-icons/ri";

import {
  PortfolioHeaderRow,
  PortfolioMainTitle,
  NewConstructionButton,
  SearchContainer,
  SearchInput,
} from "./styles";

export default function PortfolioPageHeader({
  search,
  setSearch,
  openNewConstructionModal,
}) {
  return (
    <>
      <PortfolioHeaderRow>
        <PortfolioMainTitle>
          Bienvenido a tu portafolio de obras
        </PortfolioMainTitle>
        <SearchContainer>
          <SearchInput
            value={search}
            onFocus={(event) => event.target.select()}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="navbar__search__container-icon">
            <IconContext.Provider value={{ color: "white" }}>
              <RiSearchLine className="navbar__search__icon" size={24} />
            </IconContext.Provider>
          </div>
        </SearchContainer>
        <NewConstructionButton
          onClick={() => {
            openNewConstructionModal();
          }}
        >
          Nueva Obra
          <IconContext.Provider value={{ color: "" }}>
            <AiOutlinePlus size="20" />
          </IconContext.Provider>
        </NewConstructionButton>
      </PortfolioHeaderRow>
    </>
  );
}
