import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import {
  Table,
  InputNumber,
  DatePicker,
  Popconfirm,
  Form,
  notification,
  Modal,
} from "antd";
import dayjs from "dayjs";
import moment from "moment";
import "moment/locale/es";

import {
  getProjectPrevalorizations,
  postProjectPrevalorization,
  deletePrevalorizationById,
  postPreUpdatePrevalorization,
  patchPrevalorizationById,
} from "../../providers";
import { dateFormat, clientDateFormat, serverDateFormat } from "../../utils";

import { Button } from "../Button";

export const ValorizationProgrammingModal = ({
  id,
  initial_date,
  final_date,
  setModal,
  general_aproved,
  toggleUpdatePrevalorizations,
  settoggleUpdatePrevalorizations,
  setToggleUpdateProject,
}) => {
  const [prevalorizations, setPrevalorizations] = useState([]);

  moment.locale("es");

  useEffect(() => {
    const fetchPrevalorizations = async () => {
      setPrevalorizations([
        {
          key: id,
          name: "Inicio de plazo contractual",
          initial_date,
          final_date: initial_date,
          day_name: moment(initial_date)
            .format("dddd")
            .replace(/^\w/, (c) => c.toUpperCase()),
          duration_calendar_days: "-",
          duration_utile_days: "-",
          advice: "",
          showButtons: false,
        },
      ]);
      try {
        const response = await getProjectPrevalorizations(id);
        //console.log("resp modal", response.results);
        if (response.results) {
          setPrevalorizations((prev) => [
            ...prev,
            ...response.results.map((elem, index) => ({
              ...elem,
              key: elem.id,
              name: `Valorización ${index + 1}`,
              showButtons: true,
            })),
          ]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchPrevalorizations();
  }, [toggleUpdatePrevalorizations]);

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [editingRecord, setEditingRecord] = useState({});

  const save = async () => {
    try {
      const response = await patchPrevalorizationById(editingRecord.id, {
        final_date: editingRecord.final_date,
      });
      if (response.id) {
        setEditingKey("");
        settoggleUpdatePrevalorizations((prev) => !prev);
        notification.success({
          message: "La prevalorización ha sido actualizada exitosamente",
        });
      }
      //console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await deletePrevalorizationById(id);
      //console.log("response", response);
      if (response.ok) {
        settoggleUpdatePrevalorizations((prev) => !prev);
        notification.success({
          message: "La prevalorización ha sido eliminada exitosamente",
        });
        setToggleUpdateProject((prev) => !prev);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const preUpdatePrevalorization = async (id, body) => {
    try {
      const response = await postPreUpdatePrevalorization(id, body);
      //console.log("response", response);
      if (response.final_date) {
        setEditingRecord((prev) => ({ ...prev, ...response }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: "Valorización",
      dataIndex: "name",
      align: "center",
    },
    {
      title: "Fecha de corte",
      dataIndex: "final_date",
      align: "center",
      render: (final_date, record) => {
        const editable = isEditing(record);
        return editable ? (
          <DatePicker
            value={dayjs(editingRecord.final_date)}
            format={clientDateFormat}
            onChange={(value) => {
              setEditingRecord((prev) => ({
                ...prev,
                final_date: moment(value.toDate()).format(serverDateFormat),
              }));
              preUpdatePrevalorization(record.id, {
                final_date: moment(value.toDate()).format(serverDateFormat),
              });
            }}
          />
        ) : (
          dateFormat(final_date)
        );
      },
    },

    {
      title: "Día de semana",
      dataIndex: "day_name",
      align: "center",
    },
    {
      title: "Duración-días calendario",
      dataIndex: "duration_calendar_days",
      align: "center",
      render: (duration_calendar_days, record) => {
        const editable = isEditing(record);
        return editable ? (
          <InputNumber
            value={editingRecord.duration_calendar_days}
            onChange={(value) => {
              setEditingRecord((prev) => ({
                ...prev,
                duration_calendar_days: value,
              }));
              preUpdatePrevalorization(record.id, {
                duration_calendar_days: value,
              });
            }}
          />
        ) : (
          duration_calendar_days
        );
      },
    },
    /* {
      title: "Duración-días laborables",
      dataIndex: "duration_utile_days",
      align: "center",
      editable: true,
    }, */
    {
      title: "Duración-días laborables",
      dataIndex: "duration_utile_days",
      align: "center",
      render: (duration_calendar_days, record) => {
        const editable = isEditing(record);
        return editable ? (
          <InputNumber
            value={editingRecord.duration_utile_days}
            onChange={(value) => {
              setEditingRecord((prev) => ({
                ...prev,
                duration_utile_days: value,
              }));
              preUpdatePrevalorization(record.id, {
                duration_utile_days: value,
              });
            }}
          />
        ) : (
          duration_calendar_days
        );
      },
    },
    {
      title: "Advertencia",
      dataIndex: "advice",
      align: "center",
      render: (advice) => (
        <p
          style={{
            color: `${advice === "" ? "#05BE8C" : "#D93022"}`,
            marginBottom: "0px",
          }}
        >
          {advice === "" ? "OK" : advice}
        </p>
      ),
    },
    {
      title: "",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return record.showButtons ? (
          editable ? (
            <Row>
              <Button
                className="editProject__mr"
                onClick={() => save(record.key)}
                height="32px"
                width="70px"
                background="#014083"
              >
                Guardar
              </Button>
              <span
                onClick={() => {
                  settoggleUpdatePrevalorizations((prev) => !prev);
                  setEditingKey("");
                }}
                style={{
                  textDecorationLine: "underline",
                  color: "#D93022",
                  cursor: "pointer",
                }}
              >
                Cancelar
              </span>
            </Row>
          ) : (
            <Row>
              <Button
                onClick={() => edit(record)}
                className="editProject__mr"
                height="32px"
                width={!general_aproved ? "65px" : "67px"}
                background={!general_aproved ? "#0169E4" : "#f5f5f5"}
                border={general_aproved && "1px solid rgba(95, 111, 129, 0.5)"}
                color={general_aproved && "#00000040"}
                disabled={editingKey !== "" || general_aproved}
              >
                Editar
              </Button>
              <Popconfirm
                title="¿Está seguro que desea eliminar?"
                onConfirm={() => handleDelete(record.id)}
              >
                <Button
                  height="32px"
                  width={!general_aproved ? "65px" : "67px"}
                  background={!general_aproved ? "#D93022" : "#f5f5f5"}
                  border={
                    general_aproved && "1px solid rgba(95, 111, 129, 0.5)"
                  }
                  color={general_aproved && "#00000040"}
                  disabled={general_aproved}
                >
                  Eliminar
                </Button>
              </Popconfirm>
            </Row>
          )
        ) : (
          ""
        );
      },
    },
  ];

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      dataIndex === "final_date" ? (
        <DatePicker
          value={moment(record.final_date, serverDateFormat)}
          format={clientDateFormat}
        />
      ) : (
        <InputNumber onFocus={(event) => event.target.select()} />
      );
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      name: "",
      age: "",
      address: "",
      ...record,
    });
    setEditingRecord(record);
    setEditingKey(record.key);
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const createNewPrevalorization = async () => {
    try {
      const response = await postProjectPrevalorization(id);
      //console.log("response", response);
      if (response.id) {
        notification.success({
          message: "La prevalorización ha sido creada exitosamente",
        });
        /* const newData = {
          ...response,
          key: response.id,
          name: `Valorizacion ${prevalorizations.length}`,
          showButtons: true,
        };
        setPrevalorizations((prev) => [...prev, newData]);
        edit(newData); */
        settoggleUpdatePrevalorizations((prev) => !prev);
        setToggleUpdateProject((prev) => !prev);
      }
      if (response.detail) {
        Modal.error({
          title: response.detail[0],
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={prevalorizations}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={false}
          scroll={{ y: 300 }}
        />
      </Form>
      <br />
      <br />
      <Row className="ramp editProject__modals__btnContainer">
        <Button
          onClick={createNewPrevalorization}
          width="160px"
          height="38px"
          fontSize="16px"
          background={!general_aproved ? "#0169E4" : "#f5f5f5"}
          border={general_aproved && "1px solid rgba(95, 111, 129, 0.5)"}
          boxShadow={!general_aproved && "0px 2px 8px rgba(0, 0, 0, 0.25)"}
          color={general_aproved && "#00000040"}
          disabled={general_aproved}
        >
          Agregar elemento
        </Button>
        <Button
          onClick={() => setModal(false)}
          background="#D93022"
          width="160px"
          height="38px"
          fontSize="16px"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
        >
          Regresar
        </Button>
      </Row>
    </>
  );
};
