import styled from "styled-components";
import { Row } from "reactstrap";

export const BaseFooter = styled.footer`
  margin: 0 !important;
  padding: 0 !important;
  background-color: white;
  width: 100%;
  text-align: center;
  height: 40px;
  @media (max-width: 600px) {
    height: 70px;
  }
`;

export const FooterRow = styled(Row)`
  margin: 0 !important;
  padding: 0 !important;
  text-align: left;
  font-size: 10px;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  height: 40px;
  padding-right: 50px !important;
  @media (max-width: 600px) {
    height: 70px;
    justify-content: center;
    align-content: space-around;
    padding-right: 10px !important;
  }
`;

export const FooterMainText = styled.p`
  margin: 3px 0px 0px 0px;
`;
