import styled from "styled-components";

export const NewProjectlabel = styled.h1`
  color: #004283;
  margin-left: 8%;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 15px;
`;

export const NewProjectInput = styled.input`
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
`;

export const NewProjectContainerbtns = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 20px;
  margin-left: 8%;
  margin-right: 8%;
`;

export const ConstructionSelector = styled.select`
  width: 60%;
  font-size: 16px;
  background: #ffff;
  color: #a4a4a4;
  border-style: solid;
  border-color: #767676;
  border-width: 1px;
  text-align: center;
`;

export const ConstructionOption = styled.option`
  background: #ffff;
  color: #a4a4a4;
  font-size: 16px;
  border-style: solid;
  border-color: #767676;
  border-width: 1px;
  text-align: center;
  cursor: pointer;
`;

export const ConstructionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
