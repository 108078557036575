import React, { useState } from "react";

import * as FileSaver from "file-saver";

import { sendPaymentByDebtId } from "../../../providers";

import { ToTalkModalSkeleton } from "../../Skeletons";
import { PaymentCard } from "./PaymentCard";
import { Button } from "../../Button";

import { serverDateFormat } from "../../../utils";
import moment from "moment";



const PaymentSetting = ({
  debt,
  setDebt,
  user,
  setReloadDebt,
  reloadDebt,
  notification,
  closePayment,
}) => {
  //file names
  const [files, setFiles] = useState([]);
  const [filesNamed, setFilesNamed] = useState([]);
  const [charged, setCharged] = useState(false);

  const [paymentRegister, setPaymentRegister] = useState({
    amount: null,
    currency: 1,
    method: "",
    date: moment().format(serverDateFormat),
    code: "",
  });

  const chargePaymentDocument = async (files_add) => {
    try {
      setCharged(true);
      let _files = files;
      let _filesNamed = filesNamed;
      for (let i = 0; i < files_add.length; i++) {
        _files.push(files_add[i]);
        setFiles(_files);
        _filesNamed.push({ name: files_add[i].name, id: filesNamed.length });
        setFilesNamed(_filesNamed);
      }
      setCharged(false);
    } catch (error) {
      console.log(error);
      setCharged(false);
    }
  };

  const deletePaymentDocument = async (id) => {
    try {
      setCharged(true);
      let _files = files;
      _files.splice(id, 1);
      setFiles(_files);
      let _filesNamed = filesNamed;
      _filesNamed.splice(id, 1);
      setFilesNamed(_filesNamed);
      setCharged(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getPaymentDocument = async (id) => {
    try {
      const file = files[id];
      if (true) {
        FileSaver.saveAs(file, file.name);
      } else {
        const blob = await file.blob();
        FileSaver.saveAs(blob, file.name);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sendPayment = async () => {
    try {
      setCharged(true);
      let formData = new FormData();
      formData.append("amount", paymentRegister.amount);
      formData.append("currency", paymentRegister.currency);
      formData.append("method", paymentRegister.method);
      formData.append("date", paymentRegister.date? paymentRegister.date : moment().format(serverDateFormat));
      formData.append("code", paymentRegister.code);
      if (files.length !== 0) {
        files.forEach(function (fil) {
          formData.append("documents", fil);
        });
      }
      const response = await sendPaymentByDebtId(formData, debt.id);
      if (response.id) {
        notification.success({
          message: "Se registro con éxito el pago",
        });
        closePayment();
      } else {
        if (response.documents) {
          notification.error({
            message: response.documents,
          });
        } else {
          if (response.detail) {
            notification.error({
              message: response.detail,
            });
          } else {
            notification.error({
              message: "Hubo error en el registro del pago",
            });
          }
        }
      }
      setCharged(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {charged ? (
        <ToTalkModalSkeleton />
      ) : (
        <div>
          <p className="projectVal__modal__description">
            En este sección podrá registrar un pago.
          </p>
          <div className="projectVal__modal__cardsContainer">
            <PaymentCard
              paymentRegister={paymentRegister}
              setPaymentRegister={setPaymentRegister}
              handleDebtUpdate={() => {}}
              getDebtDocument={(id) => {
                getPaymentDocument(id);
              }}
              chargeDebtDocument={chargePaymentDocument}
              deleteDebtDocument={(id) => deletePaymentDocument(id)}
              user={user}
              role="ADMIN"
              files={filesNamed}
            />
          </div>
          <div className="projectVal__modal__btns">
            <Button
              background="#05BE8C"
              width="122px"
              fontWeight="600"
              boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
              onClick={() => {
                sendPayment();
              }}
            >
              Registrar pago
            </Button>
            <Button
              background="#D93022"
              width="122px"
              fontWeight="600"
              boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
              onClick={() => closePayment()}
            >
              Cancelar
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentSetting;
