import React from "react";
import { Link } from "react-router-dom";

import "./CustomBreadcrumbNavbar.css";

export const CustomBreadcrumbNavbar = ({
  links,
  ArrowBreadcrumb,
  HomeBreadcrumb,
  currentPage,
}) => {

  return (
    <div className="breadcrumb_container">
      <div className="breadcrumb_item">
        <Link to="/" className="breadcrumb__link">
          <img src={HomeBreadcrumb} alt="img"></img>{" "}
        </Link>
      </div>
      {links.map((link, index) => (
        <>
          <div className="breadcrumb_item" key={index}>
            <Link to={link.to} className="breadcrumb__link">
              {link.name}
            </Link>
          </div>
          <div>
            <img src={ArrowBreadcrumb} alt="img"></img>{" "}
          </div>
        </>
      ))}
      <div className="breadcrumb_item">{currentPage}</div>
    </div>
  );
};
