import React from "react";

// Components
import { EditProjectBanner } from "../components/EditProjectBanner/EditProjectBanner";

const EditProjectPage = ({ history, match }) => {
  return <EditProjectBanner history={history} match={match} />;
};

export default EditProjectPage;
