// React import
import React from "react";

import { EditProjectMatesBanner } from "../components/EditProjectMatesBanner/EditProjectMatesBanner";

const EditProjectMates = ({ history, match }) => {
  return <EditProjectMatesBanner history={history} match={match} />;
};

export default EditProjectMates;
