import appFetcher from "../../App/utils/http/AppFetcher";

// Preadditionals services

export const getPreAdditional = async (id, errorHandler, successHandler) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/preadditionals/${id}`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("no_found");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const getProject = async (id, errorHandler, successHandler) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/projects/${id}`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("no_found");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const getPreAdditionalParts = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/preadditionals/${id}/parts`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("no_found");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const updatePreAdditional = async (
  id,
  body,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/preadditionals/${id}`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "PATCH",
      body: body,
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("bad_request");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const updatePreAdditionalBudget = async (
  id,
  body,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/preadditionals/${id}/budget`,
      isAuthRequired: true,
      requestContentType: "multipart/form-data",
      responseContentType: "json",
      method: "POST",
      body: body,
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("bad_request", response);
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const updatePreAdditionalGeneralExpenses = async (
  id,
  body,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/preadditionals/${id}/general_expenses`,
      isAuthRequired: true,
      requestContentType: "multipart/form-data",
      responseContentType: "json",
      method: "POST",
      body: body,
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("bad_request", response);
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const getPreAdditionalBudget = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/preadditionals/${id}/budget`,
      isAuthRequired: true,
      responseContentType: "blob",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("no_found");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const getPreAdditionalGeneralExpenses = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/preadditionals/${id}/general_expenses`,
      isAuthRequired: true,
      responseContentType: "blob",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("no_found");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const validatePreAdditionalGeneralExpenses = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/preadditionals/${id}/general_expenses/validation`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "POST",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("bad_request", response);
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const validatePreAdditionalBudget = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/preadditionals/${id}/budget/validation`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "POST",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("bad_request", response);
    }
  } catch (error) {
    errorHandler("server");
  }
};

// Additionals services

export const getAdditionalValorizations = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/additionals/${id}/valorizations`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("bad_request", response);
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const getAdditionalValorizedParts = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/add_valorizations/${id}/valorized_parts`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("bad_request", response);
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const getPresentAdditionalValorization = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/additionals/${id}/present_valorization`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("bad_request", response);
    }
  } catch (error) {
    errorHandler("server");
  }
};
