import React, {useState} from "react";
import { Row } from "reactstrap";
import { EditProjectCard } from "../../EditProjectCard/EditProjectCard";
import { Input, Switch, Select } from "antd";
import { TextWithTooltip } from "./TextWithTooltip";

export const DebtSettings = (props) => {

    const [settings,setSettings] = useState({
      num_seasons:5,
      calendar_option:0,
      tax_percentage:"18%",
      existSchedule: true,
      existTax: true,
      default_observations_listing_days: props.default_observations_listing_days,
      default_observations_solving_days: props.default_observations_solving_days,
    })

    const update = (body) => props.updateProject(body, true);

    const calendarOptions = [{label:"Calendarios",value:0},{label:"Útiles",value:1}]

    return (
      <>
      <EditProjectCard
        title="8. Configuraciones de operación"
        classname="editProject__debtSettings"
      >
          <p className="editProjectCardSubtitle__title">Pago de obligaciones</p>
          <div className="ramp editProject__valSched__gridVal3">
          <div className="editProject__mb4">
          <TextWithTooltip
              id="debtSettings"
              info="Ingresar el número de días que se tiene para pagar la deuda"
            >
              Plazo para el pago de obligaciones <br/> (días)
            </TextWithTooltip>
          </div>
          <Row className="ramp" style={{ display: "flex" }}>
            <Input
              style={{ width: "90px" }}
              type="number"
              value={settings.num_seasons}
              onFocus={(event) => event.target.select()}
              onChange={(e) => {
                setSettings({...settings,num_seasons:e.target.value.replace(/[^0-9]/g, "")})
              }}
              disabled={props.general_aproved || props.role !== "ADMIN"}
            />
            <Select
              className="editProject__ml editProject__mr editProject__mb2"
              value={settings.calendar_option}
              onChange={(value) => {
                setSettings({...settings,calendar_option:value})
              }}
              options={calendarOptions}
              disabled={props.general_aproved || props.role !== "ADMIN"}
              width={90}
            />
            </Row>
            </div>
          <Row className="ramp editProject__commercialTerms__container ">
          <p>No hay plazo para el pago de obligaciones</p>
          <Switch/>
        </Row>
        <Row className="ramp editProject__commercialTerms__container ">
          <p>Tasa de interés efectiva anual para el pago de obligaciones</p>
          <Input
            value={settings.tax_percentage}
            onFocus={(event) => event.target.select()}
            onChange={(e) => setSettings({...settings,tax_percentage:e.target.value.replace(/[^0-9.]/)})}
            onBlur={() => setSettings({...settings,tax_percentage:settings.tax_percentage+"%"})}
            type="text"
            disabled={props.general_aproved || props.role !== "ADMIN"}
            style={{
              width: `${8 * settings.tax_percentage.length}px`,
              minWidth: "80px",
            }}
          />
        </Row>
        <Row className="ramp editProject__commercialTerms__container ">
          <p>No se aplica tasa de interés en el pago de obligaciones</p>
          <Switch/>
        </Row>
        <p className="editProjectCardSubtitle__title">Condiciones de entrega de obra</p>
      <div className="ramp editProject__commercialTerms__container">
        {" "}
        <Row
          className="ramp"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <p>
            Periodo de registro de observaciones
            <br />
            (Duración en días)
          </p>

          <Input
            style={{ width: "90px" }}
            type="number"
            value={settings.default_observations_listing_days}
            onFocus={(event) => event.target.select()}
            onChange={(e) => {
              setSettings({
                ...settings,
                default_observations_listing_days: e.target.value.replace(
                  /[^0-9]/g,
                  ""
                ),
              });
            }}
            onBlur={() => {
              update({
                default_observations_listing_days:
                  settings.default_observations_listing_days,
              });
            }}
            disabled={props.general_aproved || props.role !== "ADMIN"}
          />
        </Row>
      </div>
      <div className="ramp editProject__commercialTerms__container">
        <Row
          className="ramp"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <p>
            Periodo de resolución de observaciones
            <br />
            (Duración en días)
          </p>

          <Input
            style={{ width: "90px" }}
            type="number"
            value={settings.default_observations_solving_days}
            onFocus={(event) => event.target.select()}
            onChange={(e) => {
              setSettings({
                ...settings,
                default_observations_solving_days: e.target.value.replace(
                  /[^0-9]/g,
                  ""
                ),
              });
            }}
            onBlur={() => {
              update({
                default_observations_solving_days:
                  settings.default_observations_solving_days,
              });
            }}
            disabled={props.general_aproved || props.role !== "ADMIN"}
          />
        </Row>
      </div>
    </EditProjectCard>
    </>
    );
  };