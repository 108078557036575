import React, { useState } from "react";
import { Modal, Input } from "antd";
import { FiSend } from "react-icons/fi";

import { Button } from "../../../../../../components/Button";
import { ModalButtons, Label } from "./styles";

const LiquidationVisorModal = ({
  active,
  seeReport,
  setActiveModal,
  setLiquidationModalActive,
  hasBeenDelivered,
  sendPreliminarLiquidationReport,
  closeLiquidationHandler,
}) => {
  const [email, setEmail] = useState("");
  return (
    <Modal
      open={active}
      title=""
      width={1000}
      footer={null}
      bodyStyle={{ "text-align": "center" }}
      centered
      closable={false}
    >
      <iframe
        id="pdf-iframe"
        title="liquidation_final_report"
        src={seeReport}
        style={{ marginBottom: "1rem" }}
      ></iframe>

      {hasBeenDelivered ? (
        <ModalButtons>
          <Button
            width="150px"
            fontWeight="600"
            background="#05C0AA"
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.25)"
            margin="0 0 1rem 0"
            disabled={false}
            onClick={() => {
              setTimeout(() => {
                closeLiquidationHandler();
                setLiquidationModalActive(false);
              }, 250);
            }}
          >
            Liquidar obra
          </Button>
        </ModalButtons>
      ) : (
        <>
          <Label>
            Ingrese aquí un correo para enviar el informe preliminar de
            liquidación:
          </Label>
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ width: "300px", marginBottom: "1rem" }}
          />
          <FiSend
            onClick={() => {
              sendPreliminarLiquidationReport(email);
            }}
            style={{ marginLeft: "15px", cursor: "pointer" }}
            size={22}
          />
        </>
      )}

      <ModalButtons>
        <Button
          width="122px"
          fontWeight="600"
          background="#D93022"
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.25)"
          disabled={false}
          onClick={() => {
            setTimeout(() => {
              setActiveModal(false);
              setLiquidationModalActive(false);
            }, 250);
          }}
        >
          Cerrar
        </Button>
      </ModalButtons>
    </Modal>
  );
};
export default LiquidationVisorModal;
