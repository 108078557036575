import React, { useState } from "react";

// Services
import { passwordResetService } from "../services";
// Utils
import { getResetPasswordBody } from "../utils";
// Components
import ResetPasswordForm from "../components/ResetPasswordForm";

const ResetPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState({ show: false, message: "" });
  const [success, setSuccess] = useState({ show: false, message: "" });

  const resetPasswordErrorHandler = (type = "server") => {
    setSuccess({
      show: false,
      message: "",
    });
    switch (type) {
      case "no_email":
        setError({ show: true, message: "Debe suministrar un e-mail" });
        break;
      case "bad_email":
        setError({
          show: true,
          message: "El e-mail suministrado es incorrecto",
        });
        break;
      case "server":
        setError({ show: true, message: "Error de servidor. Inténtelo de nuevo" });
        break;
      default:
        break;
    }
  };

  const resetPasswordSuccessHandler = () => {
    setError({ show: false, message: "" });
    setSuccess({
      show: true,
      message:
        "Las instrucciones para recuperar su contraseña fueron enviadas a su e-mail",
    });
  };

  const submitHandler = async () => {
    const { body, error } = getResetPasswordBody(
      email,
      resetPasswordErrorHandler
    );
    !error &&
      (await passwordResetService(
        body,
        resetPasswordErrorHandler,
        resetPasswordSuccessHandler
      ));
  };

  return (
    <ResetPasswordForm
      email={email}
      setEmail={setEmail}
      submitHandler={submitHandler}
      error={error}
      success={success}
    />
  );
};

export default ResetPasswordPage;