// React import
import React, { useState } from "react";

// Libraries import
import { useHistory } from "react-router-dom";
import { notification, Popconfirm } from "antd";

// Custom components import
import Button from "../../../../../../shared/components/Button";
import Legend from "./components/Legend";

// Styled components import
import { IndicationContainer } from "./styles";

// Services import
import { applyScheduleEditionProposal } from "../../../../../services";

const ScheduleControl = ({
  modifierActive,
  scheduleModificationActive,
  setScheduleModificationActive,
  scheduleEditionProposal,
  isProposalActive,
  currentValorization,
  userRole,
  setLoading,
  registerScheduleEditionProposal,
  setTrigger,
  trigger,
  rechargeTimeline,
}) => {
  const history = useHistory();

  // States
  const [showPopProposal, setShowPopProposal] = useState(false);
  const [showPopApplication, setShowPopApplication] = useState(false);

  const adminPermission = userRole === "ADMIN";
  const activeScheduleModificationApplication =
    scheduleEditionProposal.executor_approves_valorization_final_date ===
      "APPROVED" &&
    scheduleEditionProposal.executor_approves_meter_entering_final_date ===
      "APPROVED" &&
    scheduleEditionProposal.executor_approves_meter_validation_final_date ===
      "APPROVED" &&
    scheduleEditionProposal.executor_approves_differences_closing_final_date ===
      "APPROVED" &&
    scheduleEditionProposal.supervisor_approves_valorization_final_date ===
      "APPROVED" &&
    scheduleEditionProposal.supervisor_approves_meter_entering_final_date ===
      "APPROVED" &&
    scheduleEditionProposal.supervisor_approves_meter_validation_final_date ===
      "APPROVED" &&
    scheduleEditionProposal.supervisor_approves_differences_closing_final_date ===
      "APPROVED" &&
    (scheduleEditionProposal.is_valorization_final_date_changed ||
      scheduleEditionProposal.is_meter_validation_final_date_changed ||
      scheduleEditionProposal.is_meter_entering_final_date_changed ||
      scheduleEditionProposal.is_differences_closing_final_date_changed);

  const activeScheduleSetting =
    scheduleEditionProposal.executor_approves_valorization_final_date !==
      "SETTED" &&
    scheduleEditionProposal.executor_approves_meter_entering_final_date !==
      "SETTED" &&
    scheduleEditionProposal.executor_approves_meter_validation_final_date !==
      "SETTED" &&
    scheduleEditionProposal.executor_approves_differences_closing_final_date !==
      "SETTED" &&
    scheduleEditionProposal.supervisor_approves_valorization_final_date !==
      "SETTED" &&
    scheduleEditionProposal.supervisor_approves_meter_entering_final_date !==
      "SETTED" &&
    scheduleEditionProposal.supervisor_approves_meter_validation_final_date !==
      "SETTED" &&
    scheduleEditionProposal.supervisor_approves_differences_closing_final_date !==
      "SETTED";

  const errorHandler = (errorType, response = {}) => {
    switch (errorType) {
      case "bad_request":
        notification.error({
          message: response[Object.keys(response)[0]],
        });
        break;
      case "server":
        notification.error({
          message: "Hubo un error en el servidor",
        });
        break;
      default:
        notification.error({
          message: "Hubo un error en el servidor",
        });
        break;
    }
  };

  const successHandler = () => {
    notification.success({
      message:
        "Se realizo correctamente la aplicación de la modificación de fechas",
    });
    setTrigger(!trigger);
    setTimeout(() => history.go(0), 3000);
  };

  const scheduleEditionApplicationHandler = async () => {
    setLoading(true);
    await applyScheduleEditionProposal(
      currentValorization.id,
      errorHandler,
      successHandler
    );
    setLoading(false);
  };

  return (
    <>
      {scheduleModificationActive ? (
        <>
          {adminPermission && (
            <>
              <Popconfirm
                title="¿Esta seguro que desea proponer esta modificación a las fechas de cierre de valorización para su aprobación?"
                onConfirm={() => {
                  setShowPopProposal(false);
                  registerScheduleEditionProposal();
                }}
                onCancel={() => {
                  setShowPopProposal(false);
                }}
                open={showPopProposal}
                okText="Si"
                cancelText="No"
              >
                <Button
                  background={activeScheduleSetting ? "#05BE8C" : "#818181"}
                  margin="30px 50px 20px 50px"
                  width="250px"
                  fontWeight="600"
                  boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
                  onClick={() => {
                    setShowPopProposal(true);
                  }}
                >
                  {activeScheduleSetting
                    ? "Proponer modificación de fechas"
                    : "Proposición enviada"}
                </Button>
              </Popconfirm>

              <Popconfirm
                title="¿Esta seguro de que desea aplicar esta modificación de fechas?"
                onConfirm={() => {
                  setShowPopApplication(false);
                  activeScheduleModificationApplication &&
                    scheduleEditionApplicationHandler();
                }}
                onCancel={() => {
                  setShowPopApplication(false);
                }}
                open={showPopApplication}
                okText="Si"
                cancelText="No"
              >
                <Button
                  background={
                    activeScheduleModificationApplication
                      ? "#05BE8C"
                      : "#818181"
                  }
                  margin="20px 50px 20px 50px"
                  width="250px"
                  fontWeight="600"
                  boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
                  onClick={() => {
                    activeScheduleModificationApplication &&
                      setShowPopApplication(true);
                  }}
                >
                  Aplicar modificación de fechas
                </Button>
              </Popconfirm>
            </>
          )}
          <Button
            background="#D93022"
            margin="30px 50px 30px 50px"
            width="250px"
            fontWeight="600"
            boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
            onClick={() => {
              setScheduleModificationActive(false);
              rechargeTimeline();
            }}
          >
            Salir de modificación de fechas
          </Button>
        </>
      ) : (
        <Button
          background={modifierActive ? "#05BE8C" : "#818181"}
          margin="50px 50px 50px 50px"
          width="200px"
          fontWeight="600"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
          onClick={() => {
            setScheduleModificationActive(true);
          }}
        >
          Modificación de fechas
        </Button>
      )}
      {isProposalActive && (
        <IndicationContainer indication>
          {adminPermission
            ? "Proposición de fechas enviada"
            : "Acción requerida: Validar proposición de fechas"}
        </IndicationContainer>
      )}
      <IndicationContainer>
        {adminPermission
          ? "Para aplicar la modificación de fechas de cierre de valorización requiere tener todas las aprobaciones"
          : "En esta interfaz puede aprobar o rechazar la fechas de cierre propuestas por el administrador para su modificación"}
      </IndicationContainer>
      {scheduleModificationActive && (
        <IndicationContainer>
          <Legend />
        </IndicationContainer>
      )}
    </>
  );
};

export default ScheduleControl;
