import React from "react";
import "./styles.css";

const AddPayment = ({ setPayment }) => {
  return (
    <div className="add_payment_button" onClick={() => setPayment()}>
      +
    </div>
  );
};

export default AddPayment;
