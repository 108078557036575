import React from "react";
import { Select as AntSelect } from "antd";

export const Select = ({
  value,
  onChange,
  width,
  className,
  options,
  disabled,
}) => (
  <AntSelect
    value={value}
    onChange={onChange}
    style={{
      width,
      height: "32",
      fontSize: "15px",
    }}
    className={className}
    disabled={disabled}
  >
    {options.map((option, index) => (
      <AntSelect.Option key={index} value={option.id}>
        {option.name}
      </AntSelect.Option>
    ))}
  </AntSelect>
);
