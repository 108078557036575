import React, { useState } from "react";
import Item from "./Item";

import { Button } from "../../Button";

const PaymentReject = ({ rejectPayment }) => {
  //comments
  const [comments, setComments] = useState("");

  return (
    <>
      <div>
        <p className="projectVal__modal__description">
          Por favor indique las causas por las que rechaza el pago
        </p>
        <div className="projectVal__modal__cardsContainer">
          <Item
            title=""
            content={comments}
            width="100%"
            onChange={(value) => setComments(value)}
            disabled={false}
          />
        </div>
        <div className="projectVal__modal__btns">
          <Button
            background={comments !== "" ? "#D93022" : "#818181"}
            width="122px"
            fontWeight="600"
            boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
            onClick={() => {
              comments !== "" && rejectPayment(comments);
            }}
          >
            Rechazar
          </Button>
        </div>
      </div>
    </>
  );
};

export default PaymentReject;
