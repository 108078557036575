import React from "react";
import { format } from "date-fns";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";

// Components
import Bell from "../Bell";
import BellContainer from "../BellContainer";
import {
  DropdownItemName,
  DropdownItemTimestamp,
  DropdownItemContainer,
} from "./styles";

import "./styles.css";

const defaultNotifications = [
  {
    id: 1,
    active: true,
    message: "Se ha creado un nuevo proyecto",
    project: { name: "Proyecto 1" },
    created_at: "2021-05-20T18:00:00.000Z",
  },
  {
    id: 1,
    active: false,
    message: "Se ha creado un nuevo proyecto",
    project: { name: "Proyecto 2" },
    created_at: "2021-05-20T18:00:00.000Z",
  },
  {
    id: 1,
    active: true,
    message: "Se ha creado un nuevo proyecto",
    project: { name: "Proyecto 1" },
    created_at: "2021-05-20T18:00:00.000Z",
  },
  {
    id: 1,
    active: false,
    message: "Se ha creado un nuevo proyecto",
    project: { name: "Proyecto 2" },
    created_at: "2021-05-20T18:00:00.000Z",
  },
  {
    id: 1,
    active: true,
    message: "Se ha creado un nuevo proyecto",
    project: { name: "Proyecto 1" },
    created_at: "2021-05-20T18:00:00.000Z",
  },
  {
    id: 1,
    active: false,
    message: "Se ha creado un nuevo proyecto",
    project: { name: "Proyecto 2" },
    created_at: "2021-05-20T18:00:00.000Z",
  },
  {
    id: 1,
    active: true,
    message: "Se ha creado un nuevo proyecto",
    project: { name: "Proyecto 1" },
    created_at: "2021-05-20T18:00:00.000Z",
  },
  {
    id: 1,
    active: false,
    message: "Se ha creado un nuevo proyecto",
    project: { name: "Proyecto 2" },
    created_at: "2021-05-20T18:00:00.000Z",
  },
];

export default function NotificationsDropdown({
  bellClickHandler,
  notifications = defaultNotifications,
}) {
  return (
    <Dropdown isOpen={true} className="notifications_dropdown_container">
      <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={true}>
        <BellContainer onClick={bellClickHandler} whiteMode={true}>
          <Bell whiteMode={true} />
        </BellContainer>
      </DropdownToggle>
      <DropdownMenu className="notifications_dropdown_menu">
        {notifications.map((notification) => (
          <DropdownItemContainer
            key={notification.id}
            active={notification.active}
          >
            <div>{notification.message}</div>
            <DropdownItemName>{notification.project.name}</DropdownItemName>
            <DropdownItemTimestamp>
              {`${format(
                new Date(notification.created_at),
                "dd-LL-uuu hh:mm aaa"
              )}`}
              &nbsp;&nbsp;
            </DropdownItemTimestamp>
            <hr />
          </DropdownItemContainer>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}
