import React, { useState, useEffect } from "react";

// Libraries import
import { IconContext } from "react-icons";
import { MdComment } from "react-icons/md";
import { notification } from "antd";

// Services import
import { updatePreDeductiveProposalPart } from "../../../../services";

// Utils import
import { parseFloatNumber } from "../../../../../../utils";

export const Part = ({
  part,
  group_id,
  color_class_name,
  tab_class,
  parts_children,
  filter_handler,
  setToggleUpdateParts,
  reloadPartProposals,
  setToggleUpdatePart,
  enableEdition,
  setAdvisorModalActive,
  fileGroup,
  reloadPredeductivePage,
}) => {
  const [deductiveQuantity, setDeductiveQuantity] = useState(
    parseFloatNumber(part.part.quantity - part.proposal_quantity)
  );

  useEffect(() => {
    setDeductiveQuantity(
      parseFloatNumber(part.part.quantity - part.proposal_quantity)
    );
  }, [part.proposal_quantity]);

  const [timeLeft, setTimeLeft] = useState(30);
  const [timePercentage, setTimePercentage] = useState(100);

  useEffect(() => {
    (part.is_presended || part.is_prevalidated || part.is_prerejected) &&
      setTimeout(() => {
        if (timeLeft !== 0) {
          setTimeLeft(timeLeft - 1);
          setTimePercentage(timePercentage - 10 / 3);
        } else {
          setToggleUpdateParts((prev) => !prev);
          setToggleUpdatePart((prev) => !prev);
          setTimeLeft(30);
          setTimePercentage(100);
        }
      }, 1000);
  });

  // Save proposal part amount
  const predeductiveProposalPartUpdateErrorHandler = (
    errorType,
    response = {}
  ) => {
    switch (errorType) {
      case "bad_request":
        notification.error({
          message: response[Object.keys(response)[0]],
        });
        break;
      case "server":
        notification.error({
          message: "Hubo un error en el servidor",
        });
        break;
      default:
        notification.error({
          message: "Hubo un error en el servidor",
        });
        break;
    }
  };

  const predeductiveProposalPartUpdateSuccessHandler = (response) => {
    reloadPartProposals();
    reloadPredeductivePage();
    if (response.amount_takes_no_signed) {
      setAdvisorModalActive(true);
    }
  };

  const updateProposalPart = async () => {
    await updatePreDeductiveProposalPart(
      part.id,
      { proposal_quantity: part.part.quantity - deductiveQuantity },
      predeductiveProposalPartUpdateErrorHandler,
      predeductiveProposalPartUpdateSuccessHandler
    );
  };

  console.log("parts", part);

  return (
    <React.Fragment>
      <div
        id={fileGroup + "_PART_I_" + group_id}
        className="valorization__partHeaders"
      >
        <div className={"parts__index" + color_class_name}>
          <p className="parts__text">{part.part.part_tag}</p>
        </div>
        <div className={"parts__contract-div " + color_class_name}>
          <button
            hidden={!part.part.has_children}
            id={fileGroup + "_CB_PART_GP_" + group_id}
            className="parts__contract-button"
            onClick={() => filter_handler(group_id, fileGroup)}
          >
            -
          </button>
        </div>
        <div className={"parts__name-div" + color_class_name + tab_class}>
          <span title={part.part.name} className="parts__name-text">
            {part.part.name}
          </span>
        </div>
        <div className="parts__content" style={{ width: "72px" }}>
          <p hidden={part.part.has_children} className="parts__text">
            {parseFloatNumber(part.part.quantity)}
          </p>
        </div>
        <div className="parts__content" style={{ width: "49px" }}>
          <p className="parts__text">{part.part.unity}</p>
        </div>
        <div className="parts__content" style={{ width: "72px" }}>
          <p hidden={part.part.has_children} className="parts__text">
            {parseFloatNumber(part.part.unityprice)}
          </p>
        </div>
        <div
          className="parts__content"
          style={{ width: "73px", borderRight: "solid 1px #f3f3f3" }}
        >
          <p className="parts__text">{parseFloatNumber(part.part.ppartial)}</p>
        </div>
        <div
          className="parts__content"
          style={{ width: "72px", borderLeft: "solid 1px #f3f3f3" }}
        >
          <p hidden={part.part.has_children} className="parts__text">
            {parseFloatNumber(part.accumulated_quantity)}
          </p>
        </div>
        <div className="parts__content" style={{ width: "82px" }}>
          <p className="parts__text">
            {parseFloatNumber(part.accumulated_mount)}
          </p>
        </div>
        <div
          className="parts__content"
          style={{ width: "65px", borderRight: "solid 2px #f3f3f3" }}
        >
          <p className="parts__text">
            {parseFloatNumber(part.accumulated_advance_percentage * 100)}
            {" %"}
          </p>
        </div>
        <div
          className="parts__content"
          style={{ width: "72px", borderLeft: "solid 1px #f3f3f3" }}
        >
          <p hidden={part.part.has_children} className="parts__text">
            {parseFloatNumber(part.no_signed_accumulated_quantity)}
          </p>
        </div>
        <div className="parts__content" style={{ width: "82px" }}>
          <p className="parts__text">
            {parseFloatNumber(part.no_signed_accumulated_mount)}
          </p>
        </div>
        <div
          className="parts__content"
          style={{ width: "65px", borderRight: "solid 2px #f3f3f3" }}
        >
          <p className="parts__text">
            {parseFloatNumber(
              part.no_signed_accumulated_advance_percentage * 100
            )}
            {" %"}
          </p>
        </div>
        <div className="parts__content" style={{ width: "72px" }}>
          {part.part.has_children ? (
            <p className="parts__text">-</p>
          ) : (
            <input
              hidden={part.part.has_children}
              value={deductiveQuantity}
              onFocus={(event) => event.target.select()}
              onChange={(e) => {
                setDeductiveQuantity(e.target.value);
              }}
              onBlur={() => {
                updateProposalPart();
              }}
              disabled={!enableEdition}
              className="parts__input"
              type="number"
              min="0"
            />
          )}
        </div>
        <div className="parts__content" style={{ width: "82px" }}>
          <p className="parts__text">
            {parseFloatNumber(part.part.ppartial - part.proposal_mount)}
          </p>
        </div>
        <div
          className="parts__content"
          style={{ width: "72px", borderLeft: "solid 1px #f3f3f3" }}
        >
          <p hidden={part.part.has_children} className="parts__text">
            {parseFloatNumber(part.proposal_quantity)}
          </p>
        </div>
        <div className="parts__content" style={{ width: "82px" }}>
          <p className="parts__text">{parseFloatNumber(part.proposal_mount)}</p>
        </div>
        <div
          className="parts__content"
          style={{ width: "65px", fontSize: "12px" }}
        >
          <p className="parts__text">
            {parseFloatNumber(part.proposal_advance_percentage * 100)} %
          </p>
        </div>
        <div className="parts__content" style={{ width: "46px" }}>
          <IconContext.Provider
            value={{
              color: part.amount_takes_no_signed ? "" : "#808080",
            }}
          >
            <MdComment
              hidden={part.part.has_children}
              size={18}
              className={`navbar__menu-icon parts__pointer`}
              style={{ marginTop: "12px" }}
              onClick={() => {
                part.amount_takes_no_signed && setAdvisorModalActive(true);
              }}
            />
          </IconContext.Provider>
        </div>
      </div>
      <div id={fileGroup + "_PART_GP_" + group_id}>{parts_children}</div>
    </React.Fragment>
  );
};
