import appFetcher from "../../App/utils/http/AppFetcher";

export const passwordResetService = async (body, errorHandler, successHandler) => {
    try {
      const { status, response } = await appFetcher({
        relativePath: "/auth/password/reset/",
        isAuthRequired: false,
        requestContentType: "application/json",
        body: body,
        responseContentType: "json",
        method:'POST',
      });
      if (status === 200) {
        successHandler(response.detail);
      } else {
        errorHandler("bad_email");
      }
    } catch (error) {
      errorHandler("server");
    }
  };