// Basic import
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// Components import
import { notification, Modal } from "antd";
import Loading from "../../../shared/components/Loading";
import { Qrome } from "./Qrome";
import Qrome2 from "./Qrome2";

// Modals
import DebtModal from "../../../../components/Debt/Summary";
import ProjectDeliveryModal from "../../../../components/ProjectDelivery/Summary";
import SignModal from "./Qrome/SignModal";
import VisorModal from "./Qrome/components/VisorModal";
import TutorialModal from "./Qrome/components/TutorialModal";
import AmountsDatesModal from "./AmountsDatesModal";
import AdditionalModal from "../../submodules/Additionals/AdditionalModal";
import DeductiveModal from "../../submodules/Deductives/DeductiveModal";
import AddValorizationModal from "../../submodules/AddValorization";
import LiquidationModal from "../../submodules/Liquidation";

// Sections
import { Valorizations } from "./Valorizations/Valorizations";
import TimelineSection from "./TimelineSection";
import AdditionalsSection from "../../submodules/Additionals/AdditionalsSection";
import DeductivesSection from "../../submodules/Deductives/DeductivesSection";

// Providers import
import {
  getValorizationsScreenSettings,
  getpresentValorization,
  getLastValorization,
  sendPresentValorization,
  evaluatePresentValorization,
  signPresentValorization,
  getPresentValorizationReport,
  getValorizationSendReportValorization,
  getValorizationEvaluationReportValorization,
  getValorizationFinalReportValorization,
  getNotifications,
  cargoNotification,
  getValorizations,
} from "../../../../providers";
import { createProjectDelivery } from "../../services";

import "./index.css";

export const ProjectValuationBanner = ({
  history,
  match,
  user,
  role,
  project,
  setProject,
  image,
  general_aproved,
}) => {
  const { id } = match.params;

  // Query Params getting
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramPreadditionalId = queryParams.get("additional");
  const paramPredeductiveId = queryParams.get("deductive");

  // Main valorizations
  const [presentValorization, setPresentValorization] = useState("");
  const [lastValorization, setLastValorization] = useState("");
  // Valorization selector
  const [valorizations, setValorizations] = useState([]);
  const [currentValorization, setCurrentValorization] = useState({
    to_be_closed: false,
    debt: null,
  });
  // Section manager
  const [activeSection, setActiveSection] = useState(0);

  // loading
  const [spinLoading, setSpinLoading] = useState(false);
  //status changing
  const [firstLoadPV, setfirstLoadPV] = useState(false);
  const [firstLoadLV, setFirstLoadLV] = useState(false);
  const [isActivePV, setIsActivePV] = useState(false);
  const [isInDifferencesClosingLV, setIsInDifferencesClosingLV] =
    useState(false);
  const [isInMeterValidationLV, setIsInMeterValidationLV] = useState(false);
  const [isInMeterEnteringLV, setIsInMeterEnteringLV] = useState(false);
  // notifications
  const [notificationMessage, setNotificationMessage] = useState({
    id: 0,
    message: "",
    redirect: "",
    redirect_val_id: "",
  });
  const [activeNotification, setActiveNotification] = useState(false);
  const [triggerValorizations, setTriggerValorizations] = useState(false);
  // Sign modal status
  const [activeModalFirm, setActiveModalFirm] = useState(false);
  const [finalReport, setFinalReport] = useState("");

  // Visor modal valorizations
  const [
    activeVisorModalReportValorization,
    setActiveVisorModalReportValorization,
  ] = useState(false);
  const [visorReportValorization, setVisorReportValorization] = useState("");

  // Video tutorial modal
  const [activeVideoTutorialModal, setActiveVideoTutorialModal] =
    useState(false);
  const [tutorialVideo, setTutorialVideo] = useState("");
  const [screenSettings, setscreenSettings] = useState("");

  // Debt modal status
  const [debtModalActive, setDebtModalActive] = useState(false);

  // Project delivery modal status
  const [projectDeliveryModalActive, setProjectDeliveryModalActive] =
    useState(false);

  // Project liquidation
  const [liquidationModalActive, setLiquidationModalActive] = useState(false);

  // Additional modals statuses
  const [additionalModalActive, setAdditionalModalActive] = useState(false);
  const [additionalCreationActive, setAdditionalCreationActive] =
    useState(false);
  const [additionalFinalCreationActive, setAdditionalFinalCreationActive] =
    useState(false);
  const [preadditionalId, setPreadditionalId] = useState(0);
  const [additionalTrigger, setAdditionalTrigger] = useState(false);

  // Deductive modals statuses
  const [deductiveModalActive, setDeductiveModalActive] = useState(false);
  const [deductiveCreationActive, setDeductiveCreationActive] = useState(false);
  const [predeductiveId, setPredeductiveId] = useState(0);
  const [deductiveTrigger, setDeductiveTrigger] = useState(false);

  // Add Valorization modal status
  const [addValorizationModalActive, setAddValorizationModalActive] =
    useState(false);

  // Amount Dates modal status
  const [amountsDatesModalActive, setAmountsDatesModalActive] = useState(false);

  const sendpresentValorization = async () => {
    try {
      setSpinLoading(true);
      const response = await sendPresentValorization(id);
      if (
        response.detail[0] !== "Se ha enviado correctamente la valorización"
      ) {
        notification.error({
          message: response.detail[0],
        });
        setSpinLoading(false);
      } else {
        notification.success({
          message: response.detail[0],
        });
        setSpinLoading(false);
        history.go(0);
      }
    } catch (error) {
      notification.error({
        message: "Ocurrió un error interno. Por favor vuelva a intentarlo",
      });
    }
  };

  const evaluatepresentValorization = async () => {
    try {
      setSpinLoading(true);
      const response = await evaluatePresentValorization(id);
      if (
        response.detail[0] !==
        "Se ha enviado correctamente la evaluación de la valorización"
      ) {
        notification.error({
          message: response.detail[0],
        });
        setSpinLoading(false);
      } else {
        notification.success({
          message: response.detail[0],
        });
        setSpinLoading(false);
        history.go(0);
      }
    } catch (error) {
      notification.error({
        message: "Ocurrió un error interno. Por favor vuelva a intentarlo",
      });
    }
  };

  const blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const loadFinalReportValorization = async () => {
    try {
      setSpinLoading(true);
      const response = await getPresentValorizationReport(id);
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        const json = await response.json();
        if (json.detail) {
          notification.success({
            message: json.detail,
          });
          setSpinLoading(false);
          setTimeout(() => history.go(0), 3000);
        } else {
          notification.error({
            message: "Ocurrió un error interno. Por favor vuelva a intentarlo",
          });
          setSpinLoading(false);
        }
      } else {
        const blobFile = await response.blob();
        const base64File = await blobToBase64(blobFile);
        setFinalReport(base64File);
        setSpinLoading(false);
        setActiveModalFirm(true);
      }
    } catch (error) {
      notification.error({
        message: "Ocurrió un error interno. Por favor vuelva a intentarlo",
      });
      setSpinLoading(false);
    }
  };

  const loadValorizationSendReportValorization = async () => {
    try {
      setSpinLoading(true);
      const response = await getValorizationSendReportValorization(
        currentValorization.id
      );
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        const json = await response.json();
        if (json.detail) {
          notification.success({
            message: json.detail,
          });
          setSpinLoading(false);
          setTimeout(() => history.go(0), 3000);
        } else {
          notification.error({
            message: "Ocurrió un error interno. Por favor vuelva a intentarlo",
          });
          setSpinLoading(false);
        }
      } else {
        const blobFile = await response.blob();
        const base64File = await blobToBase64(blobFile);
        setVisorReportValorization(base64File);
        setSpinLoading(false);
        setActiveVisorModalReportValorization(true);
      }
    } catch (error) {
      notification.error({
        message: "Ocurrió un error interno. Por favor vuelva a intentarlo",
      });
      setSpinLoading(false);
    }
  };

  const loadValorizationEvaluationReportValorization = async () => {
    try {
      setSpinLoading(true);
      const response = await getValorizationEvaluationReportValorization(
        currentValorization.id
      );
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        const json = await response.json();
        if (json.detail) {
          notification.success({
            message: json.detail,
          });
          setSpinLoading(false);
          setTimeout(() => history.go(0), 3000);
        } else {
          notification.error({
            message: "Ocurrió un error interno. Por favor vuelva a intentarlo",
          });
          setSpinLoading(false);
        }
      } else {
        const blobFile = await response.blob();
        const base64File = await blobToBase64(blobFile);
        setVisorReportValorization(base64File);
        setSpinLoading(false);
        setActiveVisorModalReportValorization(true);
      }
    } catch (error) {
      notification.error({
        message: "Ocurrió un error interno. Por favor vuelva a intentarlo",
      });
      setSpinLoading(false);
    }
  };

  const loadValorizationFinalReportValorization = async () => {
    try {
      setSpinLoading(true);
      const response = await getValorizationFinalReportValorization(
        currentValorization.id
      );
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        const json = await response.json();
        if (json.detail) {
          notification.success({
            message: json.detail,
          });
          setSpinLoading(false);
          setTimeout(() => history.go(0), 3000);
        } else {
          notification.error({
            message: "Ocurrió un error interno. Por favor vuelva a intentarlo",
          });
          setSpinLoading(false);
        }
      } else {
        const blobFile = await response.blob();
        const base64File = await blobToBase64(blobFile);
        setVisorReportValorization(base64File);
        setSpinLoading(false);
        setActiveVisorModalReportValorization(true);
      }
    } catch (error) {
      notification.error({
        message: "Ocurrió un error interno. Por favor vuelva a intentarlo",
      });
      setSpinLoading(false);
    }
  };

  const signpresentValorization = async () => {
    try {
      setActiveModalFirm(false);
      setSpinLoading(true);
      const response = await signPresentValorization(id);
      if (
        response.detail[0].includes(
          "Se ha firmado correctamente la valorización"
        )
      ) {
        notification.success({
          message: response.detail[0],
        });
        setSpinLoading(false);
        setTimeout(() => {
          history.go(0);
        }, 3000);
      } else {
        notification.error({
          message: response.detail[0],
        });
        setSpinLoading(false);
      }
    } catch (error) {
      notification.error({
        message: "Ocurrió un error interno. Por favor vuelva a intentarlo",
      });
      setSpinLoading(false);
    }
  };

  const acceptNotification = async () => {
    try {
      setActiveNotification(false);
      setSpinLoading(true);
      const response = await cargoNotification(notificationMessage.id);
      notification.success({
        message: response.detail[0],
      });
      setSpinLoading(false);
      if (notificationMessage.redirect === "DEBT_VALIDATION") {
        setDebtModalActive(true);
        const _valorizations = await getValorizations(id);
        let _current_val = await _valorizations.results.find(
          (elem) => elem.id + "" === notificationMessage.redirect_val_id
        );
        setCurrentValorization(_current_val);
      } else {
        history.go(0);
      }
    } catch (error) {
      notification.error({
        message: "Ocurrió un error interno. Por favor vuelva a intentarlo",
      });
      setSpinLoading(false);
    }
  };

  useEffect(() => {
    const loadScreenSettings = async () => {
      try {
        const screen_settings = await getValorizationsScreenSettings();
        setscreenSettings(screen_settings);
        setSpinLoading(false);
      } catch (error) {
        notification.error({
          message: "Ocurrió un error interno. Por favor vuelva a intentarlo",
        });
      }
    };
    loadScreenSettings();
  }, []);

  useEffect(() => {
    const fetchValorizations = async () => {
      try {
        const _valorizations = await getValorizations(id);
        setValorizations(_valorizations.results);
        if (_valorizations.results.length > 0) {
          const _lastValorization = _valorizations.results.filter(
            (valorization) => valorization.show_closing_headers
          );
          if (_lastValorization.length === 0) {
            setCurrentValorization(_valorizations.results[0]);
          } else {
          }
          setCurrentValorization(_lastValorization[0]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchValorizations();
  }, [triggerValorizations]);

  useEffect(() => {
    setTriggerValorizations(!triggerValorizations);
  }, [
    lastValorization.executor_sign_enable,
    lastValorization.supervisor_sign_enable,
  ]);

  useEffect(() => {
    const fetchPresentValorization = async () => {
      try {
        const _present_valorizations = await getpresentValorization(id);
        setPresentValorization(_present_valorizations);
        setIsActivePV(_present_valorizations.is_active);
        setfirstLoadPV(true);
      } catch (error) {
        console.log(error);
      }
    };
    general_aproved && fetchPresentValorization();
    if (general_aproved) {
      const partsInterval = setInterval(
        () => fetchPresentValorization(),
        60000
      );
      return () => clearInterval(partsInterval);
    }
  }, [general_aproved]);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const _notifications = await getNotifications(id, {
          cargo_required: true,
        });
        if (_notifications.results.length !== 0) {
          const _notification = _notifications.results[0];
          if (notificationMessage.id !== _notification.id) {
            setNotificationMessage(_notification);
            setActiveNotification(true);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    general_aproved && fetchNotifications();
    if (general_aproved) {
      const notificationsInterval = setInterval(
        () => fetchNotifications(),
        1000000
      );
      return () => clearInterval(notificationsInterval);
    }
  }, [general_aproved]);

  useEffect(() => {
    const fetchLastValorization = async () => {
      try {
        const _last_valorizations = await getLastValorization(id);
        setLastValorization(_last_valorizations);
        setIsInDifferencesClosingLV(_last_valorizations.in_differences_closing);
        setIsInMeterValidationLV(_last_valorizations.in_meter_validation);
        setIsInMeterEnteringLV(_last_valorizations.in_meter_entering);
        setfirstLoadPV(true);
      } catch (error) {
        console.log(error);
      }
    };
    general_aproved && fetchLastValorization();
    if (general_aproved) {
      const partsInterval = setInterval(() => fetchLastValorization(), 10000);
      return () => clearInterval(partsInterval);
    }
  }, [general_aproved]);

  useEffect(() => {
    if (firstLoadLV) {
      history.go(0);
    }
  }, [
    firstLoadLV,
    ,
    isInDifferencesClosingLV,
    isInMeterValidationLV,
    isInMeterEnteringLV,
  ]);

  useEffect(() => {
    if (firstLoadPV) {
      history.go(0);
    }
  }, [isActivePV]);

  // Preadditional Id
  useEffect(() => {
    if (paramPreadditionalId) {
      if (paramPreadditionalId !== "all") {
        openPreadditionalModal(paramPreadditionalId);
      }
      setActiveSection(2);
    }
  }, [paramPreadditionalId]);

  // Predeductive Id
  useEffect(() => {
    if (paramPredeductiveId) {
      if (paramPredeductiveId !== "all") {
        openPredeductiveModal(paramPredeductiveId);
      }
      setActiveSection(3);
    }
  }, [paramPredeductiveId]);

  // Additionals handlers
  const openPreadditionalModal = (id) => {
    setPreadditionalId(id);
    setAdditionalModalActive(true);
  };

  // Deductives handlers
  const openPredeductiveModal = (id) => {
    setPredeductiveId(id);
    setDeductiveModalActive(true);
  };

  const handleModalVideoTurial = (videourl) => {
    setActiveVideoTutorialModal(true);
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = videourl.match(regex);
    const newurl = "https://www.youtube.com/embed/" + match[1];
    setTutorialVideo(newurl);
  };

  // handler project delivery
  const projectDeliveryCreationSuccessHandler = (response) => {
    notification.success({
      message: "Se ha creado correctamente la entrega de obra",
    });
    setProject({ ...project, projectdelivery: response.id });
    setSpinLoading(false);
    setProjectDeliveryModalActive(true);
  };

  const projectDeliveryCreationErrorHandler = (response) => {
    notification.error({
      message: response
        ? Object.values(response)[0][0]
        : "Ocurrió un error al acceder a la entrega de obra",
    });
    setSpinLoading(false);
  };

  const projectDeliveryCreationHandler = async () => {
    setSpinLoading(true);
    if (project.projectdelivery) {
      setProjectDeliveryModalActive(true);
      setSpinLoading(false);
    } else {
      await createProjectDelivery(
        id,
        projectDeliveryCreationErrorHandler,
        projectDeliveryCreationSuccessHandler
      );
    }
  };

  let mainSection = <div></div>;
  switch (activeSection) {
    case 0:
      mainSection = (
        <Valorizations
          id={id}
          user={user}
          role={role}
          general_aproved={general_aproved}
          valorizations={valorizations}
          setCurrentValorization={setCurrentValorization}
          currentValorization={currentValorization}
        />
      );
      break;
    case 1:
      mainSection = (
        <TimelineSection
          projectId={id}
          project={project}
          userRole={role}
          currentValorization={currentValorization}
          setLoading={setSpinLoading}
        />
      );
      break;
    case 2:
      mainSection = (
        <AdditionalsSection
          projectId={id}
          userRole={role}
          openPreadditionalModal={openPreadditionalModal}
          additionalTrigger={additionalTrigger}
          setAdditionalTrigger={setAdditionalTrigger}
          setLoading={setSpinLoading}
        />
      );
      break;
    case 3:
      mainSection = (
        <DeductivesSection
          projectId={id}
          userRole={role}
          openPredeductiveModal={openPredeductiveModal}
          deductiveTrigger={deductiveTrigger}
          setDeductiveTrigger={setDeductiveTrigger}
          setLoading={setSpinLoading}
        />
      );
      break;
    default:
      break;
  }
  return (
    <div>
      <Modal
        title="Notificación"
        open={activeNotification}
        onOk={() => acceptNotification()}
        cancelButtonProps={{ hidden: true }}
        onCancel={() => {}}
        okText="Confirmar recepción"
        cancelText=""
      >
        <p>{`${notificationMessage.message}`}</p>
      </Modal>
      <SignModal
        active={activeModalFirm}
        finalReport={finalReport}
        setActiveModalFirm={setActiveModalFirm}
        signpresentValorization={signpresentValorization}
      />
      <VisorModal
        active={activeVisorModalReportValorization}
        seeReport={visorReportValorization}
        setActiveModal={setActiveVisorModalReportValorization}
      />
      <TutorialModal
        active={activeVideoTutorialModal}
        seeTutorial={tutorialVideo}
        setActiveModal={setActiveVideoTutorialModal}
      />
      <DebtModal
        user={user}
        role={role}
        debtModalActive={debtModalActive}
        setDebtModalActive={setDebtModalActive}
        debt_id={currentValorization.debt}
        currentValorization={currentValorization}
        project_id={id}
      />
      <ProjectDeliveryModal
        user={user}
        userRole={role}
        debtModalActive={projectDeliveryModalActive}
        setDebtModalActive={setProjectDeliveryModalActive}
        setLoading={setSpinLoading}
        projectDeliveryId={project.projectdelivery}
      />
      <AmountsDatesModal
        setAmountsDatesModalActive={setAmountsDatesModalActive}
        amountsDatesModalActive={amountsDatesModalActive}
        projectId={id}
      />
      <AdditionalModal
        setAdditionalModalActive={setAdditionalModalActive}
        setAdditionalCreationActive={setAdditionalCreationActive}
        additionalModalActive={additionalModalActive}
        additionalCreationActive={additionalCreationActive}
        additionalFinalCreationActive={additionalFinalCreationActive}
        setAdditionalFinalCreationActive={setAdditionalFinalCreationActive}
        preadditionalId={preadditionalId}
        setSpinLoading={setSpinLoading}
        projectId={id}
        userRole={role}
        setActiveSection={setActiveSection}
        additionalTrigger={additionalTrigger}
        setAdditionalTrigger={setAdditionalTrigger}
        project={project}
      />
      <DeductiveModal
        setDeductiveModalActive={setDeductiveModalActive}
        setDeductiveCreationActive={setDeductiveCreationActive}
        deductiveModalActive={deductiveModalActive}
        deductiveCreationActive={deductiveCreationActive}
        predeductiveId={predeductiveId}
        setSpinLoading={setSpinLoading}
        projectId={id}
        userRole={role}
        setActiveSection={setActiveSection}
        deductiveTrigger={deductiveTrigger}
        setDeductiveTrigger={setDeductiveTrigger}
        project={project}
      />
      <AddValorizationModal
        addValorizationModalActive={addValorizationModalActive}
        setAddValorizationModalActive={setAddValorizationModalActive}
        setSpinLoading={setSpinLoading}
        userRole={role}
        project={project}
      />
      <LiquidationModal
        liquidationModalActive={liquidationModalActive}
        setLiquidationModalActive={setLiquidationModalActive}
        projectId={id}
        project={project}
        setMainLoading={setSpinLoading}
        history={history}
      />
      <Qrome
        user={user}
        presentValorization={presentValorization}
        lastValorization={lastValorization}
        valorizations={valorizations}
        setCurrentValorization={setCurrentValorization}
        currentValorization={currentValorization}
        image={image}
        history={history}
        general_aproved={general_aproved}
        project={project}
        sendpresentValorization={sendpresentValorization}
        evaluatepresentValorization={evaluatepresentValorization}
        loadFinalReportValorization={loadFinalReportValorization}
        loadValorizationSendReportValorization={
          loadValorizationSendReportValorization
        }
        loadValorizationEvaluationReportValorization={
          loadValorizationEvaluationReportValorization
        }
        loadValorizationFinalReportValorization={
          loadValorizationFinalReportValorization
        }
        handleModalVideoTurial={handleModalVideoTurial}
        screenSettings={screenSettings}
        setDebtModalActive={setDebtModalActive}
        projectDeliveryCreationHandler={projectDeliveryCreationHandler}
        setAmountsDatesModalActive={setAmountsDatesModalActive}
        setLiquidationModalActive={setLiquidationModalActive}
      />
      <Qrome2
        valorizations={valorizations}
        setCurrentValorization={setCurrentValorization}
        lastValorization={lastValorization}
        presentValorization={presentValorization}
        currentValorization={currentValorization}
        setActiveSection={setActiveSection}
        activeSection={activeSection}
        setAdditionalCreationActive={setAdditionalCreationActive}
        setDeductiveCreationActive={setDeductiveCreationActive}
        setAddValorizationModalActive={setAddValorizationModalActive}
      />
      {mainSection}
      <Loading active={spinLoading} />
    </div>
  );
};
