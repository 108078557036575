import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

//pages
import ProjectAdditionalsPage from "../pages/ProjectAdditionalsPage";
import ProjectPreAdditionalsPage from "../pages/ProjectPreAdditionalsPage";

const ProjectAdditionalsRouter = () => {
  return (
    <Switch>
      <Route
        exact
        path="/additionals/:id/preadditionals"
        component={ProjectPreAdditionalsPage}
      />
      <Route
        exact
        path="/additionals/:id/additionals"
        component={ProjectAdditionalsPage}
      />
      <Redirect to="/main" />
    </Switch>
  );
};

export default ProjectAdditionalsRouter;
