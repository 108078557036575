// React import
import React from "react";

// Styled components
import { TableItemContainer, TableItem, BaseTable } from "./styles";

//Utils import
// Utils import
import { parseFloatNumber } from "../../../../../../../../utils";

const Table = ({ additionals }) => {
  const getStatus = (status) => {
    switch (status) {
      case "CREATED":
        return ["#000000", "Por configurar"];
      case "SETTED":
        return ["#000000", "Propuesto"];
      case "VALIDATED":
        return ["#28A745", "Aceptado"];
      case "REJECTED":
        return ["#DC3545", "Rechazado"];
      default:
        break;
    }
  };

  return (
    <BaseTable>
      <TableItemContainer height="3rem">
        <TableItem width="40px" header>
          Orden
        </TableItem>
        <TableItem width="100px" header>
          Nombre
        </TableItem>
        <TableItem width="100px" header>
          Estatus
        </TableItem>
        <TableItem width="100px" header>
          Monto total de adicional
        </TableItem>
        <TableItem width="100px" header>
          Monto total pagado
        </TableItem>
      </TableItemContainer>
      {additionals ? (
        <>
          {additionals.map((additional) => (
            <TableItemContainer key={additional.id}>
              <TableItem width="40px">{additional.order}</TableItem>
              <TableItem width="100px">{additional.name || "-"}</TableItem>
              <TableItem width="100px" color={getStatus(additional.status)[0]}>
                {getStatus(additional.status)[1]}
              </TableItem>
              <TableItem width="100px">
                {`S/. ${parseFloatNumber(
                  additional.additional_budget_cost +
                    additional.general_expenses_cost
                )}`}
              </TableItem>
              <TableItem width="100px">
                {`S/. ${parseFloatNumber(
                  additional.additional?.advance_amount || 0
                )}`}
              </TableItem>
            </TableItemContainer>
          ))}
        </>
      ) : (
        <></>
      )}
    </BaseTable>
  );
};

export default Table;
