// React import
import React, { useState } from "react";

// Libraries import
import { IconContext } from "react-icons";
import { FaCheck, FaTimes, FaMinus } from "react-icons/fa";

// Styled components import
import { ApprobationPanelContainer } from "./styles";
import { Popconfirm, notification } from "antd";

// Services import
import {
  approveScheduleEditionProposalDate,
  rejectScheduleEditionProposalDate,
} from "../../../../../../../services";

const ApprobationPanel = ({
  isAdminUser,
  currentValorization,
  setLoading,
  activeForRole,
  dateChanged,
  approbationStatus,
  reload,
  date,
}) => {
  const [showPopAccept, setShowPopAccept] = useState(false);
  const [showPopReject, setShowPopReject] = useState(false);
  const dateApproved = approbationStatus === "APPROVED";
  const dateSetted = approbationStatus === "SETTED";
  const dateRejected = approbationStatus === "REJECTED";

  const errorHandler = (errorType, response = {}) => {
    switch (errorType) {
      case "bad_request":
        notification.error({
          message: response[Object.keys(response)[0]],
        });
        break;
      case "server":
        notification.error({
          message: "Hubo un error en el servidor",
        });
        break;
      default:
        notification.error({
          message: "Hubo un error en el servidor",
        });
        break;
    }
  };

  const successApprobationHandler = () => {
    notification.success({
      message:
        "Se realizo correctamente la aprobación de la fecha modificada propuesta",
    });
    reload();
  };

  const successRejectionHandler = () => {
    notification.success({
      message:
        "Se realizo correctamente el rechazo de la fecha modificada propuesta",
    });
    reload();
  };

  const approbationHandler = async () => {
    setLoading(true);
    await approveScheduleEditionProposalDate(
      currentValorization.id,
      { date: date },
      errorHandler,
      successApprobationHandler
    );
    setLoading(false);
  };

  const rejectionHandler = async () => {
    setLoading(true);
    await rejectScheduleEditionProposalDate(
      currentValorization.id,
      { date: date },
      errorHandler,
      successRejectionHandler
    );
    setLoading(false);
  };

  return (
    <ApprobationPanelContainer>
      {dateApproved &&
        (dateChanged ? (
          <IconContext.Provider
            value={{
              color: "#218838",
            }}
          >
            <FaCheck size={18} />
          </IconContext.Provider>
        ) : (
          <IconContext.Provider
            value={{
              color: "#808080",
            }}
          >
            <FaMinus size={18} />
          </IconContext.Provider>
        ))}
      {dateRejected && (
        <IconContext.Provider
          value={{
            color: "#C82333",
          }}
        >
          <FaTimes size={18} />
        </IconContext.Provider>
      )}
      {dateSetted &&
        (!isAdminUser && activeForRole ? (
          <>
            <Popconfirm
              title="¿Esta seguro de realizar esta aprobación?"
              onConfirm={() => {
                approbationHandler();
              }}
              onCancel={() => {
                setShowPopAccept(false);
              }}
              open={showPopAccept}
              okText="Si"
              cancelText="No"
            >
              <IconContext.Provider
                value={{
                  color: "#218838",
                }}
              >
                <FaCheck
                  style={{ cursor: "pointer" }}
                  size={18}
                  onClick={() => setShowPopAccept(true)}
                />
              </IconContext.Provider>
            </Popconfirm>
            <Popconfirm
              title="¿Esta seguro de realizar este rechazo?"
              onConfirm={() => {
                rejectionHandler();
              }}
              onCancel={() => {
                setShowPopReject(false);
              }}
              open={showPopReject}
              okText="Si"
              cancelText="No"
            >
              <IconContext.Provider
                value={{
                  color: "#C82333",
                }}
              >
                <FaTimes
                  style={{ cursor: "pointer" }}
                  size={18}
                  onClick={() => setShowPopReject(true)}
                />
              </IconContext.Provider>{" "}
            </Popconfirm>
          </>
        ) : (
          <IconContext.Provider
            value={{
              color: "#808080",
            }}
          >
            <FaMinus size={18} />
          </IconContext.Provider>
        ))}
    </ApprobationPanelContainer>
  );
};

export default ApprobationPanel;
