// React import
import React, { useState, useEffect } from "react";

// Support libraries import
import { DatePicker, Input, Select, notification } from "antd";
import dayjs from "dayjs";

// Components import
import Button from "../../../../../../shared/components/Button";
import Card from "./components/Card";
import { TextWithTooltip } from "../../../../../../../components/EditProjectCardGroup/ValuationSchedule/TextWithTooltip";

// Styled components import
import {
  ButtonContainer,
  Container,
  LabelContainer,
  ItemContainer,
  Text,
} from "./styles";

// Services import
import { getCalendarTypes, updatePreAdditional } from "../../../../../services";

// Utils import
import { clientDateFormat } from "../../../../../../../utils";

const TermExtensionBanner = ({
  nextButtonHandler,
  preadditional,
  setPreadditional,
  project = { final_date: "" },
  enableEdition,
  reloadPreadditional,
  setSpinLoading,
}) => {
  const [calendarTypes, setCalendarTypes] = useState([]);

  const errorHandler = () => {
    setCalendarTypes([]);
  };

  const successHandler = (results) => {
    setCalendarTypes(
      results.results.map((calendarType) => {
        return {
          value: calendarType.id,
          label: calendarType.name,
        };
      })
    );
  };

  useEffect(() => {
    const fetchCalendarTypes = async () => {
      await getCalendarTypes(errorHandler, successHandler);
    };
    fetchCalendarTypes();
  }, []);

  const preadditionalUpdateErrorHandler = (response) => {
    notification.error({
      message:
        "Hubo un error en la actualización de la propuesta de adicional.",
    });
  };
  const preadditionalUpdateSuccessHandler = () => {};

  const termExtensionDaysChangeHandler = async () => {
    setSpinLoading(true);
    await updatePreAdditional(
      preadditional.id,
      { term_extension_days: preadditional.term_extension_days },
      preadditionalUpdateErrorHandler,
      preadditionalUpdateSuccessHandler
    );
    setSpinLoading(false);
    reloadPreadditional();
  };

  const termExtensionCalendarTypeChangeHandler = async (value) => {
    setSpinLoading(true);
    await updatePreAdditional(
      preadditional.id,
      { term_extension_calendar_type: value },
      preadditionalUpdateErrorHandler,
      preadditionalUpdateSuccessHandler
    );
    setSpinLoading(false);
    reloadPreadditional();
  };

  return (
    <Card>
      <Container style={{ marginBottom: "20px" }}>
        <p className="blue bold font14" style={{ marginBottom: "20px" }}>
          Configuración de ampliación de plazo:
        </p>{" "}
        <ItemContainer>
          <LabelContainer>
            <TextWithTooltip
              id="inicioPlazo"
              info="Fecha de fin del proyecto según plazo contractual"
            >
              Fecha de fin plazo contractual
            </TextWithTooltip>
          </LabelContainer>
          <DatePicker
            value={dayjs(project.final_date)}
            format={clientDateFormat}
            disabled={true}
            style={{ width: "150px" }}
          />
        </ItemContainer>
        <ItemContainer>
          <Input
            style={{ width: "70px" }}
            value={preadditional.term_extension_days}
            onFocus={(event) => event.target.select()}
            onChange={(event) => {
              setPreadditional({
                ...preadditional,
                term_extension_days: event.target.value,
              });
            }}
            onBlur={() => {
              termExtensionDaysChangeHandler();
            }}
            disabled={!enableEdition}
          />
          <Text>Días</Text>
          <Select
            value={preadditional.term_extension_calendar_type}
            onChange={(value) => {
              termExtensionCalendarTypeChangeHandler(value);
            }}
            options={calendarTypes}
            disabled={!enableEdition}
            width={150}
          />
        </ItemContainer>
        <ItemContainer>
          <LabelContainer>
            <TextWithTooltip
              id="FinDePlazo"
              info="Fecha de fin del proyecto propuesto"
            >
              Fecha de fin plazo nuevo
            </TextWithTooltip>
          </LabelContainer>
          <DatePicker
            value={dayjs(preadditional.final_date_proposed)}
            format={clientDateFormat}
            disabled={true}
            style={{ width: "150px" }}
          />
        </ItemContainer>
      </Container>
      <Container style={{ marginBottom: "20px" }}>
        <p className="blue bold font14" style={{ marginBottom: "20px" }}>
          ¿Se va a cobrar gastos generales?
        </p>
        <div style={{ textAlign: "center" }}>
          <label style={{ marginRight: "130px" }}>
            <input
              style={{ marginRight: "8px" }}
              type="radio"
              name="general_expenses_included"
              disabled={!enableEdition}
              checked={preadditional.general_expenses_included}
              onClick={() => {
                setPreadditional({
                  ...preadditional,
                  general_expenses_included: true,
                });
              }}
            ></input>
            Si
          </label>
          <label>
            <input
              style={{ marginRight: "8px" }}
              type="radio"
              name="general_expenses_included"
              disabled={!enableEdition}
              checked={!preadditional.general_expenses_included}
              onClick={() => {
                setPreadditional({
                  ...preadditional,
                  general_expenses_included: false,
                });
              }}
            ></input>
            No
          </label>
        </div>
      </Container>
      {preadditional.general_expenses_included && (
        <Container style={{ marginBottom: "20px" }}>
          <p className="blue bold font14" style={{ marginBottom: "20px" }}>
            ¿Los gastos generales son un porcentaje del presupuesto base?
          </p>
          <div style={{ textAlign: "center" }}>
            <label style={{ marginRight: "130px" }}>
              <input
                style={{ marginRight: "8px" }}
                type="radio"
                name="is_general_expenses_percentage"
                disabled={!enableEdition}
                checked={preadditional.is_general_expenses_percentage}
                onClick={() => {
                  setPreadditional({
                    ...preadditional,
                    is_general_expenses_percentage: true,
                  });
                }}
              ></input>
              Si
            </label>
            <label>
              <input
                style={{ marginRight: "8px" }}
                type="radio"
                name="is_general_expenses_percentage"
                disabled={!enableEdition}
                checked={!preadditional.is_general_expenses_percentage}
                onClick={() => {
                  setPreadditional({
                    ...preadditional,
                    is_general_expenses_percentage: false,
                  });
                }}
              ></input>
              No
            </label>
          </div>
        </Container>
      )}
      <ButtonContainer>
        <Button
          background="#05BE8C"
          width="140px"
          fontWeight="600"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
          onClick={() => {
            nextButtonHandler();
          }}
        >
          {enableEdition ? "Guardar y seguir" : "Siguiente"}
        </Button>
      </ButtonContainer>
    </Card>
  );
};

export default TermExtensionBanner;
