import React from "react";

// Styled components import
import {
  Container,
  ContainerText,
  Img,
  Paragraph,
  ContainerImage,
  Download,
  Title,
  Uncheck,
} from "./styles";

// Assets import
import checkVerde from "./checkVerde.png";

const Indication = () => {
  return (
    <Container>
      <div style={{ margin: "15px" }}>
        <Title>El presupuesto debe</Title>

        <ContainerText>
          <ContainerImage>
            <div>
              <Img src={checkVerde} alt="icono"></Img>
            </div>{" "}
            Contar con las siguientes columnas
          </ContainerImage>
          <Uncheck>
            <Paragraph> 1. Numero de partida</Paragraph>
            <Paragraph> 2. Descripción de la partida</Paragraph>
            <Paragraph> 3. Metrado</Paragraph>
            <Paragraph> 4. Unidades</Paragraph>
            <Paragraph> 5. Precio Unitario (P.U.)</Paragraph>
            <Paragraph>
              {" "}
              6. Parcial (como referencia, la AI de Ariane calculará los
              parciales utilizado los valores ingresados)
            </Paragraph>
          </Uncheck>
        </ContainerText>

        <ContainerImage>
          <div>
            <Img src={checkVerde} alt="icono"></Img>
          </div>
          <Paragraph>
            La numeracion de las partidas debe ser coherente, Ariane SaaS hará
            lo posbile por señalizar los errores en la enumeración.
          </Paragraph>
        </ContainerImage>

        <ContainerText>
          <ContainerImage>
            <div>
              <Img src={checkVerde} alt="icono"></Img>
            </div>
            Una partida madre es aquella que engloba otras partidas. La
            numeracion de la partida madre debe ser # y la partida hija ##,
            ejemplos a continuación:
          </ContainerImage>
          <Uncheck>
            <Paragraph> **01. Estructuras</Paragraph>
            <Paragraph> **01.01. Obras provisionales</Paragraph>
            <Paragraph>
              {" "}
              Esto quiere decir que la partida "Obras provisionales" perteneces
              a la partida "Estructuras".
            </Paragraph>
          </Uncheck>
        </ContainerText>

        <div style={{ paddingBottom: "20px" }}>
          <ContainerImage>
            <div>
              <Img src={checkVerde} alt="icono"></Img>
            </div>{" "}
            En caso un metrado no haya sido ingresado Ariane considerará el
            valor cero.
          </ContainerImage>

          <ContainerImage>
            <div>
              <Img src={checkVerde} alt="icono"></Img>
            </div>{" "}
            En caso una unidad no haya sido ingresada Ariane considerará la
            unidad "Global" (glb).
          </ContainerImage>

          <ContainerImage>
            <div>
              <Img src={checkVerde} alt="icono"></Img>
            </div>
            Formato aceptado:Excel (.xlsx).
          </ContainerImage>
        </div>

        <Download>Descarga archivo excel modelo</Download>
      </div>
    </Container>
  );
};

export default Indication;
