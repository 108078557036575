import { propOr } from "ramda";

import AppSession from "../modules/App/utils/session/AppSession";

const buildUrl = (url, params = {}) => {
  const BASE_PATH = process.env.REACT_APP_ARIANE_BFF_URL;
  const queries = Object.keys(params)
    .map(
      (key) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
    )
    .join("&");

  if (!queries.length) return `${BASE_PATH}${url}`;
  return `${BASE_PATH}${url}?${queries}`;
};

const getToken = () => propOr("User", "accessToken", AppSession.get());

const validate = async (response) => {
  if (response.status === 401) {
    closeSession();
  }
  if (response.status >= 400) return await response.json();
  return response;
};

const closeSession = () => {
  localStorage.removeItem("UserToken");
  localStorage.removeItem("UserData");
  window.location.href = "/";
};

export const baseProvider = {
  httpAuthPost: async (url, body = {}) => {
    const response = await fetch(buildUrl(url), {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        accept: " */*",
      },
    });
    const responseJson = await response.json();
    return responseJson;
  },
  httpPost: async (url, body = {}) => {
    const token = await getToken();
    if (!token) return {};
    const response = await fetch(buildUrl(url), {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        accept: " */*",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 401) {
      closeSession();
    }
    const responseJson = await response.json();
    return responseJson;
  },

  httpGet: async (url, params = {}) => {
    const token = await getToken();
    if (!token) return {};
    const response = await fetch(buildUrl(url, params), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: " */*",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 401) {
      closeSession();
    }
    const responseJson = await response.json();
    return responseJson;
  },

  httpGetPrueba: async (url, search, params) => {
    const token = await getToken();
    const BASE_PATH = process.env.REACT_APP_ARIANE_BFF_URL;
    if (!token) return {};
    const statusParams = params.join(",");
    let urlnuevo = `${BASE_PATH}${url}?search=${search}&status__in=${statusParams}`;
    const response = await fetch(urlnuevo, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: " */*",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 401) {
      closeSession();
    }
    const responseJson = await response.json();
    return responseJson;
  },

  httpGetFilter: async (url, search, params) => {
    const token = await getToken();
    const BASE_PATH = process.env.REACT_APP_ARIANE_BFF_URL;
    if (!token) return {};
    let urlnuevo = `${BASE_PATH}${url}?search=${search}${
      params ? "&is_amount_changed=true" : ""
    }`;
    const response = await fetch(urlnuevo, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: " */*",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 401) {
      closeSession();
    }
    const responseJson = await response.json();
    return responseJson;
  },

  httpDelete: async (url, body = {}) => {
    const token = await getToken();
    if (!token) return {};
    const response = await fetch(buildUrl(url), {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        accept: " */*",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 401) {
      closeSession();
    }
    return response;
  },
  httpPut: async (url, body = {}) => {
    const token = await getToken();
    if (!token) return {};
    const response = await fetch(buildUrl(url), {
      method: "PUT",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        accept: " */*",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 401) {
      closeSession();
    }
    const responseJson = await response.json();
    return responseJson;
  },
  httpPostForm: async (url, formData) => {
    const token = await getToken();
    if (!token) return {};
    const response = await fetch(buildUrl(url), {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      body: formData,
    });
    if (response.status === 401) {
      closeSession();
    }
    const responseJson = await response.json();
    return responseJson;
  },
  httpPatchForm: async (url, formData) => {
    const token = await getToken();
    if (!token) return {};
    const response = await fetch(buildUrl(url), {
      method: "PATCH",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 401) {
      closeSession();
    }
    const responseJson = await response.json();
    return responseJson;
  },
  httpPatch: async (url, body = {}) => {
    const token = await getToken();
    if (!token) return {};
    const response = await fetch(buildUrl(url), {
      method: "PATCH",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        accept: " */*",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 401) {
      closeSession();
    }
    const responseJson = await response.json();
    return responseJson;
  },
  httpGetFile: async (url, params = {}) => {
    const token = await getToken();
    if (!token) return {};
    const response = await fetch(buildUrl(url, params), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: " */*",
        Authorization: `Bearer ${token}`,
      },
    });
    return validate(response);
  },
};
