//* React import
import React from "react";

//* Components import
// Third party components
import { Row } from "reactstrap";
// Custom components
import MainButtonsPanel from "./components/MainButtonsPanel";
import Counter from "./components/Counter";
import ProjectInfoPanel from "./components/ProjectInfoPanel";

export const Qrome = ({
  user,
  presentValorization,
  project,
  preadditional,
  sendpresentValorization,
  evaluatepresentValorization,
  loadFinalReportValorization,
  setDebtModalActive,
}) => {
  const getCurrentUTCDate = () => {
    const now = new Date();
    const date = now.getUTCDate().toString().padStart(2, "0");
    const month = (now.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = now.getUTCFullYear().toString().slice(-2);
    return `${date}.${month}.${year}`;
  };

  const datenow = getCurrentUTCDate();

  let ageTitle = "";

  const ageTitleGet = () => {
    if (presentValorization.in_meter_entering) {
      ageTitle = "Ingreso de metrado";
    }
    if (presentValorization.in_meter_validation) {
      ageTitle = "Validación de metrado";
    }
    if (presentValorization.in_differences_closing) {
      ageTitle = "Cierre de diferencias";
    }
  };

  ageTitleGet();

  return (
    <Row className="ramp projectVal__qrome">
      <MainButtonsPanel
        user={user}
        project={project}
        setDebtModalActive={setDebtModalActive}
        presentValorization={presentValorization}
        loadFinalReportValorization={loadFinalReportValorization}
        sendpresentValorization={sendpresentValorization}
        evaluatepresentValorization={evaluatepresentValorization}
      />
      <Counter
        title={`Adicional N° ${preadditional.order}`}
        subtitle={preadditional.name}
      />
      <Counter
        title={`Etapa de valorización N° ${presentValorization.order}`}
        subtitle={ageTitle}
      />
      <ProjectInfoPanel
        dateToday={datenow}
        preadditional={preadditional}
      ></ProjectInfoPanel>
    </Row>
  );
};
