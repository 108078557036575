import React, { useState, useEffect } from "react";

import * as FileSaver from "file-saver";

import { ToTalkModalSkeleton } from "../../Skeletons";
import { PaymentCardSummary } from "./PaymentCardSummary";
import { Button } from "../../Button";

import {
  getPaymentById,
  getDocumentsByPaymentId,
  getPaymentDocumentById,
} from "../../../providers";



const PaymentSummary = ({
  closePaymentSummary,
  paymentSummary
}) => {
  const [charged, setCharged] = useState(false);

  //file names
  const [files, setFiles] = useState([]);
  const [payment, setPayment] = useState({
    amount: 0.0,
    currency: 1,
    method: "",
    date: "13/05/2022",
    code: "",
  });

  useEffect(() => {
    const fetchPaymentInfo = async () => {
      try {
        setCharged(true);
        const _payment = await getPaymentById(paymentSummary);
        setPayment(_payment);
        const _paymentDocuments = await getDocumentsByPaymentId(paymentSummary);
        setFiles(_paymentDocuments.results);
        setCharged(false);
      } catch (error) {
        console.log(error);
        setCharged(false);
      }
    };
    paymentSummary && fetchPaymentInfo();
  }, [paymentSummary]);

  const getPaymentDocument = async (document_id, name) => {
    try {
      const debt_document = await getPaymentDocumentById(document_id);
      const blob = await debt_document.blob();
      FileSaver.saveAs(blob, name);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {charged ? (
        <ToTalkModalSkeleton />
      ) : (
        <div>
          <p className="projectVal__modal__description">
            En este sección podrá visualizar el pago realizado.
          </p>
          <div className="projectVal__modal__cardsContainer">
            <PaymentCardSummary
              payment={payment}
              files={files}
              getPaymentDocument={getPaymentDocument}
            />
          </div>
          <div className="projectVal__modal__btns">
            <Button
              background="#D93022"
              width="122px"
              fontWeight="600"
              boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
              onClick={() => closePaymentSummary()}
            >
              Regresar
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentSummary;
