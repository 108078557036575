// React import
import React, { useState } from "react";

// Components import
import Button from "../../../../../shared/components/Button";

// Styled components import
import {
  ButtonContainer,
  Indication,
  ReportSelectContainer,
  ReportOptionContainer,
  ReportTypeLabel,
  StyledCheckbox,
} from "./styles";

export default function LiquidationSetting({ onConfirm }) {
  // Component states
  const [reportType, setReportType] = useState(0);

  return (
    <>
      <Indication>
        Si el estado actual de las valorizaciones, adicionales de obra y
        deductivos de obra es el esperado proceda a generar el informe de
        liquidación de obra, de lo contrario, antes de generar el informe, haga
        los ajustes necesarios a los elementos deseados hasta que los estados
        sean los esperados.
      </Indication>
      <Indication>A continuación elija el tipo de informe deseado:</Indication>
      <ReportSelectContainer>
        <ReportOptionContainer>
          <StyledCheckbox
            checked={reportType === 1}
            onChange={() => {
              if (reportType === 1) {
                setReportType(0);
              } else {
                setReportType(1);
              }
            }}
          />
          <ReportTypeLabel>
            {" "}
            <b>Light:</b> Informe con el mínimo de información, monto final de
            liquidación{" "}
          </ReportTypeLabel>
        </ReportOptionContainer>
        <ReportOptionContainer>
          <StyledCheckbox
            checked={reportType === 2}
            onChange={() => {
              if (reportType === 2) {
                setReportType(0);
              } else {
                setReportType(2);
              }
            }}
          />
          <ReportTypeLabel>
            <b>Equilibrado</b>{" "}
          </ReportTypeLabel>
        </ReportOptionContainer>
        <ReportOptionContainer>
          <StyledCheckbox
            checked={reportType === 3}
            onChange={() => {
              if (reportType === 3) {
                setReportType(0);
              } else {
                setReportType(3);
              }
            }}
          />
          <ReportTypeLabel>
            <b>Full:</b> Informe incluyendo todos los recibos, facturas y fotos
            en formato amigable
          </ReportTypeLabel>
        </ReportOptionContainer>
      </ReportSelectContainer>
      <ButtonContainer>
        <Button
          background={reportType > 0 ? "#05BE8C" : "#818181"}
          width="300px"
          fontWeight="600"
          margin="0 0 1rem 0"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
          onClick={() => {
            if (reportType > 0) {
              onConfirm();
            }
          }}
        >
          Generar informe de liquidación de obra
        </Button>
      </ButtonContainer>
    </>
  );
}
