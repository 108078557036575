import { baseProvider } from "../baseProvider";

const getLiquidationPreliminarReport = async (id) =>
  baseProvider.httpGetFile(`/projects/${id}/liquidation/preliminar_report`);

const sendLiquidationPreliminarReport = async (id, body) =>
  baseProvider.httpPost(
    `/projects/${id}/liquidation/preliminar_report/send`,
    body
  );

const getLiquidationFinalReport = async (id) =>
  baseProvider.httpGetFile(`/projects/${id}/liquidation/final_report`);

const closeLiquidation = async (id) =>
  baseProvider.httpPost(`/projects/${id}/liquidation/execute`);

const validateLiquidation = async (id) =>
  baseProvider.httpGet(`/projects/${id}/liquidation/validation`);

const getLiquidationAdvices = async (id) =>
  baseProvider.httpGet(`/projects/${id}/liquidation/advices`);

export {
  getLiquidationPreliminarReport,
  sendLiquidationPreliminarReport,
  getLiquidationFinalReport,
  closeLiquidation,
  validateLiquidation,
  getLiquidationAdvices,
};
