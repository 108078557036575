import appFetcher from "../../App/utils/http/AppFetcher";

export const getTimelineService = async (id, errorHandler, successHandler) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/valorizations/${id}/timeline`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("no_found");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const getAmountDatesService = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/projects/${id}/amounts_dates`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("no_found");
    }
  } catch (error) {
    errorHandler("server");
  }
};

// Schedule edition

export const getScheduleEditionProposal = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/valorizations/${id}/schedule_edition`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "POST",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("no_found");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const editScheduleEditionProposal = async (
  id,
  body,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/valorizations/${id}/schedule_edition`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "PATCH",
      body: body,
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("bad_request", response);
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const approveScheduleEditionProposalDate = async (
  id,
  body,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/valorizations/${id}/schedule_edition/approve`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "POST",
      body: body,
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("bad_request", response);
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const rejectScheduleEditionProposalDate = async (
  id,
  body,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/valorizations/${id}/schedule_edition/reject`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "POST",
      body: body,
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("bad_request", response);
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const applyScheduleEditionProposal = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/valorizations/${id}/schedule_edition/apply`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "POST",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("bad_request", response);
    }
  } catch (error) {
    errorHandler("server");
  }
};

// Preadditional services

export const getProjectPreAdditionals = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/projects/${id}/preadditionals`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("no_found");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const getPreAdditional = async (id, errorHandler, successHandler) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/preadditionals/${id}`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("no_found");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const createPreAdditional = async (
  id,
  body,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/projects/${id}/preadditionals`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "POST",
      body: body,
    });
    if (status === 201) {
      successHandler(response);
    } else {
      if (status === 403) {
        errorHandler("limit_exceeded", response.detail);
      } else {
        errorHandler("server");
      }
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const updatePreAdditional = async (
  id,
  body,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/preadditionals/${id}`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "PATCH",
      body: body,
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("server");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const deletePreAdditional = async (id, errorHandler, successHandler) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/preadditionals/${id}`,
      isAuthRequired: true,
      method: "DELETE",
    });
    if (status === 204) {
      successHandler(response);
    } else {
      errorHandler("server");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const updatePreAdditionalAPU = async (
  id,
  body,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/preadditionals/${id}`,
      isAuthRequired: true,
      requestContentType: "multipart/form-data",
      responseContentType: "json",
      method: "PATCH",
      body: body,
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("bad_request", response);
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const rejectPreAdditional = async (id, errorHandler, successHandler) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/preadditionals/${id}/reject`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "POST",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("no_found");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const approvePreAdditional = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/preadditionals/${id}/approve`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "POST",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("no_found");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const getPreAdditionalAPU = async (id, errorHandler, successHandler) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/preadditionals/${id}/apu`,
      isAuthRequired: true,
      responseContentType: "blob",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("no_found");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const getPreAdditionalBudget = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/preadditionals/${id}/budget`,
      isAuthRequired: true,
      responseContentType: "blob",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("no_found");
    }
  } catch (error) {
    errorHandler("server");
  }
};

// Predeductive services

export const getProjectPreDeductives = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/projects/${id}/predeductives`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("no_found");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const getPreDeductive = async (id, errorHandler, successHandler) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/predeductives/${id}`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("no_found");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const createPreDeductive = async (
  id,
  body,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/projects/${id}/predeductives`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "POST",
      body: body,
    });
    if (status === 201) {
      successHandler(response);
    } else {
      if (status === 403) {
        errorHandler("limit_exceeded", response.detail);
      } else {
        errorHandler("server");
      }
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const updatePreDeductive = async (
  id,
  body,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/predeductives/${id}`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "PATCH",
      body: body,
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("server");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const deletePreDeductive = async (id, errorHandler, successHandler) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/predeductives/${id}`,
      isAuthRequired: true,
      method: "DELETE",
    });
    if (status === 204) {
      successHandler(response);
    } else {
      errorHandler("server");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const rejectPreDeductive = async (id, errorHandler, successHandler) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/predeductives/${id}/reject`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "POST",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("no_found");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const approvePreDeductive = async (id, errorHandler, successHandler) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/predeductives/${id}/approve`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "POST",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("no_found");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const getPreDeductiveBudget = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/predeductives/${id}/budget`,
      isAuthRequired: true,
      responseContentType: "blob",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("no_found");
    }
  } catch (error) {
    errorHandler("server");
  }
};

// Util services

export const getCalendarTypes = async (errorHandler, successHandler) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/calendar_types`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("no_found");
    }
  } catch (error) {
    errorHandler("server");
  }
};

//Project Delivery Creation
export const createProjectDelivery = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/projects/${id}/project_delivery`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "POST",
      body: {},
    });
    if (status === 201) {
      successHandler(response);
    } else {
      if (status === 403) {
        errorHandler(response);
      } else {
        errorHandler(response);
      }
    }
  } catch (error) {
    errorHandler(null);
  }
};

// Group send validation
export const sendAllValorizationValorizedParts = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/valorizations/${id}/valorized_parts/group_send`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "PATCH",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("no_found");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const evaluateAllValorizationValorizedParts = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/valorizations/${id}/valorized_parts/group_validate`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "PATCH",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("other", response);
    }
  } catch (error) {
    errorHandler("server");
  }
};
