import React, { useState } from "react";

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import { TextPanel } from "./styles";
import { Button } from "../../../../../components/Button";

const Qrome2 = () => {
  // Select Valorizations
  const [dropdown, setDropdown] = useState(false);

  const abrirCerrarDropdown = () => {
    setDropdown(!dropdown);
  };

  const CustomDropdown = () => {
    return (
      <Dropdown isOpen={dropdown} toggle={abrirCerrarDropdown}>
        <DropdownToggle caret style={{ width: "152px", height: "26px" }}>
          <span style={{ marginRight: "30px", marginLeft: "7px" }}>
            Valorización 1
          </span>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem key={1}>{`Valorización 1`}</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  return (
    <div className="valorization__partHeaders-base">
      <CustomDropdown />
      <Button
        width="152px"
        height="28px"
        background="#0169E4"
        boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
        borderRadius="4px 4px 0px 0px"
        margin="0px 1px"
        fontSize="12px"
      >
        Partidas de Valorizacion
      </Button>
      <TextPanel />
    </div>
  );
};

export default Qrome2;
