import React from "react";
import { Input, Select, notification } from "antd";

// Components import
import Item from "../../../../shared/components/Item";
import Button from "../../../../shared/components/Button";

// Styled components import
import { DeductiveSettingContainer, ButtonContainer } from "./styles";

// Services import
import { updatePreDeductive } from "../../../services";

// Utils import
import { parseFloatNumber } from "../../../../../utils";

// Select options
const filterOptions = [
  { label: "Todas las partidas", value: false },
  { label: "Con nuevos metrados", value: true },
];

const DeductiveSetting = ({
  predeductive,
  setPredeductive,
  setIsLoading,
  enableEdition = true,
  setFilterActive,
  filterActive,
}) => {
  const { TextArea } = Input;

  // Predeductive update

  const predeductiveUpdateErrorHandler = (error) => {
    setIsLoading(false);
    notification.error({
      message:
        "Ocurrió un error en la actualización de la propuesta de deductivo",
    });
  };

  const predeductiveUpdateSuccessHandler = (response) => {
    setIsLoading(false);
    notification.success({
      message: "Se actualizó la propuesta de deductivo correctamente",
    });
  };

  const saveDeductiveSettings = async () => {
    setIsLoading(true);
    await updatePreDeductive(
      predeductive.id,
      { name: predeductive.name, reason: predeductive.reason },
      predeductiveUpdateErrorHandler,
      predeductiveUpdateSuccessHandler
    );
  };

  return (
    <DeductiveSettingContainer>
      <>
        <p className="blue bold font14 projectVal__mb1">Filtro de partidas</p>
        <Select
          style={{ width: "100%" }}
          value={filterActive}
          onChange={(value) => {
            setFilterActive(value);
          }}
          options={filterOptions}
          disabled={!enableEdition}
        />
      </>
      <Item
        title="Nombre"
        content={predeductive.name}
        width="100%"
        onChange={(value) => {
          setPredeductive({ ...predeductive, name: value });
        }}
        disabled={!enableEdition}
        marginBottom={"20px"}
      />
      <>
        <p className="blue bold font14 projectVal__mb1">Justificación:</p>
        <TextArea
          value={predeductive.reason}
          onFocus={(event) => event.target.select()}
          onChange={(event) => {
            setPredeductive({ ...predeductive, reason: event.target.value });
          }}
          rows={4}
          className="projectVal__mb1"
          placeholder="Razón de la creación del deductivo"
          disabled={!enableEdition}
        />
      </>
      <ButtonContainer>
        <Button
          background={enableEdition ? "#05BE8C" : "#76817E"}
          width="140px"
          fontWeight="600"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
          onClick={() => {
            saveDeductiveSettings();
          }}
        >
          Guardar
        </Button>
      </ButtonContainer>
      <div
        style={{ textAlign: "center", marginBottom: "20px", marginTop: "20px" }}
      >
        <p style={{ marginBottom: "-1px", color: "#000" }}>
          <b>Monto total del deductivo</b>
        </p>
        <b style={{ color: "#000" }}>{`S/. ${parseFloatNumber(
          predeductive.original_project_amount -
            predeductive.proposal_project_amount
        )}`}</b>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "20px", marginTop: "20px" }}
      >
        <p style={{ marginBottom: "-1px", color: "#000" }}>
          <b>Porcentaje del presupuesto</b>
        </p>
        <b style={{ color: "#000" }}>{`${parseFloatNumber(
          ((predeductive.original_project_amount -
            predeductive.proposal_project_amount) *
            100) /
            predeductive.original_project_amount
        )}%`}</b>
      </div>
    </DeductiveSettingContainer>
  );
};

export default DeductiveSetting;
