//* React import
import React from "react";

//* Third party libraries import
import * as FileSaver from "file-saver";

//* Components import
// Third party components
import { Input } from "antd";
import { GiPaperClip } from "react-icons/gi";
import { IoCameraOutline } from "react-icons/io5";
import { AiOutlineDelete, AiFillDelete } from "react-icons/ai";
import { MdCloudDownload } from "react-icons/md";
import { IconContext } from "react-icons";
// Custom components
import { Item } from "./Item";
import { UserPhoto } from "./UserPhoto";

//* Providers import
import {
  patchAdditionalImagePart,
  getCommentFileFromAdditionalValorizedPart,
} from "../../../../../providers";

export const Card = ({
  quantity,
  percentage,
  mount,
  comments,
  setQuantity,
  setPercentage,
  setComments,
  img,
  color,
  isThereComment,
  disabled,
  id,
  editPart,
  initial,
  setToggleUpdatePart,
  existFile,
  existImage,
}) => {
  const { TextArea } = Input;

  const activate_input_file = () => {
    let element = document.getElementById("ChargeFile");
    element.click();
  };

  const editComment = async (e, type) => {
    let body = new FormData();
    body.append(type, e.target.files[0]);
    try {
      const response = await patchAdditionalImagePart(id, body);
      if (response.id) {
        setToggleUpdatePart((prev) => !prev);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const activate_input_image = () => {
    let element = document.getElementById("ChargeImage");
    element.click();
  };

  const handle_editPart = (body) => editPart(id, body, false);

  const downloadFile = async () => {
    try {
      const response = await getCommentFileFromAdditionalValorizedPart(id);
      response.blob().then((res) => {
        FileSaver.saveAs(res, existFile);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="projectVal__card__container">
      <div style={{ width: "80%" }}>
        <div className="projectVal__card__items">
          <Item
            title={initial ? "Metrado inicial" : "Metrado corregido"}
            content={quantity}
            setContent={setQuantity}
            width="129px"
            disabled={disabled}
            onBlur={() => handle_editPart({ comment_quantity: quantity })}
          />
          <Item
            title="% de"
            content={percentage}
            setContent={setPercentage}
            width="75px"
            disabled={disabled}
            onBlur={() =>
              handle_editPart({ comment_advance_percentage: percentage / 100 })
            }
          />
          <Item
            title="Monto"
            content={mount}
            width="122px"
            disabled={disabled}
          />
        </div>
        {isThereComment && (
          <div>
            <p></p>
            <div className="projectVal__card__items">
              <p className="blue bold font14 projectVal__mb1">Comentarios:</p>
              {!disabled && (
                <div>
                  <IconContext.Provider value={{ color: "#000000" }}>
                    <GiPaperClip
                      style={{ marginRight: "12px", cursor: "pointer" }}
                      size={16}
                      strokeWidth={12}
                      onClick={activate_input_file}
                    />
                  </IconContext.Provider>
                  <Input
                    type="file"
                    name="file"
                    id="ChargeFile"
                    onChange={(e) => editComment(e, "comment_file")}
                    hidden
                  />
                  <IconContext.Provider value={{ color: "#000000" }}>
                    <IoCameraOutline
                      style={{ cursor: "pointer" }}
                      size={17}
                      onClick={activate_input_image}
                    />
                  </IconContext.Provider>
                  <Input
                    type="file"
                    name="file"
                    id="ChargeImage"
                    onChange={(e) => editComment(e, "comment_image")}
                    hidden
                  />
                </div>
              )}
            </div>
            <TextArea
              value={comments}
              onFocus={(event) => event.target.select()}
              onChange={(e) => setComments(e.target.value)}
              onBlur={() => editPart(id, { comments }, false)}
              rows={4}
              className="projectVal__mb1"
              placeholder="Indique porque rechaza la valorización del proyecto"
              disabled={disabled}
            />
            {existImage && (
              <div>
                <img
                  src={`${existImage["content-type"]};base64, ${existImage.data}`}
                  alt="imgProv"
                  className="projectVal__card__image"
                />
                <div style={{ display: "flex", height: "10px" }}>
                  <div
                    className="projectVal__card__editImg"
                    style={{ left: `${disabled ? "230px" : "200px"}` }}
                  >
                    <a
                      href={`${existImage["content-type"]};base64,${existImage.data}`}
                      download="image_comment.png"
                    >
                      <IconContext.Provider
                        value={{ color: "rgba(95, 111, 129, 0.7)" }}
                      >
                        <MdCloudDownload
                          style={{
                            cursor: "pointer",
                            marginTop: "-2px",
                            marginLeft: "5px",
                          }}
                          className=""
                          size={20}
                        />
                      </IconContext.Provider>
                    </a>
                  </div>

                  {!disabled && (
                    <div className="projectVal__card__editImg projectVal__card__delete">
                      <IconContext.Provider
                        value={{ color: "rgba(95, 111, 129, 0.7)" }}
                      >
                        <AiFillDelete
                          style={{
                            cursor: "pointer",
                            marginTop: "-2px",
                            marginLeft: "5px",
                          }}
                          className=""
                          size={20}
                          onClick={() =>
                            handle_editPart({ comment_image: null })
                          }
                        />
                      </IconContext.Provider>
                    </div>
                  )}
                </div>
              </div>
            )}
            {existFile && (
              <div>
                Archivo adjunto:{" "}
                <span
                  onClick={downloadFile}
                  className="editProject__underline blue-light "
                >
                  {existFile}
                </span>
                {!disabled && (
                  <IconContext.Provider value={{ color: "#D93022" }}>
                    <AiOutlineDelete
                      style={{
                        cursor: "pointer",
                        marginTop: "-2px",
                        marginLeft: "5px",
                      }}
                      className=""
                      size={20}
                      onClick={() => handle_editPart({ comment_file: null })}
                    />
                  </IconContext.Provider>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <UserPhoto img={img} color={color} />
    </div>
  );
};
