import appFetcher from "../../../../../../App/utils/http/AppFetcher";

export const projectCreateService = async (
  body,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: "/projects",
      isAuthRequired: true,
      requestContentType: "application/json",
      body: body,
      responseContentType: "json",
      method: "POST",
    });
    if (status === 201) {
      successHandler(response.id);
    } else {
      errorHandler("bad_creation", response?.detail || "");
    }
  } catch (error) {
    errorHandler("server", "");
  }
};

export const getConstructions = async (
  queryParams = {},
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: "/constructions",
      isAuthRequired: true,
      queryParams: queryParams,
      requestContentType: "application/json",
      responseContentType: "json",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response.results);
    } else {
      errorHandler("server");
    }
  } catch (error) {
    errorHandler("server");
  }
};
