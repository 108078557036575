import appFetcher from "../../../modules/App/utils/http/AppFetcher";

// Approbation services

export const approveStartProjectDelivery = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/project_delivery/${id}/start_delivery/approve`,
      body: {},
      isAuthRequired: true,
      responseContentType: "json",
      method: "POST",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      if (status === 401) {
        errorHandler(response);
      } else {
        errorHandler(response);
      }
    }
  } catch (error) {
    errorHandler(null);
  }
};

export const approveProjectDeliveryMinute = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/project_delivery/${id}/delivery_minute/approve`,
      body: {},
      isAuthRequired: true,
      responseContentType: "json",
      method: "POST",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      if (status === 401) {
        errorHandler(response);
      } else {
        errorHandler(response);
      }
    }
  } catch (error) {
    errorHandler(null);
  }
};

export const approveProjectDeliveryObservationsListPeriod = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/project_delivery/${id}/observations_list_period/approve`,
      body: {},
      isAuthRequired: true,
      responseContentType: "json",
      method: "POST",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      if (status === 401) {
        errorHandler(response);
      } else {
        errorHandler(response);
      }
    }
  } catch (error) {
    errorHandler(null);
  }
};

export const approveProjectDeliveryObservationsList = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/project_delivery/${id}/observations_list/approve`,
      body: {},
      isAuthRequired: true,
      responseContentType: "json",
      method: "POST",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      if (status === 401) {
        errorHandler(response);
      } else {
        errorHandler(response);
      }
    }
  } catch (error) {
    errorHandler(null);
  }
};

export const approveProjectDeliverySolvingPeriod = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/project_delivery/${id}/observations_solving_period/approve`,
      body: {},
      isAuthRequired: true,
      responseContentType: "json",
      method: "POST",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      if (status === 401) {
        errorHandler(response);
      } else {
        errorHandler(response);
      }
    }
  } catch (error) {
    errorHandler(null);
  }
};

export const approveFinishProjectDelivery = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/project_delivery/${id}/finish_delivery/approve`,
      body: {},
      isAuthRequired: true,
      responseContentType: "json",
      method: "POST",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      if (status === 401) {
        errorHandler(response);
      } else {
        errorHandler(response);
      }
    }
  } catch (error) {
    errorHandler(null);
  }
};

// Load Services

export const updateProjectDeliveryMinute = async (
  id,
  body,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/project_delivery/${id}/delivery_minute`,
      isAuthRequired: true,
      requestContentType: "multipart/form-data",
      responseContentType: "json",
      method: "PATCH",
      body: body,
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler(response);
    }
  } catch (error) {
    errorHandler(null);
  }
};

export const updateObservationsListPeriod = async (
  id,
  body,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/project_delivery/${id}/observations_list_period`,
      isAuthRequired: true,
      requestContentType: "multipart/form-data",
      responseContentType: "json",
      method: "PATCH",
      body: body,
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler(response);
    }
  } catch (error) {
    errorHandler(null);
  }
};

export const updateObservationsList = async (
  id,
  body,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/project_delivery/${id}/observations_list`,
      isAuthRequired: true,
      requestContentType: "multipart/form-data",
      responseContentType: "json",
      method: "PATCH",
      body: body,
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler(response);
    }
  } catch (error) {
    errorHandler(null);
  }
};

export const updateObservationsSolvingPeriod = async (
  id,
  body,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/project_delivery/${id}/observations_solving_period`,
      isAuthRequired: true,
      requestContentType: "multipart/form-data",
      responseContentType: "json",
      method: "PATCH",
      body: body,
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler(response);
    }
  } catch (error) {
    errorHandler(null);
  }
};

export const updateFinishProjectDelivery = async (
  id,
  body,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/project_delivery/${id}/finish_delivery`,
      isAuthRequired: true,
      requestContentType: "multipart/form-data",
      responseContentType: "json",
      method: "PATCH",
      body: body,
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler(response);
    }
  } catch (error) {
    errorHandler(null);
  }
};

export const getProjectDeliveryMinuteDocument = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/project_delivery/${id}/minute`,
      isAuthRequired: true,
      responseContentType: "blob",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler(response);
    }
  } catch (error) {
    errorHandler(null);
  }
};

export const getObservationsListDocument = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/project_delivery/${id}/observations_list`,
      isAuthRequired: true,
      responseContentType: "blob",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler(response);
    }
  } catch (error) {
    errorHandler(null);
  }
};

export const getFinalProjectDeliveryMinuteDocument = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/project_delivery/${id}/final_minute`,
      isAuthRequired: true,
      responseContentType: "blob",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler(response);
    }
  } catch (error) {
    errorHandler(null);
  }
};

// Hidden vices
export const getHiddenVices = async (id, errorHandler, successHandler) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/project_delivery/${id}/hidden_vices`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("no_found");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const getHiddenVice = async (id, errorHandler, successHandler) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/hidden_vices/${id}`,
      isAuthRequired: true,
      responseContentType: "json",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler("no_found");
    }
  } catch (error) {
    errorHandler("server");
  }
};

export const updateHiddenViceStart = async (
  id,
  body,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/hidden_vices/${id}/start`,
      isAuthRequired: true,
      requestContentType: "multipart/form-data",
      responseContentType: "json",
      method: "PATCH",
      body: body,
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler(response);
    }
  } catch (error) {
    errorHandler(null);
  }
};

export const updateHiddenViceObservationsSolvingPeriod = async (
  id,
  body,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/hidden_vices/${id}/observations_solving_period`,
      isAuthRequired: true,
      requestContentType: "multipart/form-data",
      responseContentType: "json",
      method: "PATCH",
      body: body,
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler(response);
    }
  } catch (error) {
    errorHandler(null);
  }
};

export const createHiddenVice = async (id, errorHandler, successHandler) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/project_delivery/${id}/hidden_vices`,
      body: {},
      isAuthRequired: true,
      responseContentType: "json",
      method: "POST",
    });
    if (status === 201) {
      successHandler(response);
    } else {
      if (status === 401) {
        errorHandler(response);
      } else {
        errorHandler(response);
      }
    }
  } catch (error) {
    errorHandler(null);
  }
};

export const approveHiddenViceStart = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/hidden_vices/${id}/start/approve`,
      body: {},
      isAuthRequired: true,
      responseContentType: "json",
      method: "POST",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      if (status === 401) {
        errorHandler(response);
      } else {
        errorHandler(response);
      }
    }
  } catch (error) {
    errorHandler(null);
  }
};

export const approveHiddenViceObservationsSolvingPeriod = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/hidden_vices/${id}/observations_solving_period/approve`,
      body: {},
      isAuthRequired: true,
      responseContentType: "json",
      method: "POST",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      if (status === 401) {
        errorHandler(response);
      } else {
        errorHandler(response);
      }
    }
  } catch (error) {
    errorHandler(null);
  }
};

export const approveHiddenViceFinish = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/hidden_vices/${id}/finish/approve`,
      body: {},
      isAuthRequired: true,
      responseContentType: "json",
      method: "POST",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      if (status === 401) {
        errorHandler(response);
      } else {
        errorHandler(response);
      }
    }
  } catch (error) {
    errorHandler(null);
  }
};

export const getHiddenViceObservationsListDocument = async (
  id,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/hidden_vices/${id}/observations_list`,
      isAuthRequired: true,
      responseContentType: "blob",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler(response);
    }
  } catch (error) {
    errorHandler(null);
  }
};
