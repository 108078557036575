import React from "react";
import "./styles.css";
import TrashIcon from "./assets/trash_icon.svg";
import { filenameFormat } from "../../../../utils";

const FileList = ({ files, deleteDebtDocument, getDebtDocument }) => {
  return (
    <div className="debt_file_list">
      {files.map((file) => (
        <div className="debt_file_item">
          <p
            className="debt_file_item_text"
            key={file.id}
            onClick={() => {
              getDebtDocument(file.id,file.name);
            }}
          >
            {filenameFormat(file.name)}
          </p>
          <img
            className="debt_file_delete_icon"
            src={TrashIcon}
            alt="debt_trash_icon"
            onClick={() => deleteDebtDocument(file.id)}
          />
        </div>
      ))}
    </div>
  );
};

export default FileList;
