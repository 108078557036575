import styled from "styled-components";
import {
  Container,
  Row,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { MdPersonOutline } from "react-icons/md";

export const NavbarContainer = styled(Container)`
  margin: 0 !important;
  padding: 0 !important;
  background-color: white;
  width: 100%;
  text-align: center;
  filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.1));
  position: absolute;
  z-index: 500;
`;

export const NavbarRow = styled(Row)`
  margin: 0 !important;
  padding: 0 !important;
`;

export const NavbarHeadingsContainer = styled(Col)`
  margin: 0 !important;
  padding: 0 !important;
  text-align: left;
  display: flex;
  flex-wrap: nowrap;
`;

export const NavbarNotificationsContainer = styled(Col)`
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  flex-direction: row-reverse;
`;

export const NavbarMenu = styled.div`
  height: 50px;
  width: 60px;
  text-align: center;
  padding-left: 0px;
  margin-left: 0px;
  cursor: pointer;
`;

export const NavbarLogo = styled.div`
  height: 30px;
  width: 34px;
  margin: auto 10px;
  @media (max-width: 380px) {
    margin-left: 0px;
  }
`;

export const NavbarProfileDropdown = styled(Dropdown)`
  margin: auto 0px;
`;

export const NavbarNotification = styled.div`
  height: 32px;
  width: 32px;
  background-color: #004283;
  border-radius: 50%;
  margin: auto 20px auto 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 380px) {
    margin-right: 8px;
  }
`;

export const NavbarProfileImage = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  cursor: pointer;
`;

export const NavbarProfileIcon = styled(MdPersonOutline)`
  cursor: pointer;
`;

export const NavbarDropdownMenu = styled(DropdownMenu)`
  min-width: 100px !important;
  font-size: 14px !important;
  padding-left: 5px !important;
  font-weight: 500;
  cursor: pointer;
  transform: translate3d(-20px, 40px, 0px) !important;
  z-index: 999 !important;
  position: absolute !important;
`;

export const NavbarTitle = styled.p`
  margin: auto 20px auto 0px;
  @media (max-width: 700px) {
    display: none;
  }
`;

export const RoleDropdownContainer = styled.div`
  height: 50px;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RoleDropdown = styled(Dropdown)`
  .btn-secondary {
    border: 1px solid #d7d9db;
    background: #fcfcfc;
    color: #808080;
    box-shadow: none;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.01em;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
`;

export const RoleDropdownToggle = styled(DropdownToggle)`
  width: 154px;
  height: 26px;
`;

export const RoleDropdownItem = styled(DropdownItem)`
  height: 26px;
  color: #808080;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.01em;
  display: flex;
  justify-content: space-around;
`;
