// React import
import React, { useState, useEffect } from "react";

// Third part libraries import
import { Input, DatePicker, notification } from "antd";
import * as FileSaver from "file-saver";
import dayjs from "dayjs";
import moment from "moment";

// Components import
import Item from "../Item";
import ChargeFileButton from "../ChargeFileButton";
import FileItem from "../FileItem";
import ApprovalPanel from "../ApprovalPanel";
import Loading from "../../../../modules/shared/components/Loading";
import {
  ModalTitle,
  Modal,
  ModalBodyContainer,
  ItemContainer,
} from "./styles";

// Services import
import {
  getHiddenVice,
  updateHiddenViceStart,
  updateHiddenViceObservationsSolvingPeriod,
  getHiddenViceObservationsListDocument,
  approveHiddenViceStart,
  approveHiddenViceObservationsSolvingPeriod,
  approveHiddenViceFinish,
} from "../../services";

// Utils import
import { clientDateFormat, serverDateFormat } from "../../../../utils";

export default function HiddenViceDetailModal({
  hiddenViceDetailModalActive,
  setHiddenViceDetailModalActive,
  hiddenViceId,
  userRole,
  reloadHiddenVices,
}) {
  const [hiddenVice, setHiddenVice] = useState({
    name: "",
    description: "",
  });
  const [loading, setLoading] = useState(false);
  const [triggerHiddenVice, setTriggerHiddenVice] = useState(true);

  const { TextArea } = Input;

  const disableStartForm =
    !hiddenVice.start_hidden_vice_enabled ||
    userRole === "SUPERVISOR" ||
    userRole === "EXECUTOR";

  const disableObservationsSolvingForm =
    !hiddenVice.set_observations_solving_period_enabled ||
    userRole === "SUPERVISOR" ||
    userRole === "EXECUTOR";

  const [periodDuration, setPeriodDuration] = useState(1);

  const errorHandler = () => {
    setHiddenVice({});
    setLoading(false);
  };

  const successHandler = (response) => {
    setHiddenVice(response);
    setPeriodDuration(response.observations_solving_days);
    setLoading(false);
  };

  useEffect(() => {
    const fetchHiddenVice = async () => {
      setLoading(true);
      await getHiddenVice(hiddenViceId, errorHandler, successHandler);
    };
    fetchHiddenVice();
  }, [hiddenViceId, triggerHiddenVice]);

  // Handler update
  const updateSuccessHandler = (response) => {
    notification.success({
      message: "Se actualizo correctamente el vicio oculto",
    });
    setLoading(false);
    setTriggerHiddenVice(!triggerHiddenVice);
  };

  const updateErrorHandler = (response) => {
    notification.error({
      message: response
        ? Object.values(response)[0][0]
        : "Ocurrió un error al actualizar el vicio oculto",
    });
    setLoading(false);
  };

  const getStartUpdateBody = (_hiddenVice, label) => {
    let formData = new FormData();
    _hiddenVice.name &&
      label === "name" &&
      formData.append("name", _hiddenVice.name);
    _hiddenVice.description &&
      label === "description" &&
      formData.append("description", _hiddenVice.description);
    _hiddenVice.observations_list &&
      label === "observations_list" &&
      formData.append("observations_list", _hiddenVice.observations_list);
    return formData;
  };

  const updateHiddenViceStartHandler = async (item, label) => {
    setLoading(true);
    const _hiddenVice = {
      ...hiddenVice,
      [label]: item,
    };
    const updateBody = getStartUpdateBody(_hiddenVice, label);
    await updateHiddenViceStart(
      hiddenViceId,
      updateBody,
      updateErrorHandler,
      updateSuccessHandler
    );
  };

  const getObservationsSolvingUpdateBody = (_hiddenVice, label) => {
    let formData = new FormData();
    _hiddenVice.observations_solving_finish_date &&
      label === "observations_solving_finish_date" &&
      formData.append(
        "observations_solving_finish_date",
        _hiddenVice.observations_solving_finish_date
      );
    _hiddenVice.observations_solving_days &&
      label === "observations_solving_days" &&
      formData.append(
        "observations_solving_days",
        _hiddenVice.observations_solving_days
      );
    return formData;
  };

  const updateHiddenViceObservationsSolvingHandler = async (item, label) => {
    setLoading(true);
    const _hiddenVice = {
      ...hiddenVice,
      [label]: item,
    };
    const updateBody = getObservationsSolvingUpdateBody(_hiddenVice, label);
    await updateHiddenViceObservationsSolvingPeriod(
      hiddenViceId,
      updateBody,
      updateErrorHandler,
      updateSuccessHandler
    );
  };

  // Handler get document
  const documentGetErrorHandler = () => {
    notification.error({
      message: "Ocurrió un error en la descarga del listado de observaciones",
    });
  };

  const documentGetSuccessHandler = (response) => {
    FileSaver.saveAs(response, hiddenVice.observations_list_filename);
  };

  const getDocument = async (id) => {
    await getHiddenViceObservationsListDocument(
      id,
      documentGetErrorHandler,
      documentGetSuccessHandler
    );
  };

  // Handler approbation
  const approveSuccessHandler = (response) => {
    notification.success({
      message: Object.values(response)[0][0],
    });
    setLoading(false);
    setTriggerHiddenVice(!triggerHiddenVice);
    reloadHiddenVices();
  };

  const approveErrorHandler = (response) => {
    notification.error({
      message: response
        ? Object.values(response)[0][0]
        : "Ocurrió un error al realizar la aprobación",
    });
    setLoading(false);
  };

  const approveHiddenViceStartHandler = async () => {
    setLoading(true);
    await approveHiddenViceStart(
      hiddenViceId,
      approveErrorHandler,
      approveSuccessHandler
    );
  };

  const approveHiddenViceObservationsSolvingPeriodHandler = async () => {
    setLoading(true);
    await approveHiddenViceObservationsSolvingPeriod(
      hiddenViceId,
      approveErrorHandler,
      approveSuccessHandler
    );
  };

  const approveHiddenViceFinishHandler = async () => {
    setLoading(true);
    await approveHiddenViceFinish(
      hiddenViceId,
      approveErrorHandler,
      approveSuccessHandler
    );
  };

  return (
    <>
      <Modal
        title={<ModalTitle>{`Vicio oculto número ${hiddenViceId}`}</ModalTitle>}
        centered
        open={hiddenViceDetailModalActive}
        onCancel={() => {
          setHiddenViceDetailModalActive(false);
        }}
        footer={null}
        width={500}
      >
        <ModalBodyContainer>
          <Item
            title="Nombre"
            content={hiddenVice.name}
            width="100%"
            onChange={(value) => {
              setHiddenVice({ ...hiddenVice, name: value });
            }}
            onBlur={async () => {
              await updateHiddenViceStartHandler(hiddenVice.name, "name");
            }}
            disabled={disableStartForm}
            marginBottom={"20px"}
          />
          <>
            <p className="blue bold font14 projectVal__mb1">Descripción:</p>
            <TextArea
              value={hiddenVice.description}
              onFocus={(event) => event.target.select()}
              onChange={(event) => {
                setHiddenVice({
                  ...hiddenVice,
                  description: event.target.value,
                });
              }}
              onBlur={async () => {
                await updateHiddenViceStartHandler(
                  hiddenVice.description,
                  "description"
                );
              }}
              rows={4}
              className="projectVal__mb1"
              placeholder="Razón de la creación del vicio oculto"
              disabled={disableStartForm}
            />
          </>
          <div className="projectVal__card__items projectVal__mb4">
            <ChargeFileButton
              chargeDocument={async (file) => {
                !disableStartForm &&
                  (await updateHiddenViceStartHandler(
                    file,
                    "observations_list"
                  ));
              }}
            />
            <FileItem
              fileName={hiddenVice.observations_list_filename}
              getFile={() => getDocument(hiddenViceId)}
            />
          </div>
          <div className="projectVal__mb4">
            <ApprovalPanel
              label="Aceptar vicio oculto"
              approvalEnable={hiddenVice.start_hidden_vice_enabled}
              approveHandler={() => {
                approveHiddenViceStartHandler();
              }}
              userRole={userRole}
              superApproved={hiddenVice.super_approved_hidden_vice}
              execApproved={hiddenVice.exec_approved_hidden_vice}
            />
          </div>
          <ItemContainer>
            <p
              className="blue bold font14"
              style={{ marginBottom: "16px", marginTop: "16px" }}
            >
              Fecha de inicio de periodo:
            </p>
            <DatePicker
              value={dayjs(
                hiddenVice.observations_solving_start_date || new Date()
              )}
              onChange={async (value) => {}}
              format={clientDateFormat}
              disabled={true}
              style={{ width: "150px" }}
            />
          </ItemContainer>
          <ItemContainer>
            <p
              className="blue bold font14"
              style={{ marginBottom: "16px", marginTop: "16px" }}
            >
              Fecha de fin de periodo:
            </p>
            <DatePicker
              value={dayjs(
                hiddenVice.observations_solving_finish_date || new Date()
              )}
              onChange={async (value) => {
                await updateHiddenViceObservationsSolvingHandler(
                  moment(value.toDate()).format(serverDateFormat),
                  "observations_solving_finish_date"
                );
              }}
              format={clientDateFormat}
              disabled={disableObservationsSolvingForm}
              style={{ width: "150px" }}
            />
          </ItemContainer>
          <ItemContainer>
            <p
              className="blue bold font14"
              style={{ marginBottom: "16px", marginTop: "16px" }}
            >
              Duración de periodo:
            </p>
            <Input
              style={{ width: "70px" }}
              value={periodDuration}
              onFocus={(event) => event.target.select()}
              onChange={(event) => {
                setPeriodDuration(event.target.value.replace(/[^\d]/g, ""));
              }}
              onBlur={async () => {
                await updateHiddenViceObservationsSolvingHandler(
                  periodDuration,
                  "observations_solving_days"
                );
              }}
              disabled={disableObservationsSolvingForm}
            />
          </ItemContainer>
          <div className="projectVal__mb4">
            <ApprovalPanel
              label="Aprobar periodo de levantamiento de vicio oculto"
              approvalEnable={
                hiddenVice.set_observations_solving_period_enabled
              }
              approveHandler={() => {
                approveHiddenViceObservationsSolvingPeriodHandler();
              }}
              userRole={userRole}
              superApproved={
                hiddenVice.super_approved_observations_solving_period
              }
              execApproved={
                hiddenVice.exec_approved_observations_solving_period
              }
            />
          </div>
          <div className="projectVal__mb4">
            <ApprovalPanel
              label="Confirmar levantamiento de vicio oculto"
              approvalEnable={hiddenVice.finish_hidden_vice_enabled}
              approveHandler={() => {
                approveHiddenViceFinishHandler();
              }}
              userRole={userRole}
              superApproved={hiddenVice.super_approved_hidden_vice_closing}
              execApproved={hiddenVice.exec_approved_hidden_vice_closing}
            />
          </div>
        </ModalBodyContainer>
        <Loading active={loading} />
      </Modal>
    </>
  );
}
