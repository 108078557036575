import styled from "styled-components";

export const ProjectDeliverySummaryTableContainer = styled.div`
  margin-top: 0.5rem;
  width: 100%;
  height: 100%;
  margin-bottom: 0.5rem;
  overflow-y: hidden;
  overflow-x: hidden;
`;
