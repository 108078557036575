import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

//pages
import ProjectDeductivesPage from "../pages/ProjectDeductivesPage";
import ProjectPreDeductivesPage from "../pages/ProjectPreDeductivesPage";

const ProjectDeductivesRouter = () => {
  return (
    <Switch>
      <Route
        exact
        path="/deductives/:id/predeductives"
        component={ProjectPreDeductivesPage}
      />
      <Route
        exact
        path="/deductives/:id/deductives"
        component={ProjectDeductivesPage}
      />
      <Redirect to="/main" />
    </Switch>
  );
};

export default ProjectDeductivesRouter;
