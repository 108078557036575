// React import
import React, { useState, useEffect } from "react";

// Support libraries import
import { notification, Popconfirm, Input } from "antd";
import * as FileSaver from "file-saver";

// Components import
import ChargeFileButton from "../ChargeFileButton";
import FileItem from "../FileItem";

// Services import
import {
  updatePreAdditionalBudget,
  getPreAdditionalBudget,
  updatePreAdditionalGeneralExpenses,
  getPreAdditionalGeneralExpenses,
  validatePreAdditionalBudget,
  validatePreAdditionalGeneralExpenses,
  updatePreAdditional,
} from "../../../../../services";

// Utils import
import { parseFloatNumber } from "../../../../../../../utils";

// Styles import
import { FileRowContainer, Container } from "./styles";

const DocumentManagerSection = ({
  project,
  preadditional,
  setIsLoading,
  reloadPreadditionalPage,
  reloadParts,
}) => {
  // States
  const [enableEdition, setEnableEdition] = useState(false);

  const [generalExpensesPercentage, setGeneralExpensesPercentage] = useState(
    `${preadditional.general_expenses_percentage * 100}%`
  );

  useEffect(() => {
    setGeneralExpensesPercentage(
      `${preadditional.general_expenses_percentage * 100}%`
    );
    setEnableEdition(getPreadditionalEditionStatus(preadditional));
  }, [preadditional, project]);

  const getPreadditionalEditionStatus = (preadditional) => {
    let enableEdition = false;
    if (
      preadditional.status === "REJECTED" ||
      preadditional.status === "VALIDATED"
    ) {
      enableEdition = false;
    } else {
      if (
        project.role === "EXECUTOR_LEADER" &&
        preadditional.approver_role === "SUPERVISOR"
      ) {
        enableEdition = true;
      }
      if (
        project.role === "SUPERVISOR_LEADER" &&
        preadditional.approver_role === "EXECUTOR"
      ) {
        enableEdition = true;
      }
      if (project.role === "ADMIN") {
        enableEdition = true;
      }
    }
    return enableEdition;
  };

  // Filenames states

  const [preadditionalBudgetName, setPreadditionalBudgetName] = useState("");
  const [
    preadditionalGeneralExpensesName,
    setPreadditionalGeneralExpensesName,
  ] = useState("");

  const [showPopBudgetValidation, setShowPopBudgetValidation] = useState(false);
  const [
    showPopGeneralExpensesValidation,
    setShowPopGeneralExpensesValidation,
  ] = useState(false);

  // Saving files

  const getPreadditionalFileUpdateBody = (file) => {
    let formData = new FormData();
    formData.append("file", file);
    return formData;
  };

  const preadditionalFileUpdateErrorHandler = (errorType, response = {}) => {
    switch (errorType) {
      case "bad_request":
        notification.error({
          message: response[Object.keys(response)[0]],
        });
        break;
      case "server":
        notification.error({
          message: "Hubo un error en el servidor",
        });
        break;
      default:
        notification.error({
          message: "Hubo un error en el servidor",
        });
        break;
    }
  };

  const preadditionalBudgetUpdateSuccessHandler = (preadditional) => {
    notification.success({
      message: "Se realizo correctamente la carga del archivo de presupuesto",
    });
    reloadPreadditionalPage();
    reloadParts();
  };

  const preadditionalGeneralExpensesUpdateSuccessHandler = (preadditional) => {
    notification.success({
      message:
        "Se realizo correctamente la carga del archivo de gastos generales",
    });
    reloadPreadditionalPage();
    reloadParts();
  };

  const savePreadditionalBudget = async (file) => {
    setIsLoading(true);
    await updatePreAdditionalBudget(
      preadditional.id,
      getPreadditionalFileUpdateBody(file),
      preadditionalFileUpdateErrorHandler,
      preadditionalBudgetUpdateSuccessHandler
    );
    setIsLoading(false);
  };

  const savePreadditionalGeneralExpenses = async (file) => {
    setIsLoading(true);
    await updatePreAdditionalGeneralExpenses(
      preadditional.id,
      getPreadditionalFileUpdateBody(file),
      preadditionalFileUpdateErrorHandler,
      preadditionalGeneralExpensesUpdateSuccessHandler
    );
    setIsLoading(false);
  };

  // Documents getting

  const errorFileGetHandler = (errorType, response = {}) => {
    switch (errorType) {
      case "no_found":
        notification.error({
          message: response[Object.keys(response)[0]],
        });
        break;
      case "server":
        notification.error({
          message: "Hubo un error en el servidor",
        });
        break;
      default:
        notification.error({
          message: "Hubo un error en el servidor",
        });
        break;
    }
  };

  const successBudgetGetHandler = (response) => {
    FileSaver.saveAs(response, preadditionalBudgetName);
  };

  const successGeneralExpensesGetHandler = (response) => {
    FileSaver.saveAs(response, preadditionalGeneralExpensesName);
  };

  const getPreadditionalBudgetFile = async () => {
    setPreadditionalBudgetName(preadditional.budget_filename);
    await getPreAdditionalBudget(
      preadditional.id,
      errorFileGetHandler,
      successBudgetGetHandler
    );
  };

  const getPreadditionalGeneralExpensesFile = async () => {
    setPreadditionalGeneralExpensesName(
      preadditional.general_expenses_filename
    );
    await getPreAdditionalGeneralExpenses(
      preadditional.id,
      errorFileGetHandler,
      successGeneralExpensesGetHandler
    );
  };

  // Documents validation

  const errorFileValidationHandler = (errorType, response = {}) => {
    switch (errorType) {
      case "bad_request":
        notification.error({
          message: response[Object.keys(response)[0]],
        });
        break;
      case "server":
        notification.error({
          message: "Hubo un error en el servidor",
        });
        break;
      default:
        notification.error({
          message: "Hubo un error en el servidor",
        });
        break;
    }
  };

  const successBudgetValidationHandler = (preadditional) => {
    notification.success({
      message: "Se realizo correctamente la carga del archivo de presupuesto",
    });
    reloadPreadditionalPage();
  };

  const successGeneralExpensesValidationHandler = (preadditional) => {
    notification.success({
      message:
        "Se realizo correctamente la carga del archivo de gastos generales",
    });
    reloadPreadditionalPage();
  };

  const validateBudget = async (file) => {
    setIsLoading(true);
    await validatePreAdditionalBudget(
      preadditional.id,
      errorFileValidationHandler,
      successBudgetValidationHandler
    );
    setIsLoading(false);
  };

  const validateGeneralExpenses = async (file) => {
    setIsLoading(true);
    await validatePreAdditionalGeneralExpenses(
      preadditional.id,
      errorFileValidationHandler,
      successGeneralExpensesValidationHandler
    );
    setIsLoading(false);
  };

  // Preadditional update handler

  const preadditionalEditSuccessHandler = (preadditional) => {
    notification.success({
      message:
        "Se realizo correctamente la actualización de los gastos generales",
    });
    reloadPreadditionalPage();
  };

  const preadditionalEditErrorHandler = (errorType, response = {}) => {
    switch (errorType) {
      case "bad_request":
        notification.error({
          message: response[Object.keys(response)[0]],
        });
        break;
      case "server":
        notification.error({
          message: "Hubo un error en el servidor",
        });
        break;
      default:
        notification.error({
          message: "Hubo un error en el servidor",
        });
        break;
    }
  };

  const transformPercentage = (value, setValue) => {
    let newValue = value.replace("%", "");
    setValue(`${newValue}%`);
    return Number(newValue) / 100;
  };

  const updatePreadditionalHandler = async () => {
    setIsLoading(true);
    const value = transformPercentage(
      generalExpensesPercentage,
      setGeneralExpensesPercentage
    );
    await updatePreAdditional(
      preadditional.id,
      { general_expenses_percentage: value },
      preadditionalEditErrorHandler,
      preadditionalEditSuccessHandler
    );
    setIsLoading(false);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minWidth: "400px",
          maxWidth: "500px",
          alignItems: "center",
          lineHeight: "22px",
          fontWeight: "700",
          background: "white",
          marginBottom: "50px",
        }}
      >
        <div style={{ fontSize: "14px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              textAlign: "left",
              height: "32px",
              marginTop: "20px",
            }}
          >
            <p style={{ width: "250px", height: "32px", color: "#014083" }}>
              Estado Archivo Presupuesto
            </p>
            <div
              style={{
                marginLeft: "100px",
                whiteSpace: "nowrap",
                color: preadditional.is_budget_approved
                  ? "#05BE8C"
                  : preadditional.budget_filename === ""
                  ? "#818181"
                  : "#D93022",
              }}
            >
              <p>
                {preadditional.is_budget_approved
                  ? "Validado"
                  : preadditional.budget_filename === ""
                  ? "Por registrar"
                  : "Por Validar"}{" "}
              </p>
            </div>
          </div>
          <Container style={{ marginBottom: "20px" }}>
            <FileRowContainer>
              <ChargeFileButton chargeDocument={savePreadditionalBudget} />
              <FileItem
                fileName={preadditional.budget_filename}
                getFile={() => {
                  getPreadditionalBudgetFile();
                }}
              />
            </FileRowContainer>
          </Container>
          <div style={{ textAlign: "center", marginBottom: "20px" }}>
            <p style={{ marginBottom: "-1px", color: "#000" }}>
              <b>Monto total de presupuesto ingresado</b>
            </p>
            <b style={{ color: "#000" }}>{`S/. ${parseFloatNumber(
              preadditional.additional_budget_cost
            )}`}</b>
          </div>
        </div>

        <Popconfirm
          title="¿Esta seguro que desea validar el presupuesto cargado?"
          onConfirm={() => {
            setShowPopBudgetValidation(false);
            validateBudget();
          }}
          onCancel={() => {
            setShowPopBudgetValidation(false);
          }}
          open={showPopBudgetValidation}
          okText="Si"
          cancelText="No"
        >
          <div
            style={{
              height: "32px",
              width: "140px",
              background:
                preadditional.budget_filename !== "" &&
                !preadditional.is_budget_approved &&
                enableEdition
                  ? "#05BE8C"
                  : "#818181",
              borderRadius: "5px",
              fontWeight: "100",
              boxShadow: "0px 2px 8px rgba(26, 18, 18, 0.25)",
              cursor:
                preadditional.budget_filename !== "" &&
                !preadditional.is_budget_approved &&
                enableEdition
                  ? "pointer"
                  : "auto",
            }}
            onClick={() => {
              enableEdition && setShowPopBudgetValidation(true);
            }}
          >
            <div
              style={{
                paddingTop: "4px",
                color: "white",
                justifyContent: "space-around",
                textAlign: "center",
              }}
            >
              Validar
            </div>
          </div>
        </Popconfirm>
      </div>
      {preadditional.general_expenses_included &&
        !preadditional.is_general_expenses_percentage && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              minWidth: "400px",
              maxWidth: "500px",
              alignItems: "center",
              lineHeight: "22px",
              fontWeight: "700",
              background: "white",
            }}
          >
            <div style={{ fontSize: "14px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  textAlign: "left",
                  height: "32px",
                }}
              >
                <p style={{ width: "250px", height: "32px", color: "#014083" }}>
                  Estado Archivo Gastos Generales
                </p>
                <div
                  style={{
                    marginLeft: "100px",
                    whiteSpace: "nowrap",
                    color: preadditional.is_general_expenses_approved
                      ? "#05BE8C"
                      : preadditional.general_expenses_filename === ""
                      ? "#818181"
                      : "#D93022",
                  }}
                >
                  <p>
                    {preadditional.is_general_expenses_approved
                      ? "Validado"
                      : preadditional.general_expenses_filename === ""
                      ? "Por registrar"
                      : "Por Validar"}{" "}
                  </p>
                </div>
              </div>
              <Container style={{ marginBottom: "20px" }}>
                <FileRowContainer>
                  <ChargeFileButton
                    chargeDocument={savePreadditionalGeneralExpenses}
                  />
                  <FileItem
                    fileName={preadditional.general_expenses_filename}
                    getFile={() => {
                      getPreadditionalGeneralExpensesFile();
                    }}
                  />
                </FileRowContainer>
              </Container>
              <div style={{ textAlign: "center", marginBottom: "20px" }}>
                <p style={{ marginBottom: "-1px", color: "#000" }}>
                  <b>Monto total de gastos generales ingresado</b>
                </p>
                <b style={{ color: "#000" }}>{`S/. ${parseFloatNumber(
                  preadditional.general_expenses_cost
                )}`}</b>
              </div>
            </div>
            <Popconfirm
              title="¿Esta seguro que desea validar los gastos generales cargados?"
              onConfirm={() => {
                setShowPopGeneralExpensesValidation(false);
                validateGeneralExpenses();
              }}
              onCancel={() => {
                setShowPopGeneralExpensesValidation(false);
              }}
              open={showPopGeneralExpensesValidation}
              okText="Si"
              cancelText="No"
            >
              <div
                style={{
                  width: "140px",
                  height: "32px",
                  background:
                    preadditional.general_expenses_filename !== "" &&
                    !preadditional.is_general_expenses_approved &&
                    enableEdition
                      ? "#05BE8C"
                      : "#818181",
                  borderRadius: "5px",
                  fontWeight: "100",
                  boxShadow: "0px 2px 8px rgba(26, 18, 18, 0.25)",
                  cursor:
                    preadditional.general_expenses_filename !== "" &&
                    !preadditional.is_general_expenses_approved &&
                    enableEdition
                      ? "pointer"
                      : "auto",
                }}
                onClick={() => {
                  enableEdition && setShowPopGeneralExpensesValidation(true);
                }}
              >
                <div
                  style={{
                    paddingTop: "4px",
                    color: "white",
                    justifyContent: "space-around",
                    textAlign: "center",
                  }}
                >
                  Validar
                </div>
              </div>
            </Popconfirm>
          </div>
        )}
      {preadditional.is_general_expenses_percentage &&
        preadditional.general_expenses_included && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "40px",
              }}
            >
              <p>Porcentaje de gastos generales</p>
              <Input
                value={generalExpensesPercentage}
                onFocus={(event) => event.target.select()}
                onChange={(e) => setGeneralExpensesPercentage(e.target.value)}
                onBlur={() => {
                  updatePreadditionalHandler();
                }}
                disabled={!enableEdition}
                style={{
                  width: `${8 * generalExpensesPercentage.length}px`,
                  minWidth: "65px",
                }}
                type="text"
              />
            </div>
            <div
              style={{
                textAlign: "center",
                marginBottom: "20px",
                fontSize: "14px",
              }}
            >
              <p style={{ marginBottom: "-1px", color: "#000" }}>
                <b>Monto total de gastos generales</b>
              </p>
              <b style={{ color: "#000" }}>{`S/. ${parseFloatNumber(
                preadditional.general_expenses_cost
              )}`}</b>
            </div>
          </>
        )}
    </div>
  );
};

export default DocumentManagerSection;
