// Base import
import React, { useState, useEffect } from "react";

// Support libraries import
import { useHistory } from "react-router-dom";
import { GiPaperClip } from "react-icons/gi";
import { IconContext } from "react-icons";
import { Popconfirm, notification, Checkbox } from "antd";
import * as FileSaver from "file-saver";

// Components import
import TableButton from "../TableButton";

// Styled components
import {
  ActionIconContainer,
  TableItemContainer,
  TableItem,
  BaseTable,
  CreatorImg,
  ActionIcon,
} from "./styles";

// Services import
import {
  getPreAdditionalAPU,
  approvePreAdditional,
  rejectPreAdditional,
} from "../../../../../services";

// Utils import
import { parseFloatNumber } from "../../../../../../../utils";

// Assets import
import userIcon from "../../assets/icon.jpg";
import checkActive from "../../assets/check_active.svg";
import check from "../../assets/check.svg";
import rejectActive from "../../assets/reject_active.svg";
import reject from "../../assets/reject.svg";

const Table = ({
  preadditionals,
  userRole,
  checkPreadditionalHandler,
  checkAllPreadditionalsHandler,
  setTrigger,
  trigger,
  openPreadditionalModal,
}) => {
  // States
  const [showPopApprove, setShowPopApprove] = useState(false);
  const [showPopReject, setShowPopReject] = useState(false);
  const [preadditionalAPUName, setPreadditionalAPUName] = useState("");
  const [allPreadditionalsChecked, setAllPreadditionalsChecked] =
    useState(false);

  const history = useHistory();

  useEffect(() => {
    setAllPreadditionalsChecked(
      preadditionals.every((preadditional) => preadditional.checked)
    );
  }, [preadditionals]);

  const errorHandler = () => {
    setShowPopApprove(false);
    setShowPopReject(false);
    notification.error({
      message: "Ocurrió un error en la operación",
    });
  };

  const successAPUGetHandler = (response) => {
    FileSaver.saveAs(response, preadditionalAPUName);
  };

  const successPreAdditionalApprobation = (response) => {
    setShowPopApprove(false);
    notification.success({
      message:
        "Se realizo la aprobación de la propuesta del adicional con éxito",
    });
    setTrigger(!trigger);
  };

  const successPreAdditionalRejection = (response) => {
    setShowPopReject(false);
    notification.success({
      message: "Se realizo el rechazo de la propuesta del adicional con éxito",
    });
    setTrigger(!trigger);
  };

  const getAPU = async (preadditional) => {
    setPreadditionalAPUName(preadditional.apu_filename);
    await getPreAdditionalAPU(
      preadditional.id,
      errorHandler,
      successAPUGetHandler
    );
  };

  const handlePreAdditionalApprobation = async (id) => {
    await approvePreAdditional(
      id,
      errorHandler,
      successPreAdditionalApprobation
    );
  };

  const handlePreAdditionalRejection = async (id) => {
    await rejectPreAdditional(id, errorHandler, successPreAdditionalRejection);
  };

  const getStatus = (status) => {
    switch (status) {
      case "CREATED":
        return ["#000000", "Por configurar"];
      case "SETTED":
        return ["#000000", "Propuesto"];
      case "VALIDATED":
        return ["#28A745", "Aceptado"];
      case "REJECTED":
        return ["#DC3545", "Rechazado"];
      default:
        break;
    }
  };

  const getPanel = (
    sectionRole,
    userRole,
    approverRole,
    status,
    preadditionalId
  ) => {
    if (sectionRole !== approverRole) {
      return (
        <>
          <CreatorImg alt="role_icon" src={userIcon} />
          Creador
        </>
      );
    }
    let panelActive = false;
    switch (userRole) {
      case "EXECUTOR":
      case "SUPERVISOR":
        return "-";
      case "ADMIN":
        panelActive = true;
        break;
      case "EXECUTOR_LEADER":
        panelActive = approverRole === "EXECUTOR";
        break;
      case "SUPERVISOR_LEADER":
        panelActive = approverRole === "SUPERVISOR";
        break;
      default:
        break;
    }
    if (status !== "SETTED") {
      panelActive = false;
    }
    return panelActive ? (
      <ActionIconContainer>
        <Popconfirm
          placement="topLeft"
          title="¿Esta seguro de realizar la aprobación de la propuesta de adicional?"
          open={showPopApprove}
          onConfirm={() => {
            handlePreAdditionalApprobation(preadditionalId);
          }}
          onCancel={() => {
            setShowPopApprove(false);
          }}
          okText="Sí"
          cancelText="No"
        >
          <ActionIcon
            alt="check"
            src={checkActive}
            active
            onClick={() => setShowPopApprove(true)}
          />
        </Popconfirm>
        <Popconfirm
          placement="topLeft"
          title="¿Esta seguro de realizar el rechazo de la propuesta de adicional?"
          open={showPopReject}
          onConfirm={() => {
            handlePreAdditionalRejection(preadditionalId);
          }}
          onCancel={() => {
            setShowPopReject(false);
          }}
          okText="Sí"
          cancelText="No"
        >
          <ActionIcon
            alt="reject"
            src={rejectActive}
            active
            onClick={() => setShowPopReject(true)}
          />
        </Popconfirm>
      </ActionIconContainer>
    ) : (
      <ActionIconContainer>
        <ActionIcon alt="check" src={check} />
        <ActionIcon alt="reject" src={reject} />
      </ActionIconContainer>
    );
  };

  // Navigate to additional valorization page

  const navigateToAdditionalValorizationPage = (id) => {
    history.push(`/additionals/${id}/additionals`);
  };

  return (
    <BaseTable>
      <TableItemContainer>
        <TableItem width="50px" paddingTop="8px" header>
          <Checkbox
            checked={allPreadditionalsChecked}
            onChange={() => {
              checkAllPreadditionalsHandler(!allPreadditionalsChecked);
            }}
          />
        </TableItem>
        <TableItem width="60px" header>
          Orden
        </TableItem>
        <TableItem width="50px" header>
          APU
        </TableItem>
        <TableItem width="400px" header>
          Nombre
        </TableItem>
        <TableItem width="120px" header></TableItem>
        <TableItem width="120px" header></TableItem>
        <TableItem width="200px" header>
          Días de ampliación de plazo
        </TableItem>
        <TableItem width="120px" header>
          Estatus
        </TableItem>
        <TableItem width="200px" header>
          Monto total del adicional
        </TableItem>
        <TableItem width="200px" header>
          Monto total pagado
        </TableItem>
        <TableItem width="150px" header>
          Supervisor Líder
        </TableItem>
        <TableItem width="150px" header>
          Ejecutor Líder
        </TableItem>
      </TableItemContainer>
      {preadditionals.map((preadditional) => (
        <TableItemContainer key={preadditional.id}>
          <TableItem width="50px" paddingTop="8px" header>
            <Checkbox
              checked={preadditional.checked}
              onChange={() => {
                checkPreadditionalHandler(preadditional.id);
              }}
            />
          </TableItem>
          <TableItem width="60px">{preadditional.order}</TableItem>
          <TableItem width="50px">
            {preadditional.apu_filename ? (
              <IconContext.Provider value={{ color: "#000000" }}>
                <GiPaperClip
                  style={{ marginRight: "12px", cursor: "pointer" }}
                  size={16}
                  strokeWidth={12}
                  onClick={() => {
                    getAPU(preadditional);
                  }}
                />
              </IconContext.Provider>
            ) : (
              "-"
            )}
          </TableItem>
          <TableItem width="400px">{preadditional.name || "-"}</TableItem>
          <TableItem width="120px">
            <TableButton
              title="Gestión Val."
              active={preadditional.status === "VALIDATED"}
              onClick={() => {
                preadditional.status === "VALIDATED" &&
                  navigateToAdditionalValorizationPage(preadditional.id);
              }}
            />
          </TableItem>
          <TableItem width="120px">
            <TableButton
              title="Ver detalle"
              active={true}
              onClick={() => {
                openPreadditionalModal(preadditional.id);
              }}
            />
          </TableItem>
          <TableItem width="200px">
            {preadditional.term_extension_days}
          </TableItem>
          <TableItem width="120px" color={getStatus(preadditional.status)[0]}>
            {getStatus(preadditional.status)[1]}
          </TableItem>
          <TableItem width="200px">{`S/. ${parseFloatNumber(
            preadditional.additional_budget_cost +
              preadditional.general_expenses_cost
          )}`}</TableItem>
          <TableItem width="200px">{`S/. ${parseFloatNumber(
            preadditional.additional?.advance_amount || 0
          )}`}</TableItem>
          <TableItem width="150px">
            {getPanel(
              "SUPERVISOR",
              userRole,
              preadditional.approver_role,
              preadditional.status,
              preadditional.id
            )}
          </TableItem>
          <TableItem width="150px">
            {getPanel(
              "EXECUTOR",
              userRole,
              preadditional.approver_role,
              preadditional.status,
              preadditional.id
            )}
          </TableItem>
        </TableItemContainer>
      ))}
    </BaseTable>
  );
};

export default Table;
