// React import
import React from "react";

// Styled components import
import { LegendRow } from "./styles";

// Libraries import
import { IconContext } from "react-icons";
import { FaCheck, FaTimes, FaMinus } from "react-icons/fa";

const Legend = () => {
  return (
    <>
      <LegendRow>
        {" "}
        <IconContext.Provider
          value={{
            color: "#218838",
          }}
        >
          <FaCheck size={18} />
        </IconContext.Provider>
        Fecha propuesta aprobada
      </LegendRow>
      <LegendRow>
        <IconContext.Provider
          value={{
            color: "#C82333",
          }}
        >
          <FaTimes size={18} />
        </IconContext.Provider>
        Fecha propuesta desaprobada
      </LegendRow>{" "}
      <LegendRow>
        <IconContext.Provider
          value={{
            color: "#808080",
          }}
        >
          <FaMinus size={18} />
        </IconContext.Provider>
        Fecha propuesta por aprobar
      </LegendRow>
    </>
  );
};

export default Legend;
