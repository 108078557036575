import styled from "styled-components";
import { Row } from "reactstrap";

import BackgroundImage from "./assets/wallpaper.jpg";

export const PublicWallpaper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  background: linear-gradient(
      270.47deg,
      rgba(255, 255, 255, 0.879121) -8.18%,
      #ffffff -8.18%,
      rgba(255, 255, 255, 0.588355) -8.18%,
      rgba(255, 255, 255, 0.358907) -2.95%,
      rgba(255, 255, 255, 0) 45.02%
    ),
    rgba(1, 64, 131, 0.6);
  background-image: url(${BackgroundImage});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 0px;
  width: 100vw;
  height: 100vh;
`;

export const PublicMainCard = styled.div`
  width: 255px;
  background-color: #f3f3f3;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 20px;
`;

export const PublicMainCardRow = styled(Row)`
  justify-content: center;
  margin: 0 !important;
`;

export const PublicMainCardLogo = styled.div`
  height: 100px;
  width: 100px;
  background-color: white;
  border-radius: 50%;
  margin-top: 64px;
`;

export const PublicMainCardTitle = styled.p`
  font-size: 15px;
  line-height: 18px;
  margin-top: 30px;
  margin-bottom: 10px;
  color: #014083;
  ${(props) => props.bold && `font-weight: bold`};
`;

export const PublicMainCardSubtitle = styled.p`
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 26px;
  color: #014083;
`;
