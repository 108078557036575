import React, { useState } from "react";
import { withRouter, useLocation } from "react-router";
import { DropdownMenu, DropdownToggle } from "reactstrap";
import { Modal } from "antd";
import { MdDehaze } from "react-icons/md";
import { IconContext } from "react-icons";

// App
import AppSession from "../../../../../App/utils/session/AppSession";

// Components
import NewProjectModal from "./components/NewProjectModal";
import NotificationsBell from "./components/NotificationsBell";
import { CustomBreadcrumbNavbar } from "../../../../../../components/CustomBreadcrumbNavbar/CustomBreadcrumbNavbar";

//Assets
import ArrowBreadcrumb from "../Navbar/assets/arrow_breadcrumb.svg";
import HomeBreadcrumb from "../Navbar/assets/home_breadcrumb.svg";

import {
  NavbarContainer,
  NavbarRow,
  NavbarHeadingsContainer,
  NavbarNotificationsContainer,
  NavbarMenu,
  NavbarLogo,
  NavbarProfileDropdown,
  NavbarNotification,
  NavbarProfileImage,
  NavbarProfileIcon,
  NavbarDropdownMenu,
  NavbarTitle,
  RoleDropdownContainer,
  RoleDropdown,
  RoleDropdownItem,
  RoleDropdownToggle,
} from "./styles";

// Context
import { useAppContext } from "../../../../context";

// Assets
import "./styles.css";
import logo from "./assets/logo.png";
import userIcon from "./assets/user-icon.svg";
import arrowIcon from "./assets/arrow.svg";

const Navbar = (props) => {
  // Context values
  const {
    user,
    projectRole,
    setProjectRole,
    isProjectAdmin
  } = useAppContext();

  // Component states
  const [roleDropdown, setRoleDropdown] = useState(false);
  const [dropdownActive, setDropdownActive] = useState(false);
  const [projectModalOpen, setProjectModalOpen] = useState(false);

  const contruction_id =sessionStorage.getItem("construction_id");
  const construction_name =sessionStorage.getItem("construction_name");
  const project_name =sessionStorage.getItem("project_name");
  const project_id =sessionStorage.getItem("project_id");

  const links = [
    { name: "Portafolio", to: "/main" },
    { name: construction_name, to: `/constructions/${contruction_id}`},
    { name: project_name, to: "#"},
  ];

  const links2 = [
    { name: "Portafolio", to: "/main" },
    { name: construction_name, to: `/constructions/${contruction_id}`},
    { name: project_name, to: `/projects/${project_id}/edit`},
  ];


  const links3 = [
    { name: "Portafolio", to: "/main" },
  ];

  const toggleRoleDropdown = () => {
    setRoleDropdown(!roleDropdown);
  };

  const toggleDropdown = () => {
    setDropdownActive((prevState) => !prevState);
  };

  const handleLogout = () => {
    AppSession.destroy();
  };

  return (
    <>
      <NavbarContainer fluid={true} style={{ position: "relative" }}>
        <NavbarRow>
          <NavbarHeadingsContainer>
            <NavbarMenu onClick={() => props.setIsMenuActive(true)}>
              <IconContext.Provider value={{ color: "#014083" }}>
                <MdDehaze size={32} className="navbar__menu_icon" />
              </IconContext.Provider>
            </NavbarMenu>
            <NavbarLogo>
              <img alt="logo" src={logo} />
            </NavbarLogo>
            {(props.history.location.pathname.includes("/val") ||
              props.history.location.pathname.includes("/additionals")) && (
              <CustomBreadcrumbNavbar
                links={links}
                ArrowBreadcrumb={ArrowBreadcrumb}
                HomeBreadcrumb={HomeBreadcrumb}
                currentPage="Gestión valorización"
              />
            )}
            {(props.history.location.pathname.includes("/edit")) && (
              props.history.location.pathname.includes("/mates")? (
              <CustomBreadcrumbNavbar
                links={links2}
                ArrowBreadcrumb={ArrowBreadcrumb}
                HomeBreadcrumb={HomeBreadcrumb}
                currentPage="Edición de colaboradores"
              />):(
              <CustomBreadcrumbNavbar
                links={links}
                ArrowBreadcrumb={ArrowBreadcrumb}
                HomeBreadcrumb={HomeBreadcrumb}
                currentPage="Edición"
              />)
            )}
            {(props.history.location.pathname.includes("/constructions")) && (
              <CustomBreadcrumbNavbar
                links={links3}
                ArrowBreadcrumb={ArrowBreadcrumb}
                HomeBreadcrumb={HomeBreadcrumb}
                currentPage="Contratos"
              />
            )}
          </NavbarHeadingsContainer>
          <NavbarNotificationsContainer>
            <NotificationsBell />
            <NavbarProfileDropdown
              isOpen={dropdownActive}
              toggle={toggleDropdown}
            >
              <DropdownToggle
                tag="span"
                data-toggle="dropdown"
                aria-expanded={dropdownActive}
              >
                <NavbarNotification>
                  {user.image ? (
                    <NavbarProfileImage
                      src={
                        user.image &&
                        `${user.image["content-type"]};base64, ${user.image.data}`
                      }
                      alt="img-user"
                    />
                  ) : (
                    <IconContext.Provider value={{ color: "white" }}>
                      <NavbarProfileIcon size={22} />
                    </IconContext.Provider>
                  )}
                </NavbarNotification>
              </DropdownToggle>
              <NavbarDropdownMenu>
                <div>Perfil</div>
                <div onClick={handleLogout}>Cerrar sesión</div>
              </NavbarDropdownMenu>
            </NavbarProfileDropdown>
            <NavbarTitle>{user?.user?.username}</NavbarTitle>
            {(props.history.location.pathname.includes("/val") ||
              props.history.location.pathname.includes("/additionals") ||
              props.history.location.pathname.includes("/deductives")) &&
              isProjectAdmin && (
                <RoleDropdownContainer>
                  <RoleDropdown
                    isOpen={roleDropdown}
                    toggle={toggleRoleDropdown}
                  >
                    <RoleDropdownToggle>
                      <img src={userIcon} alt="user-icon" />
                      {projectRole}
                      <img src={arrowIcon} alt="arrow-icon" />
                    </RoleDropdownToggle>
                    <DropdownMenu>
                      <RoleDropdownItem
                        onClick={() => {
                          setProjectRole("Supervisor");
                        }}
                      >
                        <img src={userIcon} alt="user-icon" />
                        Supervisor
                      </RoleDropdownItem>
                      <RoleDropdownItem
                        onClick={() => {
                          setProjectRole("Ejecutor");
                        }}
                      >
                        <img src={userIcon} alt="user-icon" />
                        Ejecutor
                      </RoleDropdownItem>
                    </DropdownMenu>
                  </RoleDropdown>
                </RoleDropdownContainer>
              )}
          </NavbarNotificationsContainer>
        </NavbarRow>
      </NavbarContainer>
      <Modal
        title="Nuevo Proyecto"
        centered
        open={projectModalOpen}
        onCancel={() => setProjectModalOpen(false)}
        footer={null}
      >
        <NewProjectModal
          history={props.history}
          setModal={setProjectModalOpen}
        />
      </Modal>
    </>
  );
};

export default withRouter(Navbar);
