import styled from "styled-components";

export const ConstructionContainer = styled.div`
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  flex-direction: row;
  width: 550px;
  height: auto;
  background: #7F7F7F;
`;

export const ConstructionImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  height: auto;
  margin: 1.5rem 0rem 1rem 1.5rem;
`;

export const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
  background-image: url(${(props) => props.imageUrl});
  background-size: 100% 100%;
  width: 290px;
  height: 220px;
  @media (min-width: 1258px) and (max-width: 1432px) {
  }
  @media (max-width: 823px) {
  }
`;

export const ImageConfigProject = styled.img`
  position: absolute;
  top: 10%;
  left: 92%;
  width: 30px;
  transform: translate(-50%, -50%);
  cursor: pointer;
  @media (min-width: 1258px) and (max-width: 1432px) {
  }
  @media (max-width: 823px) {
  }
`;

export const ButtonConfigProject = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  @media (min-width: 1258px) and (max-width: 1432px) {
  }
  @media (max-width: 823px) {
  }
`;

export const ConstructionData = styled.div`
  width: 100%;
  height: auto;
  align-items: flex-start;
  text-align: left;
  color: #000000;
  font-family: "Mulish";
  font-weight: 700;
  font-size: 14px;
  margin: 0.5rem 0rem 0.5rem 0rem;
`;

export const ConstructionInfoContainer = styled.div`
  width: 40%;
  height: auto;
  align-items: flex-start;
  text-align: left;
  color: #4a4a4a;
  font-family: "Mulish";
  font-weight: 700;
  font-size: 14px;
  margin: 1.5rem 0rem 0.5rem 0rem;
`;

export const ConstructionConfigContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000000;
  font-family: "Mulish";
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  margin: 0.5rem 0rem 0.5rem 0rem;
`;
