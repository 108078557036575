import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import { DragDropContainer } from "react-drag-drop-container";

import { getMates } from "../../providers";

import { EditProjectCard } from "../EditProjectCard/EditProjectCard";
import { MateCard } from "../MateCard/MateCard";

export const ListOfCollaborators = () => {
  const [mates, setMates] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const _mates = await getMates({ search });
        setMates(_mates.results);
      } catch (error) {
        console.log(error);
      }
    };
    fetchProject();
  }, [search]);

  return (
    <EditProjectCard
      classname="editProjectMates__collaborators"
      title="Lista de colaboradores"
      padding="1rem"
      searchVisible={true}
      setSearch={setSearch}
    >
      <br />
      <Row className="ramp editProjectMates__collaborators__container">
        {mates.map((member) => (
          <DragDropContainer
            key={member.id}
            targetKey="card"
            dragData={{ ...member }}
          >
            <MateCard
              {...member}
              btnsVisible={false}
              background="#f0f4f9"
              marginBottom="20px"
              isBox={true}
              cursor="move"
            />
          </DragDropContainer>
        ))}
      </Row>
    </EditProjectCard>
  );
};
