// React import
import React, { useRef, useState } from "react";

// Third party libraries
import { Row } from "reactstrap";
import { Popconfirm } from "antd";

// Components import
import { ListOfCollaborators } from "../../../../components/EditProjectMatesCardGroup/ListOfCollaborators";
import { ProjectTeam } from "../../../../components/EditProjectMatesCardGroup/ProjectTeam";
import { Button } from "../../../../components/Button";
import { EditProjectPageContainer } from "../EditProjectPageContainer";

import "./EditProjectMatesBanner.css";

export const EditProjectMatesBanner = ({ history, match }) => {
  // Component states
  const [allTeam, setAllTeam] = useState({});
  const [showPopConfirm, setShowPopConfirm] = useState(false);

  const { id } = match.params;

  const childRef = useRef();

  const validateLeaderExist = () => {
    const existAdminLeader = allTeam["administrador"].some(
      (admin) => admin.is_leader === true
    );
    const existExecutorLeader = allTeam["ejecutor"].some(
      (exe) => exe.is_leader === true
    );
    const existSupervisorLeader = allTeam["supervisor"].some(
      (supervisor) => supervisor.is_leader === true
    );
    return existAdminLeader && existExecutorLeader && existSupervisorLeader;
  };

  return (
    <EditProjectPageContainer>
      <br/>
      <div className="editProjectMates__container">
        <ListOfCollaborators />
        <ProjectTeam
          id={id}
          ref={childRef}
          history={history}
          allTeam={allTeam}
          setAllTeam={setAllTeam}
        />
      </div>
      <Row className="ramp" style={{ justifyContent: "center", gap: "40px" }}>
        <Popconfirm
          placement="topLeft"
          title="Un grupo del proyecto (administradores, supervisores o ejecutores) no tiene líder, ¿Desea continuar?"
          open={showPopConfirm}
          onConfirm={() => {
            setShowPopConfirm(false);
            childRef.current.saveProjectTeam();
          }}
          onCancel={() => {
            setShowPopConfirm(false);
          }}
          okText="Sí"
          cancelText="No"
        >
          <Button
            background="#05BE8C"
            width="122px"
            height="40px"
            boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
            fontSize="18px"
            className="editProject__mb4"
            onClick={() => {
              const verdict = validateLeaderExist();
              verdict
                ? childRef.current.saveProjectTeam()
                : setShowPopConfirm(true);
            }}
          >
            Guardar
          </Button>
        </Popconfirm>
        <Button
          background="#D93022"
          width="122px"
          height="40px"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
          fontSize="18px"
          className="editProject__mb4"
          onClick={() => history.goBack()}
        >
          Cancelar
        </Button>
      </Row>
    </EditProjectPageContainer>
  );
};
