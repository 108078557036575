import React, { useState } from "react";
import { Modal, notification } from "antd";

import {
  patchPartById,
  patchSendValorizedPart,
  patchValidateValorizedPart,
  patchRejectValorizedPart,
  patchCorrectValorizedPart,
  patchDebateValorizedPart,
  postValorizedPartLog,
  patchValidateValorizedPartLog,
  patchRejectValorizedPartLog,
  patchStopSendValorizedPart,
  patchStopVerdictValorizedPart,
} from "../../../../../../providers";

import { Part } from "./Part";
import { ImageModal } from "./ImageModal";
import { ToTalkModal } from "../../Modal/ToTalkModal";

import "./Parts.css";

export const Parts = ({
  parts,
  search,
  pruebaid,
  current_order,
  user,
  role,
  setToggleUpdateParts,
  is_active,
  executorEnable,
  supervisorEnable,
  fileGroup,
  showBaseBudgetBlock,
  showLastAccumulatedBlock,
  showWorkSpaceBlock,
  showPresentValBlock,
  showAccumulatedBlock,
  showToValBlock,
}) => {
  const [isVisibleImageModal, setisVisibleImageModal] = useState(false);
  const [id, setId] = useState();
  const [isVisibleToTalkModal, setIsVisibleToTalkModal] = useState(false);
  const [partName, setPartName] = useState("");
  const [toggleUpdatePart, setToggleUpdatePart] = useState(false);

  const filter_handler = (id, fileGroup) => {
    let element1 = document.getElementById(fileGroup + "_PART_GP_" + id);
    let button = document.getElementById(fileGroup + "_CB_PART_GP_" + id);
    try {
      if (element1.innerHTML === "") {
        console.log("invalid");
      } else {
        if (element1.style.display === "none") {
          element1.style.display = "block";
          button.innerHTML = "-";
        } else {
          element1.style.display = "none";
          button.innerHTML = "+";
        }
      }
    } catch {}
  };

  const editPart = async (id, body, update = true) => {
    try {
      const response = await patchPartById(id, body);
      //console.log("response edit part", response);
      if (response.id && update) {
        setToggleUpdateParts((prev) => !prev);
      }
      if (response.id && !update) {
        setToggleUpdatePart((prev) => !prev);
      }
      if (response.detail) {
        notification.error({
          message: response.detail || "Ocurrío un error al editar la partida",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sendValorizedPart = async (id) => {
    try {
      const response = await patchSendValorizedPart(id);
      if (response.id) {
        setToggleUpdateParts((prev) => !prev);
      } else {
        notification.error({
          message: response.detail || "Ocurrío un error al enviar la partida",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const stopSendValorizedPart = async (id) => {
    try {
      const response = await patchStopSendValorizedPart(id);
      if (response.id) {
        setToggleUpdateParts((prev) => !prev);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validateValorizedPart = async (id) => {
    try {
      const response = await patchValidateValorizedPart(id);
      if (response.id) {
        setToggleUpdateParts((prev) => !prev);
      } else {
        notification.error({
          message: response.detail || "Ocurrío un error al validar la partida",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const rejectValorizedPart = async (id) => {
    try {
      const response = await patchRejectValorizedPart(id);
      if (response.id) {
        setToggleUpdateParts((prev) => !prev);
        setToggleUpdatePart((prev) => !prev);
      } else {
        notification.error({
          message: response.detail || "Ocurrío un error al rechazar la partida",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const stopSendVerdictValorizedPart = async (id) => {
    try {
      const response = await patchStopVerdictValorizedPart(id);
      if (response.id) {
        setToggleUpdateParts((prev) => !prev);
        setToggleUpdatePart((prev) => !prev);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const correctValorizedPart = async (id) => {
    try {
      const response = await patchCorrectValorizedPart(id);
      if (response.id) {
        setToggleUpdateParts((prev) => !prev);
        setToggleUpdatePart((prev) => !prev);
      }
      //console.log("correctValorizedPart", response);
    } catch (error) {
      console.log(error);
    }
  };

  const debateValorizedPart = async (id) => {
    try {
      const response = await patchDebateValorizedPart(id);
      if (response.id) {
        setToggleUpdateParts((prev) => !prev);
        setToggleUpdatePart((prev) => !prev);
      }
      //console.log("debateValorizedPart", response);
    } catch (error) {
      console.log(error);
    }
  };

  const createValorizedPartLog = async (id, body) => {
    try {
      const response = await postValorizedPartLog(id, body);
      if (response.id) {
        setToggleUpdatePart((prev) => !prev);
        setToggleUpdateParts((prev) => !prev);
      } else {
        notification.error({
          message: response.detail[0],
        });
      }
      //console.log("createValorizedPartLog", response);
    } catch (error) {
      console.log(error);
    }
  };

  const validateValorizedPartLog = async (id) => {
    try {
      const response = await patchValidateValorizedPartLog(id);
      if (response.id) {
        setToggleUpdatePart((prev) => !prev);
        setToggleUpdateParts((prev) => !prev);
      } else {
        notification.error({
          message: response.detail[0],
        });
      }
      //console.log("validateValorizedPartLog", response);
    } catch (error) {
      console.log(error);
    }
  };

  const rejectValorizedPartLog = async (id, body) => {
    try {
      const response = await patchRejectValorizedPartLog(id, body);
      if (response.id) {
        setToggleUpdatePart((prev) => !prev);
        setToggleUpdateParts((prev) => !prev);
      } else {
        notification.error({
          message: response.detail[0],
        });
      }
      //console.log("rejectValorizedPartLog", response);
    } catch (error) {
      console.log(error);
    }
  };

  const list_parts = (id, i, fileGroup) => {
    let partidas = parts?.map(function (part) {
      if (part.part?.parent_id === id) {
        i = i + 1;
        //TODO: group id i con 2 digitos
        /* let group_id = txti + i + "."; */
        let group_id = part.part.part_id;
        /* let group_txtci = txtci + String(i).padStart(2, "0") + "."; */
        let parts_children = list_parts(part.part.part_id, 0, fileGroup);
        let jerarchy_number = String(part.part.part_id).length;
        //Cambio de estilo según su jerarquia
        let color_class_name = "";
        let tab_class = " parts__tab__secondJerarchy";
        if (jerarchy_number < 4) {
          color_class_name = " parts__firstJerarchy";
          tab_class = "";
        }
        if (jerarchy_number > 3 && jerarchy_number < 7) {
          color_class_name = " parts__secondJerarchy";
          tab_class = " parts__tab__firstJerarchy";
        }

        return (
          <Part
            key={part.part.id}
            part={part}
            group_id={group_id}
            color_class_name={color_class_name}
            tab_class={tab_class}
            parts_children={parts_children}
            filter_handler={filter_handler}
            current_order={current_order}
            user={user}
            role={role}
            editPart={editPart}
            setisVisibleImageModal={setisVisibleImageModal}
            setId={setId}
            sendValorizedPart={sendValorizedPart}
            stopSendValorizedPart={stopSendValorizedPart}
            validateValorizedPart={validateValorizedPart}
            stopSendVerdictValorizedPart={stopSendVerdictValorizedPart}
            setToggleUpdateParts={setToggleUpdateParts}
            setToggleUpdatePart={setToggleUpdatePart}
            setIsVisibleToTalkModal={setIsVisibleToTalkModal}
            is_active={is_active}
            executorEnable={executorEnable}
            supervisorEnable={supervisorEnable}
            fileGroup={fileGroup}
            showBaseBudgetBlock={showBaseBudgetBlock}
            showLastAccumulatedBlock={showLastAccumulatedBlock}
            showWorkSpaceBlock={showWorkSpaceBlock}
            showPresentValBlock={showPresentValBlock}
            showAccumulatedBlock={showAccumulatedBlock}
            showToValBlock={showToValBlock}
          />
        );
      }
    });
    return partidas;
  };

  /* let partidas = list_parts(pruebaid, 0, "", ""); */
  let partidas = list_parts(pruebaid, 0, fileGroup);
  return (
    <>
      <Modal
        title="Edición de imagen de partida"
        centered
        open={isVisibleImageModal}
        onCancel={() => {
          setisVisibleImageModal(false);
          setId(null);
        }}
        footer={null}
        width={534}
      >
        <ImageModal
          id={id}
          setisVisibleImageModal={setisVisibleImageModal}
          user={user}
          is_active={is_active}
          executorEnable={executorEnable}
          setToggleUpdateParts={setToggleUpdateParts}
        />
      </Modal>
      <Modal
        //title="Partida numero 1.1.2.3"
        title={`Partida número ${partName}`}
        centered
        open={isVisibleToTalkModal}
        onCancel={() => setIsVisibleToTalkModal(false)}
        footer={null}
        width={534}
      >
        <ToTalkModal
          id={id}
          editPart={editPart}
          setIsVisibleToTalkModal={setIsVisibleToTalkModal}
          toggleUpdatePart={toggleUpdatePart}
          setToggleUpdatePart={setToggleUpdatePart}
          rejectValorizedPart={rejectValorizedPart}
          correctValorizedPart={correctValorizedPart}
          debateValorizedPart={debateValorizedPart}
          createValorizedPartLog={createValorizedPartLog}
          validateValorizedPartLog={validateValorizedPartLog}
          rejectValorizedPartLog={rejectValorizedPartLog}
          user={user}
          setPartName={setPartName}
        />
      </Modal>
      {partidas}
    </>
  );
};
