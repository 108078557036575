import React, { useState } from "react";

// Context
import { TimeContextProvider, useTimeContext } from "./context";

// Components
import NotificationsDropdown from "./components/NotificationsDropdown";
import BellContainer from "./components/BellContainer";
import Bell from "./components/Bell";

const NotificationBellContent = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { reload } = useTimeContext();

  const bellClickHandler = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <>
      {dropdownOpen ? (
        <NotificationsDropdown bellClickHandler={bellClickHandler} />
      ) : (
        <BellContainer onClick={bellClickHandler} whiteMode={false}>
          {reload ? (
            <div style={{ color: "white" }}>{2}</div>
          ) : (
            <Bell whiteMode={false} />
          )}
        </BellContainer>
      )}
    </>
  );
};

export default function NotificationsBell() {
  return (
    <TimeContextProvider>
      <NotificationBellContent />
    </TimeContextProvider>
  );
}
