import appFetcher from "../../utils/http/AppFetcher";

export const meService = async (errorHandler, successHandler) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: "/plt_user",
      isAuthRequired: true,
      requestContentType: "application/json",
      responseContentType: "json",
      method: "GET",
    });
    if (status === 200) {
      successHandler(response);
    } else {
      errorHandler();
    }
  } catch (error) {
    errorHandler();
  }
};
