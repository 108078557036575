import React, { useState } from "react";
import { Row, Col, Input } from "reactstrap";
import { IconContext } from "react-icons";
import { BiMap, BiSave } from "react-icons/bi";
import { TiPencil } from "react-icons/ti";
import { notification } from "antd";
import styled from "styled-components";

import { patchImage } from "../../providers";

import { EditProjectCard } from "../EditProjectCard/EditProjectCard";
import defaultImgProject from "../../assets/defaultImgProject.png";
import Pen from "../../assets/pen.svg";

export const EnterpriseAcron = styled.div`
    display: flex;
    background-color: #00c187;
    text-align: center;
    color: white;
    width: 60px;
    height: 14px;
    border-radius: 12px;
    display: flex;
    font-size: 10px;
    justify-content: space-around;
    align-items: center;
`;

export const BasicInformation = ({
  id,
  name,
  image,
  direction,
  role,
  general_aproved,
  updateProject,
  setToggleUpdateProject,
}) => {
  const [photo_charged, setPhoto_charged] = useState(false);
  const [photo, setPhoto] = useState(
    image ? `${image["content-type"]};base64, ${image.data}` : defaultImgProject
  );
  const [img, setImg] = useState();
  const [address, setAddress] = useState(direction);
  const [new_name, setNewName] = useState(name);
  const [supervisor, setSupervisor] = useState("");
  const [executer, setExecuter] = useState("");

  const activate_input_photo = () => {
    let element = document.getElementById("ChargePhoto");
    element.click();
  };

  const handleChange_photo = (e) => {
    setPhoto_charged(true);
    const _photo = e.target.files[0];
    setPhoto(URL.createObjectURL(_photo));
    setImg(_photo);
  };

  const charge_photo = async () => {
    let body = new FormData();
    body.append("image", img);
    try {
      const response = await patchImage(id, body);
      if (response.image) {
        notification.success({
          message: "La imagen del proyecto ha sido actualizada exitosamente",
        });
      } else {
        setPhoto(
          image
            ? `${image["content-type"]};base64, ${image.data}`
            : defaultImgProject
        );
        notification.error({
          message: response.detail[0],
        });
      }
      setPhoto_charged(false);
      setToggleUpdateProject((prev) => !prev);
    } catch (error) {
      console.log("err", error);
    }
  };

  return (
    <EditProjectCard
      classname="editProject__basicInfo"
      title="1. Información básica"
    >
      <Col className="ramp2">
        <div className="editProject__basicInfo__imgContainer">
          <img
            className={
              image || photo_charged ? "editProject__basicInfo__image" : ""
            }
            alt="project"
            src={photo}
          />
        </div>
        <Row
          className="editProject__basicInfo__editImg"
          style={{ cursor: `${role === "ADMIN" ? "pointer" : ""}` }}
        >
          <Input
            type="file"
            name="file"
            id="ChargePhoto"
            onChange={handleChange_photo}
            disabled={role !== "ADMIN"}
            hidden
          />
          <IconContext.Provider value={{ color: "rgba(95, 111, 129, 0.7)" }}>
            {photo_charged ? (
              <BiSave
                size={22}
                onClick={charge_photo}
                className="editProject__basicInfo__editImgIcon"
              />
            ) : (
              <TiPencil
                size={22}
                onClick={activate_input_photo}
                className="editProject__basicInfo__editImgIcon"
              />
            )}
          </IconContext.Provider>
        </Row>
      </Col>
      <Col className="ramp2">
        <Row className=" editProject__basicInfo__content">
          Nombre
          <div className="projectEditpen">
            Editar
            <img
            src={Pen}
            alt="edit-icon"
            style={{marginLeft:"5px"}}
            />
          </div>
        </Row>
        <Row className=" editProject__basicInfo__content">
          <Input
            className="editProject__basicInfo__addressInput"
            value={new_name}
            onFocus={(event) => event.target.select()}
            onChange={(e) => setNewName(e.target.value)}
            onBlur={() => updateProject({ name: new_name })}
            type="text"
            placeholder="Ingrese el nombre"
            disabled={general_aproved || role !== "ADMIN"}
          />
        </Row>
        <Row className=" editProject__basicInfo__content">
          Dirección
        </Row>
        <Row className=" editProject__basicInfo__content">
          <Input
            className="editProject__basicInfo__addressInput"
            value={address }
            onFocus={(event) => event.target.select()}
            onChange={(e) => setAddress(e.target.value)}
            onBlur={() => updateProject({ direction : address  })}
            type="text"
            placeholder="Ingrese la dirección"
            disabled={general_aproved || role !== "ADMIN"}
          />
        </Row>
        <Row className=" editProject__basicInfo__content">
          Supervisión
          <EnterpriseAcron
              style={{background: "#0169E4"}}
            >
              MW
            </EnterpriseAcron>
        </Row>
        <Row className=" editProject__basicInfo__content">
          <Input
            className="editProject__basicInfo__addressInput"
            value={supervisor }
            onFocus={(event) => event.target.select()}
            onChange={(e) => setSupervisor(e.target.value)}
            onBlur={""}
            type="text"
            placeholder="Ingrese el supervisor"
            disabled={general_aproved || role !== "ADMIN"}
          />
        </Row>
        <Row className=" editProject__basicInfo__content">
          Ejecución
          <EnterpriseAcron
              style={{background: "#05BE8C"}}
            >
              MW
            </EnterpriseAcron>
        </Row>
        <Row className=" editProject__basicInfo__content">
          <Input
            className="editProject__basicInfo__addressInput"
            value={executer }
            onFocus={(event) => event.target.select()}
            onChange={(e) => setExecuter(e.target.value)}
            onBlur={""}
            type="text"
            placeholder="Ingrese el ejecutor"
            disabled={general_aproved || role !== "ADMIN"}
          />
        </Row>
      </Col>
    </EditProjectCard>
  );
};
