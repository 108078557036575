import { baseProvider } from "../baseProvider";

const getCalendarDays = async () => baseProvider.httpGet(`/calendar_days`);

const getCalendarTypes = async () => baseProvider.httpGet(`/calendar_types`);

const getCalendarOptions = async (params) =>
  baseProvider.httpGet(`/calendar_options`, params);

const getHolidays = async (id, params) =>
  baseProvider.httpGet(`/projects/${id}/holidays`, params);

const postCreateHolidays = async (id, body) =>
  baseProvider.httpPost(`/projects/${id}/holidays`, body);

const deleteHoliday = async (id) =>
  baseProvider.httpDelete(`/project_holidays/${id}`);

export {
  getCalendarDays,
  getCalendarTypes,
  getCalendarOptions,
  getHolidays,
  postCreateHolidays,
  deleteHoliday,
};
