import React, { useState, useEffect } from "react";

import * as FileSaver from "file-saver";

import {
  updateDebtById,
  getDebtDocumentsById,
  downloadDebtDocumentsById,
  chargeDebtDocumentById,
  deleteDebtDocumentsById,
  sendDebtById,
} from "../../../providers";

import { ToTalkModalSkeleton } from "../../Skeletons";
import { DebtCard } from "./DebtCard";
import { Button } from "../../Button";
import { serverDateFormat } from "../../../utils";
import moment from "moment";



const DebtSetting = ({
  debt,
  setDebt,
  user,
  setReloadDebt,
  reloadDebt,
  notification,
}) => {
  //file names
  const [files, setFiles] = useState([]);
  const [charged, setCharged] = useState(true);

  // get debt info
  useEffect(() => {
    const fetchDebt = async () => {
      try {
        const _debtDocuments = await getDebtDocumentsById(debt.id);
        setFiles(_debtDocuments.results);
        setCharged(false);
      } catch (error) {
        console.log(error);
      }
    };
    debt && fetchDebt();
  }, [debt.id]);

  const handleDebtUpdate = async () => {
    try {
      setCharged(true);
      await updateDebtById(debt.id, {
        ...(debt.invoice_series && { invoice_series: debt.invoice_series }),
        ...(debt.invoice_number && { invoice_number: debt.invoice_number }),
        ...(debt.date? { date: debt.date }:{ date: moment().format(serverDateFormat) }),
      });
      setCharged(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getDebtDocument = async (document_id, name) => {
    try {
      const debt_document = await downloadDebtDocumentsById(document_id);
      const blob = await debt_document.blob();
      FileSaver.saveAs(blob, name);
    } catch (error) {
      console.log(error);
    }
  };

  const chargeDebtDocument = async (files) => {
    try {
      setCharged(true);
      for (let i=0; i < files.length; i++) {
          let body = new FormData();
          body.append("document", files[i]);
          await chargeDebtDocumentById(debt.id, body);
          const _debtDocuments = await getDebtDocumentsById(debt.id);
          setFiles(_debtDocuments.results);
      }
      setCharged(false);
    } catch (error) {
      console.log(error);
      setCharged(false);
    }
  };

  const deleteDebtDocument = async (id) => {
    try {
      setCharged(true);
      await deleteDebtDocumentsById(id);
      const _debtDocuments = await getDebtDocumentsById(debt.id);
      setFiles(_debtDocuments.results);
      setCharged(false);
    } catch (error) {
      console.log(error);
    }
  };

  const sendDebt = async () => {
    try {
      setCharged(true);
      await updateDebtById(debt.id, {
        ...(debt.invoice_series && { invoice_series: debt.invoice_series }),
        ...(debt.invoice_number && { invoice_number: debt.invoice_number }),
        ...(debt.date? { date: debt.date }:{ date: moment().format(serverDateFormat) }),
      });
      const response = await sendDebtById(debt.id);
      if (response.detail) {
        if (response.detail[0] === "Se envío con éxito la deuda") {
          notification.success({
            message: response.detail,
          });
          setReloadDebt(!reloadDebt);
        } else {
          notification.error({
            message: response.detail,
          });
        }
      } else {
        setReloadDebt(!reloadDebt);
      }
      setCharged(false);
    } catch (error) {
      console.log(error);
    }
  };

  const enableActionsExecutor =
    user === "Supervisor" &&
    (debt.status === "NO_STARTED" || debt.status === "REJECTED");
  return (
    <>
      {charged ? (
        <ToTalkModalSkeleton />
      ) : (
        <div>
          {debt.status !== "REJECTED" ? (
            <p className="projectVal__modal__description">
              En este sección podrá editar valores de la deuda.
            </p>
          ) : (
            <p className="projectVal__modal__description">
              En este sección podrá editar valores de la deuda. La deuda ha sido{" "}
              <b>RECHAZADA</b>.
            </p>
          )}
          <div className="projectVal__modal__cardsContainer">
            <DebtCard
              debt={debt}
              setDebt={setDebt}
              handleDebtUpdate={handleDebtUpdate}
              getDebtDocument={getDebtDocument}
              chargeDebtDocument={enableActionsExecutor && chargeDebtDocument}
              deleteDebtDocument={(id) =>
                enableActionsExecutor && deleteDebtDocument(id)
              }
              user={user}
              role="ADMIN"
              files={files}
            />
          </div>
          <div className="projectVal__modal__btns">
            <Button
              background={enableActionsExecutor ? "#05BE8C" : "#818181"}
              width="122px"
              fontWeight="600"
              boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
              onClick={() => {
                enableActionsExecutor && sendDebt();
              }}
            >
              Registrar deuda
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default DebtSetting;
