import React from "react";

// Styled components
import { ErrorMessage, StyledInput, StyledLink, StyledRow } from "./styles";
// Shared components
import Button from "../../../shared/components/Button";

const LoginForm = ({ credentials, error, setForm, submitHandler }) => {
  return (
    <StyledRow>
      {error.show && <ErrorMessage>{error.message}</ErrorMessage>}
      <StyledInput
        value={credentials.username}
        onChange={(e) => setForm("username", e.target.value)}
        placeholder="Usuario"
      />
      <StyledInput
        value={credentials.password}
        onChange={(e) => setForm("password", e.target.value)}
        placeholder="Contraseña"
        type="password"
      />
      <StyledLink to="/reset_password">¿Olvido su contraseña?</StyledLink>
      <StyledRow>
        <Button
          margin="15px 0px 20px 0px"
          width="152px"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
          onClick={submitHandler}
        >
          Iniciar sesión
        </Button>
      </StyledRow>
    </StyledRow>
  );
};

export default LoginForm;
