import React from "react";

// components
import {
  CounterContainer,
  CounterTitle,
  CounterSubtitle,
} from "./styles";

const Counter = ({
  title,
  subtitle,
}) => {
  return (
    <CounterContainer>
      <CounterTitle>{title}</CounterTitle>
      <CounterSubtitle>{subtitle}</CounterSubtitle>
    </CounterContainer>
  );
};

export default Counter;
