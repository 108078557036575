import React from "react";
import { IconContext } from "react-icons";

import { CardContainer, CardRow, CardContent, CardContentIcon } from "./styles";

const NewProjectCard = ({ createProject }) => {
  return (
    <CardContainer>
      <CardRow>
        <CardContent>
          <IconContext.Provider value={{ color: "gray" }}>
            <CardContentIcon size={40} onClick={createProject} />
          </IconContext.Provider>
        </CardContent>
      </CardRow>
    </CardContainer>
  );
};

export default NewProjectCard;
