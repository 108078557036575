// React import
import React, { useState, useEffect } from "react";

// Support libraries import
import { useHistory } from "react-router-dom";
import { notification } from "antd";
import * as FileSaver from "file-saver";

// Components import
import { Modal, Popconfirm } from "antd";
import Button from "../../../../shared/components/Button";
import AdditionalSettingBanner from "./components/AdditionalSettingBanner";
import TermExtensionBanner from "./components/TermExtensionBanner";
import AdditionalCreationBanner from "./components/AdditionalCreationBanner";

// Styled components import
import { ButtonContainer, ModalTitle, ModalSubTitle } from "./styles";

// Services import
import {
  getPreAdditional,
  getPreAdditionalAPU,
  getPreAdditionalBudget,
  createPreAdditional,
  updatePreAdditional,
  updatePreAdditionalAPU,
} from "../../../services";

export default function AdditionalModal({
  additionalCreationActive,
  additionalModalActive,
  additionalFinalCreationActive,
  setAdditionalCreationActive,
  setAdditionalModalActive,
  setAdditionalFinalCreationActive,
  preadditionalId = 0,
  setSpinLoading,
  projectId,
  userRole,
  setActiveSection,
  additionalTrigger,
  setAdditionalTrigger,
  project,
}) {
  const history = useHistory();
  // States
  const [showTermExtension, setShowTermExtension] = useState(false);
  const [enableEdition, setEnableEdition] = useState(false);
  const [preadditional, setPreadditional] = useState({
    order: "",
    name: "",
    reason: "",
    final_date_proposed: "",
    type: "",
  });
  const [rechargePreadditional, setRechargePreadditional] = useState(false);

  // Pop confirm states
  const [showPopConfirmCloseCreation, setShowPopConfirmCloseCreation] =
    useState(false);

  // Documents handlers
  const errorHandler = () => {
    notification.error({
      message: "Ocurrió un error en la descarga del documento",
    });
  };

  const successAPUGetHandler = (response) => {
    FileSaver.saveAs(response, preadditional.apu_filename);
  };

  const successBudgetGetHandler = (response) => {
    FileSaver.saveAs(response, preadditional.budget_filename);
  };

  const getAPU = async (id) => {
    await getPreAdditionalAPU(id, errorHandler, successAPUGetHandler);
  };

  const getBudget = async (id) => {
    await getPreAdditionalBudget(id, errorHandler, successBudgetGetHandler);
  };

  // Preadditional getting
  const preadditionalErrorHandler = () => {
    setAdditionalCreationActive(false);
    setAdditionalModalActive(false);
    notification.error({
      message: "Ocurrió un error en la obtención de la propuesta de adicional",
    });
  };

  const getPreadditionalEditionStatus = (preadditional) => {
    let enableEdition = false;
    if (
      preadditional.status === "REJECTED" ||
      preadditional.status === "VALIDATED"
    ) {
      enableEdition = false;
    } else {
      if (
        userRole === "EXECUTOR_LEADER" &&
        preadditional.approver_role === "SUPERVISOR"
      ) {
        enableEdition = true;
      }
      if (
        userRole === "SUPERVISOR_LEADER" &&
        preadditional.approver_role === "EXECUTOR"
      ) {
        enableEdition = true;
      }
      if (userRole === "ADMIN") {
        enableEdition = true;
      }
    }
    return enableEdition;
  };

  const preadditionalSuccessHandler = (preadditional) => {
    setPreadditional(preadditional);
    setShowTermExtension(preadditional.type === "TERM_EXTENSION_INCLUDED");
    setEnableEdition(getPreadditionalEditionStatus(preadditional));
  };

  // Preadditional creation
  const preadditionalCreationErrorHandler = (error, detail = "") => {
    switch (error) {
      case "limit_exceeded":
        notification.error({
          message: detail,
        });
        break;
      case "server":
      default:
        notification.error({
          message: "Ocurrió un error al configurar el adicional",
        });
        break;
    }
    setSpinLoading(false);
    setAdditionalCreationActive(false);
    setAdditionalModalActive(false);
  };

  const preadditionalCreationSuccessHandler = (response) => {
    setSpinLoading(false);
    history.push(`/projects/${project.id}/val?additional=${response.id}`);
  };

  useEffect(() => {
    preadditionalId !== 0 &&
      getPreAdditional(
        preadditionalId,
        preadditionalErrorHandler,
        preadditionalSuccessHandler
      );
  }, [rechargePreadditional, preadditionalId, userRole]);

  // Time extension handlers
  const approveTimeExtension = async () => {
    setAdditionalFinalCreationActive(false);
    setSpinLoading(true);
    await createPreAdditional(
      projectId,
      {
        type: "TERM_EXTENSION_INCLUDED",
        name: preadditional.name,
        reason: preadditional.reason,
      },
      preadditionalCreationErrorHandler,
      preadditionalCreationSuccessHandler
    );
  };

  const rejectTimeExtension = async () => {
    setAdditionalFinalCreationActive(false);
    setSpinLoading(true);
    await createPreAdditional(
      projectId,
      {
        type: "NO_TERM_CHANGED",
        name: preadditional.name,
        reason: preadditional.reason,
      },
      preadditionalCreationErrorHandler,
      preadditionalCreationSuccessHandler
    );
  };

  // Modal behavior handlers
  const nextButtonHandler = async () => {
    setSpinLoading(true);
    enableEdition &&
      (await updatePreAdditional(
        preadditionalId,
        {
          general_expenses_included: preadditional.general_expenses_included,
          is_general_expenses_percentage:
            preadditional.is_general_expenses_percentage,
        },
        preadditionalUpdateErrorHandler,
        preadditionalPreUpdateSuccessHandler
      ));
    setSpinLoading(false);
    setShowTermExtension(false);
  };

  const reloadPreadditional = () => {
    setRechargePreadditional(!rechargePreadditional);
  };

  const backButtonHandler = async () => {
    setSpinLoading(true);
    reloadPreadditional();
    setSpinLoading(false);
  };

  const showBudgetPage = () => {
    setAdditionalCreationActive(false);
    setAdditionalModalActive(false);
  };

  // Preadditional update

  const preadditionalUpdateErrorHandler = (error) => {
    setSpinLoading(false);
    notification.error({
      message:
        "Ocurrió un error en la actualización de la propuesta de adicional",
    });
  };

  const preadditionalPreUpdateSuccessHandler = (response) => {
    setSpinLoading(false);
  };

  const preadditionalUpdateSuccessHandler = (response) => {
    setSpinLoading(false);
    setAdditionalCreationActive(false);
    setAdditionalModalActive(false);
    notification.success({
      message: "Se actualizó la propuesta de adicional correctamente",
    });
  };

  const preadditionalAPUUpdateSuccessHandler = (response) => {
    notification.success({
      message:
        "Se actualizó correctamente el archivo de APU de la propuesta de adicional",
    });
  };

  const preadditionalAPUUpdateErrorHandler = (errorType, response = {}) => {
    notification.error({
      message: response[Object.keys(response)[0]],
    });
  };

  const getPreadditionalAPUUpdateBody = (file) => {
    let formData = new FormData();
    formData.append("apu", file);
    return formData;
  };

  const preadditionalAPUGetSuccessHandler = (preadditional) => {
    setPreadditional(preadditional);
    setEnableEdition(getPreadditionalEditionStatus(preadditional));
  };

  const saveAdditionalAPU = async (file) => {
    setSpinLoading(true);
    await updatePreAdditionalAPU(
      preadditionalId,
      getPreadditionalAPUUpdateBody(file),
      preadditionalAPUUpdateErrorHandler,
      preadditionalAPUUpdateSuccessHandler
    );
    await getPreAdditional(
      preadditionalId,
      preadditionalErrorHandler,
      preadditionalAPUGetSuccessHandler
    );
    setSpinLoading(false);
  };

  const saveAdditionalSettings = async () => {
    setSpinLoading(true);
    await updatePreAdditional(
      preadditionalId,
      { name: preadditional.name, reason: preadditional.reason },
      preadditionalUpdateErrorHandler,
      preadditionalUpdateSuccessHandler
    );
    setActiveSection(2);
    setAdditionalTrigger(!additionalTrigger);
  };

  return (
    <>
      <Modal
        title={<ModalTitle>¿Adicional con ampliación de plazo?</ModalTitle>}
        centered
        open={additionalCreationActive}
        onCancel={() => {
          setAdditionalCreationActive(false);
        }}
        footer={null}
        size={534}
      >
        <ModalSubTitle>
          <p>(modificación de fecha final de proyecto)</p>
        </ModalSubTitle>
        <ButtonContainer>
          <Button
            background="#05BE8C"
            width="80px"
            fontWeight="600"
            boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
            onClick={() => {
              setPreadditional({
                ...preadditional,
                type: "TERM_EXTENSION_INCLUDED",
              });
              setAdditionalCreationActive(false);
              setAdditionalFinalCreationActive(true);
            }}
          >
            Si
          </Button>
          <Button
            background="#D93022"
            width="80px"
            fontWeight="600"
            boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
            onClick={() => {
              setPreadditional({ ...preadditional, type: "NO_TERM_CHANGED" });
              setAdditionalCreationActive(false);
              setAdditionalFinalCreationActive(true);
            }}
          >
            No
          </Button>
        </ButtonContainer>
      </Modal>
      <Modal
        title={`Creación de propuesta de adicional de obra`}
        centered
        open={additionalFinalCreationActive}
        onCancel={() => {
          setShowPopConfirmCloseCreation(true);
        }}
        footer={null}
        size={534}
      >
        <Popconfirm
          placement="topLeft"
          title="No ha guardado los cambios. ¿Desea continuar?"
          open={showPopConfirmCloseCreation}
          onConfirm={() => {
            setShowPopConfirmCloseCreation(false);
            setTimeout(() => {
              setPreadditional({ ...preadditional, name: "", reason: "" });
              setAdditionalFinalCreationActive(false);
            }, 250);
          }}
          onCancel={() => {
            setShowPopConfirmCloseCreation(false);
          }}
          okText="Sí"
          cancelText="No"
        >
          <AdditionalCreationBanner
            preadditional={preadditional}
            setPreadditional={setPreadditional}
            createAdditional={() => {
              if (preadditional.name === "") {
                notification.error({
                  message:
                    "Debe ingresar un nombre para la propuesta de deductivo",
                });
                setSpinLoading(false);
              } else if (preadditional.reason === "") {
                notification.error({
                  message:
                    "Debe ingresar una justificación para la propuesta de deductivo",
                });
              } else if (preadditional.type === "TERM_EXTENSION_INCLUDED") {
                approveTimeExtension();
              } else {
                rejectTimeExtension();
              }
            }}
          />
        </Popconfirm>
      </Modal>
      <Modal
        title={
          showTermExtension
            ? `Propuesta de adicional con ampliación de plazos`
            : `Propuesta de adicional de obra número ${preadditional.id}: ${preadditional.name}`
        }
        centered
        open={additionalModalActive}
        onCancel={() => {
          setAdditionalModalActive(false);
          setActiveSection(2);
          setAdditionalTrigger(!additionalTrigger);
        }}
        footer={null}
        size={534}
      >
        {showTermExtension ? (
          <TermExtensionBanner
            nextButtonHandler={nextButtonHandler}
            preadditional={preadditional}
            setPreadditional={setPreadditional}
            project={project}
            enableEdition={enableEdition}
            reloadPreadditional={reloadPreadditional}
            setSpinLoading={setSpinLoading}
          />
        ) : (
          <AdditionalSettingBanner
            preadditional={preadditional}
            setPreadditional={setPreadditional}
            getAPU={getAPU}
            getBudget={getBudget}
            setRechargePreadditional={setRechargePreadditional}
            showBudgetPage={showBudgetPage}
            saveAdditionalAPU={saveAdditionalAPU}
            saveAdditionalSettings={saveAdditionalSettings}
            closeAdditionalModal={() => {
              setAdditionalModalActive(false);
            }}
            enableEdition={enableEdition}
            backButtonHandler={backButtonHandler}
          />
        )}
      </Modal>
    </>
  );
}
