import React, { useState, useEffect } from "react";
import { Upload, message, notification } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

import { getAdditionalPartById, patchAdditionalImagePart } from "../../../../../../providers";

import { ImageModalSkeleton } from "../../../../../../components/Skeletons";
import { Button } from "../../../../../../components/Button";

export const ImageModal = ({
  id,
  setisVisibleImageModal,
  user,
  is_active,
  executorEnable,
  setToggleUpdateParts,
}) => {
  const [photo, setPhoto] = useState();
  const [image, setImage] = useState(null);
  const [part, setPart] = useState();
  const [toggleUpdatePart, setToggleUpdatePart] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setPart();
    const fetchPart = async () => {
      try {
        const _part = await getAdditionalPartById(id);
        //console.log("partida", _part);
        setPart(_part);
        setPhoto(
          _part.image
            ? `${_part.image["content-type"]};base64, ${_part.image.data}`
            : null
        );
        setIsDisabled(
          user === "Supervisor" ||
            !(
              _part?.status === "NO_STARTED" ||
              _part?.status === "TO_SEND" ||
              _part?.status === "PRE_SENDED"
            ) ||
            !executorEnable
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchPart();
  }, [id, toggleUpdatePart, executorEnable, user]);

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("La imagen debe ser de tipo jpeg/png!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("La imagen debe pesar menos de 2MB!");
    }
    setLoading(true);
    setTimeout(() => {
      handleChange_photo(file);
    }, 1000);

    return isJpgOrPng && isLt2M;
  }

  const handleChange_photo = (img) => {
    setLoading(false);
    setImage(img);
    setPhoto(URL.createObjectURL(img));
  };

  const charge_photo = async () => {
    let body = new FormData();
    body.append("image", image);
    try {
      const response = await patchAdditionalImagePart(id, body);
      //console.log("response image", response);
      if (response.id) {
        if (response.exist_image) {
          setisVisibleImageModal(false);
          notification.success({
            message: "La imagen de la partida ha sido actualizada exitosamente",
          });
        } else {
          notification.success({
            message: "La imagen de la partida ha sido eliminada exitosamente",
          });
        }
        setImage(null);
        setPhoto(null);
        setToggleUpdatePart((prev) => !prev);
        setToggleUpdateParts((prev) => !prev);
      } else {
        notification.error({
          message: "Ocurrió un error. Por favor vuelva a intentarlo",
        });
      }
      // setToggleUpdateProject((prev) => !prev);
    } catch (error) {
      console.log("err", error);
    }
  };

  return (
    <>
      {!part ? (
        <ImageModalSkeleton />
      ) : (
        <div>
          <Upload
            listType="picture-card"
            className="imageModal__image"
            showUploadList={false}
            beforeUpload={beforeUpload}
            disabled={user === "Supervisor" || part?.is_validated || !is_active}
          >
            {photo ? (
              <img
                src={photo}
                alt="img"
                style={{ width: "100%", height: "100%" }}
              />
            ) : (
              <div>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            )}
          </Upload>
          <br />
          <div className="imageModal__btns">
            {part?.image?.data && !image ? (
              <Button
                onClick={charge_photo}
                disabled={!image}
                background="#05BE8C"
                width="122px"
                fontWeight="600"
                boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
              >
                <a
                  href={`${part.image["content-type"]};base64,${part.image.data}`}
                  download="image_project.png"
                >
                  Descargar
                </a>
              </Button>
            ) : (
              <Button
                onClick={charge_photo}
                disabled={!image}
                background="#05BE8C"
                width="122px"
                fontWeight="600"
                boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
              >
                Guardar
              </Button>
            )}
            {part?.image?.data && !image && (
              <Button
                onClick={charge_photo}
                width="122px"
                fontWeight="600"
                background={isDisabled ? "#f5f5f5" : "#D93022"}
                color={isDisabled && "#00000040"}
                border={isDisabled && "1px solid rgba(95, 111, 129, 0.5)"}
                boxShadow={!isDisabled && "0px 1px 5px rgba(0, 0, 0, 0.25)"}
                disabled={isDisabled}
              >
                Borrar
              </Button>
            )}
            <Button
              onClick={() => {
                setisVisibleImageModal(false);
                setImage(null);
                setPhoto(
                  part.image
                    ? `${part.image["content-type"]};base64, ${part.image.data}`
                    : null
                );
              }}
              width="122px"
              fontWeight="600"
              background="#D93022"
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.25)"
            >
              Cancelar
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
