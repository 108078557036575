import React from "react";
import { notification } from "antd";

import { Button } from "../Button";
import "./MateCard.css";
import profile from "../../assets/profile.jpg";

// Context
import { useAppContext } from "../../modules/App/context";

export const MateCard = ({
  id,
  user,
  position,
  enterprise,
  image,
  btnsVisible,
  background,
  leaderColor,
  is_admin,
  is_leader,
  marginBottom,
  cursor,
  setToggleUpdateTeam,
  data,
  role,
  setAllTeam,
}) => {
  const { user: currentUser } = useAppContext();

  const revokeMateFromProject = () => {
    if (is_admin && user.username === currentUser?.user?.username) {
      notification.error({
        message: "Un administrador de proyecto no puede auto revocarse",
      });
      return;
    }
    setAllTeam((prev) => ({
      ...prev,
      [role]: prev[role].filter((elem) => elem.id !== id),
    }));

    notification.success({
      message: "Se ha revocado al compañero del rol",
    });

    setToggleUpdateTeam((prev) => !prev);
  };

  const assignMateAsLeader = () => {
    if (is_admin && user.username === currentUser?.user?.username) {
      notification.error({
        message:
          "Un administrador de proyecto no puede auto asignarse como líder",
      });
      return;
    }
    setAllTeam((prev) => ({
      ...prev,
      [role]: [
        ...prev[role]
          .map((elem) => ({
            ...elem,
            is_leader: false,
          }))
          .filter((elem) => elem.id !== id),
        { ...data, is_leader: true },
      ].sort((a, b) => a.id - b.id),
    }));

    notification.success({
      message: "Se ha asignado al compañero como líder",
    });

    setToggleUpdateTeam((prev) => !prev);
  };

  return (
    <div className="mateCard_container" style={{ marginBottom, cursor }}>
      <div className="mateCard_header" style={{ background }}></div>
      {is_leader && (
        <div
          className="mateCard_leader"
          style={{ background: `${leaderColor}` }}
        >
          <span style={{ verticalAlign: "middle" }}>LIDER</span>
        </div>
      )}
      <div className="mateCard_containerImg">
        <img
          src={
            image ? `${image["content-type"]};base64, ${image.data}` : profile
          }
          className="mateCard_image"
          alt="img"
        />
      </div>
      <div className="mateCard_body">
        <div>
          <p className="mateCard_name mateCard_bold">
            {user?.first_name} {user?.last_name}
          </p>
          <p className="mateCard_position mateCard_bold">{position}</p>
          <div className="mateCard_flex">
            <p>Usuario:</p>
            <p className="mateCard_bold mateCard_secondItem">
              {user?.username}
            </p>
          </div>
          <div className="mateCard_flex">
            <p>Empresa:</p>
            <p className="mateCard_bold mateCard_secondItem">{enterprise}</p>
          </div>
          <div className="mateCard_flex">
            <p>Correo:</p>
            <p className="mateCard_bold mateCard_secondItem">{user?.email}</p>
          </div>
        </div>
        {btnsVisible && (
          <div className="mateCard_flex">
            <Button
              width="89px"
              background={is_leader ? "#0169E4" : "#fff"}
              border={is_leader ? "none" : "1px solid #0169E4"}
              color={!is_leader && "#0169E4"}
              boxShadow={is_leader && "0px 2px 8px rgba(0, 0, 0, 0.25)"}
              onClick={assignMateAsLeader}
              className="mateCard_btn"
            >
              LÍDER
            </Button>
            <Button
              width="89px"
              background="#D93022"
              boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
              border="none"
              onClick={revokeMateFromProject}
              className="mateCard_btn"
            >
              REVOCAR
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

MateCard.defaultProps = {
  isLeader: false,
  btnsVisible: true,
};
