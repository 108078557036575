import React from "react";
import "./styles.css";
import { ItemDiv } from "../styles";

const Headers = ({ showMobileModal }) => {
  return (
    <div
      className={
        showMobileModal
          ? "valorizations_summary_table_headers_mobile"
          : "valorizations_summary_table_headers"
      }
    >
      <ItemDiv width="120px">
        <p className="blue bold font14 projectVal__mb1">Estado general</p>
      </ItemDiv>
      <ItemDiv width="140px">
        <p className="blue bold font14 projectVal__mb1">Valorización firmada</p>
      </ItemDiv>
      <ItemDiv width="137px">
        <p className="blue bold font14 projectVal__mb1">Factura enviada</p>
      </ItemDiv>
      <ItemDiv width="137px">
        <p className="blue bold font14 projectVal__mb1">Pago realizado</p>
      </ItemDiv>
    </div>
  );
};

export default Headers;
