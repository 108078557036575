import React from "react";
import { Row } from "reactstrap";

import { convertTwoDigits } from "../../../../../utils";

// Components imports
import ActionButtonsPanel from "./components/ActionButtonsPanel";
import MainButtonsPanel from "./components/MainButtonsPanel";
import Counter from "./components/Counter";
import ProjectInfoPanel from "./components/ProjectInfoPanel";
import ProjectImagePanel from "./components/ProjectImagePanel";
import ProjectImagePanelmin from "./components/ProjectImagePanelmin";
import TutorialButton from "./components/TutorialButton";

// Styled components import
import { CountersContainer, CountersRow, SectionLabel } from "./styles";

export const Qrome = ({
  user,
  presentValorization,
  lastValorization,
  image,
  general_aproved,
  project,
  history,
  valorizations = [],
  setCurrentValorization,
  currentValorization,
  sendpresentValorization,
  evaluatepresentValorization,
  loadFinalReportValorization,
  loadValorizationSendReportValorization,
  loadValorizationEvaluationReportValorization,
  loadValorizationFinalReportValorization,
  handleModalVideoTurial,
  screenSettings,
  setDebtModalActive,
  projectDeliveryCreationHandler,
  setAmountsDatesModalActive,
  setLiquidationModalActive,
}) => {
  let activeAgeLabel = "Envío de valorización";
  let activeAgeDays = convertTwoDigits(
    lastValorization?.meter_entering_left?.days
  );
  let activeAgeHours = convertTwoDigits(
    lastValorization?.meter_entering_left?.hours
  );
  let activeAgeMins = convertTwoDigits(
    lastValorization?.meter_entering_left?.mins
  );
  if (lastValorization?.meter_validation_left?.active) {
    activeAgeLabel = "Validación de valorización";
    activeAgeDays = convertTwoDigits(
      lastValorization?.meter_validation_left?.days
    );
    activeAgeHours = convertTwoDigits(
      lastValorization?.meter_validation_left?.hours
    );
    activeAgeMins = convertTwoDigits(
      lastValorization?.meter_validation_left?.mins
    );
  }
  if (lastValorization?.differents_closing_left?.active) {
    activeAgeLabel = "Cierre de diferencias";
    activeAgeDays = convertTwoDigits(
      lastValorization?.differents_closing_left?.days
    );
    activeAgeHours = convertTwoDigits(
      lastValorization?.differents_closing_left?.hours
    );
    activeAgeMins = convertTwoDigits(
      lastValorization?.differents_closing_left?.mins
    );
  }
  if (lastValorization?.in_anticipated_signature) {
    activeAgeLabel = "Cierre anticipado de valorización";
    activeAgeDays = convertTwoDigits(
      presentValorization?.valorization_left?.days
    );
    activeAgeHours = convertTwoDigits(
      presentValorization?.valorization_left?.hours
    );
    activeAgeMins = convertTwoDigits(
      presentValorization?.valorization_left?.mins
    );
  }

  const getCurrentUTCDate = () => {
    const now = new Date(project.project_date);
    const date = now.getUTCDate().toString().padStart(2, "0");
    const month = (now.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = now.getUTCFullYear().toString().slice(-2);
    return `${date}.${month}.${year}`;
  };

  const datenow = getCurrentUTCDate();

  let status_text = "";

  function projectStatus() {
    if (project?.status < -2) {
      status_text = "Retrasada";
      if (project?.status < -10) {
        status_text = "Muy Retrasada";
      }
    } else {
      status_text = "En tiempo";
      if (project?.status > 2) {
        status_text = "Avanzada";
        if (project?.status > 10) {
          status_text = "Muy Avanzada";
        }
      }
    }
  }

  projectStatus();

  return (
    <Row className="ramp projectVal__qrome_val">
      <MainButtonsPanel
        user={user}
        valorizations={valorizations}
        setCurrentValorization={setCurrentValorization}
        currentValorization={currentValorization}
        lastValorization={lastValorization}
        general_aproved={general_aproved}
        loadFinalReportValorization={loadFinalReportValorization}
        loadValorizationSendReportValorization={
          loadValorizationSendReportValorization
        }
        loadValorizationEvaluationReportValorization={
          loadValorizationEvaluationReportValorization
        }
        loadValorizationFinalReportValorization={
          loadValorizationFinalReportValorization
        }
        sendpresentValorization={sendpresentValorization}
        evaluatepresentValorization={evaluatepresentValorization}
      />
      {/*<ProjectImagePanelmin history={history} project={project} image={image} />*/}
      <CountersContainer>
        <CountersRow>
          <Counter
            title={`Proceso de cierre de valorización N°${currentValorization.order}`}
            subtitle={
              currentValorization.is_closed
                ? "Cierre de diferencias"
                : currentValorization.id === lastValorization.id
                ? activeAgeLabel
                : "Envío de valorización"
            }
            days={
              currentValorization.is_closed
                ? "00"
                : currentValorization.id === lastValorization.id
                ? activeAgeDays
                : convertTwoDigits(project?.meter_entered_days)
            }
            hours={
              currentValorization.is_closed
                ? "00"
                : currentValorization.id === lastValorization.id
                ? activeAgeHours
                : "00"
            }
            min={
              currentValorization.is_closed
                ? "00"
                : currentValorization.id === lastValorization.id
                ? activeAgeMins
                : "00"
            }
          />
          <Counter
            title={`Valorización N° ${currentValorization.order}`}
            currentValorization={currentValorization}
            subtitle="Cierre de valorización"
            days={
              currentValorization.is_closed
                ? "00"
                : currentValorization.id === presentValorization.id
                ? convertTwoDigits(presentValorization?.valorization_left?.days)
                : currentValorization.id === lastValorization.id
                ? "00"
                : "15"
            }
            //TODO: Cambiar el 15 por un valor real
            hours={
              currentValorization.is_closed
                ? "00"
                : currentValorization.id === presentValorization.id
                ? convertTwoDigits(
                    presentValorization?.valorization_left?.hours
                  )
                : "00"
            }
            min={
              currentValorization.is_closed
                ? "00"
                : currentValorization.id === presentValorization.id
                ? convertTwoDigits(presentValorization?.valorization_left?.mins)
                : "00"
            }
          />
        </CountersRow>
        <CountersRow>
          <SectionLabel>Proceso de firma de valorización</SectionLabel>
          {screenSettings.show_tutorial_videos === true ? (
            <TutorialButton
              handleModalVideoTurial={handleModalVideoTurial}
              videourl={screenSettings.sign_process_tutorial_url}
            />
          ) : (
            <></>
          )}
        </CountersRow>
      </CountersContainer>
      <ActionButtonsPanel
        setDebtModalActive={setDebtModalActive}
        projectDeliveryCreationHandler={projectDeliveryCreationHandler}
        setAmountsDatesModalActive={setAmountsDatesModalActive}
        setLiquidationModalActive={setLiquidationModalActive}
        handleModalVideoTurial={handleModalVideoTurial}
        screenSettings={screenSettings}
      />
      <ProjectInfoPanel
        datetoday={datenow}
        history={history}
        project={project}
        image={image}
        status_text={status_text}
        component={ProjectImagePanel}
      ></ProjectInfoPanel>
    </Row>
  );
};
