import React, { useState } from "react";
import { Row } from "reactstrap";
import dayjs from "dayjs";
import moment from "moment";

import { Table, Input, DatePicker, Popconfirm, notification } from "antd";

// Services import
import { postCreateHolidays, deleteHoliday } from "../../providers";

// Utils import
import { serverDateFormat } from "../../utils";

import { Button } from "../Button";
const columns = [
  {
    title: "Nombre",
    dataIndex: "name",
  },
  {
    title: "Fecha",
    dataIndex: "date",
  },
  {
    title: "Día se semana",
    dataIndex: "day_name",
  },
];
export const EditHolidaysModal = ({ holidays, projectId, reloadHolidays }) => {
  // Component states
  const [name, setName] = useState("");
  const [date, setDate] = useState(moment(new Date()).format(serverDateFormat));
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showPopConfirm, setShowPopConfirm] = useState(false);
  const [showPopDeleteConfirm, setShowPopDeleteConfirm] = useState(false);

  // Handlers
  const addHolidayHandler = async () => {
    try {
      const response = await postCreateHolidays(projectId, {
        name,
        date,
      });
      debugger;
      if (response.id) {
        notification.success({ message: "Feriado agregado correctamente" });
        setName("");
        setDate(moment(new Date()).format(serverDateFormat));
        reloadHolidays();
      } else {
        notification.error({
          message:
            Object.values(response)[0][0] ||
            "Hubo un error al agregar el feriado",
        });
      }
    } catch (error) {
      notification.error({ message: "Hubo un error al agregar el feriado" });
    }
  };

  const deleteHolidaysHandler = async () => {
    try {
      for (let i = 0; i < selectedRowKeys.length; i++) {
        await deleteHoliday(selectedRowKeys[i]);
      }
      notification.success({
        message: "Feriados eliminados correctamente",
      });
      setSelectedRowKeys([]);
      reloadHolidays();
    } catch (error) {
      notification.error({
        message: "Hubo un error al eliminar los feriados",
      });
    }
  };

  return (
    <div>
      <Row className="ramp editProject__rowCards">
        <div className="editProject__modals__container editProject__mb2">
          <p className="editProject__mb0">Nombre</p>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            onFocus={(event) => event.target.select()}
            className=" editProject__ml"
            type="text"
            placeholder="Descripción"
          />
        </div>
        <div className="editProject__modals__container editProject__mb2">
          <p className="editProject__mb0">Fecha</p>
          <DatePicker
            className="editProject__ml"
            value={dayjs(date)}
            onChange={async (value) => {
              setDate(moment(value.toDate()).format(serverDateFormat));
            }}
          />
        </div>
        <Popconfirm
          placement="topLeft"
          title="¿Esta seguro de agregar el feriado?"
          open={showPopConfirm}
          onConfirm={() => {
            setShowPopConfirm(false);
            addHolidayHandler();
          }}
          onCancel={() => {
            setShowPopConfirm(false);
          }}
          okText="Sí"
          cancelText="No"
        >
          <Button
            width="100px"
            background="#0169E4"
            boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
            className="editProject__mb2"
            onClick={() => setShowPopConfirm(true)}
          >
            Agregar
          </Button>
        </Popconfirm>
      </Row>
      <Row className="ramp editProject__rowCards editProject__mb2">
        <p
          className="blue bold font14 editProject__mb0"
          style={{ marginTop: "5px" }}
        >
          Lista de feriados
        </p>
        <Popconfirm
          placement="topLeft"
          title="¿Esta seguro de eliminar los feriados seleccionados?"
          open={showPopDeleteConfirm}
          onConfirm={() => {
            deleteHolidaysHandler();
            setShowPopDeleteConfirm(false);
          }}
          onCancel={() => {
            setShowPopDeleteConfirm(false);
          }}
          okText="Sí"
          cancelText="No"
        >
          <Button
            width="100px"
            background="#ff4d4f"
            color="#FFFFFF"
            border="1px solid #ff4d4f"
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.25)"
            danger
            onClick={() => setShowPopDeleteConfirm(true)}
          >
            Eliminar
          </Button>
        </Popconfirm>
      </Row>
      <div>
        <Table
          rowSelection={{
            type: "checkbox",
            selectedRowKeys,
            onChange: (selectedRowKeys) => {
              setSelectedRowKeys(selectedRowKeys);
            },
          }}
          columns={columns}
          dataSource={holidays}
          pagination={false}
          scroll={{ y: 300 }}
        />
      </div>
      <br />
    </div>
  );
};
