import styled from "styled-components";

export const ItemDiv = styled.div`
  width: ${({ width }) => width};
  text-align: center;
`;

export const TableItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  width: 420px;
`;
