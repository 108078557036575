// React import
import React, { useState, useEffect } from "react";

// Third part libraries import
import { DatePicker, notification } from "antd";
import * as FileSaver from "file-saver";
import dayjs from "dayjs";
import moment from "moment";

// Components import
import ApprovalPanel from "../ApprovalPanel";
import ChargeFileButton from "../ChargeFileButton";
import FileItem from "../FileItem";
import { ItemContainer } from "./styles";

// Services import
import { getProjectDeliveryObservationsList } from "../../../../providers";
import {
  approveProjectDeliveryObservationsList,
  updateObservationsList,
  getObservationsListDocument,
} from "../../services";

// Utils import
import { clientDateFormat, serverDateFormat } from "../../../../utils";

const ObservationsListModal = ({
  id,
  userRole,
  projectDeliverySummary,
  reloadProjectDelivery,
  setLoading,
}) => {
  const [observationsList, setObservationsList] = useState({
    super_approved_observations_list: false,
    exec_approved_observations_list: false,
    observations_list_filename: "",
    observations_list: null,
    observations_list_load_date: null,
  });
  const [triggerObservationsList, setTriggerObservationsList] = useState(true);
  const disableForm =
    !projectDeliverySummary.set_observations_list_enabled ||
    userRole === "SUPERVISOR" ||
    userRole === "EXECUTOR";

  useEffect(() => {
    const fetchObservationsList = async () => {
      try {
        setLoading(true);
        const response = await getProjectDeliveryObservationsList(id);
        setObservationsList(response);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchObservationsList();
  }, [id, triggerObservationsList]);

  // Handler approbation
  const approveSuccessHandler = (response) => {
    notification.success({
      message: Object.values(response)[0][0],
    });
    setLoading(false);
    reloadProjectDelivery();
    setTriggerObservationsList(!triggerObservationsList);
  };

  const approveErrorHandler = (response) => {
    notification.error({
      message: response
        ? Object.values(response)[0][0]
        : "Ocurrió un error al aprobar el listado de observaciones",
    });
    setLoading(false);
  };

  const approveHandler = async () => {
    setLoading(true);
    await approveProjectDeliveryObservationsList(
      id,
      approveErrorHandler,
      approveSuccessHandler
    );
  };

  // Handler update
  const updateSuccessHandler = (response) => {
    notification.success({
      message: "Se actualizo correctamente el listado de observaciones de obra",
    });
    setLoading(false);
    setTriggerObservationsList(!triggerObservationsList);
  };

  const updateErrorHandler = (response) => {
    notification.error({
      message: response
        ? Object.values(response)[0][0]
        : "Ocurrió un error al actualizar el listado de observaciones de obra",
    });
    setLoading(false);
  };

  const getUpdateBody = (_observationsList) => {
    let formData = new FormData();
    _observationsList.observations_list &&
      formData.append("observations_list", _observationsList.observations_list);
    _observationsList.observations_list_load_date &&
      formData.append(
        "observations_list_load_date",
        _observationsList.observations_list_load_date
      );
    return formData;
  };

  const updateHandler = async (item, label) => {
    setLoading(true);
    const _observationsList = { ...observationsList, [label]: item };
    const updateBody = getUpdateBody(_observationsList);
    await updateObservationsList(
      id,
      updateBody,
      updateErrorHandler,
      updateSuccessHandler
    );
  };

  // Handler get document
  const documentGetErrorHandler = () => {
    notification.error({
      message: "Ocurrió un error en la descarga del listado de observaciones",
    });
  };

  const documentGetSuccessHandler = (response) => {
    FileSaver.saveAs(response, observationsList.observations_list_filename);
  };

  const getDocument = async (id) => {
    await getObservationsListDocument(
      id,
      documentGetErrorHandler,
      documentGetSuccessHandler
    );
  };

  return (
    <>
      <p className="blue bold font14" style={{ marginBottom: "2px" }}>
        Listado de observaciones:
      </p>
      <div className="projectVal__card__items">
        <ChargeFileButton
          chargeDocument={async (file) => {
            !disableForm && (await updateHandler(file, "observations_list"));
          }}
        />
        <FileItem
          fileName={observationsList.observations_list_filename}
          getFile={() => getDocument(id)}
        />
      </div>
      <ItemContainer>
        <p
          className="blue bold font14"
          style={{ marginBottom: "16px", marginTop: "16px" }}
        >
          Fecha de suscripción de listado de observaciones:
        </p>
        <DatePicker
          value={dayjs(
            observationsList.observations_list_load_date || new Date()
          )}
          onChange={async (value) => {
            await updateHandler(
              moment(value.toDate()).format(serverDateFormat),
              "observations_list_load_date"
            );
          }}
          format={clientDateFormat}
          disabled={disableForm}
          style={{ width: "150px" }}
        />
      </ItemContainer>
      <div>
        <ApprovalPanel
          label="Aprobar listado de observaciones"
          approvalEnable={projectDeliverySummary.set_observations_list_enabled}
          approveHandler={() => {
            approveHandler();
          }}
          userRole={userRole}
          superApproved={observationsList.super_approved_observations_list}
          execApproved={observationsList.exec_approved_observations_list}
        />
      </div>
    </>
  );
};

export default ObservationsListModal;
