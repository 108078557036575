import React, { useState, useEffect } from "react";

// Third part libraries import
import { Input, DatePicker, notification } from "antd";
import dayjs from "dayjs";
import moment from "moment";

// Components import
import ApprovalPanel from "../ApprovalPanel";
import { ItemContainer } from "./styles";

// Services import
import { getProjectDeliveryObservationsListPeriod } from "../../../../providers";
import {
  approveProjectDeliveryObservationsListPeriod,
  updateObservationsListPeriod,
} from "../../services";

// Utils import
import { clientDateFormat, serverDateFormat } from "../../../../utils";

const ObservationsListPeriodModal = ({
  id,
  userRole,
  projectDeliverySummary,
  reloadProjectDelivery,
  setLoading,
}) => {
  const [observationsListPeriod, setObservationsListPeriod] = useState({
    super_approved_observations_list_period: false,
    exec_approved_observations_list_period: false,
  });
  const [triggerObservationsListPeriod, setTriggerObservationsListPeriod] =
    useState(true);
  const [periodDuration, setPeriodDuration] = useState(1);

  const disableForm =
    !projectDeliverySummary.set_observations_list_period_enabled ||
    userRole === "SUPERVISOR" ||
    userRole === "EXECUTOR";

  useEffect(() => {
    const fetchObservationsListPeriod = async () => {
      try {
        setLoading(true);
        const response = await getProjectDeliveryObservationsListPeriod(id);
        setObservationsListPeriod(response);
        setPeriodDuration(response.observations_list_days);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchObservationsListPeriod();
  }, [id, triggerObservationsListPeriod]);

  // Handler approbation
  const approveSuccessHandler = (response) => {
    notification.success({
      message: Object.values(response)[0][0],
    });
    setLoading(false);
    reloadProjectDelivery();
    setTriggerObservationsListPeriod(!triggerObservationsListPeriod);
  };

  const approveErrorHandler = (response) => {
    notification.error({
      message: response
        ? Object.values(response)[0][0]
        : "Ocurrió un error al aprobar el periodo de listado de observaciones",
    });
    setLoading(false);
  };

  const approveHandler = async () => {
    setLoading(true);
    await approveProjectDeliveryObservationsListPeriod(
      id,
      approveErrorHandler,
      approveSuccessHandler
    );
  };

  // Handler update
  const updateSuccessHandler = (response) => {
    notification.success({
      message:
        "Se actualizo correctamente el periodo de listado de observaciones",
    });
    setLoading(false);
    setTriggerObservationsListPeriod(!triggerObservationsListPeriod);
  };

  const updateErrorHandler = (response) => {
    notification.error({
      message: response
        ? Object.values(response)[0][0]
        : "Ocurrió un error al actualizar el periodo de listado de observaciones",
    });
    setLoading(false);
  };

  const getUpdateBody = (_observationsListPeriod, label) => {
    let formData = new FormData();
    _observationsListPeriod.observations_list_finish_date &&
      label === "observations_list_finish_date" &&
      formData.append(
        "observations_list_finish_date",
        _observationsListPeriod.observations_list_finish_date
      );
    _observationsListPeriod.observations_list_days &&
      label === "observations_list_days" &&
      formData.append(
        "observations_list_days",
        _observationsListPeriod.observations_list_days
      );
    return formData;
  };

  const updateHandler = async (item, label) => {
    setLoading(true);
    const _observationsListPeriod = {
      ...observationsListPeriod,
      [label]: item,
    };
    const updateBody = getUpdateBody(_observationsListPeriod, label);
    await updateObservationsListPeriod(
      id,
      updateBody,
      updateErrorHandler,
      updateSuccessHandler
    );
  };

  return (
    <>
      <ItemContainer>
        <p
          className="blue bold font14"
          style={{ marginBottom: "16px", marginTop: "16px" }}
        >
          Fecha de inicio de periodo:
        </p>
        <DatePicker
          value={dayjs(
            observationsListPeriod.observations_list_start_date || new Date()
          )}
          onChange={async (value) => {}}
          format={clientDateFormat}
          disabled={true}
          style={{ width: "150px" }}
        />
      </ItemContainer>
      <ItemContainer>
        <p
          className="blue bold font14"
          style={{ marginBottom: "16px", marginTop: "16px" }}
        >
          Fecha de fin de periodo:
        </p>
        <DatePicker
          value={dayjs(
            observationsListPeriod.observations_list_finish_date || new Date()
          )}
          onChange={async (value) => {
            await updateHandler(
              moment(value.toDate()).format(serverDateFormat),
              "observations_list_finish_date"
            );
          }}
          format={clientDateFormat}
          disabled={disableForm}
          style={{ width: "150px" }}
        />
      </ItemContainer>
      <ItemContainer>
        <p
          className="blue bold font14"
          style={{ marginBottom: "16px", marginTop: "16px" }}
        >
          Duración de periodo:
        </p>
        <Input
          style={{ width: "70px" }}
          value={periodDuration}
          onFocus={(event) => event.target.select()}
          onChange={(event) => {
            setPeriodDuration(event.target.value.replace(/[^\d]/g, ""));
          }}
          onBlur={async () => {
            await updateHandler(periodDuration, "observations_list_days");
          }}
          disabled={disableForm}
        />
      </ItemContainer>
      <div>
        <ApprovalPanel
          label="Aprobar periodo de listado de observaciones"
          approvalEnable={
            projectDeliverySummary.set_observations_list_period_enabled
          }
          approveHandler={() => {
            approveHandler();
          }}
          userRole={userRole}
          superApproved={
            observationsListPeriod.super_approved_observations_list_period
          }
          execApproved={
            observationsListPeriod.exec_approved_observations_list_period
          }
        />
      </div>
    </>
  );
};

export default ObservationsListPeriodModal;
