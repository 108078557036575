// basic imports
import styled from "styled-components";

export const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 14px;
  gap: 12px;
`;

export const PanelSuperContainer = styled.div`
  display: flex;
  height: 140px;
  flex-direction: row;
  gap: 12px;
`;
