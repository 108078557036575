import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

//pages
import ResetPasswordPage from "../pages/ResetPasswordPage";

const ResetPasswordRouter = () => {
  return (
    <Switch>
      <Route exact path={"/reset_password"} component={ResetPasswordPage} />
      <Redirect to="/reset_password" />
    </Switch>
  );
};

export default ResetPasswordRouter;