import React from "react";
import { ItemDiv, TableItemContainer } from "./styles";

const Headers = ({ showMobileModal }) => {
  return (
    <TableItemContainer>
      <ItemDiv width="230px">
        <p className="blue bold font14 projectVal__mb1"></p>
      </ItemDiv>
      <ItemDiv width="150px">
        <p className="blue bold font14 projectVal__mb1">Estado</p>
      </ItemDiv>
    </TableItemContainer>
  );
};

export default Headers;
