// React import
import React, { useState } from "react";

// Libraries import
import { notification } from "antd";

// Styled components import
import {
  SectionContainer,
  TimelineContainer,
  ScheduleControlContainer,
} from "./styles";

// Custom components import
import Timeline from "./components/Timeline";
import ScheduleControl from "./components/ScheduleControl";

// Services import
import { editScheduleEditionProposal } from "../../../services";

const TimelineSection = ({
  projectId,
  project,
  currentValorization,
  userRole,
  setLoading,
}) => {
  const [scheduleModificationActive, setScheduleModificationActive] =
    useState(false);
  const [isProposalActive, setIsProposalActive] = useState(false);
  const [timelineTrigger, setTimelineTrigger] = useState(false);

  const [scheduleEditionProposal, setScheduleEditionProposal] = useState({
    valorization_final_date: "",
    meter_entering_final_date: "",
    meter_validation_final_date: "",
    differences_closing_final_date: "",
  });

  const [trigger, setTrigger] = useState(true);

  const errorHandler = (errorType, response = {}) => {
    switch (errorType) {
      case "bad_request":
        notification.error({
          message: response[Object.keys(response)[0]],
        });
        break;
      case "server":
        notification.error({
          message: "Hubo un error en el servidor",
        });
        break;
      default:
        notification.error({
          message: "Hubo un error en el servidor",
        });
        break;
    }
  };

  const successHandler = () => {
    notification.success({
      message:
        "Se realizo correctamente el registro de la modificación de fechas",
    });
    setTrigger(!trigger);
    rechargeTimeline();
  };

  const registerScheduleEditionProposal = async () => {
    setLoading(true);
    const body = {
      valorization_final_date: scheduleEditionProposal.valorization_final_date,
      meter_entering_final_date:
        scheduleEditionProposal.meter_entering_final_date,
      meter_validation_final_date:
        scheduleEditionProposal.meter_validation_final_date,
      differences_closing_final_date:
        scheduleEditionProposal.differences_closing_final_date,
    };
    await editScheduleEditionProposal(
      currentValorization.id,
      body,
      errorHandler,
      successHandler
    );
    setLoading(false);
  };

  let modifierActive =
    userRole === "ADMIN" ||
    userRole === "SUPERVISOR_LEADER" ||
    userRole === "EXECUTOR_LEADER";

  let rechargeTimeline = () => setTimelineTrigger(!timelineTrigger);

  return (
    <SectionContainer>
      <TimelineContainer>
        <Timeline
          id={projectId}
          project={project}
          scheduleModificationActive={scheduleModificationActive}
          currentValorization={currentValorization}
          userRole={userRole}
          scheduleEditionProposal={scheduleEditionProposal}
          setScheduleEditionProposal={setScheduleEditionProposal}
          setIsProposalActive={setIsProposalActive}
          setLoading={setLoading}
          setTrigger={setTrigger}
          trigger={trigger}
          timelineTrigger={timelineTrigger}
        />
      </TimelineContainer>
      <ScheduleControlContainer>
        <ScheduleControl
          modifierActive={modifierActive}
          currentValorization={currentValorization}
          userRole={userRole}
          setLoading={setLoading}
          scheduleModificationActive={scheduleModificationActive}
          setScheduleModificationActive={setScheduleModificationActive}
          scheduleEditionProposal={scheduleEditionProposal}
          registerScheduleEditionProposal={registerScheduleEditionProposal}
          isProposalActive={isProposalActive}
          setTrigger={setTrigger}
          trigger={trigger}
          rechargeTimeline={rechargeTimeline}
        />
      </ScheduleControlContainer>
    </SectionContainer>
  );
};
export default TimelineSection;
