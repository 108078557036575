// React import
import React from "react";

// Components import
import NewProjectCard from "../NewProjectCard";
import ProjectCard from "../ProjectCard";

// Styles import
import { BannerContainer } from "./styles";

export default function ProjectsBanner({
  projects = [],
  openProjectModal,
}) {

  return (
    <div>
      <BannerContainer>
        {projects?.map((project) => (
          <ProjectCard key={project.id} {...project} />
        ))}

        <NewProjectCard createProject={() => openProjectModal()} />
      </BannerContainer>
    </div>
  );
}
