import React from "react";
import { BaseButton } from "./styles";

const TableButton = ({ title, active, onClick }) => {
  return (
    <BaseButton active={active} onClick={() => onClick()}>
      {title}
    </BaseButton>
  );
};

export default TableButton;
