import React from "react";
import Item from "./Item";
import ChargeFileButton from "./ChargeFileButton";
import FileList from "./FileList";
import { DatePicker } from "antd";
import { clientDateFormat, serverDateFormat } from "../../../utils";
import dayjs from "dayjs";
import moment from "moment";

export const PaymentCard = ({
  paymentRegister,
  setPaymentRegister,
  handleDebtUpdate,
  user,
  files,
  getDebtDocument,
  chargeDebtDocument,
  deleteDebtDocument,
}) => {
  const changePaymentValue = (key, value) => {
    const settedPaymentRegister = { ...paymentRegister };
    settedPaymentRegister[key] = value;
    setPaymentRegister(settedPaymentRegister);
  };

  return (
    <div className="projectVal__card__container">
      <div style={{ width: "90%" }}>
        <div className="projectVal__card__items">
          <Item
            title="Código"
            content={paymentRegister.code}
            width="192px"
            onChange={(value) => changePaymentValue("code", value)}
            disabled={false}
          />
          <div className="debt_item">
            <p className="blue bold font14 projectVal__mb1">Fecha ingreso</p>
            <DatePicker
              value={dayjs(paymentRegister.date)}
              onChange={(value) =>
                changePaymentValue(
                  "date",
                  moment(value.toDate()).format(serverDateFormat)
                )
              }
              format={clientDateFormat}
              disabled={false}
              style={{ width: "143px" }}
              className="editProject__mb2"
            />{" "}
          </div>
        </div>
        <div className="projectVal__card__items">
          <Item
            title="Método de pago"
            content={paymentRegister.method}
            width="192px"
            onChange={(value) => changePaymentValue("method", value)}
            disabled={false}
          />
          <Item
            title="Monto (S/.)"
            content={paymentRegister.amount}
            width="143px"
            onChange={(value) =>
              changePaymentValue("amount", value.replace(/[^0-9.]/g, ""))
            }
            disabled={false}
          />
        </div>
        <div className="projectVal__card__items">
          <ChargeFileButton chargeDebtDocument={chargeDebtDocument} />
          <FileList
            files={files}
            getDebtDocument={getDebtDocument}
            deleteDebtDocument={deleteDebtDocument}
          />
        </div>
      </div>
    </div>
  );
};
