import styled from "styled-components";

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 300px);
  overflow-x: auto;
  overflow-y: auto;
  background-color: #ffff;
  @media (min-width: 667px) and (max-width: 1257px) {
    height: calc(100vh - 406px);
  }
  @media (max-width: 666px) {
    height: calc(100vh - 406px);
  }
`;

export const TimelineContainer = styled.div`
  width: 1250px;
  background-color: #ffff;
`;

export const ScheduleControlContainer = styled.div`
  width: 500px;
  background-color: #ffff;
  text-align: center;
`;