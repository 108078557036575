import React from "react";
import { GiPaperClip } from "react-icons/gi";
import { IconContext } from "react-icons";
import { Label, File } from "./styles";

const ChargeFileButton = ({ chargeDocument }) => {
  const handlerClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.click();

    input.addEventListener("change", (event) => {
      const file = event.target.files[0];
      chargeDocument(file);
    });
  };

  return (
    <>
      <File onClick={handlerClick}>
        <Label>Adjuntar documento</Label>
        <p></p>
        <IconContext.Provider value={{ color: "#014083" }}>
          <GiPaperClip
            style={{ marginRight: "6px", marginTop: "4px" }}
            size={16}
            strokeWidth={12}
          />
        </IconContext.Provider>
      </File>
    </>
  );
};

export default ChargeFileButton;
