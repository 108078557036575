import React, { useState, useEffect } from "react";
import "./styles.css";
import CheckActive from "./assets/check_active.svg";
import Check from "./assets/check.svg";
import RejectActive from "./assets/reject_active.svg";
import Reject from "./assets/reject.svg";

import { Popconfirm, notification } from "antd";

import { validatePaymentById } from "../../../../../../../../providers";

const AprobationItem = ({ payment, setShowReject, paymentReload, user }) => {
  const [showPopConfirm, setShowPopConfirm] = useState(false);

  const validatePayment = async () => {
    try {
      await validatePaymentById(payment.id);
      paymentReload();
    } catch (error) {
      console.log(error);
      notification.error("Se produjo un error al validar el pago");
    } finally {
      setShowPopConfirm(false);
    }
  };
  const activeItems = user === "Ejecutor" && payment.status === "SENDED";
  return (
    <div className="payments_validation_panel">
      {activeItems ? (
        <>
          <Popconfirm
            placement="topLeft"
            title="Estas seguro de realizar la aprobación del pago"
            open={showPopConfirm}
            onConfirm={() => validatePayment()}
            onCancel={() => {
              setShowPopConfirm(false);
            }}
            okText="Sí"
            cancelText="No"
          >
            <img
              alt="check_active"
              src={CheckActive}
              className="payment_action_active"
              onClick={() => setShowPopConfirm(true)}
            />
          </Popconfirm>
          <img
            alt="reject_active"
            src={RejectActive}
            className="payment_action_active"
            onClick={() => setShowReject(true, payment.id)}
          />
        </>
      ) : (
        <>
          <img alt="check" src={Check} className="payment_action" />
          <img alt="reject" src={Reject} className="payment_action" />
        </>
      )}
    </div>
  );
};

export default AprobationItem;
