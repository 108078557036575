import React from "react";
import { Row } from "reactstrap";
import { Input, Radio, DatePicker } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { dateFormat, clientDateFormat, serverDateFormat } from "../../../utils";

import { EditProjectCard } from "../../EditProjectCard/EditProjectCard";
import { Button } from "../../Button";
import { TextWithTooltip } from "./TextWithTooltip";
import { Select } from "./Select";
import { Item } from "./Item";

export const Valuation1 = ({
  radioStyle,
  setModal,
  updateProject,
  calendarTypes,
  calendarOptions,
  calendarDays,
  initial_date,
  setInitial_date,
  num_seasons,
  setNum_seasons,
  calendar_type,
  setCalendar_type,
  calendar_option,
  setCalendar_option,
  final_date,
  valCalendarOptions,
  valorization_generator_option,
  setValorization_generator_option,
  valorization_num_seasons,
  setValorization_num_seasons,
  valorization_calendar_option,
  setValorization_calendar_option,
  valorization_calendar_type,
  setValorization_calendar_type,
  valorization_week_day,
  setValorization_week_day,
  generateValuationSchedule,
  exist_valorizations_program,
  general_aproved,
  role,
}) => {
  const update = (body) => updateProject(body, true);

  const changeCalendarOption = (
    value,
    name,
    option,
    type,
    setType,
    setOption,
  ) => {
    if (value === 2) {
      option = 4;
    } else {
      option = 1;
    }
    update({ ...type, [name]: option });
    setType(value);
    setOption(option);
  };
  return (
    <EditProjectCard
      classname="editProject__valSched__section1"
      title="4. Cronograma de valorizaciones"
      padding="1rem 0rem 1rem 1rem"
    >
      <div className="editProject__valSched__section1-container">
        {/* 1 */}
        <div className="ramp editProject__valSched__gridVal1">
          <div className="ramp editProject__mb2">
            <TextWithTooltip
              id="inicioPlazo"
              info="Colocar la fecha en que se inició o iniciará su plazo contractual, esta fecha debe considerar que todos los requisitos estén cumplidos y será aprobada luego por ambas partes."
            >
              Fecha de inicio plazo contractual{" "}
            </TextWithTooltip>
          </div>
          <DatePicker
            value={dayjs(initial_date)}
            onChange={(value) => {
              update({
                initial_date: moment(value.toDate()).format(serverDateFormat),
              });
              setInitial_date(value);
            }}
            format={clientDateFormat}
            disabled={general_aproved || role !== "ADMIN"}
            style={{ width: "216px" }}
            className="editProject__mb2"
          />
        </div>
        {/* 1 */}

        {/* 2 */}
        <div className="ramp editProject__valSched__gridVal1">
          <div className="  editProject__mb2">
            <TextWithTooltip
              id="plazoContractual"
              info="Ingresar fecha pactada de plazo de obra, puede ingresarla en días calendario o útiles."
            >
              Plazo contractual{" "}
            </TextWithTooltip>
          </div>
          <Row className="ramp" style={{ display: "flex" }}>
            <Input
              style={{ width: "90px" }}
              value={num_seasons}
              onFocus={(event) => event.target.select()}
              onChange={(e) => {
                setNum_seasons(e.target.value);
              }}
              onBlur={() => {
                update({ num_seasons });
              }}
              disabled={general_aproved || role !== "ADMIN"}
            />
            <Select
              className="editProject__ml editProject__mr editProject__mb2"
              value={calendar_option}
              onChange={(value) => {
                update({ calendar_option: value });
                setCalendar_option(value);
              }}
              options={calendarOptions}
              disabled={general_aproved || role !== "ADMIN"}
              width={90}
            />
            <Select
              className=" editProject__mb2"
              value={calendar_type}
              onChange={(value) =>
                changeCalendarOption(
                  value,
                  "calendar_option",
                  calendar_option,
                  { calendar_type: value },
                  setCalendar_type,
                  setCalendar_option
                )
              }
              options={calendarTypes}
              disabled={general_aproved || role !== "ADMIN"}
              width={133}
            />
          </Row>
        </div>
        {/* 2 */}

        {/* 3 */}
        <div className="editProject__mb4 editProject__valSched__gridVal1">
          <div className="editProject__mb2">
            <TextWithTooltip
              id="finPlazo"
              info="Fecha de fin de la obra, se calculará de forma automática."
            >
              Fecha de fin plazo contractual{" "}
            </TextWithTooltip>
          </div>
          <Input
            style={{ width: "216px" }}
            className="editProject__mb2"
            disabled
            value={dateFormat(final_date)}
          />
        </div>
         {/* 3 */}

        <div>
          <Item>
            <TextWithTooltip
              id="duracionPeriodo"
              info="Días de duración para establecer el inicio y fin de los periodos valorizables."
            >
              <strong>Duración del periodo valorizaciones </strong>
            </TextWithTooltip>
          </Item>
          <Radio.Group
            value={valorization_generator_option}
            onChange={(e) => {
              setValorization_generator_option(e.target.value);
              update({
                valorization_generator_option: e.target.value,
              });
            }}
            disabled={general_aproved || role !== "ADMIN"}
            className="editProject__mb4"
          >
            <Row className="editProject__ml">
              <Radio style={radioStyle} value="CUSTOM">
                Personalizado cada
              </Radio>
              <Input
                className="editProject__mb2"
                style={{ width: "90px" }}
                value={valorization_num_seasons}
                onFocus={(event) => event.target.select()}
                onChange={(e) => {
                  setValorization_num_seasons(e.target.value);
                }}
                onBlur={() => {
                  update({ valorization_num_seasons });
                }}
                disabled={general_aproved || role !== "ADMIN"}
              />
              <Select
                className="editProject__ml"
                value={valorization_calendar_option}
                onChange={(value) => {
                  update({ valorization_calendar_option: value });
                  setValorization_calendar_option(value);
                }}
                options={valCalendarOptions}
                disabled={general_aproved || role !== "ADMIN"}
                width={90}
              />
              <Select
                className="editProject__ml editProject__mb2"
                value={valorization_calendar_type}
                onChange={(value) =>
                  changeCalendarOption(
                    value,
                    "valorization_calendar_option",
                    valorization_calendar_option,
                    { valorization_calendar_type: value },
                    setValorization_calendar_type,
                    setValorization_calendar_option
                  )
                }
                options={calendarTypes}
                disabled={general_aproved || role !== "ADMIN"}
                width={133}
              />
            </Row>
            <Row className="editProject__ml editProject__mb2">
              <Radio style={radioStyle} value="WEEKLY">
                Semanal, todos los
              </Radio>
              <Select
                value={valorization_week_day}
                onChange={(value) => {
                  update({ valorization_week_day: value });
                  setValorization_week_day(value);
                }}
                options={calendarDays}
                disabled={general_aproved || role !== "ADMIN"}
                width={110}
              />
            </Row>
            <Row className="editProject__ml editProject__mb2">
              <Radio style={radioStyle} value="BIWEEKLY">
                Quincenal
              </Radio>
            </Row>
            <Row className="editProject__ml editProject__mb2">
              <Radio style={radioStyle} value="MONTHLY">
                Mensual
              </Radio>
            </Row>
          </Radio.Group>
          <Row className="editProject__mb4">
            <Button
              fontSize="15px"
              margin="auto"
              width="330px"
              background={role === "ADMIN" ? "#0169E4" : "#f5f5f5"}
              border={role !== "ADMIN" && "1px solid rgba(95, 111, 129, 0.5)"}
              boxShadow={role === "ADMIN" && "0px 2px 8px rgba(0, 0, 0, 0.25)"}
              color={role !== "ADMIN" && "#00000040"}
              onClick={() => role === "ADMIN" && generateValuationSchedule()}
              disabled={role !== "ADMIN"}
            >
              Generar programación de valorización
            </Button>
          </Row>
          {exist_valorizations_program ? (
              <Row className="editProject__mb4">
              <Button
                fontSize="15px"
                margin="auto"
                width="330px"
                background={exist_valorizations_program ? "#ffffff" : "rgb(129, 129, 129)"}
                border={"1px solid #0169E4"}
                boxShadow={"0px 2px 8px rgba(0, 0, 0, 0.25)"}
                color={exist_valorizations_program ? "#0169E4" : "rgb(129, 129, 129)"}
                onClick={() => exist_valorizations_program && setModal((prev) => !prev)}
              >
                Ver programación de valorización
              </Button>
            </Row>
            ) : (
              <></>
            )}
          
        </div>
      </div>
    </EditProjectCard>
  );
};
