import styled from "styled-components";
import { Link } from "react-router-dom";
import { Row } from "reactstrap";

export const ErrorMessage = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  line-height: 12px;
  padding-left: 30px;
  padding-right: 30px;
  color: #df4e4e;
`;

export const StyledInput = styled.input`
  font-size: 14px;
  line-height: 16px;
  color: #b6b6b6;
  outline: none;
  border-radius: 10px;
  border: 1px solid #bcbcbc;
  box-sizing: border-box;
  height: 32px;
  width: 80%;
  padding-left: 10px;
  margin-bottom: 10px;
`;

export const StyledLink = styled(Link)`
  margin-top: 5px;
  font-size: 12px;
  line-height: 12px;
  color: #0169e4;
`;

export const StyledRow = styled(Row)`
  justify-content: center;
  margin: 0 !important;
`;
