import React from "react";

import { IconContext } from "react-icons";
import { AiOutlinePlus } from "react-icons/ai";
import { RiSearchLine } from "react-icons/ri";

import {
  ConstructionHeaderRow,
  ConstructionMainTitle,
  NewConstructionButton,
  SearchContainer,
  SearchInput,
} from "./styles";

export default function ConstructionPageHeader({
  search,
  setSearch,
  constructionName,
  openProjectModal,
}) {
  return (
    <>
      <ConstructionHeaderRow>
        <ConstructionMainTitle>
          Contratos de la obra {constructionName}
        </ConstructionMainTitle>
        <SearchContainer>
          <SearchInput
            value={search}
            onFocus={(event) => event.target.select()}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="navbar__search__container-icon">
            <IconContext.Provider value={{ color: "white" }}>
              <RiSearchLine className="navbar__search__icon" size={24} />
            </IconContext.Provider>
          </div>
        </SearchContainer>
        <NewConstructionButton
          onClick={() => {
            openProjectModal();
          }}
        >
          Nuevo contrato
          <IconContext.Provider value={{ color: "" }}>
            <AiOutlinePlus size="20" />
          </IconContext.Provider>
        </NewConstructionButton>
      </ConstructionHeaderRow>
    </>
  );
}
