// Basic imports
import React from "react";

// Support libraries import
import { useHistory } from "react-router-dom";

// Components imports
import { PanelContainer, PanelSuperContainer } from "./styles";
import { Button } from "../../../../../../../components/Button";
import MainButton from "../MainButton";

const MainButtonsPanel = ({
  project,
  predeductive,
  loadPredeductiveReport,
}) => {
  const history = useHistory();

  // navigate to predeductives modal
  const navigateToPredeductiveModal = () => {
    predeductive.type === "NEW_PARTS"
      ? history.push(`/projects/${project.id}/val?deductive=${predeductive.id}`)
      : history.push(`/projects/${project.id}/val?deductive=all`);
  };

  return (
    <PanelSuperContainer>
      <PanelContainer>
        {predeductive.type === "NEW_PARTS" && (
          <Button
            width="126px"
            height="26px"
            background="#f0f4f9"
            border="1px solid black"
            borderRadius="4px"
            color="#4A4A4A"
            fontSize="12px"
            margin="0px 0px 0px 0px"
            padding="0px 0px 0px 0px"
          >
            Gestión de pagos
          </Button>
        )}
        <Button
          background="#0169E4"
          fontSize="12px"
          width="126px"
          height="26px"
          margin="0px 0px 0px 0px"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
          onClick={() => {
            navigateToPredeductiveModal();
          }}
        >
          Lista deductivos
        </Button>
      </PanelContainer>
      {predeductive.type === "NEW_PARTS" && (
        <PanelContainer>
          <MainButton
            width="100px"
            color={"#000000"}
            disabled={false}
            onClick={() => {
              loadPredeductiveReport();
            }}
            document={true}
          >
            Inf. deductivo
          </MainButton>
        </PanelContainer>
      )}
    </PanelSuperContainer>
  );
};

export default MainButtonsPanel;
