// Basic import
import React from "react";

// Components imports
import { ValuationCard } from "../../ValuationCard";

// Styled components import
import { InfoProjectContainer } from "./styles";

// Utils import
import { parseFloatNumber } from "../../../../../../../utils";

const ProjectInfoPanel = ({ dateToday, preadditional }) => {
  return (
    <InfoProjectContainer>
      <ValuationCard width="400px" background="#f0f4f9">
        <div className="projectInfo__contentDiv">
          <p className="grey font14">HOY:</p>
          <p className="grey font14 bold">{dateToday}</p>
        </div>
        <div className="projectInfo__contentDiv">
          <p className="grey font14 bold">
            {`${parseFloatNumber(
              preadditional?.additional?.advance_percentage
            )}%`}
          </p>
          <p className="grey font14">Avance de adicional</p>
        </div>
        <div className="projectInfo__contentDiv">
          <p className="grey font14 bold">
            {preadditional?.additional?.num_valorizations || 1}
          </p>
          <p className="grey font14">
            {(preadditional?.additional?.num_valorizations || 1) === 1
              ? "Valorización"
              : "Valorizaciones"}{" "}
            de adicional
          </p>
        </div>
      </ValuationCard>
    </InfoProjectContainer>
  );
};
export default ProjectInfoPanel;
