// React import
import React, { useEffect, useState } from "react";

// Components import
import Button from "../../../../../shared/components/Button";
import Table from "./components/Table";

// Styled components import
import {
  ModalTitle,
  ModalHeader,
  ButtonContainer,
  TableContainer,
  Indication,
} from "./styles";

// Services import
import { getProjectPreDeductives } from "../../../../services";

export default function DeductivesValidation({
  projectId,
  setLoading,
  onReject,
  onConfirm,
}) {
  const [predeductives, setPredeductives] = useState([]);

  const errorHandler = () => {
    setPredeductives([]);
  };

  const successHandler = (results) => {
    let _predeductives = results.results.map((predeductives) => {
      return { ...predeductives, checked: false };
    });
    setPredeductives(_predeductives);
  };

  useEffect(() => {
    const fetchPreDeductives = async () => {
      setLoading(true);
      await getProjectPreDeductives(projectId, errorHandler, successHandler);
      setLoading(false);
    };
    fetchPreDeductives();
  }, [projectId]);

  return (
    <>
      <ModalHeader>
        <ModalTitle>Resumen pago de deductivos</ModalTitle>
      </ModalHeader>
      <TableContainer>
        <Table deductives={predeductives} />
      </TableContainer>
      <ButtonContainer>
        <Button
          background="#05BE8C"
          width="80px"
          margin="20px 0 0 0"
          fontWeight="600"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
          onClick={() => {
            onReject();
          }}
        >
          Regresar
        </Button>
        <Button
          background="#05BE8C"
          width="80px"
          margin="20px 0 0 0"
          fontWeight="600"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
          onClick={() => {
            onConfirm();
          }}
        >
          Continuar
        </Button>
      </ButtonContainer>
      <Indication>
        Si el estado de cada deductivo es el esperado, prosiga con la siguiente
        etapa, de lo contrario haga los ajustes necesarios a cada presupuesto
        deductivo hasta que los estados en este resumen, sea el deseado
      </Indication>
      <Indication>
        <b>Advertencia: </b>Los deductivos de obra en estado propuesto no se
        incluirán en el cálculo del monto final de la liquidación. El monto
        total de los presupuestos deductivos en estado propuesto se mostrará en
        una sección aparte en el informe final de liquidación.
      </Indication>
    </>
  );
}
