import React, { useEffect, useState, useRef } from "react";
import html2canvas from "html2canvas";
import dayjs from "dayjs";
import { es } from "dayjs/locale/es";
import { Modal } from "antd";
import { getAmountDatesService } from "../../../services";
import { Title, ModalContent, SharesButton } from "./styles";
import { parseFloatNumber, clientDateFormat } from "../../../../../utils";

const AmountsDatesModal = ({
  setAmountsDatesModalActive,
  amountsDatesModalActive,
  projectId,
}) => {
  const [amountsAndDate, setAmountsAndDates] = useState({
    contractor: "",
    client: "",
    acumulated_real_value: 0,
    percentage: 0,
    initial_date: null,
    final_date_original: null,
    final_date_new: null,
    status: 0,
    date: null,
  });

  const modalRef = useRef(null);

  const successHandler = (response) => {
    setAmountsAndDates(response);
  };

  const errorHandler = (error) => {
    setAmountsAndDates({
      contractor: "",
      client: "",
      acumulated_real_value: 0,
      percentage: 0,
      initial_date: null,
      final_date_original: null,
      final_date_new: null,
      status: 0,
      date: null,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      await getAmountDatesService(projectId, errorHandler, successHandler);
    };
    fetchData();
  }, []);

  const now = dayjs().locale("es");

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getStatusLabels = () => {
    let label = "";
    let color = "#28A745";
    if (amountsAndDate.status < 0) {
      color = "#DC3545";
      label = "Retrasada";
      if (amountsAndDate.status < -10) {
        label = "Muy Retrasada";
      }
    } else if (amountsAndDate.status > 2) {
      label = "Avanzada";
      if (amountsAndDate.status > 10) {
        label = "Muy Avanzada";
      }
    } else {
      label = "En Tiempo";
    }
    return { label, color };
  };

  const handleCapture = async () => {
    try {
      const canvas = await html2canvas(modalRef.current);
      const image = canvas.toDataURL("image/jpeg");

      // Esperar 500 milisegundos antes de descargar la imagen
      setTimeout(() => {
        const link = document.createElement("a");
        link.href = image;
        link.download = "captured-image.jpg";
        link.click();
      }, 500);
    } catch (error) {
      console.error("Error al capturar la imagen:", error);
    }
  };

  return (
    <Modal
      centered
      open={amountsDatesModalActive}
      onCancel={() => setAmountsDatesModalActive(false)}
      size={603}
      style={{ background: "#E5E5E5" }}
      footer={null}
    >
      <ModalContent>
        <div ref={modalRef}>
          <Title>{`${capitalize(now.format("dddd D"))} de ${capitalize(
            now.format("MMMM")
          )} del ${now.format("YYYY")}`}</Title>
          <div className="contenedor-modal">
            <div className="parrafo-container">
              <p className="parrafo-model">Contratista</p>
              <p className="dato-model">{amountsAndDate.contractor}</p>
            </div>
            <div className="parrafo-container">
              <p className="parrafo-model">Comitente</p>
              <p className="dato-model">{amountsAndDate.client}</p>
            </div>
            <div className="parrafo-container">
              <p className="parrafo-model">Monto valorizado hasta la fecha</p>
              <p className="dato-model">{`S/. ${parseFloatNumber(
                amountsAndDate.acumulated_real_value
              )}`}</p>
            </div>
            <div className="parrafo-container">
              <p className="parrafo-model">% de avance de obra</p>
              <p className="dato-model">{`${parseFloatNumber(
                amountsAndDate.percentage * 100
              )}%`}</p>
            </div>
            <div className="parrafo-container">
              <p className="parrafo-model">Evaluación</p>
              <p
                className="dato-model"
                style={{ color: getStatusLabels().color }}
              >
                {`${getStatusLabels().label} ${parseFloatNumber(
                  Math.abs(amountsAndDate.status)
                )}%`}
              </p>
            </div>
            <div className="parrafo-container">
              <p className="parrafo-model">Inicio de obra</p>
              <p className="dato-model">
                {dayjs(amountsAndDate.initial_date).format(clientDateFormat)}
              </p>
            </div>
            <div className="parrafo-container">
              <p className="parrafo-model">Fin de obra - original</p>
              <p className="dato-model">
                {dayjs(amountsAndDate.final_date_original).format(
                  clientDateFormat
                )}
              </p>
            </div>
            <div className="parrafo-container">
              <p className="parrafo-model">Fin de obra - nueva</p>
              <p className="dato-model">
                {dayjs(amountsAndDate.final_date_new).format(clientDateFormat)}
              </p>
            </div>
          </div>
        </div>
      </ModalContent>
      <SharesButton className="a_model" onClick={handleCapture}></SharesButton>
    </Modal>
  );
};

export default AmountsDatesModal;
