import React from "react";
import imgProv from "../../../../../assets/projectValuation/image.png";
import Nut from "../../assets/tuerca.svg";
import {
  ImageContainer,
  ImageConfigProject,
  ButtonConfigProject,
} from "./styles";

const ProjectImagePanel = ({ history, project, image }) => {
  return (
    <ImageContainer
      imageUrl={
        image ? `${image["content-type"]};base64, ${image.data}` : imgProv
      }
    >
      <ButtonConfigProject
        onClick={() => history.push(`/projects/${project.id}/edit`)}
      >
        <ImageConfigProject src={Nut} alt="edit-icon" />
      </ButtonConfigProject>
    </ImageContainer>
  );
};
export default ProjectImagePanel;
