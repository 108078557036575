import styled from "styled-components";

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
  padding-top: 14px;
  padding-left: 8px;
  padding-right: 8px;
  border-left: 3px dashed black;
  border-right: 3px dashed black;
`;

export const ButtonsColumn = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
  margin: 0px 5px 0px 5px;
`;

export const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
  gap: 5px;
`;

export const SectionLabel = styled.div`
  font-size: 14px;
  color: black;
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: center;
`;
