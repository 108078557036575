import React from "react";
import { BrowserRouter } from "react-router-dom";
import { isEmpty, isNil } from "ramda";

import AppSession from "../utils/session/AppSession";

import PrivateRouter from "./PrivateRouter";
import PublicRouter from "./PublicRouter";

const AppRouter = ({ privateModules = [], publicModules = [] }) => {
  const sessionValidator = () => {
    const user = AppSession.get();
    return !isEmpty(user) && !isNil(user);
  };

  return (
    <BrowserRouter>
      {sessionValidator() ? (
        <>
          <PrivateRouter modules={privateModules} />
        </>
      ) : (
        <PublicRouter modules={publicModules} />
      )}
    </BrowserRouter>
  );
};

export default AppRouter;
