import styled from "styled-components";

export const CounterContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 30px;
`;

export const CounterTitle = styled.p`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #4a4a4a;
  margin-bottom: 10px;
`;

export const CounterSubtitle = styled.p`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #014083;
  margin-bottom: 7px;
`;

export const NumberContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 140px;
`;

export const Number = styled.p`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 8px;
`;

export const NumberLabel = styled.p`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 22px;
  color: #014083;
  margin-bottom: 7px;
`;
