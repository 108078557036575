// Basic import
import React, { useState, useEffect } from "react";

// Util libraries import
import { notification } from "antd";

// Components import
import { Qrome } from "./Qrome";
import Qrome2 from "./Qrome2";
import Loading from "../../../shared/components/Loading";
import VisorModal from "./Valorizations/components/VisorModal";

// Modals
import DeductiveDebtModal from "../../../../components/DeductiveDebt/Summary";

// Sections
import { Valorizations } from "./Valorizations";

// Context
import { useAppContext } from "../../../App/context";

// Services & Providers import
import {
  getPreDeductive,
  getProject,
  getDeductiveValorizations,
  getPresentDeductiveValorization,
} from "../../services";

import {
  sendPresentDeductiveValorization,
  evaluatePresentDeductiveValorization,
  signPresentDeductiveValorization,
  getPresentDeductiveValorizationReport,
  getPredeductiveValidatedReport,
} from "../../../../providers";

// Styles import
import "./index.css";

const DeductiveValorizationBanner = ({ history, match }) => {
  const { id } = match.params;

  // Context import
  const {
    projectRole,
    setProjectRole,
    setIsProjectAdmin,
    setProjectName,
    setProjectEnterprise,
  } = useAppContext();

  //* Component states

  // predeductive/deductive
  const [predeductive, setPredeductive] = useState({ order: "X" });
  const [deductive, setDeductive] = useState({ id: null });
  // project
  const [project, setProject] = useState({ image: null });
  // valorizations
  const [presentValorization, setPresentValorization] = useState({});
  const [currentValorization, setCurrentValorization] = useState({
    order: 1,
    to_be_closed: false,
    debt: null,
  });
  const [valorizations, setValorizations] = useState([{ order: 1 }]);

  // trigger predeductive/deductive getting
  const [trigger, setTrigger] = useState(true);
  // loading
  const [isLoading, setIsLoading] = useState(false);

  // Sign modal status
  const [activeModalFirm, setActiveModalFirm] = useState(false);
  const [finalReport, setFinalReport] = useState("");

  // Debt modal status
  const [debtModalActive, setDebtModalActive] = useState(false);

  // Visor modal
  const [activeVisorModalReport, setActiveVisorModalReport] = useState(false);
  const [predeductiveReport, setPredeductiveReport] = useState("");

  //* Component useEffects
  useEffect(() => {
    const fetchPredeductivePage = async () => {
      setIsLoading(true);
      await getPreDeductive(id, errorHandler, getPredeductiveSuccessHandler);
      setIsLoading(false);
    };

    fetchPredeductivePage();
  }, [trigger]);

  useEffect(() => {
    const fetchProject = async () => {
      setIsLoading(true);
      await getProject(
        predeductive.project,
        errorHandler,
        getProjectSuccessHandler
      );
      setIsLoading(false);
    };

    predeductive?.project && fetchProject();
  }, [predeductive]);

  useEffect(() => {
    const fetchValorizations = async () => {
      setIsLoading(true);
      await getDeductiveValorizations(
        deductive.id,
        errorHandler,
        getValorizationsSuccessHandler
      );
      setIsLoading(false);
    };
    deductive.id && fetchValorizations();
  }, [deductive, debtModalActive]);

  useEffect(() => {
    const fetchPresentDeductiveValorization = async () => {
      await getPresentDeductiveValorization(
        deductive.id,
        errorHandler,
        getDeductivePresentValorizationSuccessHandler
      );
    };

    deductive.id && fetchPresentDeductiveValorization();
    if (deductive.id) {
      const presentDeductiveValorizationInterval = setInterval(
        () => fetchPresentDeductiveValorization(),
        10000
      );
      return () => clearInterval(presentDeductiveValorizationInterval);
    }
  }, [deductive]);

  // get predeductive handlers
  const errorHandler = (type = "server", message = "") => {
    switch (type) {
      case "server":
      case "no_found":
        history.push("/main");
        break;
      default:
        break;
    }
  };

  const getPredeductiveSuccessHandler = (predeductive) => {
    if (predeductive.deductive) {
      setPredeductive(predeductive);
      setDeductive(predeductive.deductive);
    } else {
      history.push("/main");
    }
  };

  // get project handlers
  const getProjectSuccessHandler = (project) => {
    setProject(project);
    setProjectRole(
      project.role === "SUPERVISOR_LEADER" || project.role === "SUPERVISOR"
        ? "Supervisor"
        : "Ejecutor"
    );
    setIsProjectAdmin(project.role === "ADMIN");
    setProjectName(project.name);
    setProjectEnterprise(project.enterprise);
  };

  // get valorizations handlers
  const getValorizationsSuccessHandler = (valorizations) => {
    setValorizations(valorizations.results);
    if (valorizations.results.length > 0) {
      const _presentValorization = valorizations.results.filter(
        (valorization) => valorization.is_active
      );
      if (_presentValorization.length === 0) {
        setCurrentValorization(valorizations.results[0]);
      } else {
      }
      setCurrentValorization(_presentValorization[0]);
    }
  };

  // get present valorization handlers
  const getDeductivePresentValorizationSuccessHandler = (valorization) => {
    setPresentValorization(valorization);
  };

  const reloadPredeductivePage = () => {
    setTrigger(!trigger);
  };

  // Main actions

  const sendPresentValorizationHandler = async () => {
    try {
      setIsLoading(true);
      const response = await sendPresentDeductiveValorization(deductive.id);
      if (
        response.detail[0] !== "Se ha enviado correctamente la valorización"
      ) {
        notification.error({
          message: response.detail[0],
        });
        setIsLoading(false);
      } else {
        notification.success({
          message: response.detail[0],
        });
        setIsLoading(false);
        setTimeout(() => history.go(0), 3000);
      }
    } catch (error) {
      notification.error({
        message: "Ocurrió un error interno. Por favor vuelva a intentarlo",
      });
    }
  };

  const evaluatePresentValorizationHandler = async () => {
    try {
      setIsLoading(true);
      const response = await evaluatePresentDeductiveValorization(deductive.id);
      if (
        response.detail[0] !==
        "Se ha enviado correctamente la evaluación de la valorización"
      ) {
        notification.error({
          message: response.detail[0],
        });
        setIsLoading(false);
      } else {
        notification.success({
          message: response.detail[0],
        });
        setIsLoading(false);
        setTimeout(() => history.go(0), 3000);
      }
    } catch (error) {
      notification.error({
        message: "Ocurrió un error interno. Por favor vuelva a intentarlo",
      });
    }
  };

  const blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const loadFinalReportValorizationHandler = async () => {
    try {
      setIsLoading(true);
      const response = await getPresentDeductiveValorizationReport(
        deductive.id
      );
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        const json = await response.json();
        if (json.detail) {
          notification.success({
            message: json.detail,
          });
          setIsLoading(false);
          setTimeout(() => history.go(0), 3000);
        } else {
          notification.error({
            message: "Ocurrió un error interno. Por favor vuelva a intentarlo",
          });
          setIsLoading(false);
        }
      } else {
        const blobFile = await response.blob();
        const base64File = await blobToBase64(blobFile);
        setFinalReport(base64File);
        setIsLoading(false);
        setActiveModalFirm(true);
      }
    } catch (error) {
      notification.error({
        message: "Ocurrió un error interno. Por favor vuelva a intentarlo",
      });
      setIsLoading(false);
    }
  };

  // Load predeductive report

  const loadPredeductiveReport = async () => {
    try {
      setIsLoading(true);
      const response = await getPredeductiveValidatedReport(predeductive.id);
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        const json = await response.json();
        if (json.detail) {
          notification.success({
            message: json.detail,
          });
          setIsLoading(false);
          setTimeout(() => history.go(0), 3000);
        } else {
          notification.error({
            message: "Ocurrió un error interno. Por favor vuelva a intentarlo",
          });
          setIsLoading(false);
        }
      } else {
        const blobFile = await response.blob();
        const base64File = await blobToBase64(blobFile);
        setPredeductiveReport(base64File);
        setIsLoading(false);
        setActiveVisorModalReport(true);
      }
    } catch (error) {
      notification.error({
        message: "Ocurrió un error interno. Por favor vuelva a intentarlo",
      });
      setIsLoading(false);
    }
  };

  return (
    <div>
      <VisorModal
        active={activeVisorModalReport}
        seeReport={predeductiveReport}
        setActiveModal={setActiveVisorModalReport}
      />
      <Loading active={isLoading} />
      <DeductiveDebtModal
        user={projectRole}
        role={projectRole}
        debtModalActive={debtModalActive}
        setDebtModalActive={setDebtModalActive}
        debt_id={deductive.debt}
        currentValorization={currentValorization}
        deductive_id={deductive.id}
      />
      <Qrome
        user={projectRole}
        presentValorization={presentValorization}
        currentValorization={{
          to_be_closed: false,
          debt: null,
        }}
        image={project.image}
        predeductive={predeductive}
        loadPredeductiveReport={loadPredeductiveReport}
        project={project}
        history={history}
        sendpresentValorization={() => {
          sendPresentValorizationHandler();
        }}
        evaluatepresentValorization={() => {
          evaluatePresentValorizationHandler();
        }}
        loadFinalReportValorization={() => {
          loadFinalReportValorizationHandler();
        }}
        setDebtModalActive={() => {
          setDebtModalActive(true);
        }}
      />
      <Qrome2
        valorizations={valorizations}
        setCurrentValorization={setCurrentValorization}
        currentValorization={currentValorization}
      />
      {predeductive.type === "NEW_PARTS" && (
        <Valorizations
          id={id}
          role={projectRole}
          project={project}
          general_aproved={project.general_aproved}
          valorizations={[]}
          predeductive={predeductive}
          setPredeductive={setPredeductive}
          setCurrentValorization={() => {}}
          currentValorization={{
            to_be_closed: false,
            debt: null,
          }}
          setIsLoading={setIsLoading}
          reloadPredeductivePage={reloadPredeductivePage}
        />
      )}
    </div>
  );
};

export default DeductiveValorizationBanner;
