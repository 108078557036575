export const dateFormat = (date) => {
  let parts = date.split(/[-|/]/);
  return `${parts[2]}/${parts[1]}/${parts[0]}`;
};

const months = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic",
];

export const transformDate = (date = "12/12/12") => {
  let parts = date?.split(/[-|/]/);
  return `${parts[2]}.${months[parts[1] - 1]}.${parts[0]}`;
};

export const clientDateFormat = "DD/MM/YYYY";
export const serverDateFormat = "YYYY-MM-DD";
