import appFetcher from "../../../../App/utils/http/AppFetcher";

// add valorization
export const addAdditionalValorization = async (
  id,
  body,
  errorHandler,
  successHandler
) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: `/projects/${id}/add_valorization`,
      body: body,
      isAuthRequired: true,
      responseContentType: "json",
      method: "POST",
    });
    if (status === 201) {
      successHandler(response);
    } else {
      if (status === 401) {
        errorHandler("unauthorized", response.detail);
      } else {
        errorHandler("server");
      }
    }
  } catch (error) {
    errorHandler("server");
  }
};
