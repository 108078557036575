import styled from "styled-components";

export const ApprovalPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ApprovalPanelCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  width: 100%;
  margin-top: 30px;
  padding: 20px;
`;

export const ApprovalPanelItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ApprovalPanelIconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-around;
  width: 100px;
  margin-top: 10px;
`;

export const LabelItem = styled.h1`
  font-size: 14px;
  font-weight: bold;
`;

export const IconContainer = styled.div`
  height: 32px;
  width: 32px;
  background-color: #004283;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 380px) {
    margin-right: 8px;
  }
`;
