import styled from "styled-components";

export const FileRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Container = styled.div`
  display: "flex";
  flex-direction: "column";
  margin-bottom: "20px" !important;
`;
