// Basic import
import React from "react";

// React icons import
import SendIcon from "../../assets/send-icon.svg";
import SignIcon from "../../assets/sign-icon.svg";
import DocumentIcon from "../../assets/document-icon.svg";
import DeactivateSignIcon from "../../assets/deactivate-sign-icon.svg";

// Styled components import
import { Container, Text, Icon } from "./styles";

const MainButton = ({
  children,
  color,
  sign,
  document,
  height,
  width,
  disabled,
  onClick,
}) => {
  return (
    <Container
      borderColor={color}
      height={height}
      width={width}
      disabled={disabled}
      onClick={onClick}
    >
      <Text color={color}>{children}</Text>
      <Icon
        src={
          document
            ? DocumentIcon
            : sign
            ? disabled
              ? DeactivateSignIcon
              : SignIcon
            : SendIcon
        }
        alt="main-button-icon"
      />
    </Container>
  );
};

export default MainButton;
