import React from "react";
import { Link } from "react-router-dom";

import { parseFloatNumber } from "../../../../../../../../utils";
import { ItemDiv } from "../../styles";
import "./styles.css";

import AprobationItem from "../AprobationItem";

const Item = ({
  payment,
  withValidation = true,
  paymentReload,
  user,
  setShowReject,
  onClickDetails,
}) => {
  let class_status = "";
  let str_status = "";
  if (payment.status === "SENDED") {
    class_status = "";
    str_status = "Por validar";
  }
  if (payment.status === "VALIDATED") {
    class_status = "payments_status_validated";
    str_status = "Validado";
  }
  if (payment.status === "REJECTED") {
    class_status = "payments_status_rejected";
    str_status = "Rechazado";
  }

  return (
    <div
      className={
        withValidation ? "payments_table_items" : "payments_table_items_min"
      }
      index={payment.id}
    >
      <ItemDiv width="90px">
        <p className="bold font14 projectVal__mb1">{"Pago " + payment.order}</p>
      </ItemDiv>
      <ItemDiv width="100px">
        <p className="bold font14 projectVal__mb1">
          {"S/." + parseFloatNumber(payment.amount)}
        </p>
      </ItemDiv>
      {withValidation && (
        <ItemDiv width="80px">
          <AprobationItem
            payment={payment}
            user={user}
            paymentReload={paymentReload}
            setShowReject={setShowReject}
          />
        </ItemDiv>
      )}
      <ItemDiv width="85px" onClick={() => onClickDetails()}>
        <Link>
          <p className="blue bold font14 projectVal__mb1">Ver detalle</p>
        </Link>
      </ItemDiv>
      <ItemDiv width="104px">
        <p className={"bold font14 projectVal__mb1 " + class_status}>
          {str_status}
        </p>
      </ItemDiv>
    </div>
  );
};

export default Item;
