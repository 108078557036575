import AppSession from "../../App/utils/session/AppSession";
import appFetcher from "../../App/utils/http/AppFetcher";

export const loginService = async (body, errorHandler) => {
  try {
    const { status, response } = await appFetcher({
      relativePath: "/auth/login/",
      isAuthRequired: false,
      requestContentType: "application/json",
      body: body,
      responseContentType: "json",
      method: "POST",
    });
    if (status === 200) {
      AppSession.create({ accessToken: response.access_token });
      window.location.replace("/main");
    } else {
      errorHandler();
    }
  } catch (error) {
    errorHandler();
  }
};
