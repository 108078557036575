import React, { useState } from "react";
import { Row } from "reactstrap";
import Button from "../../../shared/components/Button";
import { notification } from "antd";
import { createProject } from "../../services";
import {
  NewProjectlabel,
  NewProjectInput,
  NewProjectContainerbtns,
} from "./styles";

const NewProjectModal = ({
  history,
  setModal,
  reloadProjects,
  constructionId,
}) => {
  const [name, setName] = useState("");

  const projectCreationSuccess = (project) => {
    setModal(false);
    notification.success({
      message: "El contrato se ha creado exitosamente",
    });
    history.push(`/projects/${project.id}/edit`);
  };

  const projectCreationError = (type = "server", message = "") => {
    setModal(false);
    switch (type) {
      case "bad_creation":
        notification.error({
          message: message,
        });
        break;
      case "server":
        notification.error({
          message: "Error de servidor. Inténtelo de nuevo",
        });
        break;
      default:
        break;
    }
  };

  const submitHandler = async () => {
    await createProject(
      { name: name, construction: constructionId },
      projectCreationError,
      projectCreationSuccess
    );
    reloadProjects();
  };

  return (
    <>
      <NewProjectlabel>Nombre del contrato:</NewProjectlabel>
      <Row>
        <NewProjectInput
          value={name}
          onFocus={(event) => event.target.select()}
          onChange={(e) => setName(e.target.value)}
          type="text"
          placeholder="Inserte nombre de la obra"
        />
      </Row>
      <NewProjectContainerbtns>
        <Button
          width="130px"
          background="#0169E4"
          fontSize="15px"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
          borderRadius="5px"
          onClick={submitHandler}
          disabled={!name}
        >
          Crear contrato
        </Button>
        <Button
          width="130px"
          background="#D93022"
          fontSize="15px"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
          borderRadius="5px"
          onClick={() => {
            setModal(false);
            setName("");
          }}
        >
          Cancelar
        </Button>
      </NewProjectContainerbtns>
    </>
  );
};
export default NewProjectModal;
