import React, { useEffect, useState } from "react";

// Components import
import DebtMainCard from "./index";
import { ToTalkModalSkeleton } from "../Skeletons";
import ProjectDeliverySummary from "./components/ValorizationsSummaryTable";
import HiddenViceModal from "./components/HiddenViceModal";
import HiddenViceDetailModal from "./components/HiddenViceDetailModal";
import {
  CardDiv,
  ModalTitle,
  Modal,
  CardContainer,
  ToggleButton,
  ModalHeader,
} from "./styles";

// Assets import
import ToggleButtonLeft from "./assets/toggle-button-left.png";
import ToggleButtonBottom from "./assets/toggle-button-bottom.png";

// Services import
import { getProjectDeliverySummary } from "../../providers";

export default function ProjectDeliveryModal({
  user,
  userRole,
  debtModalActive,
  setDebtModalActive,
  setLoading,
  projectDeliveryId,
}) {
  const [triggerProjectDelivery, setTriggerProjectDelivery] = useState(true);
  const [triggerHiddenVices, setTriggerHiddenVices] = useState(true);

  const [projectDeliverySummary, setProjectDeliverySummary] = useState({
    start_delivery_enabled: false,
    set_delivery_minute_enabled: false,
    set_observations_list_period_enabled: false,
    set_observations_list_enabled: false,
    set_observations_solving_period_enabled: false,
    finish_delivery_enabled: false,
  });

  const [charged, setCharged] = useState(false);
  const [showLeftMenu, setShowLeftMenu] = useState(true);
  const [showMobileModal, setShowMobileModal] = useState(false);
  const [section, setSection] = useState(1);
  // Hidden Vice Modal
  const [hiddenViceModalActive, setHiddenViceModalActive] = useState(false);
  // Hidden Vice Detail Modal
  const [hiddenViceSelectedId, setHiddenViceSelectedId] = useState(null);
  const [hiddenViceDetailModalActive, setHiddenViceDetailModalActive] =
    useState(false);

  useEffect(() => {
    const fetchProjectDeliverySummary = async () => {
      try {
        setCharged(false);
        const response = await getProjectDeliverySummary(projectDeliveryId);
        setProjectDeliverySummary(response);
        setCharged(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchProjectDeliverySummary();
  }, [projectDeliveryId, debtModalActive, triggerProjectDelivery]);

  useEffect(() => {
    setShowMobileModal(window.innerWidth < 1118);
  }, [window.innerWidth]);

  const reloadProjectDelivery = () => {
    setTriggerProjectDelivery(!triggerProjectDelivery);
  };

  const [scrolling, setScrolling] = useState(false);

  const handleWheel = (event) => {
    if (!scrolling) {
      setScrolling(true);
      const sensitivityFactor = 1;
      const adjustedDeltaY = event.deltaY * sensitivityFactor;
      if (adjustedDeltaY > 0) {
        if (section == 6) {
          setSection(6);
        } else {
          setSection(section + 1);
        }
      } else {
        if (section == 1) {
          setSection(1);
        } else {
          setSection(section - 1);
        }
      }
      setTimeout(() => {
        setScrolling(false);
      }, 1000);
    }
  };

  useEffect(() => {
    const wheelHandler = (event) => handleWheel(event);
    document.addEventListener("wheel", wheelHandler);
    return () => {
      document.removeEventListener("wheel", wheelHandler);
    };
  }, [handleWheel]);

  const reloadHiddenVices = () => {
    setTriggerHiddenVices(!triggerHiddenVices);
  };

  return (
    <>
      <Modal
        centered
        open={debtModalActive}
        onCancel={() => {
          setDebtModalActive(false);
        }}
        closable={false}
        footer={null}
        width={showLeftMenu ? (showMobileModal ? 500 : 1000) : 500}
      >
        <CardContainer showMobileModal={showMobileModal}>
          {showLeftMenu}
          {showLeftMenu && (
            <CardDiv order="first" showMobileModal={showMobileModal}>
              {charged ? (
                <ToTalkModalSkeleton />
              ) : (
                <>
                  <ModalHeader>
                    <ModalTitle>Proceso de entrega de obra</ModalTitle>
                  </ModalHeader>
                  <div>
                    <p className="projectVal__modal__description">
                      Haga clic en cada etapa para ver el detalle.
                    </p>
                  </div>
                  <ProjectDeliverySummary
                    projectDeliverySummary={projectDeliverySummary}
                    showMobileModal={showMobileModal}
                    setSection={setSection}
                    section={section}
                  />
                </>
              )}
            </CardDiv>
          )}
          <ToggleButton
            onClick={() => {
              setShowLeftMenu(!showLeftMenu);
            }}
            src={showMobileModal ? ToggleButtonBottom : ToggleButtonLeft}
            showMobileModal={showMobileModal}
            alt="toggle-button-summary"
          />
          <CardDiv order="second" showMobileModal={showMobileModal}>
            <DebtMainCard
              section={section}
              debtModalActive={debtModalActive}
              setDebtModalActive={setDebtModalActive}
              user={user}
              userRole={userRole}
              projectDeliverySummary={projectDeliverySummary}
              setLoading={setLoading}
              projectDeliveryId={projectDeliveryId}
              reloadProjectDelivery={reloadProjectDelivery}
              setHiddenViceModalActive={setHiddenViceModalActive}
            />
          </CardDiv>
        </CardContainer>
      </Modal>
      <HiddenViceModal
        hiddenViceModalActive={hiddenViceModalActive}
        setHiddenViceModalActive={setHiddenViceModalActive}
        projectDeliveryId={projectDeliveryId}
        showMobileModal={showMobileModal}
        setHiddenViceDetailModalActive={setHiddenViceDetailModalActive}
        setHiddenViceSelectedId={setHiddenViceSelectedId}
        triggerHiddenVices={triggerHiddenVices}
        reloadHiddenVices={reloadHiddenVices}
      />
      <HiddenViceDetailModal
        hiddenViceDetailModalActive={hiddenViceDetailModalActive}
        setHiddenViceDetailModalActive={setHiddenViceDetailModalActive}
        hiddenViceId={hiddenViceSelectedId}
        userRole={userRole}
        reloadHiddenVices={reloadHiddenVices}
      />
    </>
  );
}
