import React, { useState } from "react";

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import { Button } from "../../../../../components/Button";
import { TextBanner } from "../../../../../components/TextBanner";

const Qrome2 = ({
  setActiveSection,
  activeSection,
  currentValorization,
  lastValorization,
  presentValorization,
  setAdditionalCreationActive,
  setDeductiveCreationActive,
  setAddValorizationModalActive,
}) => {
  // "Más" button actions select
  const [mainDropdownActive, setMainDropdownActive] = useState(false);

  const toggleMainDropdown = () => {
    setMainDropdownActive(!mainDropdownActive);
  };

  const MainDropdown = () => {
    return (
      <Dropdown isOpen={mainDropdownActive} toggle={toggleMainDropdown}>
        <DropdownToggle
          caret
          style={{ width: "111px", background: "#05BE8C", color: "#FFFFFF" }}
        >
          <span
            style={{
              marginLeft: "17px",
              marginRight: "38px",
              lineHeight: "0px",
            }}
          >
            Más
          </span>
          <span className="caret"></span>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => setAdditionalCreationActive(true)}>
            Crear adicional
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              setDeductiveCreationActive(true);
            }}
          >
            Crear deductivo
          </DropdownItem>
          <DropdownItem onClick={() => setAddValorizationModalActive(true)}>
            Agregar valorización
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  return (
    <div className="valorization__partHeaders-base">
      <Button
        width="152px"
        height="28px"
        background={activeSection === 0 ? "#4A4A4A" : "#0169E4"}
        boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
        borderRadius="4px 4px 0px 0px"
        margin="0px 1px"
        fontSize="12px"
        onClick={() => setActiveSection(0)}
      >
        Partidas de Valorizacion
      </Button>
      <Button
        width="134px"
        height="28px"
        background={activeSection === 1 ? "#4A4A4A" : "#0169E4"}
        boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
        borderRadius="4px 4px 0px 0px"
        margin="0px 1px"
        fontSize="12px"
        onClick={() => setActiveSection(1)}
      >
        Timeline valorizacion
      </Button>
      <Button
        width="111px"
        height="28px"
        background={activeSection === 2 ? "#4A4A4A" : "#0169E4"}
        boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
        borderRadius="4px 4px 0px 0px"
        margin="0px 1px"
        fontSize="12px"
        onClick={() => setActiveSection(2)}
      >
        Lista adicionales
      </Button>
      <Button
        width="111px"
        height="28px"
        background={activeSection === 3 ? "#4A4A4A" : "#0169E4"}
        boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
        borderRadius="4px 4px 0px 0px"
        margin="0px 1px"
        fontSize="12px"
        onClick={() => setActiveSection(3)}
      >
        Lista deductivos
      </Button>
      <MainDropdown />
      <TextBanner
        content={
          currentValorization.is_closed
            ? "VALORIZACIÓN CERRADA"
            : currentValorization.id === lastValorization.id
            ? "VALORIZACIÓN EN PROCESO DE CIERRE"
            : currentValorization.id === presentValorization.id
            ? "VALORIZACIÓN EN CURSO"
            : "VALORIZACIÓN POR COMENZAR"
        }
      />
    </div>
  );
};

export default Qrome2;
