import React from "react";
import "./Header.css";

export const Header = () => {
  return (
    <div>
      <div className="headerVal__sticky">
        <div className="headerVal__subheader">
          <div
            className="headerVal__secondHeader headerVal__valItems"
            style={{ borderLeft: "solid 1px #f3f3f3" }}
          >
            ACUMULADO ACTUAL
          </div>
          <div
            className="headerVal__secondHeader headerVal__valItems"
            style={{ borderLeft: "solid 1px #f3f3f3" }}
          >
            ACUMULADO ACTUAL NO FIRMADO
          </div>
          <div
            className="headerVal__secondHeader headerVal__thirdvalItems"
            style={{ borderLeft: "solid 1px #f3f3f3" }}
          >
            METRADO A DEDUCIR
          </div>
          <div
            className="headerVal__secondHeader headerVal__secondaryValItems"
            style={{ borderLeft: "solid 1px #f3f3f3" }}
          >
            NUEVO PRESUPUESTO BASE
          </div>
        </div>
        <div className="headerVal__subheader">
          <div
            className="headerVal__content"
            style={{ width: "72px", borderLeft: "solid 1px #f3f3f3" }}
          >
            <p className="headerVal__text">Metrado</p>
          </div>
          <div className="headerVal__content" style={{ width: "82px" }}>
            <p className="headerVal__text">Monto (S/.)</p>
          </div>
          <div
            className="headerVal__content"
            style={{ width: "65px", borderRight: "solid 2px #f3f3f3" }}
          >
            <p className="headerVal__text">%Avance</p>
          </div>
          <div
            className="headerVal__content"
            style={{ width: "72px", borderLeft: "solid 1px #f3f3f3" }}
          >
            <p className="headerVal__text">Metrado</p>
          </div>
          <div className="headerVal__content" style={{ width: "82px" }}>
            <p className="headerVal__text">Monto (S/.)</p>
          </div>
          <div
            className="headerVal__content"
            style={{ width: "65px", borderRight: "solid 2px #f3f3f3" }}
          >
            <p className="headerVal__text">%Avance</p>
          </div>
          <div
            className="headerVal__content"
            style={{ width: "72px", borderLeft: "solid 1px #f3f3f3" }}
          >
            <p className="headerVal__text">Metrado</p>
          </div>
          <div className="headerVal__content" style={{ width: "82px" }}>
            <p className="headerVal__text">Monto (S/.)</p>
          </div>
          <div
            className="headerVal__content"
            style={{ width: "72px", borderLeft: "solid 1px #f3f3f3" }}
          >
            <p className="headerVal__text">Metrado</p>
          </div>
          <div className="headerVal__content" style={{ width: "82px" }}>
            <p className="headerVal__text">Monto (S/.)</p>
          </div>
          <div className="headerVal__content" style={{ width: "65px" }}>
            <p className="headerVal__text">%Avance</p>
          </div>
          <div
            className="headerVal__content"
            style={{ width: "46px", borderRight: "solid 2px #f3f3f3" }}
          ></div>
        </div>
      </div>
    </div>
  );
};
