// Basic import
import React, { useState } from "react";

// Libraries import
import { Input, Row } from "antd";

// Components import
import Button from "../../../../../shared/components/Button";
import Card from "./components/Card";

// Styled components import
import { ButtonContainer, Container } from "./styles";

const AddValorizationBanner = ({
  addValorizationHandler,
  project,
  bannerActivation,
}) => {
  const [numValorizations, setNumValorizations] = useState(1);
  const getCalendarOptionLabel = (calendarOptionId) => {
    switch (calendarOptionId) {
      case 1:
        return "días";
      case 2:
        return "semanas";
      case 3:
        return "meses";
      case 4:
        return "días";
      default:
        return "";
    }
  };

  const getCalendarTypeLabel = (calendarTypeId) => {
    switch (calendarTypeId) {
      case 1:
        return "calendarios";
      case 2:
        return "útiles";
      default:
        return "";
    }
  };

  const getWeekDayLabel = (weekDayId) => {
    switch (weekDayId) {
      case 1:
        return "lunes";
      case 2:
        return "martes";
      case 3:
        return "miércoles";
      case 4:
        return "jueves";
      case 5:
        return "viernes";
      case 6:
        return "sábados";
      case 7:
        return "domingos";
      default:
        return "";
    }
  };

  const valorizationGeneratorOption = () => {
    switch (project.valorization_generator_option) {
      case "CUSTOM":
        const number_seasons = project.valorization_num_seasons;
        const calendarOptionId = project.valorization_calendar_option;
        const calendarTypeId = project.valorization_calendar_type;
        return `Personalizado cada ${number_seasons} ${getCalendarOptionLabel(
          calendarOptionId
        )} ${getCalendarTypeLabel(calendarTypeId)}`;
      case "WEEKLY":
        const weekDayId = project.valorization_week_day;
        return `Semanal, todos los ${getWeekDayLabel(weekDayId)}`;
      case "BIWEEKLY":
        return "Quincenal";
      case "MONTHLY":
        return "Mensual";
      default:
        return "";
    }
  };

  return (
    <Card>
      <Container style={{ marginBottom: "20px" }}>
        <p className="blue bold font14" style={{ marginBottom: "20px" }}>
          Número total de valorizaciones
        </p>
        <Input
          value={project.num_valorizations}
          onFocus={(event) => event.target.select()}
          onChange={(e) => console.log(e.target.value)}
          className="editProject__ml"
          style={{ width: "67px" }}
          disabled={true}
        />
      </Container>
      <Container style={{ marginBottom: "20px" }}>
        <p className="blue bold font14" style={{ marginBottom: "20px" }}>
          Duración del periodo de valorizaciones
        </p>{" "}
        <Row className="editProject__ml editProject__mb2">
          {valorizationGeneratorOption()}
        </Row>
      </Container>
      <Container style={{ marginBottom: "20px" }}>
        <p className="blue bold font14" style={{ marginBottom: "20px" }}>
          Número de valorizaciones a agregar
        </p>
        <Input
          value={numValorizations}
          onFocus={(event) => event.target.select()}
          onChange={(e) =>
            setNumValorizations(e.target.value > 0 ? e.target.value : 1)
          }
          className="editProject__ml"
          style={{ width: "67px" }}
          type="number"
          disabled={!bannerActivation}
        />
      </Container>
      <ButtonContainer>
        <Button
          background={bannerActivation ? "#05BE8C" : "#808080"}
          width="140px"
          fontWeight="600"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
          onClick={() => {
            bannerActivation && addValorizationHandler(numValorizations);
          }}
        >
          Agregar
        </Button>
      </ButtonContainer>
    </Card>
  );
};

export default AddValorizationBanner;
