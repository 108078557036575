import styled from "styled-components";

export const TableItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: ${({ height }) => height || "2rem"};
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border: 1px solid rgba(95, 111, 129, 0.7);
  width: 1021px;
  margin-left: 10px;
`;

export const TableItem = styled.div`
  width: ${({ width }) => width};
  color: ${({ header, color }) => (header ? "#014083" : color || "black")};
  ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop};`};
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

export const BaseTable = styled.div`
  margin-top: 0.1rem;
`;

export const CreatorImg = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 5px;
  border-radius: 50%;
`;

export const ActionIcon = styled.img`
  width: 15px;
  height: 15px;
  ${(props) => props.active && `cursor: pointer;`}
`;

export const ActionIconContainer = styled.div`
  width: 45px;
  height: 15px;
  display: flex;
  justify-content: space-between;
`;
