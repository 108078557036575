import styled from "styled-components";

export const TableSummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 2rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  width: 1908px;
  margin-left: 10px;
`;

export const TableItem = styled.div`
  width: ${({ width }) => width};
  color: ${({ header, color }) => (header ? "#014083" : color || "black")};
  ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop};`};
  font-weight: bold;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || "center"};
`;
