import { baseProvider } from "../baseProvider";

const getDebtById = async (id) => baseProvider.httpGet(`/debts/${id}`);
const updateDebtById = async (id, body) =>
  baseProvider.httpPatch(`/debts/${id}`, body);
const chargeDebtDocumentById = async (id, body) =>
  baseProvider.httpPostForm(`/debts/${id}/documents`, body);
const getDebtDocumentsById = async (id) =>
  baseProvider.httpGet(`/debts/${id}/documents`);
const downloadDebtDocumentsById = async (id) =>
  baseProvider.httpGetFile(`/debt_documents/${id}`);
const deleteDebtDocumentsById = async (id) =>
  baseProvider.httpDelete(`/debt_documents/${id}`);

const sendDebtById = async (id) =>
  baseProvider.httpPost(`/debts/${id}/send`);
const validateDebtById = async (id) =>
  baseProvider.httpPost(`/debts/${id}/validate`);
const rejectDebtById = async (id, body) =>
  baseProvider.httpPost(`/debts/${id}/reject`, body);

export {
  getDebtById,
  updateDebtById,
  chargeDebtDocumentById,
  getDebtDocumentsById,
  downloadDebtDocumentsById,
  deleteDebtDocumentsById,
  sendDebtById,
  validateDebtById,
  rejectDebtById,
};
