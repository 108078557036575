// Basic imports
import React, { useState, useEffect } from "react";

// Components imports
import { ProjectValuationBanner } from "../components/ProjectValorizationBanner";

// Context
import { useAppContext } from "../../App/context";

// Services import
import { getProjectById } from "../../../providers";

const ProjectValorizationPage = ({ history, match }) => {
  const { id } = match.params;
  const {
    projectRole,
    setProjectRole,
    setIsProjectAdmin,
    setProjectName,
    setProjectEnterprise,
  } = useAppContext();

  const [project, setProject] = useState({ projectdelivery: null });

  const fetchProject = async () => {
    try {
      const _project = await getProjectById(id);
      setProject(_project);
      setProjectRole(
        _project.role === "SUPERVISOR_LEADER" || _project.role === "SUPERVISOR"
          ? "Supervisor"
          : "Ejecutor"
      );
      setIsProjectAdmin(_project.role === "ADMIN");
      setProjectName(_project.name);
      setProjectEnterprise(_project.enterprise);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProject();
  }, []);

  return (
    <>
      <ProjectValuationBanner
        history={history}
        match={match}
        user={projectRole}
        setUser={setProjectRole}
        role={project?.role}
        name={project?.name}
        project={project}
        setProject={setProject}
        image={project?.image}
        general_aproved={project?.general_aproved}
      />
    </>
  );
};

export default ProjectValorizationPage;
