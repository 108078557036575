// React import
import React, { useState, useEffect } from "react";

// Third part libraries import
import { DatePicker, notification } from "antd";
import * as FileSaver from "file-saver";
import dayjs from "dayjs";
import moment from "moment";

// Components import
import { Button } from "../../../Button";
import ApprovalPanel from "../ApprovalPanel";
import ChargeFileButton from "../ChargeFileButton";
import FileItem from "../FileItem";
import { ItemContainer, HiddenViceContainer, Indication } from "./styles";

// Services import
import { getFinishProjectDelivery } from "../../../../providers";
import {
  approveFinishProjectDelivery,
  updateFinishProjectDelivery,
  getFinalProjectDeliveryMinuteDocument,
} from "../../services";

// Utils import
import { clientDateFormat, serverDateFormat } from "../../../../utils";

const FinishDeliveryModal = ({
  id,
  userRole,
  projectDeliverySummary,
  reloadProjectDelivery,
  setLoading,
  setHiddenViceModalActive,
}) => {
  const [projectDeliveryFinish, setProjectDeliveryFinish] = useState({
    super_approved_finish_delivery: false,
    exec_approved_finish_delivery: false,
    final_minute_filename: "",
    final_minute: null,
    final_minute_load_date: null,
  });
  const [triggerProjectDeliveryFinish, setTriggerProjectDeliveryFinish] =
    useState(true);
  const disableForm =
    !projectDeliverySummary.finish_delivery_enabled ||
    userRole === "SUPERVISOR" ||
    userRole === "EXECUTOR";

  useEffect(() => {
    const fetchProjectDeliveryFinish = async () => {
      try {
        setLoading(true);
        const response = await getFinishProjectDelivery(id);
        setProjectDeliveryFinish(response);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchProjectDeliveryFinish();
  }, [id, triggerProjectDeliveryFinish]);

  // Handler approbation
  const approveSuccessHandler = (response) => {
    notification.success({
      message: Object.values(response)[0][0],
    });
    setLoading(false);
    reloadProjectDelivery();
    setTriggerProjectDeliveryFinish(!triggerProjectDeliveryFinish);
  };

  const approveErrorHandler = (response) => {
    notification.error({
      message: response
        ? Object.values(response)[0][0]
        : "Ocurrió un error al aprobar el cierre de la entrega de obra",
    });
    setLoading(false);
  };

  const approveHandler = async () => {
    setLoading(true);
    await approveFinishProjectDelivery(
      id,
      approveErrorHandler,
      approveSuccessHandler
    );
  };

  // Handler update
  const updateSuccessHandler = (response) => {
    notification.success({
      message: "Se actualizo correctamente el cierre de entrega de obra",
    });
    setLoading(false);
    setTriggerProjectDeliveryFinish(!triggerProjectDeliveryFinish);
  };

  const updateErrorHandler = (response) => {
    notification.error({
      message: response
        ? Object.values(response)[0][0]
        : "Ocurrió un error al actualizar el cierre de entrega de obra",
    });
    setLoading(false);
  };

  const getUpdateBody = (_projectDeliveryFinish) => {
    let formData = new FormData();
    _projectDeliveryFinish.final_minute &&
      formData.append("final_minute", _projectDeliveryFinish.final_minute);
    _projectDeliveryFinish.final_minute_load_date &&
      formData.append(
        "final_minute_load_date",
        _projectDeliveryFinish.final_minute_load_date
      );
    return formData;
  };

  const updateHandler = async (item, label) => {
    setLoading(true);
    const _projectDeliveryFinish = { ...projectDeliveryFinish, [label]: item };
    const updateBody = getUpdateBody(_projectDeliveryFinish);
    await updateFinishProjectDelivery(
      id,
      updateBody,
      updateErrorHandler,
      updateSuccessHandler
    );
  };

  // Handler get document
  const documentGetErrorHandler = () => {
    notification.error({
      message: "Ocurrió un error en la descarga del acta de entrega definitiva",
    });
  };

  const documentGetSuccessHandler = (response) => {
    FileSaver.saveAs(response, projectDeliveryFinish.final_minute_filename);
  };

  const getDocument = async (id) => {
    await getFinalProjectDeliveryMinuteDocument(
      id,
      documentGetErrorHandler,
      documentGetSuccessHandler
    );
  };

  return (
    <>
      <p className="blue bold font14" style={{ marginBottom: "2px" }}>
        Acta definitiva de entrega de obra sin observaciones:
      </p>
      <div className="projectVal__card__items">
        <ChargeFileButton
          chargeDocument={async (file) => {
            !disableForm && (await updateHandler(file, "final_minute"));
          }}
        />
        <FileItem
          fileName={projectDeliveryFinish.final_minute_filename}
          getFile={() => getDocument(id)}
        />
      </div>
      <ItemContainer>
        <p
          className="blue bold font14"
          style={{ marginBottom: "16px", marginTop: "16px" }}
        >
          Fecha de suscripción de acta:
        </p>
        <DatePicker
          value={dayjs(
            projectDeliveryFinish.final_minute_load_date || new Date()
          )}
          onChange={async (value) => {
            await updateHandler(
              moment(value.toDate()).format(serverDateFormat),
              "final_minute_load_date"
            );
          }}
          format={clientDateFormat}
          disabled={disableForm}
          style={{ width: "150px" }}
        />
      </ItemContainer>
      <div>
        <ApprovalPanel
          label="Firma de entrega de obra"
          approvalEnable={projectDeliverySummary.finish_delivery_enabled}
          approveHandler={() => {
            approveHandler();
          }}
          userRole={userRole}
          superApproved={projectDeliveryFinish.super_approved_finish_delivery}
          execApproved={projectDeliveryFinish.exec_approved_finish_delivery}
        />
      </div>
      <HiddenViceContainer>
        <Button
          background="#0169E4"
          width="fit-content"
          fontWeight="600"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
          padding="0 16px 0 16px"
          onClick={() => {
            setHiddenViceModalActive(true);
          }}
        >
          Vicio oculto
        </Button>
        <Indication>
          ** Use el botón "Vicio oculto" para ingresa un listado de
          observaciones adicionales antes de la firma de la entrega de obra
        </Indication>
      </HiddenViceContainer>
    </>
  );
};

export default FinishDeliveryModal;
