//* React import
import React, { useState } from "react";

//* Components import
// Third party components
import { Modal, Popconfirm } from "antd";
// Custom components
import { Button } from "../../../../../components/Button";

export default function SignModal(props) {
  const [activePopFirm, setActivePopFirm] = useState(false);
  return (
    <Modal
      open={props.active}
      title=""
      width={1000}
      footer={null}
      bodyStyle={{ "text-align": "center" }}
      centered
      closable={false}
    >
      <iframe
        id="pdf-iframe"
        title="valorization_final_report"
        src={props.finalReport}
      ></iframe>
      <div className="projectVal__modal__btns">
        <Popconfirm
          placement="topLeft"
          title="He leído completamente el reporte y estoy seguro que deseo firmar la valorización"
          open={activePopFirm}
          onConfirm={() => {
            setActivePopFirm(false);
            setTimeout(() => {
              props.signpresentValorization();
            }, 250);
          }}
          onCancel={() => setActivePopFirm(false)}
          okText="Sí"
          cancelText="No"
        />
        <Button
          width="122px"
          fontWeight="600"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
          background={false ? "#92DECD" : "#05BE8C"}
          disabled={false}
          onClick={() => {
            setActivePopFirm(true);
          }}
        >
          Confirmar firma
        </Button>

        <Button
          width="122px"
          fontWeight="600"
          background="#D93022"
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.25)"
          disabled={false}
          onClick={() => {
            setActivePopFirm(false);
            setTimeout(() => {
              props.setActiveModalFirm(false);
            }, 250);
          }}
        >
          No firmar
        </Button>
      </div>
    </Modal>
  );
}
