import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

const moveLeft = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const Wrapper = styled.div`
  overflow: hidden;
  white-space: wrap;
  position: relative;
  background-color: black;
  width: calc(100% - 598px);
`;

const Text = styled.span`
  display: inline-block;
  animation: ${moveLeft} 30s linear infinite;
  width: 100%;
  text-align:center;
  color: white;
`;

export const TextBanner = ({ content }) => {
  const [text, setText] = useState(content);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setText(() => content);
    }, 10000);

    return () => clearInterval(intervalId);
  }, [content]);

  return (
    <Wrapper>
      <Text>{text}</Text>
    </Wrapper>
  );
};
