import React, { useEffect, useState } from "react";
import { ModalTitle, ModalHeader, ModalCloseButton } from "./styles";

import { getDebtById, rejectPaymentById } from "../../providers";

import { serverDateFormat } from "../../utils";
import moment from "moment";
import CloseButtonModal from "./assets/modal-close-button.png";

import StartDeliveryModal from "./components/StartDeliveryModal";
import DeliveryMinuteModal from "./components/DeliveryMinuteModal";
import ObservationsListPeriodModal from "./components/ObservationsListPeriodModal";
import ObservationsListModal from "./components/ObservationsListModal";
import ObservationsSolvingPeriodModal from "./components/ObservationsSolvingPeriodModal";
import FinishDeliveryModal from "./components/FinishDeliveryModal";

export default function DebtMainCard({
  section,
  debtModalActive,
  setDebtModalActive,
  userRole,
  projectDeliverySummary,
  setLoading,
  projectDeliveryId = 2,
  reloadProjectDelivery,
  setHiddenViceModalActive,
  showMobileModal,
}) {
  const getTitle = (_section) => {
    let title = "";
    switch (_section) {
      case 1:
        title = "Inicio de entrega de obra";
        break;
      case 2:
        title = "Acta de entrega de obra";
        break;
      case 3:
        title = "Periodo de listado de observaciones";
        break;
      case 4:
        title = "Listado de observaciones";
        break;
      case 5:
        title = "Periodo de resolución de observaciones";
        break;
      case 6:
        title = "Cierre de entrega de obra";
        break;
      default:
        break;
    }
    return title;
  };
  return (
    <>
      {debtModalActive && (
        <>
          <ModalHeader>
            <ModalTitle>{getTitle(section)}</ModalTitle>
            <ModalCloseButton
              alt="modal-close-button"
              src={CloseButtonModal}
              onClick={() => {
                setDebtModalActive(false);
              }}
            />
          </ModalHeader>
          {section === 1 && (
            <StartDeliveryModal
              id={projectDeliveryId}
              userRole={userRole}
              projectDeliverySummary={projectDeliverySummary}
              reloadProjectDelivery={reloadProjectDelivery}
              setLoading={setLoading}
            />
          )}
          {section === 2 && (
            <DeliveryMinuteModal
              id={projectDeliveryId}
              userRole={userRole}
              projectDeliverySummary={projectDeliverySummary}
              reloadProjectDelivery={reloadProjectDelivery}
              setLoading={setLoading}
            />
          )}
          {section === 3 && (
            <ObservationsListPeriodModal
              id={projectDeliveryId}
              userRole={userRole}
              projectDeliverySummary={projectDeliverySummary}
              reloadProjectDelivery={reloadProjectDelivery}
              setLoading={setLoading}
            />
          )}
          {section === 4 && (
            <ObservationsListModal
              id={projectDeliveryId}
              userRole={userRole}
              projectDeliverySummary={projectDeliverySummary}
              reloadProjectDelivery={reloadProjectDelivery}
              setLoading={setLoading}
            />
          )}
          {section === 5 && (
            <ObservationsSolvingPeriodModal
              id={projectDeliveryId}
              userRole={userRole}
              projectDeliverySummary={projectDeliverySummary}
              reloadProjectDelivery={reloadProjectDelivery}
              setLoading={setLoading}
            />
          )}
          {section === 6 && (
            <FinishDeliveryModal
              id={projectDeliveryId}
              userRole={userRole}
              projectDeliverySummary={projectDeliverySummary}
              reloadProjectDelivery={reloadProjectDelivery}
              setLoading={setLoading}
              setHiddenViceModalActive={setHiddenViceModalActive}
              showMobileModal={showMobileModal}
            />
          )}
        </>
      )}
    </>
  );
}
