import React from "react";

import { notification } from "antd";

// Components import
import NewConstructionCard from "../NewConstructionCard";
import ConstructionCard from "../ConstructionCard";
import { BannerContainer } from "./styles";

// Services import
import { deleteConstruction } from "../../services";

export default function PortfolioBanner({
  constructions = [],
  reloadConstructions,
  openNewConstructionModal,
  openEditConstructionModal,
}) {
  const errorHandler = (type = "server", message = "") => {
    switch (type) {
      case "server":
        notification.error({
          message: "Error de servidor. Inténtelo de nuevo",
        });
        break;
      case "bad_request":
        notification.error({ message: message });
        break;
      default:
        break;
    }
  };


  const deleteConstructionSuccessHandler = () => {
    notification.success({
      message: "Obra eliminada correctamente",
    });
    reloadConstructions();
  };

  const deleteConstructionHandler = async (id) => {
    await deleteConstruction(
      id,
      errorHandler,
      deleteConstructionSuccessHandler
    );
  };

  return (
    <div>
      <BannerContainer>
        {constructions?.map((project) => (
          <ConstructionCard
            key={project.id}
            {...project}
            deleteConstruction={() => deleteConstructionHandler(project.id)}
            openEditConstructionModal={() =>
              openEditConstructionModal(project.id)
            }
          />
        ))}

        <NewConstructionCard
          createConstruction={() => openNewConstructionModal()}
        />
      </BannerContainer>
    </div>
  );
}
