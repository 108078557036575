import React from "react";
import "./styles.css";
import { ItemDiv } from "../styles";

import checkStateRound from "./assets/check-state-round.png";
import grayStateRound from "./assets/gray-state-round.png";
import redStateRound from "./assets/red-state-round.png";

const Item = ({
  valorization,
  isActive,
  setActiveValorization,
  setActiveDebtId,
  showMobileModal,
}) => {
  let firstImageIcon = grayStateRound;
  let secondImageIcon = grayStateRound;
  let thirdImageIcon = grayStateRound;

  switch (valorization.general_status) {
    case "SIGNED":
      firstImageIcon = checkStateRound;
      secondImageIcon = redStateRound;
      thirdImageIcon = redStateRound;
      break;
    case "DEBT_VALIDATED":
      firstImageIcon = checkStateRound;
      secondImageIcon = checkStateRound;
      thirdImageIcon = redStateRound;
      break;
    case "DEBT_CLOSED":
      firstImageIcon = checkStateRound;
      secondImageIcon = checkStateRound;
      thirdImageIcon = checkStateRound;
      break;
    default:
      firstImageIcon = grayStateRound;
      secondImageIcon = grayStateRound;
      thirdImageIcon = grayStateRound;
      break;
  }

  return (
    <div
      className={
        showMobileModal
          ? "valorizations_summary_table_item_mobile"
          : "valorizations_summary_table_item"
      }
    >
      <ItemDiv width="120px">
        <div
          className={
            isActive
              ? "white bold font14 projectVal__mb1 valorization_summary_button_active"
              : "blue bold font14 projectVal__mb1 valorization_summary_button"
          }
          onClick={() => {
            setActiveDebtId(valorization.debt);
            setActiveValorization(valorization.order);
          }}
        >
          {(showMobileModal ? "Val. " : "Valorización ") + valorization.order}
        </div>
      </ItemDiv>
      <ItemDiv width="140px">
        <img alt="val_state" src={firstImageIcon} />
      </ItemDiv>
      <ItemDiv width="137px">
        <img alt="val_state" src={secondImageIcon} />
      </ItemDiv>
      <ItemDiv width="137px">
        <img alt="val_state" src={thirdImageIcon} />
      </ItemDiv>
    </div>
  );
};

export default Item;
