import { baseProvider } from "../baseProvider";

const postCreateProject = async (body) =>
  baseProvider.httpPost("/projects", body);

const getProjects = async (params) =>
  baseProvider.httpGet("/projects", params);

const getProjectById = async (id) =>
  baseProvider.httpGet(`/projects/${id}`);

const patchImage = async (id, body) =>
  baseProvider.httpPatchForm(`/projects/${id}`, body);

const patchProjectById = async (id, body) =>
  baseProvider.httpPatch(`/projects/${id}`, body);

const deleteProjectById = async (id) =>
  baseProvider.httpDelete(`/projects/${id}`);

// Aprobación: ejecutor y supervisor
const patchApproval = async (id, body) =>
  baseProvider.httpPatch(`/projects/${id}/settings_aprobation`, body);

export {
  postCreateProject,
  getProjects,
  getProjectById,
  patchImage,
  patchProjectById,
  deleteProjectById,
  patchApproval,
};
