import React from "react";
import { Popconfirm, notification } from "antd";

import { patchApproval } from "../../../providers";

import { Button } from "../../Button";

export const AcceptButtons = ({
  approvedByExecutor,
  approvedBySupervisor,
  role,
  id,
  name,
  setToggleUpdateProject,
  setSpinLoading
}) => {
  const approveDocuments = async () => {
    try {
      setSpinLoading(true)
      const response = await patchApproval(id, { setting: name });
      if (response.detail) {
        notification.success({
          message: response.detail,
        });
        setToggleUpdateProject((prev) => !prev);
        setSpinLoading(false)
      } else {
        notification.error({
          message: "Ocurrió un error. Por favor vuelva a intentarlo",
        });
        setSpinLoading(false)
      }
    } catch (error) {
      notification.error({
        message: "Ocurrió un error interno. Por favor vuelva a intentarlo",
      });
      setSpinLoading(false)
    }
  };

  return (
    <>
      {approvedByExecutor ? (
        <p className="editProject__green editProject__center">aprobado</p>
      ) : (
        <Popconfirm
          title="¿Esta seguro de realizar esta aprobación?"
          onConfirm={approveDocuments}
          onCancel={() => {}}
          okText="Si"
          cancelText="No"
        >
          <Button
            width="81px"
            background="#ffffff"
            border={
              role === "EXECUTOR_LEADER"
                ? "1px solid #40a9ff"
                : "1px solid rgba(95, 111, 129, 0.5)"
            }
            className="editProject__center"
            borderRadius="2px"
            color={
              role === "EXECUTOR_LEADER" ? "#40a9ff" : "rgba(95, 111, 129, 0.5)"
            }
            disabled={role !== "EXECUTOR_LEADER"}
          >
            Aceptar
          </Button>
        </Popconfirm>
      )}
      {approvedBySupervisor ? (
        <p className="editProject__green editProject__center">aprobado</p>
      ) : (
        <Popconfirm
          title="¿Esta seguro de realizar esta aprobación?"
          onConfirm={approveDocuments}
          onCancel={() => {}}
          okText="Si"
          cancelText="No"
        >
          <Button
            width="81px"
            background="#ffffff"
            border={
              role === "SUPERVISOR_LEADER"
                ? "1px solid #40a9ff"
                : "1px solid rgba(95, 111, 129, 0.5)"
            }
            className="editProject__center"
            borderRadius="2px"
            color={
              role === "SUPERVISOR_LEADER"
                ? "#40a9ff"
                : "rgba(95, 111, 129, 0.5)"
            }
            /* onClick={showConfirm} */
            disabled={role !== "SUPERVISOR_LEADER"}
          >
            Aceptar
          </Button>
        </Popconfirm>
      )}
    </>
  );
};
