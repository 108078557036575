import React from "react";

import { ItemDiv, TableItemContainer } from "./styles";

import checkStateRound from "./assets/check-state-round.png";
import grayStateRound from "./assets/gray-state-round.png";

const Item = ({ label, isActive, isBlocked, showMobileModal, onClick }) => {
  let statusImageIcon = grayStateRound;

  if (!isBlocked) {
    statusImageIcon = checkStateRound;
  }

  return (
    <TableItemContainer>
      <ItemDiv width="230px">
        <div
          className={
            isActive
              ? "white bold font14 projectVal__mb1 valorization_summary_button_active"
              : "blue bold font14 projectVal__mb1 valorization_summary_button"
          }
          onClick={() => {
            onClick();
          }}
        >
          {label}
        </div>
      </ItemDiv>
      <ItemDiv width="150px">
        <img alt="val_state" src={statusImageIcon} />
      </ItemDiv>
    </TableItemContainer>
  );
};

export default Item;
