import styled from "styled-components";

// Styled component for the container
const ImageWithTextContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

// Styled component for the image
const Image = styled.img`
  height: auto;
  width: 100px;
  display: block;
`;

// Styled component for the text overlay
const TextOverlay = styled.div`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
`;

const ArrowButton = ({ imageUrl, text, click }) => {
  return (
    <ImageWithTextContainer >
      <Image src={imageUrl} alt="Example" />
      <TextOverlay>
        <p>{text}</p>
      </TextOverlay>
    </ImageWithTextContainer>
  );
};

export default ArrowButton;



