import React from "react";
import Item from "./Item";
import FileList from "./FileList";
import { parseFloatNumber } from "../../../utils";
import { Input, DatePicker } from "antd";
import { clientDateFormat, serverDateFormat } from "../../../utils";
import moment from "moment";

export const DebtValidationCard = ({
  debt,
  user,
  files,
  comments,
  setComments,
  getDebtDocument,
  deleteDebtDocument,
  enableActionsSupervisor,
  activateComments = true,
}) => {
  const { TextArea } = Input;

  const enableEdition = user === "Supervisor" && debt.status === "NO_STARTED";

  return (
    <div className="projectVal__card__container">
      <div style={{ width: "90%" }}>
        <div className="projectVal__card__items">
          <Item
            title="Monto de la factura (inc. IGV)"
            content={"S/." + parseFloatNumber(debt.amount)}
            width="192px"
            disabled={true}
          />
          <div className="debt_item">
            <p className="blue bold font14 projectVal__mb1">Fecha ingreso</p>
            <DatePicker
              value={moment(debt.date, serverDateFormat)}
              format={clientDateFormat}
              disabled={true}
              style={{ width: "167px" }}
              className="editProject__mb2"
            />{" "}
          </div>
        </div>
        <div className="projectVal__card__items">
          <Item
            title="Serie"
            content={debt.invoice_series}
            width="192px"
            disabled={!enableEdition}
          />
          <Item
            title="Número"
            content={debt.invoice_number}
            width="143px"
            disabled={!enableEdition}
          />
        </div>
        <div className="projectVal__card__items">
          <FileList
            files={files}
            getDebtDocument={getDebtDocument}
            deleteDebtDocument={deleteDebtDocument}
          />
        </div>
        {activateComments && (
          <>
            <p className="blue bold font14 projectVal__mb1">
              Comentarios de rechazo:
            </p>
            <TextArea
              value={comments}
              onFocus={(event) => event.target.select()}
              onChange={(event) => {
                setComments(event.target.value);
              }}
              rows={4}
              className="projectVal__mb1"
              placeholder="Indique porque rechaza la valorización del proyecto"
              disabled={!enableActionsSupervisor}
            />
          </>
        )}
      </div>
    </div>
  );
};
