import React from "react";
import { Row, Col } from "reactstrap";
import Skeleton from "react-loading-skeleton";

export const HeaderSkeleton = () => {
  return (
    <div style={{ padding: "0.75rem 0rem" }}>
      <Row className="ramp" style={{ justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Skeleton height={30} width={250} />
          <Skeleton height={30} width={200} />
        </div>
        <Skeleton height={60} width={108} />
      </Row>
      <br />
      <Row className="ramp editProject__rowCards">
        <Skeleton height={420} width={364} />
        <Skeleton height={420} width={400} />
        <Skeleton height={420} width={400} />
      </Row>
      <br />
      <Skeleton height={420} />
    </div>
  );
};
