
import styled from "styled-components";

export const ModalButtons = styled.div`
    display: flex;
    gap: 30px;
    align-self: center;
    /* justify-content: flex-end; */
    justify-content: center;
    margin: auto;
`;
