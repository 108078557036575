import styled from "styled-components";

export const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
  background-image: url(${(props) => props.imageUrl});
  background-size: 100% 100%;
  width: 190px;
  height: 140px;
  @media (min-width: 1258px) and (max-width: 1432px) {
    display: none;
  }
  @media (max-width: 823px) {
    display: none;
  }
`;

export const ImageConfigProject = styled.img`
  position: absolute;
  top: 15%;
  left: 90%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  @media (min-width: 1258px) and (max-width: 1432px) {
    display: none;
  }
  @media (max-width: 823px) {
    display: none;
  }
`;

export const ButtonConfigProject = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  @media (min-width: 1258px) and (max-width: 1432px) {
    display: none;
  }
  @media (max-width: 823px) {
    display: none;
  }
`;
