import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";

import { notification, Popconfirm } from "antd";

import {
  getProjectById,
  patchProjectById,
  postProjectCronogram,
} from "../../../../providers";

import { Button } from "../../../../components/Button";

import "./EditProjectBanner.css";

import {
  BasicInformation,
  ProjectTeam,
  Calendar,
  ValuationSchedule,
  ValuationDocuments,
  CommercialTerms,
  InitialConditions,
  DebtSettings,
  ProjectDeliverySettings,
} from "../../../../components/EditProjectCardGroup";

import { HeaderSkeleton } from "../../../../components/Skeletons/HeaderSkeleton";

import { EditProjectPageContainer } from "../EditProjectPageContainer";

// Services import
import { deleteProject } from "../../services";

export const EditProjectBanner = ({ history, match }) => {
  const { id } = match.params;

  const [project, setProject] = useState();
  const [name, setName] = useState("");
  const [editName, setEditName] = useState(false);
  const [toggleUpdateProject, setToggleUpdateProject] = useState(false);
  const [loading, setLoading] = useState(true); //first loading
  const [spinLoading, setSpinLoading] = useState(false);
  const [isPreVal, setIsPreVal] = useState(false);

  const [showPopConfirm, setShowPopConfirm] = useState(false);

  const [modal, setModal] = useState(false);
  const [toggleUpdatePrevalorizations, settoggleUpdatePrevalorizations] =
    useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const _project = await getProjectById(id);
        if (
          _project.role !== "ADMIN" &&
          _project.role !== "SUPERVISOR_LEADER" &&
          _project.role !== "EXECUTOR_LEADER"
        ) {
          history.push("/main");
        }
        setProject(_project);
        setName(_project.name);
        setIsPreVal(
          _project.exist_valorizations_program &&
            _project.exist_contractual_budget &&
            _project.exist_general_expenses &&
            _project.exist_valued_shedule
        );
      } catch (error) {
        notification.error({
          message: "Ocurrió un error interno. Por favor vuelva a intentarlo",
        });
      }
    };
    fetchProject();
  }, [toggleUpdateProject]);

  const updateProject = async (body, toggleUpdate = false) => {
    try {
      setSpinLoading(true);
      const response = await patchProjectById(id, body);
      if (response.id && toggleUpdate) {
        setToggleUpdateProject(!toggleUpdateProject);
        setSpinLoading(false);
        return;
      }
      if (response.id) {
        setEditName(false);
        notification.success({
          message: "El proyecto ha sido actualizado exitosamente",
        });
        setToggleUpdateProject(!toggleUpdateProject);
        setSpinLoading(false);
      }
    } catch (error) {
      notification.error({
        message: "Ocurrió un error interno. Por favor vuelva a intentarlo",
      });
      setSpinLoading(false);
    }
  };

  const generateValuationSchedule = async () => {
    try {
      setSpinLoading(true);
      const response = await postProjectCronogram(id);
      if (response.detail) {
        notification.success({
          message: response.detail[0],
        });
        settoggleUpdatePrevalorizations((prev) => !prev);
        setToggleUpdateProject((prev) => !prev);
        setSpinLoading(false);
      } else {
        notification.error({
          message: response.detail[0],
        });
        setSpinLoading(false);
      }
    } catch (error) {
      notification.error({
        message: "Ocurrió un error interno. Por favor vuelva a intentarlo",
      });
      setSpinLoading(false);
    }
  };

  // Deletion handler
  const errorHandler = (type = "server", message = "") => {
    switch (type) {
      case "server":
        notification.error({
          message: "Error de servidor. Inténtelo de nuevo",
        });
        break;
      case "bad_request":
        notification.error({ message: message });
        break;
      default:
        break;
    }
  };

  const deleteProjectSuccessHandler = () => {
    notification.success({
      message: "Proyecto eliminado correctamente",
    });
    history.push(`/constructions/${project.construction}`);
  };

  const deleteProjectHandler = async () => {
    await deleteProject(id, errorHandler, deleteProjectSuccessHandler);
    setShowPopConfirm(false);
  };

  return (
    <EditProjectPageContainer>
      {loading ? (
        <HeaderSkeleton />
      ) : (
        <>
          <Row className="editProject__header">
            <div className="title">{name}</div>
            <div style={{ marginRight: "2rem" }}>
              <Button
                background={
                  project.general_aproved || isPreVal ? "#05BE8C" : "#f5f5f5"
                }
                color={!(project.general_aproved || isPreVal) && "#00000040"}
                border={
                  !(project.general_aproved || isPreVal) &&
                  "1px solid rgba(95, 111, 129, 0.5)"
                }
                margin="auto"
                height="40px"
                width="298px"
                fontSize="18px"
                boxShadow={
                  (project.general_aproved || isPreVal) &&
                  "0px 2px 8px rgba(0, 0, 0, 0.25)"
                }
                onClick={() => {
                  if (project.general_aproved) {
                    history.push(`/projects/${id}/val`);
                    return;
                  }
                  if (isPreVal) {
                    history.push(`/projects/${id}/pre-val`);
                  }
                }}
                disabled={!(project.general_aproved || isPreVal)}
              >
                Ir a gestión de valorización
              </Button>
            </div>
          </Row>
          <Row className="ramp editProject__rowCards">
            {project && (
              <BasicInformation
                {...project}
                updateProject={updateProject}
                setToggleUpdateProject={setToggleUpdateProject}
              />
            )}
            <ProjectTeam id={id} history={history} {...project} />
            <Calendar
              projectId={id}
              calendar_days={project?.calendar_days}
              updateProject={updateProject}
              general_aproved={project.general_aproved}
              {...project}
            />
          </Row>
          <Row className="ramp">
            <ValuationSchedule
              {...project}
              updateProject={updateProject}
              modal={modal}
              setModal={setModal}
              toggleUpdateProject={toggleUpdateProject}
              setToggleUpdateProject={setToggleUpdateProject}
              generateValuationSchedule={generateValuationSchedule}
              toggleUpdatePrevalorizations={toggleUpdatePrevalorizations}
              settoggleUpdatePrevalorizations={settoggleUpdatePrevalorizations}
            />
          </Row>
          <Row className="ramp editProject__rowCards">
            <ValuationDocuments
              id={id}
              setToggleUpdateProject={setToggleUpdateProject}
              setSpinLoading={setSpinLoading}
              {...project}
            />
            <CommercialTerms {...project} updateProject={updateProject} />
            <DebtSettings {...project} updateProject={updateProject} />
            <InitialConditions
              {...project}
              setModal={setModal}
              setToggleUpdateProject={setToggleUpdateProject}
              setSpinLoading={setSpinLoading}
            />
          </Row>

          <Row className="ramp editProject__mb4">
            <Button
              background={
                project.general_aproved || isPreVal ? "#05BE8C" : "#f5f5f5"
              }
              color={!(project.general_aproved || isPreVal) && "#00000040"}
              border={
                !(project.general_aproved || isPreVal) &&
                "1px solid rgba(95, 111, 129, 0.5)"
              }
              margin="0 auto 2rem auto"
              height="40px"
              width="298px"
              fontSize="18px"
              boxShadow={
                (project.general_aproved || isPreVal) &&
                "0px 2px 8px rgba(0, 0, 0, 0.25)"
              }
              onClick={() => {
                if (project.general_aproved) {
                  history.push(`/projects/${id}/val`);
                  return;
                }
                if (isPreVal) {
                  history.push(`/projects/${id}/pre-val`);
                }
              }}
              disabled={!(project.general_aproved || isPreVal)}
            >
              Ir a la gestión de la valorización
            </Button>
          </Row>

          <Row
            className="ramp editProject__mb4"
            style={{ justifyContent: "center" }}
          >
            <Popconfirm
              placement="topLeft"
              title="¿Esta seguro de eliminar el contrato?"
              open={showPopConfirm}
              onConfirm={() => {
                deleteProjectHandler();
              }}
              onCancel={() => {
                setShowPopConfirm(false);
              }}
              okText="Sí"
              cancelText="No"
            >
              <Button
                background="#D93022"
                color="#FFFFFF"
                margin="0 auto 2rem auto"
                height="40px"
                width="298px"
                fontSize="18px"
                onClick={() => {
                  setShowPopConfirm(true);
                }}
              >
                Eliminar contrato
              </Button>{" "}
            </Popconfirm>
          </Row>
        </>
      )}
    </EditProjectPageContainer>
  );
};
