// Base import
import React, { useState, useEffect } from "react";

// Support libraries import
import { useHistory } from "react-router-dom";
import { Popconfirm, notification, Checkbox } from "antd";

// Components import
import TableButton from "../TableButton";

// Styled components
import {
  ActionIconContainer,
  TableItemContainer,
  TableItem,
  BaseTable,
  CreatorImg,
  ActionIcon,
} from "./styles";

// Services import
import {
  approvePreDeductive,
  rejectPreDeductive,
} from "../../../../../services";

// Utils import
import { parseFloatNumber } from "../../../../../../../utils";

// Assets import
import userIcon from "../../assets/icon.jpg";
import checkActive from "../../assets/check_active.svg";
import check from "../../assets/check.svg";
import rejectActive from "../../assets/reject_active.svg";
import reject from "../../assets/reject.svg";

const Table = ({
  predeductives,
  userRole,
  checkPredeductiveHandler,
  checkAllPredeductivesHandler,
  setTrigger,
  trigger,
  openPredeductiveModal,
}) => {
  // States
  const [showPopApprove, setShowPopApprove] = useState(false);
  const [showPopReject, setShowPopReject] = useState(false);
  const [allPredeductivesChecked, setAllPredeductivesChecked] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setAllPredeductivesChecked(
      predeductives.every((predeductive) => predeductive.checked)
    );
  }, [predeductives]);

  const errorHandler = () => {
    setShowPopApprove(false);
    setShowPopReject(false);
    notification.error({
      message: "Ocurrió un error en la operación",
    });
  };

  const successPreDeductiveApprobation = (response) => {
    setShowPopApprove(false);
    notification.success({
      message:
        "Se realizo la aprobación de la propuesta de deductivo con éxito",
    });
    setTrigger(!trigger);
  };

  const successPreDeductiveRejection = (response) => {
    setShowPopReject(false);
    notification.success({
      message: "Se realizo el rechazo de la propuesta de deductivo con éxito",
    });
    setTrigger(!trigger);
  };

  const handlePreDeductiveApprobation = async (id) => {
    await approvePreDeductive(id, errorHandler, successPreDeductiveApprobation);
  };

  const handlePreDeductiveRejection = async (id) => {
    await rejectPreDeductive(id, errorHandler, successPreDeductiveRejection);
  };

  const getStatus = (status) => {
    switch (status) {
      case "CREATED":
        return ["#000000", "Por configurar"];
      case "SETTED":
        return ["#000000", "Propuesto"];
      case "VALIDATED":
        return ["#28A745", "Aceptado"];
      case "REJECTED":
        return ["#DC3545", "Rechazado"];
      default:
        break;
    }
  };

  const getPanel = (
    sectionRole,
    userRole,
    approverRole,
    status,
    predeductiveId
  ) => {
    if (sectionRole !== approverRole) {
      return (
        <>
          <CreatorImg alt="role_icon" src={userIcon} />
          Creador
        </>
      );
    }
    let panelActive = false;
    switch (userRole) {
      case "EXECUTOR":
      case "SUPERVISOR":
        return "-";
      case "ADMIN":
        panelActive = true;
        break;
      case "EXECUTOR_LEADER":
        panelActive = approverRole === "EXECUTOR";
        break;
      case "SUPERVISOR_LEADER":
        panelActive = approverRole === "SUPERVISOR";
        break;
      default:
        break;
    }
    if (status !== "SETTED") {
      panelActive = false;
    }
    return panelActive ? (
      <ActionIconContainer>
        <Popconfirm
          placement="topLeft"
          title="¿Esta seguro de realizar la aprobación de la propuesta de deductivo?"
          open={showPopApprove}
          onConfirm={() => {
            handlePreDeductiveApprobation(predeductiveId);
          }}
          onCancel={() => {
            setShowPopApprove(false);
          }}
          okText="Sí"
          cancelText="No"
        >
          <ActionIcon
            alt="check"
            src={checkActive}
            active
            onClick={() => setShowPopApprove(true)}
          />
        </Popconfirm>
        <Popconfirm
          placement="topLeft"
          title="¿Esta seguro de realizar el rechazo de la propuesta de deductivo?"
          open={showPopReject}
          onConfirm={() => {
            handlePreDeductiveRejection(predeductiveId);
          }}
          onCancel={() => {
            setShowPopReject(false);
          }}
          okText="Sí"
          cancelText="No"
        >
          <ActionIcon
            alt="reject"
            src={rejectActive}
            active
            onClick={() => setShowPopReject(true)}
          />
        </Popconfirm>
      </ActionIconContainer>
    ) : (
      <ActionIconContainer>
        <ActionIcon alt="check" src={check} />
        <ActionIcon alt="reject" src={reject} />
      </ActionIconContainer>
    );
  };

  // Navigate to deductive valorization page

  const navigateToDeductiveValorizationPage = (id) => {
    history.push(`/deductives/${id}/deductives`);
  };

  // Navigate to predeductive valorization page
  const navigateToPredeductiveValorizationPage = (id) => {
    history.push(`/deductives/${id}/predeductives`);
  };

  return (
    <BaseTable>
      <TableItemContainer>
        <TableItem width="50px" paddingTop="8px" header>
          <Checkbox
            checked={allPredeductivesChecked}
            onChange={() => {
              checkAllPredeductivesHandler(!allPredeductivesChecked);
            }}
          />
        </TableItem>
        <TableItem width="60px" header>
          Orden
        </TableItem>
        <TableItem width="400px" header>
          Nombre
        </TableItem>
        <TableItem width="120px" header></TableItem>
        <TableItem width="120px" header></TableItem>
        <TableItem width="200px" header>
          Tipo
        </TableItem>
        <TableItem width="120px" header>
          Estatus
        </TableItem>
        <TableItem width="200px" header>
          Monto total - Presupuesto
        </TableItem>
        <TableItem width="270px" header>
          Monto total - Presupuesto modificado
        </TableItem>
        <TableItem width="200px" header>
          Deductivo - Monto total
        </TableItem>
        <TableItem width="150px" header>
          Supervisor Líder
        </TableItem>
        <TableItem width="150px" header>
          Ejecutor Líder
        </TableItem>
      </TableItemContainer>
      {predeductives.map((predeductive) => (
        <TableItemContainer key={predeductive.id}>
          <TableItem width="50px" paddingTop="8px" header>
            <Checkbox
              checked={predeductive.checked}
              onChange={() => {
                checkPredeductiveHandler(predeductive.id);
              }}
            />
          </TableItem>
          <TableItem width="60px">{predeductive.order}</TableItem>
          <TableItem width="400px">{predeductive.name || "-"}</TableItem>
          <TableItem width="120px">
            <TableButton
              title="Gestión Val."
              active={predeductive.status === "VALIDATED"}
              onClick={() => {
                predeductive.status === "VALIDATED" &&
                  navigateToDeductiveValorizationPage(predeductive.id);
              }}
            />
          </TableItem>
          <TableItem width="120px">
            <TableButton
              title="Ver detalle"
              active={true}
              onClick={() => {
                predeductive.type === "NEW_PARTS"
                  ? openPredeductiveModal(predeductive.id)
                  : navigateToPredeductiveValorizationPage(predeductive.id);
              }}
            />
          </TableItem>
          <TableItem width="200px">
            {predeductive.type === "NEW_PARTS"
              ? "Partidas no existentes"
              : "Partidas existentes"}
          </TableItem>
          <TableItem width="120px" color={getStatus(predeductive.status)[0]}>
            {getStatus(predeductive.status)[1]}
          </TableItem>
          <TableItem width="200px">
            {predeductive.type === "NEW_PARTS"
              ? "NA-Partida nueva"
              : `S/. ${parseFloatNumber(predeductive.original_project_amount)}`}
          </TableItem>
          <TableItem width="270px">
            {predeductive.type === "NEW_PARTS"
              ? "NA-Partida nueva"
              : `S/. ${parseFloatNumber(predeductive.proposal_project_amount)}`}
          </TableItem>
          <TableItem width="200px">
            {predeductive.type === "NEW_PARTS"
              ? `S/. ${parseFloatNumber(predeductive.budget_cost)}`
              : "no, partidas existentes"}
          </TableItem>
          <TableItem width="150px">
            {getPanel(
              "SUPERVISOR",
              userRole,
              predeductive.approver_role,
              predeductive.status,
              predeductive.id
            )}
          </TableItem>
          <TableItem width="150px">
            {getPanel(
              "EXECUTOR",
              userRole,
              predeductive.approver_role,
              predeductive.status,
              predeductive.id
            )}
          </TableItem>
        </TableItemContainer>
      ))}
    </BaseTable>
  );
};

export default Table;
