import { baseProvider } from "../baseProvider";

const sendPaymentByDebtId = async (formData,id) =>
  baseProvider.httpPostForm(`/debts/${id}/payments`,formData);

const validatePaymentById = async (id) =>
  baseProvider.httpPost(`/payments/${id}/validate`);
const rejectPaymentById = async (id, body) =>
  baseProvider.httpPost(`/payments/${id}/reject`, body);

const getPaymentsByDebtId = async (id) =>
  baseProvider.httpGet(`/debts/${id}/payments`);
const getPaymentById = async (id) =>
  baseProvider.httpGet(`/payments/${id}`);

const getDocumentsByPaymentId = async (id) =>
  baseProvider.httpGet(`/payments/${id}/documents`);
const getPaymentDocumentById = async (id) =>
  baseProvider.httpGetFile(`/payment_documents/${id}`);

export {
    sendPaymentByDebtId,
    getPaymentsByDebtId,
    validatePaymentById,
    rejectPaymentById,
    getPaymentById,
    getDocumentsByPaymentId,
    getPaymentDocumentById
};
