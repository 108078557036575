// Basic
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { map, prop } from "ramda";

// App
import { AppContextProvider } from "../context";

// Layouts
import PrivateLayout from "../layouts/PrivateLayout";

const PrivateRouter = ({ modules }) => {
  //TODO: Consider to include the app main layout inside this component
  return (
    <AppContextProvider>
      <PrivateLayout>
        <Switch>
          {map(
            (module) => (
              <Route
                path={prop("pathBase", module)}
                key={prop("pathBase", module)}
                component={() => <module.moduleRouter />}
              />
            ),
            modules
          )}
          <Route path="*" render={() => <Redirect to="/main" />} />
        </Switch>
      </PrivateLayout>
    </AppContextProvider>
  );
};

export default PrivateRouter;
