import React from "react";
import Skeleton from "react-loading-skeleton";
import { Container } from "./styles";

const ProjectsBannerSkeleton = () => {
  return (
    <Container>
      <Skeleton className="card" />
      <Skeleton className="card" />
      <Skeleton className="card" />
      <Skeleton className="card" />
      <Skeleton className="card" />
      <Skeleton className="card" />
    </Container>
  );
};

export default ProjectsBannerSkeleton;