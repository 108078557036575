import styled from "styled-components";

export const TimelineSectionContainer = styled.div`
  margin-top: 24px;
  margin-left: ${(props) => props.marginLeft || "10px"};
  width: ${(props) => props.width || "1214px"};
`;

export const TimelineTitle = styled.p`
  margin-left: 10px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #0169e4;
  margin-top: 30px;
`;

export const InnerCircle = styled.div`
  border-radius: 1.5rem;
  height: 0.5rem;
  width: 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.active ? "#05BE8C" : "#3b82f6")};
  &:before {
    content: "";
    background-color: ${(props) => (props.active ? "#05BE8C" : "#3b82f6")};
    display: inline-block;
    height: 1rem;
    width: 1rem;
    min-width: 1rem;
    border-radius: 6.25rem;
    opacity: 0.5;
  }
`;
