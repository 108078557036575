import React from "react";
import { Input } from "antd";
import "./styles.css";

const Item = ({ title, content, onChange, width, disabled, onBlur }) => {
  return (
    <div className="debt_item">
      <p className="blue bold font14 projectVal__mb1">{title}</p>
      {disabled ? (
        <div className="projectVal__card__output" style={{ width }}>
          {content}
        </div>
      ) : (
        <Input
          value={content}
          onChange={(e) => onChange(e.target.value)}
          onBlur={onBlur}
          disabled={title === "Monto"}
          style={{ width }}
        />
      )}
    </div>
  );
};

export default Item;
