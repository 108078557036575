import { baseProvider } from "../baseProvider";

const getAdditionalValorizationsSummary = async (id) =>
  baseProvider.httpGet(`/additionals/${id}/valorizations/summary`);

const sendPresentAdditionalValorization = async (id) =>
  baseProvider.httpPost(`/additionals/${id}/present_valorization/send`);

const evaluatePresentAdditionalValorization = async (id) =>
  baseProvider.httpPost(`/additionals/${id}/present_valorization/evaluate`);

const getPresentAdditionalValorizationReport = async (id) =>
  baseProvider.httpGetFile(`/additionals/${id}/present_valorization/report`);

const signPresentAdditionalValorization = async (id) =>
  baseProvider.httpPost(`/additionals/${id}/present_valorization/sign`);

export {
  getAdditionalValorizationsSummary,
  sendPresentAdditionalValorization,
  evaluatePresentAdditionalValorization,
  getPresentAdditionalValorizationReport,
  signPresentAdditionalValorization,
};
