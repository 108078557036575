import { baseProvider } from "../baseProvider";

/* Predeductive Part Proposals */
const getPredeductivePartsProposals = async (id, search, params) =>
  baseProvider.httpGetFilter(
    `/predeductives/${id}/proposal_parts`,
    search,
    params
  );

/* Deductive Valorized Part */
const getDeductiveParts = async (id, search, params) =>
  baseProvider.httpGetPrueba(
    `/ded_valorizations/${id}/valorized_parts`,
    search,
    params
  );

const getDeductivePartById = async (id) =>
  baseProvider.httpGet(`/ded_valorized_parts/${id}`);

const patchDeductivePartById = async (id, body) =>
  baseProvider.httpPatch(`/ded_valorized_parts/${id}`, body);

const patchDeductiveImagePart = async (id, body) =>
  baseProvider.httpPatchForm(`/ded_valorized_parts/${id}`, body);

// /* Valorized Part Flux */
const getDeductiveValorizedPartLogs = async (id) =>
  baseProvider.httpGet(`/ded_valorized_parts/${id}/logs`);

// /* Supervisor */
const patchSendDeductiveValorizedPart = async (id) =>
  baseProvider.httpPatch(`/ded_valorized_parts/${id}/send`);

const patchCorrectDeductiveValorizedPart = async (id) =>
  baseProvider.httpPatch(`/ded_valorized_parts/${id}/correct`);

const patchDebateDeductiveValorizedPart = async (id) =>
  baseProvider.httpPatch(`/ded_valorized_parts/${id}/debate`);

const postDeductiveValorizedPartLog = async (id, body) =>
  baseProvider.httpPost(`/ded_valorized_parts/${id}/logs`, body);

// /* Executor */
const patchValidateDeductiveValorizedPart = async (id) =>
  baseProvider.httpPatch(`/ded_valorized_parts/${id}/validate`);

const patchRejectDeductiveValorizedPart = async (id) =>
  baseProvider.httpPatch(`/ded_valorized_parts/${id}/reject`);

const patchValidateDeductiveValorizedPartLog = async (id) =>
  baseProvider.httpPatch(`/ded_valorized_parts/${id}/log_validate`);

const patchRejectDeductiveValorizedPartLog = async (id, body) =>
  baseProvider.httpPatch(`/ded_valorized_parts/${id}/log_reject`, body);

// /* Valorized Part Comment */
const getCommentFileFromDeductiveValorizedPart = async (id) =>
  baseProvider.httpGetFile(`/ded_valorized_parts/${id}/comment_file`);

export {
  getPredeductivePartsProposals,
  getDeductiveParts,
  getDeductivePartById,
  patchDeductivePartById,
  patchDeductiveImagePart,
  patchSendDeductiveValorizedPart,
  patchDebateDeductiveValorizedPart,
  patchValidateDeductiveValorizedPart,
  patchRejectDeductiveValorizedPart,
  patchCorrectDeductiveValorizedPart,
  getCommentFileFromDeductiveValorizedPart,
  getDeductiveValorizedPartLogs,
  postDeductiveValorizedPartLog,
  patchValidateDeductiveValorizedPartLog,
  patchRejectDeductiveValorizedPartLog,
};
