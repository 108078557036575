import styled from "styled-components";
import { Link } from "react-router-dom";
import { Row } from "reactstrap";

export const StyledRow = styled(Row)`
  justify-content: center;
  margin: 0 !important;
`;

export const StyledLink = styled(Link)`
  margin-top: 5px;
  font-size: 12px;
  line-height: 12px;
  color: #0169e4;
`;

export const StyledInput = styled.input`
  font-size: 14px;
  line-height: 16px;
  color: #b6b6b6;
  outline: none;
  border-radius: 10px;
  border: 1px solid #bcbcbc;
  box-sizing: border-box;
  height: 32px;
  width: 80%;
  padding-left: 10px;
  margin-bottom: 10px;
`;

export const Advice = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  line-height: 12px;
  padding-left: 30px;
  padding-right: 30px;
  ${(props) => props.error && `color: #df4e4e`};
  ${(props) => props.success && `color: #05be8c`};
`;

export const ResetPasswordTitle = styled.p`
  margin-left: -45px;
  margin-top: -5px;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #014083;
`;

export const ResetPasswordIndications = styled.p`
  font-size: 12px;
  text-align: justify;
  padding-left: 30px;
  padding-right: 30px;
  line-height: 12px;
  font-weight: 300;
  letter-spacing: 0.02em;
  color: #014083;
`;
