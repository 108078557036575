// Basic imports
import React from "react";

// Components imports
import PreadditionalValorizationBanner from "../components/PreadditionalValorizationBanner";

const ProjectPreAdditionalsPage = ({ history, match }) => {
  return <PreadditionalValorizationBanner history={history} match={match} />;
};

export default ProjectPreAdditionalsPage;
