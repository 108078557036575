// Base import
import React from "react";

// Libraries import
import { Row } from "reactstrap";

// Components import
import MainButtonsPanel from "./components/MainButtonsPanel";
import Counter from "./components/Counter";

export const Qrome = ({
  user,
  presentValorization,
  project,
  predeductive,
  loadPredeductiveReport,
  sendpresentValorization,
  evaluatepresentValorization,
  loadFinalReportValorization,
  setDebtModalActive,
}) => {
  return (
    <Row
      className="ramp"
      style={{
        justifyContent: "left",
        gap: "32px",
        marginBottom: "20px !important",
      }}
    >
      <MainButtonsPanel
        user={user}
        project={project}
        setDebtModalActive={setDebtModalActive}
        loadPredeductiveReport={loadPredeductiveReport}
        presentValorization={presentValorization}
        loadFinalReportValorization={loadFinalReportValorization}
        sendpresentValorization={sendpresentValorization}
        evaluatepresentValorization={evaluatepresentValorization}
      />
      <Counter
        title={`Deductivo N° ${predeductive.order}`}
        subtitle={predeductive.name}
      />
    </Row>
  );
};
