// React import
import React, { useEffect, useState } from "react";

// Components import
import ValorizationsSummaryTable from "./components/ValorizationsSummaryTable";
import Button from "../../../../../shared/components/Button";

// Styled components import
import { ModalTitle, ModalHeader, ButtonContainer, Indication } from "./styles";

// Services import
import { getValorizationsSummary } from "../../../../../../providers";

export default function ValorizationsValidation({
  projectId,
  setLoading,
  onConfirm,
  onReject,
}) {
  const [valorizations, setValorizations] = useState([]);
  useEffect(() => {
    const fetchValorizations = async () => {
      try {
        setLoading(true);
        const response = await getValorizationsSummary(projectId);
        setValorizations(response.results);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchValorizations();
  }, []);

  return (
    <>
      <ModalHeader>
        <ModalTitle>Resumen pago de valorizaciones</ModalTitle>
      </ModalHeader>
      <div>
        <p className="projectVal__modal__description">
          En este sección podrá editar valores de la deuda.
        </p>
      </div>
      <ValorizationsSummaryTable
        valorizations={valorizations}
        setActiveValorization={() => {}}
        setActiveDebtId={() => {}}
        showMobileModal={() => {}}
      />
      <ButtonContainer>
        <Button
          background="#05BE8C"
          width="80px"
          margin="20px 0 0 0"
          fontWeight="600"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
          onClick={() => {
            onReject();
          }}
        >
          Regresar
        </Button>
        <Button
          background="#05BE8C"
          width="80px"
          margin="20px 0 0 0"
          fontWeight="600"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.25)"
          onClick={() => {
            onConfirm();
          }}
        >
          Continuar
        </Button>
      </ButtonContainer>
      <Indication>
        Si el estado de cada valorización es el esperado, prosiga con la
        siguiente etapa, de lo contrario haga los ajustes necesarios a cada
        valorización hasta que los estados en este resumen, sea el deseado
      </Indication>
      <Indication>
        <b>Advertencia: </b>Las valorizaciones en las cuales el pago no has sido
        realizado se incluirán en el cálculo del monto final de la liquidación.
      </Indication>
    </>
  );
}
