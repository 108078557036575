import React, { useState, useEffect } from "react";

// styled components
import { SectionContainer, SectionTitle } from "./styles";
import Table from "./components/Table";
import TableSummary from "./components/TableSummary";

// services import
import { getProjectPreDeductives } from "../../../services";

const DeductivesSection = ({
  projectId,
  userRole,
  openPredeductiveModal,
  deductiveTrigger,
  setDeductiveTrigger,
  setLoading,
}) => {
  const [predeductives, setPredeductives] = useState([]);

  const errorHandler = () => {
    setPredeductives([]);
  };

  const successHandler = (results) => {
    let _predeductives = results.results.map((predeductive) => {
      return { ...predeductive, checked: false };
    });
    setPredeductives(_predeductives);
  };

  useEffect(() => {
    const fetchPreDeductives = async () => {
      await getProjectPreDeductives(projectId, errorHandler, successHandler);
    };
    fetchPreDeductives();
  }, [deductiveTrigger, projectId]);

  // Check predeductive handlers
  const checkPredeductiveHandler = (id) => {
    let _predeductives = predeductives.map((predeductive) => {
      if (predeductive.id === id) {
        return { ...predeductive, checked: !predeductive.checked };
      }
      return predeductive;
    });
    setPredeductives(_predeductives);
  };

  const checkAllPredeductivesHandler = (value) => {
    let _predeductives = predeductives.map((predeductive) => {
      return { ...predeductive, checked: value };
    });
    setPredeductives(_predeductives);
  };

  return (
    <SectionContainer>
      <SectionTitle>Lista de deductivos de obra</SectionTitle>
      <TableSummary
        predeductives={predeductives}
        userRole={userRole}
        setTrigger={setDeductiveTrigger}
        trigger={deductiveTrigger}
        setLoading={setLoading}
      />
      <Table
        predeductives={predeductives}
        checkPredeductiveHandler={checkPredeductiveHandler}
        checkAllPredeductivesHandler={checkAllPredeductivesHandler}
        userRole={userRole}
        setTrigger={setDeductiveTrigger}
        trigger={deductiveTrigger}
        openPredeductiveModal={openPredeductiveModal}
      />
    </SectionContainer>
  );
};

export default DeductivesSection;
