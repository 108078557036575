import React from "react";
import { Modal } from "antd";
import { Button } from "../../../../../../../components/Button";
import { ModalButtons } from "./styles.jsx";

const VisorModal =({active,seeReport,setActiveModal}) => {
return (
    <Modal
    open={active}
    title=""
    width={1000}
    footer={null}
    bodyStyle={{ "text-align": "center" }}
    centered
    closable={false}
    >
    <iframe
        id="pdf-iframe"
        title="valorization_final_report"
        src={seeReport}
    ></iframe>
    <ModalButtons>
        <Button
        width="122px"
        fontWeight="600"
        background="#D93022"
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.25)"
        disabled={false}
        onClick={() => {
            setTimeout(() => {
            setActiveModal(false);
            }, 250);
        }}
        >
        Cerrar
        </Button>
    </ModalButtons>
    </Modal>
);
}
export default VisorModal;