import React from "react";
import styled from "styled-components";
import { IconContext } from "react-icons";
import { IoPersonCircleOutline } from "react-icons/io5";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 68px;
  /* border: 2px solid ${(props) => props.color || "#cecece"}; */
  border-radius: 50%;
  img {
    width: 60px;
    height: 60px;
  }
`;

export const UserPhoto = ({ img, color, hidden }) => {
  return (
    <Container hidden={hidden} color={color}>
      {/*  <img src={img} alt="user1" /> */}
      <IconContext.Provider value={{ color }}>
        <IoPersonCircleOutline size={68} />
      </IconContext.Provider>
    </Container>
  );
};
