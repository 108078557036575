import React, { useState, useEffect } from "react";
import { Progress } from "antd";

import { IconContext } from "react-icons";
import { FaCheck, FaTimes } from "react-icons/fa";

import { IoMdSend } from "react-icons/io";
import { FiSend } from "react-icons/fi";
import { MdAddAPhoto, MdComment } from "react-icons/md";
import { FaRegStopCircle } from "react-icons/fa";

import { parseFloatNumber } from "../../../../../../utils";

export const Part = ({
  part,
  group_id,
  color_class_name,
  tab_class,
  parts_children,
  filter_handler,
  current_order,
  user,
  editPart,
  setisVisibleImageModal,
  setId,
  sendValorizedPart,
  stopSendValorizedPart,
  validateValorizedPart,
  setToggleUpdateParts,
  setToggleUpdatePart,
  setIsVisibleToTalkModal,
  stopSendVerdictValorizedPart,
  is_active,
  executorEnable,
  supervisorEnable,
  fileGroup,
  showBaseBudgetBlock,
  showLastAccumulatedBlock,
  showWorkSpaceBlock,
  showPresentValBlock,
  showAccumulatedBlock,
  showToValBlock,
}) => {
  const [pre_quantity, setPre_quantity] = useState(
    parseFloatNumber(part.pre_quantity)
  );
  const [pre_advance_percentage, setPre_advance_percentage] = useState(
    parseFloatNumber(part.pre_advance_percentage * 100)
  );

  useEffect(() => {
    setPre_quantity(parseFloatNumber(part.pre_quantity));
    setPre_advance_percentage(
      parseFloatNumber(part.pre_advance_percentage * 100)
    );
  }, [part.pre_quantity, part.pre_advance_percentage]);

  const [timeLeft, setTimeLeft] = useState(30);
  const [timePercentage, setTimePercentage] = useState(100);

  useEffect(() => {
    (part.is_presended || part.is_prevalidated || part.is_prerejected) &&
      setTimeout(() => {
        if (timeLeft !== 0) {
          setTimeLeft(timeLeft - 1);
          setTimePercentage(timePercentage - 10 / 3);
        } else {
          //console.log("se envia");
          setToggleUpdateParts((prev) => !prev);
          setToggleUpdatePart((prev) => !prev);
          setTimeLeft(30);
          setTimePercentage(100);
        }
      }, 1000);
  });

  const statusArr = [
    { status: "NO_STARTED", name: "No Comenzado", color: "black" },
    { status: "TO_SEND", name: "Por Enviar", color: "#42cca7" },
    { status: "PRE_SENDED", name: "Pre Enviado", color: "#e0a800" },
    { status: "SENDED", name: "Por Validar", color: "#1f4e78" },
    { status: "PRE_REJECTED", name: "Pre Objetado", color: "#d6563f" },
    { status: "REJECTED", name: "Objetado", color: "#d6563f" },
    { status: "PRE_VALIDATED", name: "Pre Validado", color: "#1e9245" },
    { status: "VALIDATED", name: "Validado", color: "#1e9245" },
    { status: "DEBATABLE", name: "A Conversar", color: "#278ed3" },
    { status: "SENDED*", name: "Por Validar*", color: "#1f4e78" },
    { status: "REJECTED*", name: "Objetado*", color: "#d6563f" },
  ];

  return (
    <React.Fragment>
      <div
        id={fileGroup + "_PART_I_" + group_id}
        className="valorization__partHeaders"
      >
        <div className={"parts__index" + color_class_name}>
          <p className="parts__text">{part.part.part_tag}</p>
        </div>
        <div className={"parts__contract-div " + color_class_name}>
          <button
            hidden={!part.part.has_children}
            id={fileGroup + "_CB_PART_GP_" + group_id}
            className="parts__contract-button"
            onClick={() => filter_handler(group_id, fileGroup)}
          >
            -
          </button>
        </div>
        <div className={"parts__name-div" + color_class_name + tab_class}>
          <span title={part.part.name} className="parts__name-text">
            {part.part.name}
          </span>
        </div>
        {showBaseBudgetBlock ? (
          <>
            {" "}
            <div
              className="parts__content"
              style={{ width: "72px", background: "#efefef" }}
            >
              <p hidden={part.part.has_children} className="parts__text">
                {parseFloatNumber(part.part.quantity)}
              </p>
            </div>
            <div
              className="parts__content"
              style={{ width: "49px", background: "#efefef" }}
            >
              <p className="parts__text">{part.part.unity}</p>
            </div>
            <div
              className="parts__content"
              style={{ width: "72px", background: "#efefef" }}
            >
              <p hidden={part.part.has_children} className="parts__text">
                {parseFloatNumber(part.part.unityprice)}
              </p>
            </div>
            <div
              className="parts__content"
              style={{
                width: "73px",
                borderRight: "solid 1px #f3f3f3",
                background: "#efefef",
              }}
            >
              <p className="parts__text">
                {parseFloatNumber(part.part.ppartial)}
              </p>
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "1.5rem",
              backgroundColor: "#EFEFEF",
            }}
          />
        )}
        {current_order !== 1 &&
          (showLastAccumulatedBlock ? (
            <>
              <div
                className="parts__content"
                style={{
                  width: "72px",
                  borderLeft: "solid 1px #f3f3f3",
                  background: "#efefef",
                }}
              >
                <p hidden={part.part.has_children} className="parts__text">
                  {parseFloatNumber(part.acumulated_quantity - part.quantity)}
                </p>
              </div>
              <div
                className="parts__content"
                style={{ width: "82px", background: "#efefef" }}
              >
                <p className="parts__text">
                  {parseFloatNumber(part.acumulated_mount - part.mount)}
                </p>
              </div>
              <div
                className="parts__content"
                style={{
                  width: "65px",
                  borderRight: "solid 2px #f3f3f3",
                  background: "#efefef",
                }}
              >
                <p className="parts__text">
                  {parseFloatNumber(
                    (part.acumulated_advance_percentage -
                      part.advance_percentage) *
                      100
                  )}
                  {" %"}
                </p>
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "1.5rem",
                backgroundColor: "#EFEFEF",
              }}
            />
          ))}
        {showWorkSpaceBlock ? (
          <>
            <div className="parts__content" style={{ width: "46px" }}>
              <input type="checkbox" className="project_part_check" />
            </div>
            <div className="parts__content" style={{ width: "72px" }}>
              {part.part.has_children ? (
                <p className="parts__text">-</p>
              ) : (
                <input
                  hidden={part.part.has_children}
                  value={pre_quantity}
                  onFocus={(event) => event.target.select()}
                  onChange={(e) => setPre_quantity(e.target.value)}
                  onBlur={() => editPart(part.id, { pre_quantity })}
                  disabled={
                    user !== "Ejecutor" ||
                    part.is_sended ||
                    part.is_closed ||
                    part.is_completed ||
                    !executorEnable
                  }
                  className="parts__input"
                  type="number"
                  min="0"
                />
              )}
            </div>
            <div className="parts__content" style={{ width: "46px" }}></div>
            <div className="parts__content" style={{ width: "82px" }}>
              <p className="parts__text">{parseFloatNumber(part.pre_mount)}</p>
            </div>
            <div
              className="parts__content"
              style={{ width: "65px", fontSize: "12px" }}
            >
              {part.part.has_children ? (
                <p className="parts__text">
                  {parseFloatNumber(part.pre_advance_percentage * 100)} %
                </p>
              ) : (
                <>
                  <input
                    value={pre_advance_percentage}
                    onFocus={(event) => event.target.select()}
                    onChange={(e) => setPre_advance_percentage(e.target.value)}
                    onBlur={() =>
                      editPart(part.id, {
                        pre_advance_percentage: pre_advance_percentage / 100,
                      })
                    }
                    disabled={
                      user !== "Ejecutor" ||
                      part.part.has_children ||
                      part.is_sended ||
                      part.is_closed ||
                      part.is_completed ||
                      !executorEnable
                    }
                    className="parts__input"
                    type="number"
                    min="0"
                  />{" "}
                  %
                </>
              )}
            </div>
            <div
              hidden={user === "Supervisor"}
              className="parts__content"
              style={{ width: "35px" }}
            >
              <IconContext.Provider
                value={{
                  color: `${
                    part.is_sended || !executorEnable ? "#808080" : ""
                  }`,
                }}
              >
                {part.is_sended ? (
                  <IoMdSend
                    hidden={part.part.has_children}
                    size={18}
                    className="navbar__menu-icon"
                    style={{ marginLeft: "8px" }}
                  />
                ) : (
                  <FiSend
                    size={18}
                    hidden={part.part.has_children}
                    className={`navbar__menu-icon ${
                      executorEnable && "parts__pointer"
                    }`}
                    style={{ marginLeft: "8px" }}
                    onClick={() => {
                      if (executorEnable) {
                        sendValorizedPart(part.id);
                      }
                    }}
                  />
                )}
              </IconContext.Provider>
            </div>
            <div
              hidden={user === "Ejecutor"}
              className="parts__content"
              style={{ width: "35px" }}
            >
              <IconContext.Provider
                value={{
                  color: `${
                    !part.is_closed &&
                    part.is_sended &&
                    part.is_validated === false &&
                    part.is_rejected === false &&
                    !part.is_debatable &&
                    supervisorEnable
                      ? "#218838"
                      : "#808080"
                  }`,
                }}
              >
                <FaCheck
                  hidden={part.part.has_children}
                  size={18}
                  className={`navbar__menu-icon ${
                    !part.is_closed &&
                    part.is_sended &&
                    part.is_validated === false &&
                    part.is_rejected === false &&
                    !part.is_debatable &&
                    supervisorEnable &&
                    "parts__pointer"
                  }`}
                  onClick={() => {
                    if (
                      !part.is_closed &&
                      part.is_sended &&
                      part.is_validated === false &&
                      part.is_rejected === false &&
                      !part.is_debatable &&
                      supervisorEnable
                    ) {
                      validateValorizedPart(part.id);
                    }
                  }}
                />
              </IconContext.Provider>
            </div>
            <div
              hidden={user === "Ejecutor"}
              className="parts__content"
              style={{ width: "35px" }}
            >
              <IconContext.Provider
                value={{
                  color: `${
                    !part.is_closed &&
                    part.is_sended &&
                    part.is_validated === false &&
                    part.is_rejected === false &&
                    !part.is_debatable &&
                    supervisorEnable
                      ? "#C82333"
                      : "#808080"
                  }`,
                }}
              >
                <FaTimes
                  hidden={part.part.has_children}
                  size={18}
                  className={`navbar__menu-icon ${
                    !part.is_closed &&
                    part.is_sended &&
                    part.is_validated === false &&
                    part.is_rejected === false &&
                    !part.is_debatable &&
                    supervisorEnable &&
                    "parts__pointer"
                  }`}
                  onClick={() => {
                    if (
                      !part.is_closed &&
                      part.is_sended &&
                      part.is_validated === false &&
                      part.is_rejected === false &&
                      !part.is_debatable &&
                      supervisorEnable
                    ) {
                      setId(part.id);
                      setIsVisibleToTalkModal(true);
                    }
                  }}
                />
              </IconContext.Provider>
            </div>
            <div className="parts__content" style={{ width: "46px" }}>
              <IconContext.Provider
                value={{
                  color: !part.exist_image ? "#808080" : "",
                }}
              >
                <MdAddAPhoto
                  hidden={part.part.has_children}
                  size={18}
                  className={`navbar__menu-icon ${
                    ((user === "Ejecutor" &&
                      ((executorEnable &&
                        (part.exist_image || !part.is_sended)) ||
                        (!executorEnable && part.exist_image))) ||
                      (part.exist_image && user === "Supervisor")) &&
                    "parts__pointer"
                  }`}
                  onClick={() => {
                    if (
                      (user === "Ejecutor" &&
                        ((executorEnable &&
                          (part.exist_image || !part.is_sended)) ||
                          (!executorEnable && part.exist_image))) ||
                      (part.exist_image && user === "Supervisor")
                    ) {
                      setId(part.id);
                      setisVisibleImageModal(true);
                    }
                  }}
                />
              </IconContext.Provider>
            </div>
            <div className="parts__content" style={{ width: "46px" }}>
              <IconContext.Provider
                value={{
                  color: !(part.is_rejected || part.is_debatable)
                    ? "#808080"
                    : "",
                }}
              >
                <MdComment
                  hidden={part.part.has_children}
                  size={18}
                  className={`navbar__menu-icon ${
                    (part.is_rejected || part.is_debatable) && "parts__pointer"
                  }`}
                  style={{ marginTop: "12px" }}
                  onClick={() => {
                    if (part.is_rejected || part.is_debatable) {
                      setId(part.id);
                      setIsVisibleToTalkModal(true);
                    }
                  }}
                />
              </IconContext.Provider>
            </div>
            <div className="parts__content" style={{ width: "46px" }}>
              <Progress
                hidden={
                  !part.is_presended &&
                  !part.is_prevalidated &&
                  !part.is_prerejected
                }
                style={{ marginTop: "7px", marginRight: "4px" }}
                type="circle"
                size={21}
                percent={timePercentage}
                format={() => `${timeLeft}`}
              />
              <FaRegStopCircle
                hidden={
                  !part.is_presended &&
                  !part.is_prevalidated &&
                  !part.is_prerejected
                }
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (part.is_presended) {
                    stopSendValorizedPart(part.id);
                  } else {
                    stopSendVerdictValorizedPart(part.id);
                  }
                }}
                size={21}
              />
            </div>
            <div
              className="parts__content"
              style={{ width: "100px", borderRight: "solid 2px #f3f3f3" }}
            >
              <p
                hidden={part.part.has_children}
                className="parts__text"
                style={{
                  fontWeight: "bold !important",
                  color: `${
                    statusArr.find((elem) => elem.status === part.status)?.color
                  }`,
                }}
              >
                {statusArr.find((elem) => elem.status === part.status)?.name}
              </p>
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "1.5rem",
            }}
          />
        )}
        {showPresentValBlock ? (
          <>
            {" "}
            <div
              className="parts__content"
              style={{ width: "72px", background: "#efefef" }}
            >
              <p className="parts__text">
                {part.part.has_children ? "-" : parseFloatNumber(part.quantity)}
              </p>
            </div>
            <div
              className="parts__content"
              style={{ width: "82px", background: "#efefef" }}
            >
              <p className="parts__text">{parseFloatNumber(part.mount)}</p>
            </div>
            <div
              className="parts__content"
              style={{
                width: "65px",
                borderRight: "solid 2px #f3f3f3",
                background: "#efefef",
              }}
            >
              <p className="parts__text">
                {parseFloatNumber(part.advance_percentage * 100)}
                {" %"}
              </p>
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "1.5rem",
              backgroundColor: "#EFEFEF",
            }}
          />
        )}

        {showAccumulatedBlock ? (
          <>
            {" "}
            <div
              className="parts__content"
              style={{ width: "72px", background: "#efefef" }}
            >
              <p className="parts__text">
                {part.part.has_children
                  ? "-"
                  : parseFloatNumber(part.acumulated_quantity)}
              </p>
            </div>
            <div
              className="parts__content"
              style={{ width: "82px", background: "#efefef" }}
            >
              <p className="parts__text">
                {parseFloatNumber(part.acumulated_mount)}
              </p>
            </div>
            <div
              className="parts__content"
              style={{
                width: "65px",
                borderRight: "solid 2px #f3f3f3",
                background: "#efefef",
              }}
            >
              <p className="parts__text">
                {parseFloatNumber(part.acumulated_advance_percentage * 100)}
                {" %"}
              </p>
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "1.5rem",
              backgroundColor: "#EFEFEF",
            }}
          />
        )}

        {showToValBlock ? (
          <>
            {" "}
            <div
              className="parts__content"
              style={{ width: "72px", background: "#efefef" }}
            >
              <p className="parts__text">
                {part.part.has_children
                  ? "-"
                  : parseFloatNumber(part.residue_quantity)}
              </p>
            </div>
            <div
              className="parts__content"
              style={{ width: "82px", background: "#efefef" }}
            >
              <p className="parts__text">
                {parseFloatNumber(part.residue_mount)}
              </p>
            </div>
            <div
              className="parts__content"
              style={{ width: "65px", background: "#efefef" }}
            >
              <p className="parts__text">
                {parseFloatNumber(part.residue_advance_percentage * 100)}
                {" %"}
              </p>
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "1.5rem",
              backgroundColor: "#EFEFEF",
            }}
          />
        )}
      </div>
      <div id={fileGroup + "_PART_GP_" + group_id}>{parts_children}</div>
    </React.Fragment>
  );
};
