import React from "react";
import { IconContext } from "react-icons";

import { BellIcon } from "./styles";

export default function Bell({ whiteMode = false }) {
  return (
    <IconContext.Provider value={{ color: whiteMode ? "#004283" : "white" }}>
      <BellIcon size={18} />
    </IconContext.Provider>
  );
}
