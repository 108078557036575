import React from "react";
import { ValuationCard } from "../../ValuationCard";
import { InfoProyectContainer } from "./styles";
import ProjectImagePanel from "../../components/ProjectImagePanel";
import { parseFloatNumber } from "../../../../../../../utils";

const ProjectInfoPanel = ({
  datetoday,
  history,
  project,
  status_text,
  image,
}) => {
  const getStatusLabels = () => {
    let label = "";
    let color = "#28A745";
    if (project?.status < 0) {
      color = "#DC3545";
      label = "Retrasada";
      if (project?.status < -10) {
        label = "Muy Retrasada";
      }
    } else if (project?.status > 2) {
      label = "Avanzada";
      if (project?.status > 10) {
        label = "Muy Avanzada";
      }
    } else {
      label = "En Tiempo";
    }
    return { label, color };
  };

  return (
    <InfoProyectContainer>
      <ValuationCard width="170px" background="#f0f4f9">
        <div className="projectInfo__contentDiv">
          <p className="grey font14">HOY:</p>
          <p className="grey font14 bold">{datetoday}</p>
        </div>
        <div className="projectInfo__contentDiv">
          <p className="grey font14 bold">
            {`${parseFloatNumber(project?.percentage)}%`}
          </p>
          <p className="grey font14">Avance</p>
        </div>
        <div className="projectInfo__contentDiv">
          <p className="font14 bold" style={{ color: getStatusLabels().color }}>
            {`${getStatusLabels().label} ${parseFloatNumber(
              Math.abs(project?.status)
            )}%`}
          </p>
        </div>
        <div className="projectInfo__contentDiv">
          <p className="grey font14 bold">{project?.num_valorizations}</p>
          <p className="grey font14">Valorizaciones</p>
        </div>
      </ValuationCard>
      <ProjectImagePanel history={history} project={project} image={image} />
    </InfoProyectContainer>
  );
};
export default ProjectInfoPanel;
