import React from "react";
import styled from "styled-components";

const Container = styled.div`
  max-width: ${(props) => props.width || "483px"};
  width: 100%;
  height: 140px;
  background: ${(props) => props.background || "#ffffff"};
  border-radius: 4px;
  padding: 10px 15px;
  @media (max-width: 500px) {
    height: auto;
  }
`;

export const ValuationCard = ({ width, background, title, children }) => {
  return (
    <Container width={width} background={background}>
      <p className="projectVal__valCard-title">{title}</p>
      {children}
    </Container>
  );
};
