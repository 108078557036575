import React from "react";
import "./styles.css";

import Headers from "./components/Headers";
import Item from "./components/Item";
import AddPayment from "./components/AddPayment";

const PaymentsTable = ({
  withValidation,
  user,
  setPayment,
  payments,
  paymentReload,
  setShowReject,
  openPaymentSummary,
  showAddPayment
}) => {
  //TODO: implementar búsqueda de pagos

  return (
    <div className="payment_table_container">
      {payments.length !== 0 && <Headers withValidation={withValidation} />}
      {payments.map((payment) => (
        <Item
          payment={payment}
          withValidation={withValidation}
          user={user}
          paymentReload={paymentReload}
          setShowReject={setShowReject}
          onClickDetails={()=>{openPaymentSummary(payment.id)}}
        />
      ))}
      <br />
      {!withValidation && showAddPayment && <AddPayment setPayment={setPayment} />}
    </div>
  );
};

export default PaymentsTable;
