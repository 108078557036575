import styled from "styled-components";

export const Container = styled.div`
  width: 116px;
  height: ${(props) => props.height || "26px"};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid ${(props) => props.borderColor || "#05be8c"};
  box-sizing: border-box;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  text-align: center;
  opacity: ${(props) => (props.disabled ? "0.4" : "")};
  cursor: pointer;
  .signature {
    width: 20px;
    height: 10px;
    margin-top: -3px;
    margin-left: -8px;
  }
  .send {
    width: 10px;
    height: 10px;
    margin-top: -10px;
    margin-left: -5px;
  }
`;

export const Text = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
  margin-right: 5px;
  color: ${(props) => props.color || "#05be8c"};
`;

export const Icon = styled.img`
  width: 13px;
  height: 13px;
`;
