import styled from "styled-components";

export const SidebarMenuContainer = styled.div`
  margin: 0 !important;
  padding: 0 !important;
  background-color: #054381;
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-left: 0px;
  margin-left: 0px;
`;

export const SidebarBottomMenuContainer = styled.div`
  margin: 0 !important;
  padding: 0 !important;
  width: 60px;
  text-align: center;
  padding-left: 0px;
  margin-left: 0px;
`;

export const SidebarOption = styled.div`
  /*   ${(props) =>
    props.isActive &&
    `
    background-color: #7aa0c6;
    `} */
  height: 60px;
`;

export const SidebarMenuLabel = styled.p`
  color: white;
  font-size: 8.5px;
  margin-bottom: 0px;
`;
