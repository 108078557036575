import styled from "styled-components";

export const ProjectContainer = styled.div`
    margin: 0 !important;
    padding: 0 !important;
    display: flex;
    flex-direction: row;
    width: 550px;
    height: auto;
    background: #7F7F7F;
`;

export const ProjectImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 60%;
    height: auto;
    text-align: right;
    color: #000000;
    font-family: "Mulish";
    font-weight:700;
    font-size: 14px;
    margin: 0.5rem 0rem 1rem 1.5rem;
`;

export const ImageContainer = styled.div`
    position: relative;
    display: inline-block;
    background-image: url(${(props) => props.imageUrl});
    background-size: 100% 100%;
    width: 90%;
    height: 200px;
    margin-right: 10px;
    @media (min-width: 1258px) and (max-width: 1432px) {
    }
    @media (max-width: 823px) {
    }
`;

export const ImageConfigProject = styled.img`
    position: absolute;
    top: 10%;
    left: 92%;
    width:30px;
    transform: translate(-50%, -50%);
    cursor: pointer;
    @media (min-width: 1258px) and (max-width: 1432px) {
    }
    @media (max-width: 823px) {
    }
`;

export const ButtonConfigProject = styled.button`
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    @media (min-width: 1258px) and (max-width: 1432px) {
    }
    @media (max-width: 823px) {
    }
`;

export const ProjectData = styled.div`
    width: 100%;
    height: auto;
    align-items: flex-start;
    text-align: left;
    color: #000000;
    font-family: "Mulish";
    font-weight:700;
    font-size: 14px;
    margin: 0.5rem 0rem 0.5rem 0rem;
`;


export const ProjectInfoContainer = styled.div`
    width: 40%;
    height: auto;
    display:flex;
    flex-direction:column;
    justify-content:space-around;
    text-align: left;
    color: #000000;
    font-family: "Mulish";
    font-weight:700;
    font-size: 14px;
    margin: 1.5rem 0rem 0.5rem 1rem;
`;

export const ProjectConfig = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    margin: 0.5rem 0rem 0.5rem 0rem;
`;


export const ProjectConfigContainer = styled.div`
    width: 100%;
    height: auto;
    display:flex;
    flex-direction: column;
    align-items: center;
    color: #000000;
    font-family: "Mulish";
    font-weight:700;
    font-size: 14px;
    text-decoration: none;
`;

export const ProjectButtonContainer = styled.div`
    width: 100%;
    height: auto;
    display:flex;
    flex-direction: row;
    justify-content:space-between;
`;

export const ProjectButtomColumn = styled.div`
    width: 50%;
    height: auto;
    display:flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-family: "Mulish";
    font-weight:normal;
    font-size: 14px;
    flex-direction: column;
    margin-bottom: 2rem;
`;


export const ProjectButton = styled.div`
    display: flex;
    background-color: #00c187;
    cursor: pointer;
    text-align: center;
    color: white;
    width: 90px;
    height: 28px;
    border-radius: 12px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 0rem;
`;
