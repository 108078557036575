import styled from "styled-components";
import { Modal as BaseModal } from "antd";

export const Modal = styled(BaseModal)`
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-header {
    padding: 10px 24px !important;
  }
`;

export const ModalTitle = styled.p`
  font-family: Mulish;
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px;
  line-height: 22px !important;
  color: #014083 !important;
  margin-bottom: 0rem;
`;

export const TableContainer = styled.div`
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 16px;
`;

export const Indication = styled.p`
  margin-left: 16px;
  margin-top: 16px;
  font-weight: 500;
`;

export const AddHiddenViceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;