import React from "react";
import Item from "./Item";
import ChargeFileButton from "./ChargeFileButton";
import FileList from "./FileList";
import { parseFloatNumber } from "../../../utils";
import { Input, DatePicker } from "antd";
import { clientDateFormat, serverDateFormat } from "../../../utils";
import dayjs from "dayjs";
import moment from "moment";

export const DebtCard = ({
  debt,
  setDebt,
  handleDebtUpdate,
  user,
  files,
  getDebtDocument,
  chargeDebtDocument,
  deleteDebtDocument,
}) => {
  const { TextArea } = Input;

  const changeDebtValue = (key, value) => {
    const settedDebt = { ...debt };
    settedDebt[key] = value;
    setDebt(settedDebt);
  };

  const enableEdition =
    user === "Ejecutor" &&
    (debt.status === "NO_STARTED" || debt.status === "REJECTED");

  return (
    <div className="projectVal__card__container">
      <div style={{ width: "90%" }}>
        <div className="projectVal__card__items">
          <Item
            title="Monto facturable (inc. IGV)"
            content={"S/." + parseFloatNumber(debt.net_amount)}
            width="167px"
            disabled={true}
          />
          <Item
            title="Retención (inc. IGV)"
            content={"S/." + parseFloatNumber(debt.discount_amount)}
            width="167px"
            disabled={true}
          />
        </div>
        <div className="projectVal__card__items">
          <Item
            title="Monto a pagar (inc. IGV)"
            content={"S/." + parseFloatNumber(debt.amount)}
            width="167px"
            disabled={true}
          />
        </div>

        <p
          className="blue bold projectVal__mb1"
          style={{ marginTop: "10px", fontSize: "16px" }}
        >
          Registro de factura
        </p>

        <div className="projectVal__card__items">
          <Item
            title="Serie"
            content={debt.invoice_series}
            width="167px"
            onChange={(value) => changeDebtValue("invoice_series", value)}
            onBlur={() => handleDebtUpdate()}
            disabled={!enableEdition}
          />
          <Item
            title="Número"
            content={debt.invoice_number}
            width="167px"
            onChange={(value) => changeDebtValue("invoice_number", value)}
            onBlur={() => handleDebtUpdate()}
            disabled={!enableEdition}
          />
        </div>
        <div className="projectVal__card__items">
          <div className="debt_item">
            <p className="blue bold font14 projectVal__mb1">Fecha ingreso</p>
            <DatePicker
              value={dayjs(debt.date)}
              onChange={(value) =>
                changeDebtValue(
                  "date",
                  moment(value.toDate()).format(serverDateFormat)
                )
              }
              onBlur={() => handleDebtUpdate()}
              format={clientDateFormat}
              disabled={!enableEdition}
              style={{ width: "167px" }}
              className="editProject__mb2"
            />{" "}
          </div>
        </div>
        <div className="projectVal__card__items">
          <ChargeFileButton chargeDebtDocument={chargeDebtDocument} />
          <FileList
            files={files}
            getDebtDocument={getDebtDocument}
            deleteDebtDocument={deleteDebtDocument}
          />
        </div>
        {debt.status === "REJECTED" && (
          <>
            <p className="blue bold font14 projectVal__mb1">
              Comentarios de rechazo:
            </p>
            <TextArea
              value={debt.rejected_comments}
              onFocus={(event) => event.target.select()}
              rows={4}
              className="projectVal__mb1"
              placeholder="Indique porque rechaza la valorización del proyecto"
              disabled={true}
            />
          </>
        )}
      </div>
    </div>
  );
};
