import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { map, prop } from "ramda";

import PublicLayout from "../layouts/PublicLayout";

const PublicRouter = ({ modules }) => {
  return (
    <PublicLayout>
      <Switch>
        {map(
          (module) => (
            <Route
              path={prop("pathBase", module)}
              key={prop("pathBase", module)}
              component={() => <module.moduleRouter />}
            />
          ),
          modules
        )}
        <Route path="*" render={() => <Redirect to="/login" />} />
      </Switch>
    </PublicLayout>
  );
};

export default PublicRouter;
