import React, { useState, useEffect } from "react";
import { Modal } from "antd";

import {
  getCalendarTypes,
  getCalendarOptions,
  getCalendarDays,
} from "../../../providers";

import { ValorizationProgrammingModal } from "../../ValorizationProgrammingModal/ValorizationProgrammingModal";
import { Valuation1 } from "./Valuation1";
import { Valuation2 } from "./Valuation2";

import "./ValuationSchedule.css";

export const ValuationSchedule = (props) => {
  const [calendarTypes, setCalendarTypes] = useState([]);
  const [calendarOptions, setCalendarOptions] = useState([]);
  const [valCalendarOptions, setValCalendarOptions] = useState([]);
  const [calendarDays, setCalendarDays] = useState([]);

  // Plazo contractual
  const [initial_date, setInitial_date] = useState(props.initial_date);
  const [num_seasons, setNum_seasons] = useState(props.num_seasons);
  const [calendar_type, setCalendar_type] = useState(props.calendar_type);
  const [calendar_option, setCalendar_option] = useState(props.calendar_option);

  // Duración del periodo valorizaciones
  const [valorization_generator_option, setValorization_generator_option] =
    useState(props.valorization_generator_option);
  const [valorization_num_seasons, setValorization_num_seasons] = useState(
    props.valorization_num_seasons
  );
  const [valorization_calendar_option, setValorization_calendar_option] =
    useState(props.valorization_calendar_option);
  const [valorization_calendar_type, setValorization_calendar_type] = useState(
    props.valorization_calendar_type
  );
  const [valorization_week_day, setValorization_week_day] = useState(
    props.valorization_week_day
  );

  // En caso la valorización no sea enviada por el ejecutor
  const [valorization_no_sended_option, setValorization_no_sended_option] =
    useState(props.valorization_no_sended_option);

  // Tiempo en días de ingreso y validación de metrado. A contar después de la fecha de corte
  const [meter_entered_days, setMeter_entered_days] = useState(
    props.meter_entered_days
  );
  const [meter_validation_days, setMeter_validation_days] = useState(
    props.meter_validation_days
  );
  const [conflicts_resolution_days, setConflicts_resolution_days] = useState(
    props.conflicts_resolution_days
  );

  const [valorization_close_calendar_type, setValorization_close_calendar_type] = useState(
    props.valorization_close_calendar_type
  );

  const [valorization_firm_calendar_type, setValorization_firm_calendar_type] = useState(
    props.valorization_firm_calendar_type
  );

  //Firma de valorización
  const [valorization_firm_days, setValorization_firm_days] = useState(
    props.valorization_firm_days
  );

  useEffect(() => {
    setMeter_entered_days(props.meter_entered_days);
    setMeter_validation_days(props.meter_validation_days);
    setConflicts_resolution_days(props.conflicts_resolution_days);
    setValorization_firm_days(props.valorization_firm_days);
  }, [props]);

  useEffect(() => {
    const fetchCalendarTypes = async () => {
      try {
        const _calendarTypes = await getCalendarTypes();
        setCalendarTypes(_calendarTypes.results);
        const _calendarDays = await getCalendarDays();
        setCalendarDays(_calendarDays.results);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCalendarTypes();
  }, []);

  const fetchCalendarOptions = async (setData, condition) => {
    try {
      const _calendarOptions = await getCalendarOptions({
        calendar_type: condition,
      });
      setData(_calendarOptions.results);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCalendarOptions(setCalendarOptions, calendar_type);
  }, [calendar_type]);

  useEffect(() => {
    fetchCalendarOptions(setValCalendarOptions, valorization_calendar_type);
  }, [valorization_calendar_type]);

  const radioStyle = {
    height: "auto",
    lineHeight: "30px",
    fontSize: "15px",
    whiteSpace: "wrap",
  };

  return (
    <>
      <div className="ramp editProject__valSched editProject__valSched__container">
        <Valuation1
          calendarTypes={calendarTypes}
          calendarOptions={calendarOptions}
          calendarDays={calendarDays}
          initial_date={initial_date}
          setInitial_date={setInitial_date}
          num_seasons={num_seasons}
          setNum_seasons={setNum_seasons}
          calendar_type={calendar_type}
          setCalendar_type={setCalendar_type}
          calendar_option={calendar_option}
          setCalendar_option={setCalendar_option}
          final_date={props.final_date}
          valCalendarOptions={valCalendarOptions}
          valorization_generator_option={valorization_generator_option}
          setValorization_generator_option={setValorization_generator_option}
          valorization_num_seasons={valorization_num_seasons}
          setValorization_num_seasons={setValorization_num_seasons}
          valorization_calendar_option={valorization_calendar_option}
          setValorization_calendar_option={setValorization_calendar_option}
          valorization_calendar_type={valorization_calendar_type}
          setValorization_calendar_type={setValorization_calendar_type}
          valorization_week_day={valorization_week_day}
          setValorization_week_day={setValorization_week_day}
          radioStyle={radioStyle}
          updateProject={props.updateProject}
          generateValuationSchedule={props.generateValuationSchedule}
          exist_valorizations_program={props.exist_valorizations_program}
          setModal={props.setModal}
          settoggleUpdatePrevalorizations={
            props.settoggleUpdatePrevalorizations
          }
          general_aproved={props.general_aproved}
          role={props.role}
        />
        <Valuation2
          valorization_no_sended_option={valorization_no_sended_option}
          setValorization_no_sended_option={setValorization_no_sended_option}
          meter_entered_days={meter_entered_days}
          setMeter_entered_days={setMeter_entered_days}
          meter_validation_days={meter_validation_days}
          setMeter_validation_days={setMeter_validation_days}
          conflicts_resolution_days={conflicts_resolution_days}
          setConflicts_resolution_days={setConflicts_resolution_days}
          valorization_firm_days={valorization_firm_days}
          setValorization_firm_days={setValorization_firm_days}
          radioStyle={radioStyle}
          updateProject={props.updateProject}
          exist_valorizations_program={props.exist_valorizations_program}
          general_aproved={props.general_aproved}
          valorization_close_calendar_type={valorization_close_calendar_type}
          setValorization_close_calendar_type={setValorization_close_calendar_type}
          valorization_firm_calendar_type={valorization_firm_calendar_type}
          setValorization_firm_calendar_type={setValorization_firm_calendar_type}
          calendarTypes={calendarTypes}
          role={props.role}
        />
      </div>
      <Modal
        title="Programación de valorización"
        centered
        open={props.modal}
        onCancel={() => props.setModal(false)}
        footer={null}
        width={1200}
      >
        <ValorizationProgrammingModal
          setModal={props.setModal}
          {...props}
          toggleUpdatePrevalorizations={props.toggleUpdatePrevalorizations}
          settoggleUpdatePrevalorizations={
            props.settoggleUpdatePrevalorizations
          }
        />
      </Modal>
    </>
  );
};
