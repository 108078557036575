import styled from "styled-components";

export const Button = styled.button`
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "32px"};
  padding: ${(props) => props.padding || "auto"};
  margin: ${(props) => props.margin || 0};
  background: ${(props) => props.background || "#007BFF"};
  font-family: Mulish;
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || "normal"};
  font-size: ${(props) => props.fontSize || "14px"};
  line-height: ${(props) => props.lineHeigt || "22px"};
  color: ${(props) => props.color || "#ffffff"};
  border: ${(props) => props.border || "none"};
  border-radius: ${(props) => props.borderRadius || "4px"};
  box-shadow: ${(props) => props.boxShadow || "none"};
`;
